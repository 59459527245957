<template>
  <v-row>
    <v-dialog v-model="noteEdit.dialog" width="800px">
      <v-card>
        <v-card-title class="bg-indigo"
          >Notiz
          {{
            noteEdit.noteObject.id ? "bearbeiten" : "erstellen"
          }}</v-card-title
        >
        <v-card-text>
          <v-select
            v-model="noteEdit.noteObject.type"
            :items="types"
            item-text="title"
            item-value="value"
            label="Typ"
          ></v-select>
          <v-text-field v-model="noteEdit.noteObject.name" label="Name">
          </v-text-field>
          <v-text-field v-model="noteEdit.noteObject.action" label="Aktion">
          </v-text-field>

          <v-card>
            <QuillEditor
              toolbar="essential"
              v-model:content="noteEdit.noteObject.text"
              contentType="html"
              placeholder="Text"
            />
          </v-card>
          <v-btn-toggle v-model="noteEdit.noteObject.is_positive" class="mt-4">
            <v-btn value="true" color="green">Positiv</v-btn>
            <v-btn value="false" color="error">Negativ</v-btn>
            <v-btn value="neutral">Neutral</v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="noteEdit.dialog = false">Abbrechen</v-btn>
          <v-btn @click="saveNote" color="primary">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      class="ma-4"
      @click="
        noteEdit.noteObject = {
          id: null,
          type: null,
          text: null,
          is_positive: null,
        };
        noteEdit.dialog = true;
      "
      append-icon="mdi-plus"
      color="success"
      >Neue Notiz</v-btn
    >
    <v-data-table
      :headers="headers"
      :items="noteTemplates"
      :loading="loading"
      item-key="id"
      class="elevation-1 ma-4"
    >
      <template v-slot:item.type="{ value }">
        {{ typeObject[value] }}
      </template>
      <template v-slot:item.text="{ value }">
        <div v-html="value"></div>
      </template>
      <template v-slot:item.is_positive="{ value }">
        <v-chip v-if="value === null" variant="elevated">Neutral</v-chip>
        <v-chip v-if="value === true" color="success" variant="elevated"
          >Positiv</v-chip
        >
        <v-chip v-if="value === false" color="error" variant="elevated"
          >Negativ</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          :loading="loading"
          icon
          @click="
            noteEdit.noteObject = item;
            noteEdit.dialog = true;
          "
          size="x-small"
          flat
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteItem(item)" size="x-small" flat>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-row>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
</script>

<script>
export default {
  data() {
    return {
      loading: false,
      types: [
        { title: "Mitarbeiter", value: "employees" },
        { title: "Bewerber", value: "candidates" },
        { title: "Hotel", value: "hotels" },
      ],
      typeObject: {
        employees: "Mitarbeiter",
        candidates: "Bewerber",
        hotels: "Hotel",
      },
      noteEdit: {
        dialog: false,
        noteObject: {
          id: null,
          type: null,
          text: null,
          is_positive: null,
        },
      },
      noteTemplates: [],
      headers: [
        { title: "Name", value: "name" },
        { title: "Aktion", value: "action" },
        { title: "Text", value: "text" },
        { title: "Typ", value: "type" },
        { title: "Bewertung", value: "is_positive" },
        { title: "Aktionen", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async getNoteTemplates() {
      this.loading = true;
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "note_templates",
        order: { key: "id", ascending: true },
      });
      this.noteTemplates = response.data;
      this.loading = false;
    },
    async saveNote() {
      this.loading = true;
      if (this.noteEdit.noteObject.is_positive == "neutral") {
        this.noteEdit.noteObject.is_positive = null;
      }
      if (this.noteEdit.noteObject.id) {
        await this.$store.dispatch("updateSupabaseEntity", {
          table: "note_templates",
          id: this.noteEdit.noteObject.id,
          payload: this.noteEdit.noteObject,
        });
      } else {
        await this.$store.dispatch("createSupabaseEntity", {
          table: "note_templates",
          payload: this.noteEdit.noteObject,
        });
      }
      this.getNoteTemplates();
      this.noteEdit.dialog = false;
      this.loading = false;
    },
    async deleteItem(item) {
      this.loading = true;
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "note_templates",
        id: item.id,
      });
      this.getNoteTemplates();
      this.loading = false;
    },
  },
  async mounted() {
    this.getNoteTemplates();
  },
};
</script>
