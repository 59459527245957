<template>
  <v-row class="ma-2">
    <v-col cols="6" align="left">
      <v-card variant="tonal" color="blue-darken-4" rounded="xl" elevation="4">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="text-h6">Regelmäßige Schichttage</div>
            </v-col>
            <v-col cols="12">
              <template v-for="day in shiftDays">
                {{ day.short }}
                <v-icon
                  :color="day.checked ? 'green-darken-2' : null"
                  size="40px"
                  class="mr-3"
                  @click="day.checked = !day.checked"
                  :icon="
                    day.checked
                      ? 'mdi-checkbox-marked'
                      : 'mdi-checkbox-blank-outline'
                  "
                >
                </v-icon>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loading" @click="saveShiftDates()">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card rounded="xl" elevation="4">
        <v-card-title>Kommentar</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="employeeInfo.availability_note"
            variant="outlined"
          ></v-text-field>
          <v-btn :loading="loading" @click="saveAvailabilityNote()"
            >Speichern</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <EmployeeDialog
        :employee="{ data: { employee_id: employeeInfo.id } }"
      ></EmployeeDialog>
    </v-col>
  </v-row>
</template>

<script setup>
import EmployeeDialog from "@/components/Shifts/EmployeeDialog.vue";

const props = defineProps({ employeeInfo: { type: Object, required: true } });
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      calendar: {
        events: [],
      },
      employeeDialog: {
        data: false,
      },
      shiftDays: [
        { value: 1, checked: false, short: "Mo" },
        { value: 2, checked: false, short: "Di" },
        { value: 3, checked: false, short: "Mi" },
        { value: 4, checked: false, short: "Do" },
        { value: 5, checked: false, short: "Fr" },
        { value: 6, checked: false, short: "Sa" },
        { value: 0, checked: false, short: "So" },
      ],
    };
  },
  methods: {
    async getAvailableDays() {
      this.employeeInfo.shift_days?.forEach((day) => {
        this.shiftDays.forEach((item) => {
          if (item.value == day) {
            item.checked = true;
          }
        });
      });
    },
    async getCompanyDays() {
      var dateTypes = {
        holiday_block: "Urlaubssperre",
      };
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "company_dates",
      });
      response.data.forEach((item) => {
        var object = {
          key: item.title,
          dates: new Date(item.date),
          highlight: "red",
          popover: {
            label: dateTypes[item.type],
          },
        };
        this.calendar.events.push(object);
      });
    },

    async saveAvailabilityNote() {
      this.loading = true;
      await this.$store.dispatch("updateSupabaseEntity", {
        table: "employees",
        id: this.employeeInfo.id,
        payload: { availability_note: this.employeeInfo.availability_note },
      });
      this.loading = false;
    },

    async saveShiftDates(event) {
      this.loading = true;
      var shift_days = [];
      this.shiftDays.forEach((day) => {
        if (day.checked) {
          shift_days.push(day.value);
        }
      });
      var response = await this.$store.dispatch("updateSupabaseEntity", {
        table: "employees",
        id: this.employeeInfo.id,
        payload: { shift_days: shift_days },
      });
      this.loading = false;
    },
  },
  mounted() {
    this.getAvailableDays();
    this.getCompanyDays();
  },
};
</script>
