<template>
  <div>
    <v-row class="ma-2 pa-2">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text>
            <v-row class="mb-3">
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  <v-chip class="mb-1" color="indigo-darken-4">{{
                    "KW " + getISOWeek(startDate)
                  }}</v-chip>
                  Schichtplan für Woche
                  {{
                    startDate.toLocaleDateString() +
                    " - " +
                    endDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" align="left">
                <v-btn @click="sendEmailsToHotels()"
                  >Auftragsbestätigungen senden</v-btn
                >
              </v-col>
              <v-dialog v-model="sendEmailProcess.dialog" width="600px">
                <v-card>
                  <v-card-text>
                    <v-chip
                      size="x-small"
                      v-for="hotel in sendEmailProcess.hotels"
                      class="ma-1"
                      :prepend-icon="hotel.error ? 'mdi-alert' : null"
                      :append-icon="hotel.is_checked ? 'mdi-check' : null"
                      :color="
                        hotel.is_checked
                          ? 'success'
                          : hotel.error
                            ? 'error'
                            : null
                      "
                      variant="elevated"
                    >
                      {{ hotel.name }}
                    </v-chip>
                    <v-progress-linear
                      class="mt-4"
                      height="30"
                      :model-value="
                        (sendEmailProcess.sendHotelNumber * 100) /
                        sendEmailProcess.allHotelNumber
                      "
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-col
                cols="4"
                v-for="city in hotelCities"
                :key="city.id"
                class="ma-n2"
              >
                <v-card elevation="10">
                  <v-card-title>
                    {{ city.city }}
                  </v-card-title>
                  <v-card-text>
                    <v-btn
                      size="small"
                      rounded="lg"
                      :color="getButtonColor(hotel)"
                      v-for="hotel in city.hotels"
                      @click="
                        selectedHotel = hotel.id;
                        isSenior = true;
                        loadHotel(hotel);
                      "
                      :key="hotel.id"
                      :append-icon="hotel.progress == 100 ? 'mdi-check' : null"
                    >
                      {{ hotel.short_name }}
                      {{
                        hotel.completeShifts ? hotel.completeShifts.length : 0
                      }}
                      / {{ hotel.shiftDays }}
                      <span v-if="false && hotel.progress != 100"
                        >{{ hotel.progress }}%</span
                      >
                      <v-chip
                        class="ml-1"
                        color="blue-grey"
                        variant="elevated"
                        size="x-small"
                        prepend-icon="mdi-school"
                        v-if="
                          hotel.trainingShifts &&
                          hotel.trainingShifts.length > 0
                        "
                        >{{ hotel.trainingShifts.length }}</v-chip
                      >
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <template v-if="hotelObject.id">
              <v-row>
                <v-col class="mb-n4">
                  <v-btn
                    :color="isSenior ? 'primary' : null"
                    @click="
                      isSenior = true;
                      loadHotel();
                    "
                    >Senior</v-btn
                  >
                  <v-btn
                    :color="isSenior ? null : 'primary'"
                    v-if="hotelObject.bonus_points_clerk"
                    @click="
                      isSenior = false;
                      loadHotel();
                    "
                    >Clerk</v-btn
                  >
                  <v-btn
                    :color="isTraining ? 'primary' : null"
                    @click="
                      isTraining = !isTraining;
                      loadHotel();
                    "
                    >Einarbeitung
                    <v-chip class="ml-1" size="x-small" color="green">{{
                      chosenHotel.trainingShifts
                        ? chosenHotel.trainingShifts.length
                        : 0
                    }}</v-chip></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-dialog v-model="newEmployeeMatch.dialog" width="700px">
                  <v-card>
                    <v-card-title>Mitarbeitermatching</v-card-title>
                    <v-card-text>
                      <v-card class="ma-6">
                        <v-card-title>Gematchte Mitarbeiter</v-card-title>
                        <v-card-text>
                          <template
                            v-for="employee in hotelObject.employees_hotels"
                          >
                            <v-chip
                              :color="
                                employee.is_blocked
                                  ? 'error'
                                  : employee.is_senior
                                    ? 'success'
                                    : 'warning'
                              "
                              @click="
                                newEmployeeMatch.data.employee_id =
                                  employee.employees.id;
                                checkLevels();
                              "
                              class="ma-1"
                              size="small"
                              v-if="employee.employees"
                              variant="elevated"
                            >
                              {{ employee.employees.name }}
                            </v-chip>
                          </template>
                          <v-chip
                            @click="
                              newEmployeeMatch.data.employee_id = null;
                              newEmployeeMatch.newEmployee = true;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-chip>
                        </v-card-text>
                      </v-card>

                      <v-autocomplete
                        v-if="
                          newEmployeeMatch.data.employee_id ||
                          newEmployeeMatch.newEmployee
                        "
                        :items="allEmployees"
                        item-title="name"
                        item-value="id"
                        label="Mitarbeiter"
                        v-model="newEmployeeMatch.data.employee_id"
                      ></v-autocomplete>
                      <div v-if="newEmployeeMatch.data.employee_id">
                        <v-btn
                          :color="
                            newEmployeeMatch.data.is_senior ? 'success' : null
                          "
                          @click="
                            newEmployeeMatch.data.is_senior =
                              !newEmployeeMatch.data.is_senior
                          "
                          >Senior</v-btn
                        >
                        <v-btn
                          :color="
                            newEmployeeMatch.data.is_clerk ? 'success' : null
                          "
                          @click="
                            newEmployeeMatch.data.is_clerk =
                              !newEmployeeMatch.data.is_clerk
                          "
                          >Clerk</v-btn
                        >
                        <v-btn
                          :color="
                            newEmployeeMatch.data.is_blocked ? 'error' : null
                          "
                          @click="
                            newEmployeeMatch.data.is_blocked =
                              !newEmployeeMatch.data.is_blocked
                          "
                          >Blockiert</v-btn
                        >
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="saveNewLevel()"
                        :loading="newEmployeeMatch.loading"
                        >Speichern</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-col
                  cols="4"
                  class="mt-5"
                  v-if="sheetCheck.loading || sheetCheck.link"
                >
                  <v-card variant="outlined">
                    <v-card-title>Aktionen</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-btn @click="sendEmailsToHotels(selectedHotel)"
                          >Auftragsbestätigung senden (TESTING)</v-btn
                        >
                        <v-col cols="12">
                          <v-progress-linear
                            indeterminate
                            v-if="sheetCheck.loading"
                          ></v-progress-linear>
                          <a
                            :href="sheetCheck.link"
                            target="_blank"
                            v-if="sheetCheck.link"
                            >Link zum Sheet</a
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="9">
                  <v-data-table-virtual
                    density="compact"
                    :headers="shiftPlan.headers"
                    :items="shiftPlan.shifts"
                  >
                    <template #item.employee="{ item }">
                      <template v-if="isTraining || item.showAllEmployees">
                        <v-autocomplete
                          class="mt-2"
                          v-model="item.employee"
                          :items="allEmployees"
                          item-title="name"
                          returnObject
                          variant="outlined"
                          density="compact"
                          append-icon="mdi-delete"
                          @update:model-value="
                            (value) => {
                              if (value) {
                                item.employee = value;
                                item.employee.employee_id = value.id;
                                item.is_training = isTraining;
                                saveShift(item, value);
                              }
                            }
                          "
                          @click:append="
                            ((item.is_training = true),
                            saveShift(
                              item,
                              item.employee,
                              item.employee.id,
                              true,
                            ))
                          "
                        ></v-autocomplete>
                      </template>

                      <div class="my-2" v-if="!isTraining">
                        <v-icon
                          class="mr-2"
                          @click="item.showSelection = !item.showSelection"
                          :icon="
                            item.showSelection
                              ? 'mdi-arrow-up'
                              : 'mdi-arrow-down'
                          "
                        ></v-icon>
                        <template
                          v-for="emp in item.employees"
                          :key="emp.employee_id"
                        >
                          <v-btn
                            size="x-small"
                            :append-icon="getIcon(item, emp)"
                            :disabled="checkAvailability(item, emp)"
                            v-if="
                              (item.showSelection ||
                                item.employeeId == emp.employee_id) &&
                              emp.status != 'Inaktiv'
                            "
                            :color="getEmployeeColor(item, emp)"
                            @click="
                              item.employeeId = emp.employee_id;
                              saveShift(item, emp);
                            "
                            >{{ emp.employee_name
                            }}<v-rating
                              readonly
                              class="ml-1 mb-1"
                              density="compact"
                              size="x-small"
                              length="5"
                              v-model="emp.level"
                            ></v-rating
                          ></v-btn>
                        </template>
                        <v-btn color="primary" v-if="item.employeeId > 0 && !item.employees.find(employee => employee.employee_id == item.employeeId)" class="ml-2" size="x-small">
                            {{item.employee.name + " (Individuell)"}} 
                          </v-btn>
                        <v-btn v-if="item.showSelection && !isTraining" class="ml-2" @click="item.showAllEmployees = !item.showAllEmployees" :color="item.showAllEmployees ? 'primary' : 'grey'" size="x-small">
                            Alle Mitarbeiter
                          </v-btn>
                        <v-btn
                          @click="saveShift(item, null, item.employeeId)"
                          v-if="item.employeeId"
                          color="warning"
                          size="x-small"
                          >Entfernen</v-btn
                        >
                      </div>
                    </template>
                    <template #item.hotel_booking="{ item }">
                      <div class="my-2">
                        <v-btn
                          v-if="!item.is_on_demand && item.is_booked"
                          @click="
                            item.is_booked = true;
                            updateBooking(item);
                          "
                          size="x-small"
                          color="green"
                          disabled
                          >Reguläre Buchung</v-btn
                        >
                        <v-btn
                          v-if="!item.is_on_demand && item.is_booked == false"
                          @click="
                            item.is_booked = null;
                            updateBooking(item);
                          "
                          size="x-small"
                          >Reguläre Buchung</v-btn
                        >
                        <v-btn
                          v-if="(item.is_booked || item.is_booked == false) && !item.is_on_demand"
                          @click="
                            item.is_booked = false;
                            updateBooking(item);
                          "
                          size="x-small"
                          :color="item.is_booked == false ? 'red' : null"
                          >Keine Reguläre Buchung</v-btn>
                        <v-btn
                          v-if="!item.is_booked"
                          @click="
                            item.is_on_demand = !item.is_on_demand;
                            updateBooking(item);
                          "
                          size="x-small"
                          :color="item.is_on_demand ? 'green' : null"
                          >Abrufdienst</v-btn
                        >
                        <v-btn
                          v-if="item.is_booked || item.is_on_demand"
                          @click="
                            item.is_special_shift = !item.is_special_shift;
                            updateBooking(item);
                          "
                          size="x-small"
                          :color="item.is_special_shift ? 'green' : null"
                          >Sonderschicht</v-btn
                        >
                        <v-btn
                          v-if="item.is_booked || item.is_on_demand"
                          @click="
                            item.is_same_day_shift = !item.is_same_day_shift;
                            updateBooking(item);
                          "
                          size="x-small"
                          :color="item.is_same_day_shift ? 'green' : null"
                          >Same Day Schicht</v-btn
                        >
                      </div>
                    </template>
                  </v-data-table-virtual>
                </v-col>
                <v-col cols="3">
                  <v-card variant="outlined">
                    <v-card-title>Mitarbeiterliste</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col align="center" cols="12">
                          <v-btn
                            color="success"
                            @click="
                              newEmployeeMatch.newEmployee = false;
                              newEmployeeMatch.data.employee_id = null;
                              newEmployeeMatch.dialog = true;
                            "
                            size="small"
                            prepend-icon="mdi-plus"
                          >
                            Mitarbeiter hinzufügen/blockieren</v-btn
                          >
                        </v-col>
                      </v-row>
                      <template v-for="(employee, index) in employeeRecord">
                        <v-col cols="12" v-if="index < 10 || showAllEmployees">
                          <v-btn
                            style="text-decoration: underline; color: blue"
                            variant="text"
                            size="x-small"
                            @click="
                              employeeDialog.data = employee;
                              employeeDialog.dialog = true;
                            "
                            >{{ employee.employee_name }}</v-btn
                          >
                          <span>
                            <v-progress-circular
                              class="mr-1"
                              size="small"
                              color="indigo"
                              width="6"
                              :model-value="
                                ((employee.shifts.length * 8) /
                                  (employee.weekly_hours -
                                    employee.holidays.length * 8)) *
                                100
                              "
                            >
                            </v-progress-circular>
                            {{ employee.shifts.length * 8 }} <span>/</span
                            >{{
                              employee.weekly_hours -
                              employee.holidays.length * 8
                            }}</span
                          >
                          <span class="mx-2"
                            ><v-icon>mdi-home</v-icon
                            >{{ employee.free_days.length }}</span
                          >
                          <span
                            ><v-icon>mdi-beach</v-icon
                            >{{ employee.holidays.length }}</span
                          >
                          <v-chip
                            prepend-icon="mdi-alert-circle"
                            v-if="employee.availability_note"
                            size="x-small"
                            >{{ employee.availability_note }}</v-chip
                          >
                        </v-col>
                      </template>
                      <v-btn
                        size="small"
                        @click="showAllEmployees = !showAllEmployees"
                      >
                        <v-icon>{{
                          showAllEmployees ? "mdi-arrow-up" : "mdi-arrow-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="employeeDialog.dialog" width="1200px">
      <EmployeeDialog :employee="employeeDialog" :shift="true" />
    </v-dialog>
    <!-- Your template code goes here -->
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>{{ snackbar.data }}</div>
    <div>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
  <v-dialog v-model="snackbar.errorShow" width="600px" persistent>
    <v-card>
      <v-alert type="error">
        <v-card-title> Fehler </v-card-title>
        <v-card-text>
          {{ snackbar.error }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="snackbar.errorShow = false"> Close </v-btn>
        </v-card-actions>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script setup>
import EmployeeDialog from "./EmployeeDialog.vue";
import { getISOWeek } from "date-fns";
</script>
<script>
export default {
  name: "Shifts",
  props: {
    locationFilter: Array,
  },
  data() {
    return {
      shifts: [],
      allEmployees: [],
      employeeRecord: [],
      chosenHotel: {},
      hotelObject: {},
      hotelCities: [],
      snackbar: {
        show: false,
        color: "success",
        error: "TEST",
        errorShow: false,
        data: null,
        timeout: 4000,
      },
      hotels: [],
      selectedHotel: null,
      isSenior: true,
      isTraining: false,
      isClerk: false,
      today: new Date(),
      forwardDays: 6,
      employeeDialog: {
        dialog: false,
        data: null,
        loading: false,
      },
      sheetCheck: {
        loading: false,
        link: null,
      },
      newEmployeeMatch: {
        dialog: false,
        newEmployee: false,
        data: {
          employee_id: null,
          hotel_id: null,
          is_senior: false,
          is_clerk: false,
        },
      },
      backDays: 0,
      startDate: new Date(),
      endDate: new Date(),
      showAllEmployees: false,
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      endingEmployees: {},
      sendEmailProcess: {
        dialog: false,
        hotelName: null,
        allHotelNumber: null,
        sendHotelNumber: null,
        hotels: [],
      },
      shiftPlan: {
        month: null,
        year: null,
        days: null,
        headers: [
          {
            title: "Datum",
            align: "start",
            sortable: false,
            value: "date",
          },
          {
            title: "Hotelbuchung",
            align: "center",
            sortable: false,
            value: "hotel_booking",
          },
          {
            title: "Mitarbeiter",
            align: "start",
            sortable: false,
            value: "employee",
          },
        ],
        shifts: [],
      },
      // Your data properties go here
    };
  },
  methods: {
    getIcon(item, emp) {
      let day = new Date(item.dateDB).getDay();
      if (emp.shifts.find((shift) => shift == item.date)) {
        return "mdi-domain";
      } else if (emp.holidays.find((holiday) => holiday == item.date)) {
        return "mdi-beach";
      } else if (emp.free_days.find((freeDay) => freeDay == item.date)) {
        return "mdi-home";
      } else if (
        emp.is_out_of_company_days.find(
          (outOfOfficeCompanyDays) => outOfOfficeCompanyDays == item.date,
        )
      ) {
        return "mdi-account-off";
      } else if (emp.is_free_wish.find((freeDay) => freeDay == item.date)) {
        return "mdi-table-question";
      } else if (
        emp.is_office_shift.find((officeDay) => officeDay == item.date)
      ) {
        return "mdi-office-building";
      } else if (
        emp.shift_days.find((shiftDay) => shiftDay == day) == undefined
      ) {
        return "mdi-thumb-down";
      } else {
        return null;
      }
    },
    getEmployeeColor(item, emp) {
      let day = new Date(item.dateDB).getDay();
      if (item.employeeId == emp.employee_id) {
        return "primary";
      }
      if (
        emp.is_free_wish.find((freeDay) => freeDay == item.date) ||
        emp.shift_days.find((shiftDay) => shiftDay == day) == undefined
      ) {
        return "orange-lighten-2";
      }
    },
    checkAvailability(item, emp) {
      const alreadyOnShift = emp.shifts.find((shift) => shift == item.date);
      const onHoliday = emp.holidays.find((holiday) => holiday == item.date);
      const onFreeDay = emp.free_days.find((freeDay) => freeDay == item.date);
      const outOfCompanyDay = emp.is_out_of_company_days.find(
        (outOfCompanyDay) => outOfCompanyDay == item.date,
      );
      const onOfficeShift = emp.is_office_shift.find(
        (officeDay) => officeDay == item.date,
      );
      if (
        (alreadyOnShift ||
          onHoliday ||
          onFreeDay ||
          onOfficeShift ||
          outOfCompanyDay) &&
        item.employee != emp.employee_name
      ) {
        return true;
      } else {
        return false;
      }
    },
    async sendEmailsToHotels(hotelId) {
      this.sendEmailProcess.dialog = true;
      this.sendEmailProcess.hotels = [];
      this.sendEmailProcess.allHotelNumber = this.hotels.length;
      this.sendEmailProcess.sendHotelNumber = 0;
      for (var i = 0; i < this.hotels.length; i++) {
        this.sendEmailProcess.hotels.push({
          name: this.hotels[i].name,
          is_checked: false,
        });
        this.sendEmailProcess.hotelName = this.hotels[i].short_name;
        if (!hotelId || hotelId == this.hotels[i].id) {
          try {
            var url =
              "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=sendHotelEmails&secret_key=TEW567LPOVBN267";
            var options = {
              method: "POST",
              body: JSON.stringify({ hotelId: hotelId || this.hotels[i].id }),
            };
            var response = await fetch(url, options);
            response = await response.json();
            this.sendEmailProcess.hotels[i].is_checked = true;
          } catch (err) {
            this.sendEmailProcess.hotels[i].is_error = true;
            this.sendEmailProcess.hotels[i].error = err;
            console.log(err);
          }
        }
        this.sendEmailProcess.sendHotelNumber++;
      }
    },
    async saveShift(item, emp, oldEmpId, removeTraining) {
      console.log(item, emp, oldEmpId, removeTraining);
      const shiftObject = {
        hotel_id: this.hotelObject.id,
        date: item.dateDB,
        is_training: item.is_training ? true : false,
        is_senior: this.isSenior ? true : false,
        employee_id: emp ? emp.employee_id : null,
        is_same_day_shift: item.is_same_day_shift,
        is_special_shift: item.is_special_shift,
      };

      if (emp || oldEmpId) {
        let eventEmployeeId = null;
        if (emp && emp.employee_id) {
          eventEmployeeId = emp.employee_id;
        }
        else if (oldEmpId) {
          eventEmployeeId = oldEmpId;
        }
        if (removeTraining) {
          item.is_training = false;
        }
        await this.$store.dispatch("upsertSupabaseEntity",{
          table:"employee_date_events",
          payload: {
            employee_id: eventEmployeeId,
            date: item.dateDB,
            is_training: item.is_training ? true : false,
            hotel_id: this.hotelObject.id,
          },
        })
        await this.$store.dispatch("upsertSupabaseEntity",{
          table:"employee_date_event_matching",
          payload: {
            employee_id: eventEmployeeId,
            employee_date_event_id: 8,
            date: item.dateDB,
            hotel_id: this.hotelObject.id,
            is_activated: item.is_training ? true : false,
          }
        })
      }

      if (removeTraining) {
        shiftObject.employee_id = null;
      }

      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts",
        payload: shiftObject,
      });
      shiftObject.oldEmpId = oldEmpId;
      this.synchronizeWithSheets(shiftObject);
      this.loadHotel();
      this.getProgress();
    },
    async synchronizeWithSheets(shiftObject) {
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=updateShiftPlan&secret_key=TEW567LPOVBN267";
      var options = {
        method: "POST",
        body: JSON.stringify(shiftObject),
      };
      var response = await fetch(url, options);
      response = await response.json();
      var hotel = this.hotels.find(
        (hotel) => hotel.id == response.payload.hotel_id,
      );
      var date = new Date(response.payload.date).toLocaleDateString("de-DE");
      this.snackbar.data = hotel.short_name + " am " + date;
      if (response.error) {
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      } else {
        this.snackbar.color = "success";
        this.snackbar.timeout = 1000;
        this.snackbar.error = null;
        this.snackbar.show = true;
      }
    },
    getButtonColor(hotel) {
      if (hotel.id == this.selectedHotel) {
        return "primary";
      } else if (hotel.progress == 100) {
        return "success";
      } else {
        return null;
      }
    },
    async getProgress() {
      await this.getHotels();
      for (var i = 0; i < this.hotelCities.length; i++) {
        for (var j = 0; j < this.hotelCities[i].hotels.length; j++) {
          var hotel = this.hotelCities[i].hotels[j];
          var shiftDaysSenior = hotel.shift_days_senior
            ? hotel.shift_days_senior.length
            : 0;
          var shiftDaysClerk = hotel.shift_days_clerk
            ? hotel.shift_days_clerk.length
            : 0;
          var shiftDays = shiftDaysSenior + shiftDaysClerk;

          hotel.shifts.forEach((shift) => {
            var day = new Date(shift.date).getDay();

            if (
              shift.is_booked != false &&
              shift.is_senior &&
              hotel.shift_days_senior.find((check) => check == day) >= 0
            ) {
              shift.is_booked = true;
            } else if (
              shift.is_booked != false &&
              !shift.is_senior &&
              hotel.shift_days_clerk &&
              hotel.shift_days_clerk.find((check) => check == day) >= 0
            ) {
              shift.is_booked = true;
            }
          });

          var onDemandShifts = hotel.shifts.filter(
            (shift) => shift.is_on_demand && !shift.is_training,
          ).length;

          var removedShifts = hotel.shifts.filter(
            (shift) => shift.is_booked == false && !shift.is_on_demand && !shift.is_training,
          ).length;

          if (shiftDays == 0) {
            shiftDays = onDemandShifts
          }
          else {
            shiftDays = shiftDays + onDemandShifts - removedShifts;
          }

          if (shiftDays < 0) {
            shiftDays = 0;
          }

          var matched = 0;
          var toMatch = 0;
          this.hotelCities[i].hotels[j].completeShifts = [];
          this.hotelCities[i].hotels[j].trainingShifts = [];
          this.hotelCities[i].hotels[j].shiftDays = shiftDays;
          if (shiftDays > 0) {
            var completeShifts = hotel.shifts.filter(
              (shift) =>
                shift.employee_id && (shift.is_booked || shift.is_on_demand) && !shift.is_training,
            );
            var trainingShifts = hotel.shifts.filter(
              (shift) => shift.is_training && shift.employee_id > 0,
            );
            var result = completeShifts.length / shiftDays;
            this.hotelCities[i].hotels[j].completeShifts = completeShifts;
            this.hotelCities[i].hotels[j].trainingShifts = trainingShifts;
            this.hotelCities[i].hotels[j].shiftDays = shiftDays;
            this.hotelCities[i].hotels[j].progress = Math.round(result * 100);
          } else {
            this.hotelCities[i].hotels[j].progress = 100;
          }
        }
      }
    },
    async updateBooking(shift) {
      let payload = {
        hotel_id: this.hotelObject.id,
        date: shift.dateDB,
        is_senior: this.isSenior,
        is_training: false,
        is_booked: shift.is_booked,
        is_on_demand: shift.is_on_demand,
        is_same_day_shift: shift.is_same_day_shift,
        is_special_shift: shift.is_special_shift,
        check_id: 1,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts",
        payload: payload,
      });
      if (payload.is_booked == null) {
        payload.is_booked = true;
      }
      payload.is_hotel_booking = true;
      this.synchronizeWithSheets(payload);
      this.getProgress();
      this.loadHotel();
    },
    async getHotels() {
      this.hotels = [];
      let queryObject = {
        table: "cities",
        select:
          "*,hotels(name,short_name,id,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,shifts(*)))",
        where: [
          { type: "is", key: "hotels.email", value: true },
          { type: "is", key: "hotel_city", value: true },
          {
            type: "gte",
            key: "hotels.shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "hotels.shifts.date",
            value: this.endDate.toISOString(),
          },
          {
            type:"eq",
            key:"hotels.shifts.check_id",
            value:1
          }
        ],
      };
      if (this.locationFilter.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "id",
          value: this.locationFilter,
        });
      }
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((city) => {
        city.hotels.sort((a, b) => a.short_name.localeCompare(b.short_name));
        city.hotels.forEach((hotel) => {
          this.hotels.push(hotel);
        });
      });
      this.hotelCities = response.data;
      this.hotelCities.sort((a, b) => b.hotels.length - a.hotels.length);
    },
    async removeTraining(shift) {
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "shifts",
        id: shift.shiftInfo.id,
      });
      this.chosenHotel.trainingShifts.pop();
    },
    async getSheetLink() {
      this.sheetCheck.loading = true;
      this.sheetCheck.link = null;
      var payload = {
        hotel_id: this.selectedHotel,
        date: this.startDate.toISOString(),
      };
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=getSheetLink&secret_key=TEW567LPOVBN267";
      var options = {
        method: "POST",
        body: JSON.stringify(payload),
      };
      var response = await fetch(url, options);
      response = await response.json();
      this.sheetCheck.loading = false;
      this.sheetCheck.link = response;
    },
    async loadHotel(hotel) {
      this.getSheetLink(hotel);
      if (hotel) {
        this.newEmployeeMatch.data.hotel_id = hotel.id;
      }
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);
      this.shiftPlan.shifts = [];
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        select:
          "id,name,short_name,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,employees_hotels!inner(*,employees(id,name,first_name,last_name,status,employee_data_matching(*),shifts(*),employee_date_event_matching!employee_id(*,key:employee_date_events_keys(*)),shift_days,availability_note)),shifts(*,employees(name,first_name,last_name,status,employee_data_matching(*),shift_days,availability_note))",
        where: [
          { type: "eq", key: "id", value: this.selectedHotel },
          /*{
            type: "is",
            key: "employees_hotels.is_senior",
            value: this.isSenior,
          },*/
          {
            type: "gte",
            key: "employees_hotels.employees.shifts.date",
            value: start.toISOString(),
          },
          {
            type: "lte",
            key: "employees_hotels.employees.shifts.date",
            value: end.toISOString(),
          },
          {
            type: "eq",
            key: "employees_hotels.employees.status",
            value: "Aktiv",
          },
          {
            type: "is",
            key: "shifts.is_senior",
            value: this.isSenior,
          },
          {
            type: "eq",
            key:"shifts.check_id",
            value:1
          },
          {
            type: "gte",
            key: "employees_hotels.employees.employee_date_event_matching.date",
            value: start.toISOString(),
          },
          {
            type: "neq",
            key: "employees_hotels.employees.status",
            value: "Inaktiv",
          },
          {
            type: "lte",
            key: "employees_hotels.employees.employee_date_event_matching.date",
            value: end.toISOString(),
          },
          {
            type: "is",
            key: "employees_hotels.employees.employee_date_event_matching.is_activated",
            value: true,
          },
          {
            type: "eq",
            key: "shifts.employees.employee_data_matching.key_id",
            value: 30,
          },
        ],
      });

      this.hotelObject = response.data[0]
        ? response.data[0]
        : { employees_hotels: [], shifts: [], id: this.selectedHotel };
      const queryObjectEmployeeHotels = {
        table: "employees_hotels",
        where: [
          {
            type: "eq",
            key: "hotel_id",
            value: this.selectedHotel,
          },
        ],
      };
      if (this.isSenior == true) {
        queryObjectEmployeeHotels.where.push({
          type: "is",
          key: "is_senior",
          value: true,
        });
      } else {
        queryObjectEmployeeHotels.where.push({
          type: "is",
          key: "is_clerk",
          value: true,
        });
      }
      const employeeHotelResponse = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObjectEmployeeHotels,
      );
      this.hotelObject.all_employee_hotels = employeeHotelResponse.data
        ? employeeHotelResponse.data
        : [];
      if (hotel) {
        this.chosenHotel.trainingShifts = hotel.trainingShifts
          ? hotel.trainingShifts
          : [];
      }
      this.shifts = this.hotelObject.shifts;
      this.createEmployeeShifts();
      this.createShiftPlan();
    },
    async changeDays(type) {
      this.isSenior = true;
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.shiftPlan.shifts = [];
      this.getProgress();
      if (this.hotelObject.id) {
        this.loadHotel();
        /*this.createEmployeeShifts()
        this.createShiftPlan();
        */
      }
    },
    async createShiftPlan() {
      this.shiftPlan.shifts = [];

      let start = new Date(this.startDate);
      let end = new Date(this.endDate);
      var days = this.backDays + this.forwardDays;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "shifts",
        select: "*,employees(id,name,status)",
        where: [
          { type: "gte", key: "date", value: start.toISOString() },
          { type: "lte", key: "date", value: end.toISOString() },
          { type: "eq", key: "hotel_id", value: this.hotelObject.id },
          { type: "neq", key: "employees.status", value: "Inaktiv" },
          { type: "is", key: "is_senior", value: this.isSenior },
          { type: "is", key: "is_training", value: this.isTraining },
          { type: "eq", key: "check_id", value: 1}
        ],
      });
      var shifts = response.data ? response.data : [];
      var shiftDateObject = {};
      shifts.forEach((shift) => {
        var date = new Date(shift.date);
        shiftDateObject[date.toISOString().slice(0, 10)] = shift;
      });
      for (let i = 0; i <= days; i++) {
        var date = new Date(this.today);
        date.setDate(start.getDate() + i);
        var dateISO = date.toISOString().slice(0, 10);
        var weekday = this.weekdays[date.getDay()];

        if (this.isSenior) {
          if (this.hotelObject.shift_days_senior) {
            var regularBooking = this.hotelObject.shift_days_senior?.find(
              (day) => day === date.getDay())
            }
        } else {
          if (this.hotelObject.shift_days_clerk) {
            var regularBooking = this.hotelObject.shift_days_clerk?.find(
              (day) => day === date.getDay())
          }
        }

        let isBooked = null

        if (regularBooking == undefined && (!shiftDateObject[dateISO] || shiftDateObject[dateISO].is_booked != false)) {
          isBooked = null
        }
        else if (regularBooking >= 0) {
          isBooked = (!shiftDateObject[dateISO] || shiftDateObject[dateISO].is_booked != false);
        }


        let regularBookingShow = isBooked && (!shiftDateObject[dateISO] || (shiftDateObject[dateISO] && shiftDateObject[dateISO].employee_id == null));
        let onDemandBookingShow = shiftDateObject[dateISO] && shiftDateObject[dateISO].is_on_demand && shiftDateObject[dateISO].employee_id == null

        let showSelection = regularBookingShow || onDemandBookingShow;

        this.shiftPlan.shifts.push({
          shiftInfo: shiftDateObject[dateISO],
          employeeId: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].employee_id
            : null,
          showSelection: showSelection,
          date: date.toLocaleDateString() + ", " + weekday,
          dateDB: date.toISOString(),
          is_booked: isBooked,
          is_special_shift: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].is_special_shift
            : false,
          is_same_day_shift: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].is_same_day_shift
            : false,
          is_on_demand: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].is_on_demand
            : false,
          employee:
            shiftDateObject[dateISO] && shiftDateObject[dateISO].employees,
          employees: this.employeeRecord,
        });
      }
    },
    // Your methods go here
    createEmployeeShifts() {
      let employeeCheck = {};
      this.hotelObject.employees_hotels.forEach((employeeHotels) => {
        if (!employeeHotels.is_blocked && employeeHotels.employees) {
          let employee = employeeHotels.employees;
          let weeklyHours = employee.employee_data_matching.find(
            (data) => data.key_id == 30,
          );
          weeklyHours = weeklyHours ? weeklyHours.value_number : null;
          employeeCheck[employee.id] = {
            employee_id: employee.id,
            status: employee.status,
            employee_name: employee.name,
            shift_days: employee.shift_days,
            weekly_hours: weeklyHours,
            count: 0,
            senior_count: 0,
            clerk_count: 0,
            training_count: 0,
            availability_note: employee.availability_note
              ? employee.availability_note
              : null,
          };
        }
      });
      this.shifts.forEach((shift) => {
        if (employeeCheck[shift.employee_id]) {
          employeeCheck[shift.employee_id].count++;
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count++;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].clerk_count++;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].training_count++;
          }
        }
      });

      this.employeeRecord = Object.values(employeeCheck);
      this.employeeRecord.forEach((emp) => {
        if (this.isSenior) {
          var toCount = emp.senior_count;
        } else {
          var toCount = emp.clerk_count;
        }
        if (toCount > 40) {
          emp.level = 5;
        } else if (toCount > 30) {
          emp.level = 4;
        } else if (toCount > 20) {
          emp.level = 3;
        } else if (toCount > 10) {
          emp.level = 2;
        } else if (toCount > 0) {
          emp.level = 1;
        } else {
          emp.level = 0;
        }
        this.addExistingShifts(emp);
        this.addFreeDays(emp);
      });
      this.employeeRecord.sort((a, b) => b.count - a.count);
    },
    getAllEmployees() {
      this.allEmployees = [];
      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employees",
          select: "name,id,status,employee_data_matching(*)",
          where: [
            { type: "eq", key: "status", value: "Aktiv" },
            { type: "eq", key: "employee_data_matching.key_id", value: 59 },
          ],
        })
        .then((response) => {
          response.data.forEach((employee) => {
            employee.employee_name =
              employee.last_name + ", " + employee.first_name;
          });
          this.allEmployees = response.data;
          let endingEmployeesArray = response.data.filter(
            (employee) =>
              employee.employee_data_matching.length > 0 &&
              employee.employee_data_matching[0].value_date,
          );
          endingEmployeesArray.forEach((endingEmployee) => {
            endingEmployee.leave_date = new Date(
              endingEmployee.employee_data_matching[0].value_date,
            ).toLocaleDateString("de-DE");
            this.endingEmployees[endingEmployee.id] = endingEmployee;
          });
        });
    },
    addExistingShifts(employee) {
      employee.shifts = [];
      var checkShifts = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkShifts && checkShifts.employees) {
        checkShifts = checkShifts.employees.shifts;
      } else {
        checkShifts = [];
      }
      checkShifts.forEach((shift) => {
        var weekday = this.weekdays[new Date(shift.date).getDay()];
        shift.date = new Date(shift.date).toLocaleDateString() + ", " + weekday;
        employee.shifts.push(shift.date);
      });
    },
    addFreeDays(employee) {
      employee.free_days = [];
      employee.holidays = [];
      employee.is_free_wish = [];
      employee.is_office_shift = [];
      employee.is_out_of_company_days = [];
      var checkFreeDays = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkFreeDays && checkFreeDays.employees) {
        checkFreeDays = checkFreeDays.employees.employee_date_event_matching;
      } else {
        checkFreeDays = [];
      }
      checkFreeDays.forEach((freeDays) => {
        var weekday = this.weekdays[new Date(freeDays.date).getDay()];
        freeDays.date =
          new Date(freeDays.date).toLocaleDateString() + ", " + weekday;
        if (freeDays.key.value == 'is_holiday') {
          employee.holidays.push(freeDays.date);
        } else if (freeDays.key.value == 'is_free') {
          employee.free_days.push(freeDays.date);
        } else if (freeDays.key.value == 'is_free_wish') {
          employee.is_free_wish.push(freeDays.date);
        } else if (freeDays.key.value == 'is_office_shift') {
          employee.is_office_shift.push(freeDays.date);
        } else if (freeDays.key.value == 'is_out_of_company') {
          employee.is_out_of_company_days.push(freeDays.date);
        }
      });
    },
    async saveNewLevel() {
      this.newEmployeeMatch.loading = true;
      this.$store.dispatch("upsertSupabaseEntity", {
        table: "employees_hotels",
        payload: this.newEmployeeMatch.data,
      });
      this.newEmployeeMatch.loading = false;
      this.newEmployeeMatch.dialog = false;
      this.loadHotel();
    },
    async checkLevels() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employees_hotels",
        where: [
          {
            type: "eq",
            key: "hotel_id",
            value: this.newEmployeeMatch.data.hotel_id,
          },
          {
            type: "eq",
            key: "employee_id",
            value: this.newEmployeeMatch.data.employee_id,
          },
        ],
      });
      if (response.data.length > 0) {
        this.newEmployeeMatch.data.is_senior = response.data[0].is_senior;
        this.newEmployeeMatch.data.is_clerk = response.data[0].is_clerk;
        this.newEmployeeMatch.data.is_blocked = response.data[0].is_blocked;
      }
    },
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      this.today.setDate(this.today.getDate() + daysUntilNextMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
    },
  },
  watch: {
    "newEmployeeMatch.data.employee_id": {
      handler(newValue, oldValue) {
        if (newValue) {
          this.checkLevels();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.getNextMonday();
    this.getProgress();
    this.getAllEmployees();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
