<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="email-sidebar">
          <v-card-title>E-Mail-Gruppen</v-card-title>
          <v-select
            :items="portalEmails"
            label="E-Mail-Gruppe auswählen"
            item-title="email"
            item-value="email"
            v-model="selectedEmail"
            variant="outlined"
            density="comfortable"
            class="mx-3"
          ></v-select>

          <v-text-field
            v-if="selectedEmail"
            v-model="searchTerm"
            label="Suche"
            color="primary"
            variant="outlined"
            density="comfortable"
            class="mx-3 mt-2"
            hide-details
            clearable
            append-icon="mdi-magnify"
            @click:append="getEmails"
            v-on:keyup.enter="getEmails"
          ></v-text-field>

          <v-switch
            v-model="processedSwitch"
            label="Nur unbearbeitete E-Mails anzeigen"
            color="primary"
            class="mx-3 mt-2"
            hide-details
          ></v-switch>

          <v-divider class="mt-2"></v-divider>

          <v-tabs v-model="emailTab" centered class="mt-2" v-if="selectedEmail">
            <v-tab value="received">Empfangen</v-tab>
            <v-tab value="sent">Gesendet</v-tab>
          </v-tabs>

          <v-list v-if="emails.length" class="email-list">
            <v-list-item
              v-for="email in emails"
              :key="email.id"
              :class="{ 'email-unread': !email.read_already }"
              @click="selectEmail(email)"
              :active="selectedEmailId === email.id"
            >
              <v-list-item-title class="text-truncate font-weight-medium">
                {{ email.from }}
                <v-progress-circular
                  v-if="email.attachmentLoading"
                  indeterminate
                  color="primary"
                  size="20"
                ></v-progress-circular>
                <span>
                  <v-chip
                    v-if="email.attachments && email.attachments.length > 0"
                    prepend-icon="mdi-paperclip"
                  >
                    {{ email.attachments.length }}
                  </v-chip>
                </span>
              </v-list-item-title>
              <v-list-item-subtitle class="text-truncate">
                {{ email.subject }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-truncate text-caption">
                {{ formatDate(email.date) }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-pagination
            v-if="selectedEmail"
            v-model="pagination.page"
            :length="Math.ceil(pagination.totalItems / pagination.itemsPerPage)"
            @click="getEmails"
            class="mt-3"
          ></v-pagination>
          <v-col align="center" class="mt-n2" v-if="selectedEmail">
            {{ pagination.totalItems }} Emails
          </v-col>

          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="mt-5 mx-auto d-block"
          ></v-progress-circular>

          <v-alert v-if="error" type="error" class="mt-3 mx-3">
            {{ error }}
          </v-alert>

          <v-alert
            v-if="!loading && emails.length === 0 && selectedEmail"
            type="info"
            class="mt-3 mx-3"
          >
            Keine E-Mails für diese Gruppe gefunden
          </v-alert>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <email-detail
          v-if="currentEmail"
          :email="currentEmail"
          :mandatorInfo="mandatorInfo"
          :user="user"
          @email-processed="handleEmailProcessed"
        ></email-detail>
        <v-card
          v-else
          class="d-flex align-center justify-center email-placeholder"
          height="500"
        >
          <span
            v-if="selectedEmail"
            class="text-subtitle-1 text-medium-emphasis"
          >
            Wählen Sie eine E-Mail aus, um den Inhalt anzuzeigen
          </span>
          <span v-else class="text-subtitle-1 text-medium-emphasis">
            Wählen Sie eine E-Mail-Gruppe aus, um E-Mails anzuzeigen
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmailDetail from "@/components/Emails/EmailDetail.vue";

export default {
  name: "Emails",
  components: {
    EmailDetail,
  },
  data() {
    return {
      emails: [],
      portalEmails: [],
      selectedEmail: null,
      selectedEmailId: null,
      currentEmail: null,
      loading: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      user: null,
      error: null,
      searchTerm: null,
      processedSwitch: true,
      emailTab: "received", // Default to received emails
    };
  },
  watch: {
    selectedEmail(newValue) {
      if (newValue) {
        this.currentEmail = null;
        this.selectedEmailId = null;
        this.getEmails();
      } else {
        this.emails = [];
        this.currentEmail = null;
      }
    },
    processedSwitch() {
      if (this.selectedEmail) {
        this.getEmails();
      }
    },
    emailTab() {
      if (this.selectedEmail) {
        this.getEmails();
      }
    },
  },
  methods: {
    async getEmails() {
      this.loading = true;
      this.error = null;

      try {
        let queryObject = {
          table: "emails",
          where: [],
          limit: 10,
          range: {
            range_min:
              (this.pagination.page - 1) * this.pagination.itemsPerPage,
            range_max: this.pagination.page * this.pagination.itemsPerPage,
          },
          order: { key: "date", ascending: false },
        };

        // Add email direction filter based on tab selection
        if (this.emailTab === "sent") {
          // Show sent emails - from matches selected email
          queryObject.where.push({
            type: "eq",
            key: "from",
            value: this.selectedEmail,
          });
        } else {
          // Show received emails - to contains selected email
          queryObject.where.push({
            type: "ilike",
            key: "to",
            value: "%" + this.selectedEmail + "%",
          });
        }

        if (this.searchTerm) {
          let searchTerm = "%" + this.searchTerm + "%";
          queryObject.where.push({
            type: "or",
            value: `subject.ilike.${searchTerm},from.ilike.${searchTerm},to.ilike.${searchTerm},body.ilike.${searchTerm}`,
          });
        }

        if (this.processedSwitch) {
          queryObject.where.push({
            type: "is",
            key: "processed",
            value: false,
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );

        this.emails = response.data || [];
        this.pagination.totalItems = response.count || 0;
        this.emails.forEach((email) => {
          email.attachmentLoading = true;
        });
        let gmailIds = this.emails.map((email) => email.gmail_id);
        if (this.selectedEmail == "bewerbung@bellcaptain.team") {
          this.user = "bewerbung@bellcaptain.team";
        } else {
          this.user = this.mandatorInfo.central_email;
        }

        const attachments = await this.$store.dispatch(
          "getEmailAttachmentsPerBackend",
          { gmailIds: gmailIds, user: this.user },
        );

        this.emails.forEach((email) => {
          email.body = email.body
            .replace(/src="cid:icon.png"/g, "")
            .replace(/src="cid:warning_triangle.png"/g, "");
          email.attachments = attachments[email.gmail_id] || [];
          email.attachmentLoading = false;
        });
      } catch (error) {
        console.error("Error fetching emails:", error);
        this.error =
          "E-Mails konnten nicht abgerufen werden. Bitte versuchen Sie es erneut.";
      } finally {
        this.loading = false;
      }
    },

    async markEmailAsProcessed(email) {
      try {
        const updateObject = {
          table: "emails",
          id: email.id,
          payload: {
            processed: true,
          },
        };

        await this.$store.dispatch("updateSupabaseEntity", updateObject);
        return true;
      } catch (error) {
        console.error("Error marking email as processed:", error);
        this.error = "E-Mail konnte nicht als bearbeitet markiert werden.";
        return false;
      }
    },

    async handleEmailProcessed(email) {
      const success = await this.markEmailAsProcessed(email);
      if (success) {
        // Remove the email from the current view if showing only unprocessed emails
        if (this.processedSwitch) {
          this.currentEmail = null;
          this.selectedEmailId = null;
        }
        // Reload emails to update the list
        this.getEmails();
      }
    },

    selectEmail(email) {
      this.selectedEmailId = email.id;
      this.currentEmail = email;
    },

    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleString("de-DE");
    },
  },
  async mounted() {
    await this.$store.dispatch("getUserInfo");
    const mandatorObject = {
      table: "mandators",
      where: [
        {
          type: "eq",
          key: "id",
          value: this.$store.state.userInfo.mandator_id,
        },
      ],
    };
    const mandatorResponse = await this.$store.dispatch(
      "getSupabaseEntity",
      mandatorObject,
    );
    this.mandatorInfo = mandatorResponse.data[0];
    try {
      var roleRights = await this.$store.dispatch("checkRole");
      let checkEmailGroups = {};
      roleRights.emailGroups.forEach((group) => {
        if (group.is_checked && group.email_groups.use_frontend && !checkEmailGroups[group.email_group_id]) {
          this.portalEmails.push(group.email_groups);
          checkEmailGroups[group.email_group_id] = true;
        }
      });
    } catch (error) {
      console.error("Error loading email groups:", error);
      this.error =
        "E-Mail-Gruppen konnten nicht geladen werden. Bitte Seite neu laden.";
    }
  },
};
</script>

<style scoped>
.email-sidebar {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.email-list {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.email-unread {
  font-weight: bold;
  background-color: rgba(33, 150, 243, 0.05);
}

.email-placeholder {
  color: #757575;
  font-style: italic;
}

.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.v-list-item--active {
  background-color: rgba(33, 150, 243, 0.1);
}

.v-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
