// Composables
import { createRouter, createWebHistory } from "vue-router";
import Hotels from "@/views/Hotels.vue";
import HotelDetails from "@/views/HotelDetails.vue";
import Employees from "@/views/Employees";
import Recruiting from "@/views/Recruiting";
import Reporting from "@/views/Reporting";
import Reports from "@/views/Reports";
import Import from "@/views/Import";
import Emails from "@/views/Emails";
import Accounting from "@/views/Accounting";
import Shifts from "@/views/Shifts";
import Settings from "@/views/Settings";
import Candidates from "@/views/CandidateDetails";
import EmployeeProfile from "@/components/Employees/Profile";
import JobDetails from "@/views/JobDetails";
import UserSettings from "@/views/UserSettings.vue";
import AdminSettings from "@/components/Settings/Admin";
import HotelSettings from "@/components/Settings/Hotels";
import RecruitingSettings from "@/components/Settings/Recruiting";
import EmployeeSettings from "@/components/Settings/Employees";
import GeneralSettings from "@/components/Settings/General";
import Task from "@/views/Tasks.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
      {
        path: "/Hotels",
        name: "hotels",
        component: Hotels,
      },
      {
        path: "/Hotels/:id",
        name: "hotelDetails",
        component: HotelDetails,
      },
      {
        path: "/Mitarbeiter",
        name: "employees",
        component: Employees,
      },
      {
        path: "/Mitarbeiter/:id",
        name: "employeeProfile",
        component: EmployeeProfile,
      },
      {
        path: "/Kandidaten/:id",
        name: "candidates",
        component: Candidates,
      },
      {
        path: "/Jobs/:id",
        name: "jobsDetails",
        component: JobDetails,
      },
      {
        path: "/Recruiting",
        name: "recruiting",
        component: Recruiting,
      },
      {
        path: "/Reports",
        name: "reports",
        component: Reports,
      },
      {
        path: "/Emails",
        name: "emails",
        component: Emails,
      },
      {
        path: "/Reporting",
        name: "reporting",
        component: Reporting,
      },
      {
        path: "/Accounting",
        name: "accounting",
        component: Accounting,
      },
      {
        path: "/Schichten",
        name: "shifts",
        component: Shifts,
      },
      {
        path: "/Import",
        name: "import",
        component: Import,
      },
      {
        path: "/Tasks",
        name: "tasks",
        component: Task,
      },
      {
        path: "/Settings",
        name: "settings",
        component: Settings,
      },
      {
        path: "/Settings/Recruiting",
        name: "recruitingSettings",
        component: RecruitingSettings,
      },
      {
        path: "/Settings/Hotels",
        name: "hotelSettings",
        component: HotelSettings,
      },
      {
        path: "/Settings/Employees",
        name: "employeeSettings",
        component: EmployeeSettings,
      },
      {
        path: "/Settings/Admin",
        name: "adminSettings",
        component: AdminSettings,
      },
      {
        path: "/Settings/General",
        name: "generalSettings",
        component: GeneralSettings,
      },

      { path: "/user", component: UserSettings, name: "userSettings" },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
