<template>
  <div>
    <v-card rounded elevation="2" class="bonus-simulator mb-3">
      <v-card-title class="d-flex align-center py-2">
        <v-icon color="deep-purple" class="mr-2">mdi-chart-scatter-plot</v-icon>
        Bonus Simulation
      </v-card-title>

      <v-card-text>
        <!-- Simulation controls -->
        <v-row dense>
          <v-col cols="12" md="3">
            <v-select
              v-model="selectedPeriod"
              :items="availablePeriods"
              label="Analysezeitraum"
              variant="outlined"
              :disabled="isLoading"
              @update:model-value="loadSimulationData"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="selectedCity"
              :items="cities"
              item-title="city"
              item-value="id"
              label="Stadt"
              variant="outlined"
              :disabled="isLoading"
              clearable
              @update:model-value="handleCityChange"
            ></v-select>
          </v-col>
        </v-row>

        <div v-if="selectedCity">
          <v-card flat class="my-4 pa-3">
            <h3 class="text-h6 mb-3">Simulations-Übersicht</h3>

            <template v-if="hasData">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card
                    outlined
                    class="summary-card mb-2"
                    :loading="isLoading"
                  >
                    <v-card-title class="py-2 text-subtitle-1">
                      Originale Daten
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="7">Mitarbeiter:</v-col>
                        <v-col cols="5" class="text-right">{{
                          originalData.employeeCount
                        }}</v-col>

                        <v-col cols="7">Gesamtpunkte:</v-col>
                        <v-col cols="5" class="text-right">{{
                          originalData.totalPoints
                        }}</v-col>

                        <v-col cols="7">Ø Punkte pro MA:</v-col>
                        <v-col cols="5" class="text-right">{{
                          originalData.avgPointsPerEmployee.toFixed(2)
                        }}</v-col>

                        <v-col cols="7">Gesamtkosten:</v-col>
                        <v-col cols="5" class="text-right">{{
                          formatCurrency(originalData.totalCost)
                        }}</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card
                    outlined
                    class="summary-card mb-2"
                    color="blue-grey-lighten-5"
                    :loading="isLoading"
                  >
                    <v-card-title class="py-2 text-subtitle-1">
                      Simulierte Daten
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="7">Mitarbeiter:</v-col>
                        <v-col cols="5" class="text-right">{{
                          simulatedData.employeeCount
                        }}</v-col>

                        <v-col cols="7">Gesamtpunkte:</v-col>
                        <v-col cols="5" class="text-right">
                          {{ simulatedData.totalPoints }}
                          <span class="ml-1" :class="pointsDiffClass">
                            ({{
                              formatDifference(
                                simulatedData.totalPoints -
                                  originalData.totalPoints,
                              )
                            }})
                          </span>
                        </v-col>

                        <v-col cols="7">Ø Punkte pro MA:</v-col>
                        <v-col cols="5" class="text-right">
                          {{ simulatedData.avgPointsPerEmployee.toFixed(2) }}
                          <span class="ml-1" :class="pointsAvgDiffClass">
                            ({{
                              formatDifference(
                                simulatedData.avgPointsPerEmployee -
                                  originalData.avgPointsPerEmployee,
                                2,
                              )
                            }})
                          </span>
                        </v-col>

                        <v-col cols="7">Gesamtkosten:</v-col>
                        <v-col cols="5" class="text-right">
                          {{ formatCurrency(simulatedData.totalCost) }}
                          <span class="ml-1" :class="costDiffClass">
                            ({{
                              formatCurrencyDifference(
                                simulatedData.totalCost -
                                  originalData.totalCost,
                              )
                            }})
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Employee impact table -->
              <v-expansion-panels>
                <v-expansion-panel title="Auswirkung auf Mitarbeiter">
                  <v-expansion-panel-text>
                    <v-card outlined>
                      <v-card-title>
                        <v-text-field
                          v-model="employeeSearch"
                          append-icon="mdi-magnify"
                          label="Suchen"
                          single-line
                          hide-details
                          dense
                          outlined
                          class="search-field"
                          style="max-width: 300px"
                        ></v-text-field>
                      </v-card-title>

                      <v-card-text class="pa-0">
                        <v-data-table
                          :headers="employeeHeaders"
                          :items="employeeImpact"
                          :search="employeeSearch"
                          class="employee-impact-table"
                          no-data-text="Bitte eine Stadt auswählen"
                          dense
                        >
                          <template v-slot:item.name="{ item }">
                            {{ item.name }}
                            <v-chip
                              size="x-small"
                              class="ml-2"
                              :color="getPositionColor(item.position)"
                              label
                            >
                              {{ item.position }}
                            </v-chip>
                          </template>
                          <template v-slot:item.originalPoints="{ item }">
                            {{ item.originalPoints }}
                          </template>
                          <template v-slot:item.points="{ item }">
                            {{ item.points }}
                            <span
                              v-if="item.pointsDifference !== 0"
                              class="ml-1"
                              :class="getDiffClass(item.pointsDifference, true)"
                            >
                              ({{ formatDifference(item.pointsDifference) }})
                            </span>
                          </template>
                          <template v-slot:item.originalLevel="{ item }">
                            {{ item.originalLevel }}
                          </template>
                          <template v-slot:item.simulatedLevel="{ item }">
                            <span
                              :class="{
                                'level-changed': item.levelChanged,
                              }"
                            >
                              {{ item.simulatedLevel }}
                            </span>
                          </template>
                          <template v-slot:item.originalWage="{ item }">
                            {{ formatCurrency(item.originalWage) }}
                          </template>
                          <template v-slot:item.simulatedWage="{ item }">
                            {{ formatCurrency(item.simulatedWage) }}
                          </template>
                          <template v-slot:item.wageDifference="{ item }">
                            <span :class="getDiffClass(item.wageDifference)">
                              {{
                                formatCurrencyDifference(item.wageDifference)
                              }}
                            </span>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>

            <div v-else class="text-center py-5">
              <v-icon x-large color="grey-lighten-1" class="mb-2"
                >mdi-chart-line</v-icon
              >
              <div class="text-body-1">
                Wählen Sie einen Zeitraum aus, um eine Simulation zu starten
              </div>
            </div>
          </v-card>

          <!-- Tabs for different simulation types -->
          <v-tabs
            v-model="activeTab"
            slider-color="blue-grey-darken-2"
            class="mt-4"
            :disabled="isLoading"
          >
            <v-tab value="hotel-points">
              <v-icon small class="mr-2">mdi-domain</v-icon>
              Hotel Punkte
            </v-tab>
            <v-tab value="bonus-config">
              <v-icon small class="mr-2">mdi-check</v-icon>
              Bonus/Malus
            </v-tab>
            <v-tab value="levels">
              <v-icon small class="mr-2">mdi-stairs</v-icon>
              Bonusstufen
            </v-tab>
          </v-tabs>

          <v-window v-model="activeTab">
            <!-- Hotel Points tab -->
            <v-window-item value="hotel-points">
              <v-card flat class="my-4">
                <h3 class="text-h6 mb-3 px-3 pt-3">Hotel Punkte Simulation</h3>

                <HotelBonusSimulator
                  :hotel-bonus-data="bonusConfiguration.hotelBonusPoints"
                  :cities="bonusConfiguration.cities"
                  :employee-data="bonusConfiguration.employeeData"
                  :bonus-levels="bonusConfiguration.bonusLevels"
                  :is-loading="isLoading"
                  @update:simulation="updateSimulation"
                />
              </v-card>
            </v-window-item>

            <!-- Bonus/Malus Config tab -->
            <v-window-item value="bonus-config">
              <v-card flat class="my-4">
                <h3 class="text-h6 mb-3 px-3 pt-3">Bonus/Malus Simulation</h3>

                <BonusMalusSimulator
                  :bonus-data="bonusConfiguration.bonuses"
                  :employee-data="bonusConfiguration.employeeData"
                  :bonus-levels="bonusConfiguration.bonusLevels"
                  :is-loading="isLoading"
                  @update:simulation="updateSimulation"
                />
              </v-card>
            </v-window-item>

            <!-- Bonus Levels tab -->
            <v-window-item value="levels">
              <v-card flat class="my-4">
                <h3 class="text-h6 mb-3 px-3 pt-3">Bonusstufen Simulation</h3>
                <BonusLevelSimulator
                  :original-data="originalData"
                  :bonus-configuration="bonusConfiguration"
                  :selected-period="selectedPeriod"
                  :selected-city="selectedCity"
                  :is-loading="isLoading"
                  @update:simulation="updateSimulation"
                />
              </v-card>
            </v-window-item>
          </v-window>
        </div>
        <v-alert
          v-else
          type="info"
          class="mt-4"
          border="left"
          elevation="2"
          icon="mdi-information"
        >
          Bitte einen Zeitraum und eine Stadt auswählen, um die Simulation zu
          starten.
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { supabase } from "@/supabase";
import {
  format,
  parseISO,
  startOfQuarter,
  endOfQuarter,
  subMonths,
  subQuarters,
  startOfMonth,
  endOfMonth,
  addMonths,
} from "date-fns";
import { de } from "date-fns/locale";
import useSnackbar from "@/utils/useSnackbar";
import BonusCalculatorService from "@/services/BonusCalculatorService";
import HotelBonusSimulator from "./Simulation/HotelBonusSimulator.vue";
import BonusMalusSimulator from "./Simulation/BonusMalusSimulator.vue";
import BonusLevelSimulator from "./Simulation/BonusLevelSimulator.vue";

// Use snackbar for notifications
const { showSuccess, showError } = useSnackbar();

// Component state
const isLoading = ref(false);
const activeTab = ref("overview");
const selectedPeriod = ref(null);
const selectedCity = ref(null);
const cities = ref([]);
const employeeSearch = ref(""); // New search field for employee table

// Constants for employee data matching
const EMPLOYEE_DATA_MATCHING_CITY_ID = 5;
const EMPLOYEE_DATA_MATCHING_POSITION_ID = 7;
const positionMap = {
  Bronze: "bronze",
  Silber: "silver",
  Gold: "gold",
  Shiftleader: "shiftleader",
};

// Data storage
const originalData = ref({
  employeeCount: 0,
  totalPoints: 0,
  avgPointsPerEmployee: 0,
  totalCost: 0,
  details: [],
});

const simulatedData = ref({
  employeeCount: 0,
  totalPoints: 0,
  avgPointsPerEmployee: 0,
  totalCost: 0,
  details: [],
  simulationType: null,
  simulationData: null,
});

// Simulation configuration
const bonusConfiguration = ref({
  bonuses: [], // List of all bonus/malus events
  bonusLevels: [], // Bonus level configurations
  hotelBonusPoints: [], // Hotel bonus points
  employeeData: [], // Employee data with their bonus points
  cities: [], // Cities with bonus systems
  citiesWithBonus: [], // City IDs that have bonus systems
});

// Headers for employee impact table
const employeeHeaders = [
  { title: "Name", key: "name", sortable: true },
  {
    title: "Originale Punkte",
    key: "originalPoints",
    sortable: true,
    width: "120px",
  },
  { title: "Simulierte Punkte", key: "points", sortable: true, width: "170px" },
  {
    title: "Originale Stufe",
    key: "originalLevel",
    sortable: true,
    width: "60px",
  },
  {
    title: "Simulierte Stufe",
    key: "simulatedLevel",
    sortable: true,
    width: "60px",
  },
  {
    title: "Originaler Lohn",
    key: "originalWage",
    sortable: true,
    width: "120px",
  },
  {
    title: "Simulierter Lohn",
    key: "simulatedWage",
    sortable: true,
    width: "120px",
  },
  { title: "Differenz", key: "wageDifference", sortable: true, width: "120px" },
];

const getPositionColor = (position) => {
  const positionColors = {
    Bronze: "amber-darken-1",
    Silber: "grey-lighten-1",
    Gold: "amber-accent-3",
    Shiftleader: "purple-lighten-1",
  };

  return positionColors[position] || "primary";
};

// Generate available periods for selection (months)
const availablePeriods = computed(() => {
  // Start from January 2025
  const startMonth = new Date(2025, 0);
  const currentMonth = new Date();
  const periods = [];

  // Add months from startMonth to current date
  let monthDate = new Date(startMonth);
  while (monthDate <= currentMonth) {
    const startDate = startOfMonth(monthDate);
    const endDate = endOfMonth(monthDate);

    periods.push({
      title: format(monthDate, "MMMM yyyy", { locale: de }),
      value: {
        startDate,
        endDate,
        label: format(monthDate, "MM/yyyy"),
      },
    });

    monthDate = addMonths(monthDate, 1);
  }

  return periods.reverse(); // Most recent first
});

// Computed properties for styling and formatting
const hasData = computed(() => {
  return originalData.value.employeeCount > 0;
});

const pointsDiffClass = computed(() => {
  const diff = simulatedData.value.totalPoints - originalData.value.totalPoints;
  return diff > 0 ? "green--text" : diff < 0 ? "red--text" : "";
});

const pointsAvgDiffClass = computed(() => {
  const diff =
    simulatedData.value.avgPointsPerEmployee -
    originalData.value.avgPointsPerEmployee;
  return diff > 0 ? "green--text" : diff < 0 ? "red--text" : "";
});

const costDiffClass = computed(() => {
  const diff = simulatedData.value.totalCost - originalData.value.totalCost;
  return diff > 0 ? "red--text" : diff < 0 ? "green--text" : "";
});

// Employee impact table computed property
const employeeImpact = computed(() => {
  if (
    !originalData.value.details ||
    !simulatedData.value.details ||
    !selectedCity.value ||
    originalData.value.details.length === 0
  ) {
    return [];
  }

  // Filter employees by the selected city, if selected
  const cityEmployees = selectedCity.value
    ? originalData.value.details.filter(
        (emp) => emp.city_id === selectedCity.value,
      )
    : originalData.value.details;

  return cityEmployees
    .map((employee) => {
      // Find the simulated employee data
      const simulatedEmployee = simulatedData.value.details.find(
        (empSim) => empSim.id === employee.id,
      );

      if (!simulatedEmployee) return null;

      // Calculate difference in wages
      const wageDifference =
        simulatedEmployee.monthlyWage - employee.monthlyWage;
      const pointsDifference =
        simulatedEmployee.totalPoints - employee.totalPoints;
      const levelChanged = employee.bonusLevel !== simulatedEmployee.bonusLevel;

      return {
        id: employee.id,
        name: employee.name,
        position: employee.position || "Unbekannt", // Add position property
        originalPoints: employee.totalPoints,
        points: simulatedEmployee.totalPoints,
        pointsDifference,
        originalLevel: employee.bonusLevel ?? "Keine Stufe",
        simulatedLevel: simulatedEmployee.bonusLevel ?? "Keine Stufe",
        originalWage: employee.monthlyWage,
        simulatedWage: simulatedEmployee.monthlyWage,
        wageDifference,
        levelChanged,
      };
    })
    .filter(Boolean); // Remove any null entries
});

// Load simulation data for selected period
const loadSimulationData = async () => {
  if (!selectedPeriod.value) return;
  isLoading.value = true;

  try {
    const startDate = selectedPeriod.value.startDate;
    const endDate = selectedPeriod.value.endDate;

    // Format dates for API calls
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    // Fetch all required data in parallel
    await Promise.all([
      loadCities(),
      loadBonusEvents(),
      loadBonusLevels(),
      loadHotelBonusPoints(),
    ]);

    // Load employee data after we have the cities with bonus
    await loadEmployeeData(formattedStartDate, formattedEndDate);

    // Update the imported cities data with our current city ID from employee data
    if (
      bonusConfiguration.value.employeeData &&
      bonusConfiguration.value.employeeData.length > 0 &&
      !selectedCity.value
    ) {
      // Get unique city IDs from employee data
      const employeeCityIds = [
        ...new Set(
          bonusConfiguration.value.employeeData
            .map((emp) => emp.city_id)
            .filter((id) => id),
        ),
      ];
    }

    // Process the data to calculate totals
    const processedData = calculateOriginalData();
    originalData.value = processedData;

    // Initialize simulated data with a copy of original data
    simulatedData.value = JSON.parse(JSON.stringify(processedData));
  } catch (error) {
    console.error("Fehler beim Laden der Daten:", error);
    showError("Fehler beim Laden der Daten");
  } finally {
    isLoading.value = false;
  }
};

// Load cities with bonus systems
const loadCities = async () => {
  try {
    // First get the city IDs that have bonus levels
    const { data: bonusLevelsData, error: bonusLevelsError } = await supabase
      .from("bonus_levels")
      .select("city_id")
      .not("city_id", "is", null);

    if (bonusLevelsError) throw bonusLevelsError;

    if (!bonusLevelsData || bonusLevelsData.length === 0) {
      cities.value = [];
      return;
    }

    // Extract unique city IDs
    const cityIds = [...new Set(bonusLevelsData.map((item) => item.city_id))];

    // Save cities with bonus for later use
    bonusConfiguration.value.citiesWithBonus = cityIds;

    // Then fetch the city details for those IDs
    const { data: citiesData, error: citiesError } = await supabase
      .from("cities")
      .select("*")
      .in("id", cityIds)
      .order("city", { ascending: true });

    if (citiesError) throw citiesError;

    cities.value = citiesData || [];
    bonusConfiguration.value.cities = citiesData || [];
  } catch (error) {
    console.error("Fehler beim Laden der Städte:", error);
    showError("Fehler beim Laden der Städte");
  }
};

// Fetch bonus and malus events (positive and negative points)
const loadBonusEvents = async () => {
  try {
    const { data, error } = await supabase
      .from("bonuses")
      .select("*")
      .is("deleted_at", null)
      .order("created_at", { ascending: true });

    if (error) throw error;

    // Process and format the bonus events
    bonusConfiguration.value.bonuses = data.map((bonus) => {
      return {
        ...bonus,
        // Ensure historical_values is an array
        historical_values: Array.isArray(bonus.historical_values)
          ? bonus.historical_values
          : typeof bonus.historical_values === "string"
            ? JSON.parse(bonus.historical_values)
            : [],
      };
    });
  } catch (error) {
    console.error("Fehler beim Laden der Bonus-Ereignisse:", error);
    throw error;
  }
};

// Fetch bonus levels for all cities
const loadBonusLevels = async () => {
  try {
    // First fetch cities with bonus systems - already done in loadCities()
    const cityIds = bonusConfiguration.value.citiesWithBonus;

    if (cityIds.length === 0) {
      bonusConfiguration.value.bonusLevels = [];
      return;
    }

    // Now fetch all bonus levels
    const { data: levelsData, error: levelsError } = await supabase
      .from("bonus_levels")
      .select("*")
      .in("city_id", cityIds)
      .is("deleted_at", null);

    if (levelsError) throw levelsError;

    // Process and format the bonus levels
    bonusConfiguration.value.bonusLevels = levelsData.map((level) => {
      return {
        ...level,
        // Ensure historical_values is an array
        historical_values: Array.isArray(level.historical_values)
          ? level.historical_values
          : typeof level.historical_values === "string"
            ? JSON.parse(level.historical_values)
            : [],
      };
    });
  } catch (error) {
    console.error("Fehler beim Laden der Bonusstufen:", error);
    throw error;
  }
};

// Fetch hotel bonus points
const loadHotelBonusPoints = async () => {
  try {
    // Get all hotel bonus points
    const { data, error } = await supabase
      .from("bonus_hotel_points")
      .select("*, hotels:hotel_id(id, name, city_id, cities:city_id(id, city))")
      .order("created_at", { ascending: true });

    if (error) throw error;

    // Process and format the hotel bonus points
    bonusConfiguration.value.hotelBonusPoints = data.map((hotelBonus) => {
      return {
        ...hotelBonus,
        name: hotelBonus.hotels?.name || "Unbekanntes Hotel",
        city_id: hotelBonus.hotels?.city_id,
        city_name: hotelBonus.hotels?.cities?.city || "Unbekannte Stadt",
        // Ensure historical_values is an array
        historical_values: Array.isArray(hotelBonus.historical_values)
          ? hotelBonus.historical_values
          : typeof hotelBonus.historical_values === "string"
            ? JSON.parse(hotelBonus.historical_values)
            : [],
      };
    });
  } catch (error) {
    console.error("Fehler beim Laden der Hotel-Bonuspunkte:", error);
    throw error;
  }
};

// Handle city change, reset bonus levels
const handleCityChange = async () => {
  const previousSimulationType = simulatedData.value.simulationType;
  const previousSimulationData = simulatedData.value.simulationData;

  loadSimulationData().then(() => {
    // After loading new data, reapply simulation if one was active
    if (previousSimulationType && previousSimulationData) {
      previousSimulationData.bonusLevels = null;
      reapplySimulation(previousSimulationType, previousSimulationData);
    }
  });
};

// Add a new function to reapply simulations after city changes
const reapplySimulation = async (simulationType, simulationData) => {
  if (!simulationType || !simulationData) return;

  try {
    // Make a fresh copy of the original data
    const updatedData = JSON.parse(JSON.stringify(originalData.value));

    // Process the simulation with all available data
    await processSimulation(updatedData, simulationData);

    // Update the simulatedData ref with our modified data
    simulatedData.value = {
      ...updatedData,
      simulationType,
      simulationData,
    };
  } catch (error) {
    console.error("Fehler bei der Wiederanwendung der Simulation:", error);
    showError("Fehler bei der Wiederanwendung der Simulation");
  }
};

// Fetch employee data with bonus points - similar to MonthlyBonusReport
const loadEmployeeData = async (startDate, endDate) => {
  try {
    // Get employees either for the selected city or all cities with bonus systems
    let employeeIds = [];

    if (selectedCity.value) {
      // Get employees for the selected city using employee_data_matching
      const { data: employeeDataResponse, error: employeeDataError } =
        await supabase
          .from("employee_data_matching")
          .select("employee_id")
          .eq("key_id", EMPLOYEE_DATA_MATCHING_CITY_ID)
          .eq("value_id", selectedCity.value);

      if (employeeDataError) throw employeeDataError;

      if (employeeDataResponse && employeeDataResponse.length > 0) {
        employeeIds = employeeDataResponse.map((item) => item.employee_id);
      }
    } else if (bonusConfiguration.value.citiesWithBonus.length > 0) {
      // Get employees for all cities with bonus systems
      const cityIds = bonusConfiguration.value.citiesWithBonus;

      const { data: employeeDataResponse, error: employeeDataError } =
        await supabase
          .from("employee_data_matching")
          .select("employee_id")
          .eq("key_id", EMPLOYEE_DATA_MATCHING_CITY_ID)
          .in("value_id", cityIds);

      if (employeeDataError) throw employeeDataError;

      if (employeeDataResponse && employeeDataResponse.length > 0) {
        employeeIds = employeeDataResponse.map((item) => item.employee_id);
      }
    }

    if (employeeIds.length === 0) {
      bonusConfiguration.value.employeeData = [];
      return;
    }

    // Get employee details
    const { data: employees, error: employeesError } = await supabase
      .from("employees")
      .select("id, first_name, last_name")
      .in("id", employeeIds)
      .eq("status", "Aktiv")
      .eq("bonus_eligible", true)
      .order("last_name", { ascending: true });

    if (employeesError) throw employeesError;

    if (!employees || employees.length === 0) {
      bonusConfiguration.value.employeeData = [];
      return;
    }

    // Get employee positions
    const { data: positionsData, error: positionsError } = await supabase
      .from("employee_data_matching")
      .select("employee_id, value_text")
      .in(
        "employee_id",
        employees.map((e) => e.id),
      )
      .eq("key_id", EMPLOYEE_DATA_MATCHING_POSITION_ID);

    if (positionsError) throw positionsError;

    // Create position map
    const employeePositions = {};
    if (positionsData && positionsData.length > 0) {
      positionsData.forEach((item) => {
        employeePositions[item.employee_id] = item.value_text;
      });
    }

    // Calculate bonus data for employees
    const employeeBonusData =
      await BonusCalculatorService.calculateBonusForMultipleEmployees(
        employeeIds,
        new Date(startDate),
        new Date(endDate),
      );

    // Map the bonus data to our employee objects
    bonusConfiguration.value.employeeData = employees.map((employee) => {
      const name = `${employee.first_name} ${employee.last_name}`;
      const position = employeePositions[employee.id] || "Unbekannt";

      const positionKey = getPositionKey(position);
      const bonusData = employeeBonusData[employee.id] || {
        totalPoints: 0,
        positivePoints: 0,
        negativePoints: 0,
        hasBonusSystem: false,
        city_id: selectedCity.value,
      };

      // Determine bonus level based on points
      const bonusLevelName = "Keine Stufe"; // This will be replaced by calculateEmployeeWages
      const hourlyWage = 0; // This will be replaced by calculateEmployeeWages
      const monthlyWage = 0; // This will be replaced by calculateEmployeeWages

      return {
        ...employee,
        name,
        position,
        positionKey,
        totalPoints: bonusData.totalPoints || 0,
        positivePoints: bonusData.positivePoints || 0,
        negativePoints: bonusData.negativePoints || 0,
        city_id: bonusData.city_id || selectedCity.value,
        hasBonusSystem: bonusData.hasBonusSystem || false,
        bonusLevel: bonusLevelName,
        hourlyWage: hourlyWage,
        monthlyWage: monthlyWage,
        rawBonusData: bonusData,
      };
    });
  } catch (error) {
    console.error("Fehler beim Laden der Mitarbeiterdaten:", error);
    throw error;
  }
};

// Calculate employee wages based on points and bonus levels
const calculateEmployeeWages = (employees, bonusLevels) => {
  return employees.map((employee) => {
    // Find applicable bonus level for this employee's points
    const cityBonusLevels = bonusLevels
      .filter((level) => level.city_id === employee.city_id)
      .sort((a, b) => b.min_points - a.min_points); // Sort by min_points descending

    let applicableLevel = null;

    // For employees with 0 or negative points, automatically assign the lowest level
    if (employee.totalPoints <= 0 && cityBonusLevels.length > 0) {
      applicableLevel = [...cityBonusLevels].sort(
        (a, b) => a.min_points - b.min_points,
      )[0];
    } else {
      for (const level of cityBonusLevels) {
        if (employee.totalPoints >= level.min_points) {
          applicableLevel = level;
          break;
        }
      }
    }

    // Calculate estimated monthly wages based on level
    let hourlyWage = 0;
    let bonusLevelName = "Keine Stufe";

    if (applicableLevel) {
      // Get proper hourly wage based on position
      const position = employee.position;
      const positionKey = getPositionKey(position);
      const wageKey = `hourly_wage_${positionKey}`;

      // If employee has penalty, use the lowest level
      if (employee.hasPenalty && cityBonusLevels.length > 0) {
        const lowestLevel = [...cityBonusLevels].sort(
          (a, b) => a.min_points - b.min_points,
        )[0];
        hourlyWage = lowestLevel[wageKey] || 0;
      } else {
        hourlyWage = applicableLevel[wageKey] || 0;
      }

      bonusLevelName = applicableLevel.level_name || "Unbekannte Stufe";
    }

    // Assuming 160 hours per month (40 hours per week * 4 weeks)
    const monthlyWage = hourlyWage * 160;

    return {
      ...employee,
      bonusLevel: bonusLevelName,
      hourlyWage: hourlyWage,
      monthlyWage: monthlyWage,
    };
  });
};

// Helper function to get position key
function getPositionKey(position) {
  if (!position) return "";
  return positionMap[position] || position.toLowerCase();
}

// Process the fetched data to calculate original summary data
const calculateOriginalData = () => {
  const employees = bonusConfiguration.value.employeeData;
  const bonusLevels = bonusConfiguration.value.bonusLevels;

  // Add wage information to employees
  const employeesWithWages = calculateEmployeeWages(employees, bonusLevels);

  // Calculate totals
  const employeeCount = employeesWithWages.length;
  const totalPoints = employeesWithWages.reduce(
    (sum, emp) => sum + emp.totalPoints,
    0,
  );
  const avgPointsPerEmployee =
    employeeCount > 0 ? totalPoints / employeeCount : 0;
  const totalCost = employeesWithWages.reduce(
    (sum, emp) => sum + emp.monthlyWage,
    0,
  );

  return {
    employeeCount,
    totalPoints,
    avgPointsPerEmployee,
    totalCost,
    details: employeesWithWages,
  };
};

// Update simulation results based on simulator components output
const updateSimulation = async (simulationResult) => {
  if (!simulationResult || !simulationResult.type) return;

  isLoading.value = true;

  try {
    // Get current simulation data
    const currentSimulationType = simulatedData.value.simulationType || null;
    const currentSimulationData = simulatedData.value.simulationData || {};

    // Merge the new simulation data with existing data to support multiple simulation types
    const updatedSimulationData = mergeSimulationData(
      currentSimulationType,
      currentSimulationData,
      simulationResult.type,
      simulationResult.data,
    );

    // Make a fresh copy of the original data
    const updatedData = JSON.parse(JSON.stringify(originalData.value));

    // Process the simulation with all available data
    await processSimulation(updatedData, updatedSimulationData);

    // Update the simulatedData ref with our modified data
    simulatedData.value = {
      ...updatedData,
      simulationType: getSimulationType(updatedSimulationData),
      simulationData: updatedSimulationData,
    };
  } catch (error) {
    console.error("Fehler bei der Simulation:", error);
    showError("Fehler bei der Simulation");
  } finally {
    isLoading.value = false;
  }
};

// Merge existing simulation data with new data from child components
const mergeSimulationData = (currentType, currentData, newType, newData) => {
  // Start with current data (or empty object)
  const merged = { ...currentData };

  // Merge based on the simulation type
  if (newType === "hotel-points") {
    merged.hotels = newData.hotels;
  } else if (newType === "bonus-events") {
    merged.bonuses = newData.bonuses;
    merged.maluses = newData.maluses;
  } else if (newType === "levels") {
    merged.bonusLevels = newData.bonusLevels;
  }

  return merged;
};

// Determine composite simulation type
const getSimulationType = (simulationData) => {
  const hasHotels = simulationData.hotels && simulationData.hotels.length > 0;
  const hasBonuses =
    (simulationData.bonuses && simulationData.bonuses.length > 0) ||
    (simulationData.maluses && simulationData.maluses.length > 0);
  const hasLevels =
    simulationData.bonusLevels && simulationData.bonusLevels.length > 0;

  // Create a composite type based on what data is present
  const types = [];
  if (hasHotels) types.push("hotel-points");
  if (hasBonuses) types.push("bonus-events");
  if (hasLevels) types.push("levels");

  if (types.length > 1) return "combo";
  if (types.length === 1) return types[0];
  return null;
};

// Process all simulation data and apply changes
const processSimulation = async (data, simulationData) => {
  // Get current period dates
  const startDate = selectedPeriod.value.startDate;
  const endDate = selectedPeriod.value.endDate;

  // Get employee IDs
  const employeeIds = bonusConfiguration.value.employeeData.map(
    (emp) => emp.id,
  );
  if (employeeIds.length === 0) return;

  try {
    // Prepare simulation parameters
    const simulationParams = {
      employeeIds,
      startDate,
      endDate,
    };

    // Add simulated hotel points if available
    if (simulationData.hotels && simulationData.hotels.length > 0) {
      // Transform hotel data to the format expected by the simulator
      simulationParams.simulatedHotelPoints = simulationData.hotels.map(
        (hotel) => {
          return {
            id: hotel.id,
            hotel_id: hotel.hotel_id || hotel.id,
            clerk_points: Number(hotel.clerk_points || 0),
            senior_points: Number(hotel.senior_points || 0),
            effective_from: format(startDate, "yyyy-MM-dd"),
          };
        },
      );
    }

    // Add simulated bonus/malus points if available
    if (
      (simulationData.bonuses && simulationData.bonuses.length > 0) ||
      (simulationData.maluses && simulationData.maluses.length > 0)
    ) {
      simulationParams.simulatedBonuses = prepareSimulatedBonuses(
        bonusConfiguration.value.bonuses,
        simulationData.bonuses || [],
        simulationData.maluses || [],
      );
    }

    // Add simulated bonus levels if available
    if (simulationData.bonusLevels && simulationData.bonusLevels.length > 0) {
      // Ensure each bonus level has the proper structure and city_id is set
      simulationParams.simulatedBonusLevels = simulationData.bonusLevels.map(
        (level) => ({
          ...level,
          // Ensure these are numbers
          hourly_wage_bronze: Number(level.hourly_wage_bronze || 0),
          hourly_wage_silver: Number(level.hourly_wage_silver || 0),
          hourly_wage_gold: Number(level.hourly_wage_gold || 0),
          hourly_wage_shiftleader: Number(level.hourly_wage_shiftleader || 0),
          min_points: Number(level.min_points || 0),
          // Make sure city_id is set
          city_id: level.city_id || selectedCity.value,
        }),
      );

      // Also provide city IDs for the createSimulatedCityBonusInfo function
      simulationParams.cityIds = [selectedCity.value];
    }

    // Call the simulation API with all parameters
    const simulationResults =
      await BonusCalculatorService.simulateBonusCalculation(simulationParams);

    // Update the employee data with simulated points
    const employeesWithUpdatedBonus = data.details.map((employee) => {
      // Find the corresponding simulated results
      const simResult = simulationResults[employee.id] || null;

      if (simResult) {
        return {
          ...employee,
          totalPoints: simResult.totalPoints,
          positivePoints: simResult.positivePoints,
          negativePoints: simResult.negativePoints,
          rawBonusData: {
            ...employee.rawBonusData,
            ...simResult,
          },
        };
      }

      return employee;
    });

    // Calculate new wages with updated points
    const employeesWithWages = calculateEmployeeWages(
      employeesWithUpdatedBonus,
      simulationData.bonusLevels || bonusConfiguration.value.bonusLevels,
    );

    // Update data with simulated values
    data.details = employeesWithWages;
    data.totalPoints = employeesWithWages.reduce(
      (sum, emp) => sum + emp.totalPoints,
      0,
    );
    data.avgPointsPerEmployee =
      data.employeeCount > 0 ? data.totalPoints / data.employeeCount : 0;
    data.totalCost = employeesWithWages.reduce(
      (sum, emp) => sum + emp.monthlyWage,
      0,
    );

    // Calculate summary statistics
    simulationData.summary = {
      totalPointsChange: data.totalPoints - originalData.value.totalPoints,
      estimatedCostChange: data.totalCost - originalData.value.totalCost,
      employeesAffected: employeesWithWages.filter(
        (emp) =>
          emp.totalPoints !==
          originalData.value.details.find((o) => o.id === emp.id)?.totalPoints,
      ).length,
    };
  } catch (error) {
    console.error("Fehler bei der Simulation:", error);
    throw error;
  }
};

// Helper function to prepare simulated bonuses array by applying changes
const prepareSimulatedBonuses = (
  originalBonuses,
  bonusChanges = [],
  malusChanges = [],
) => {
  // Create a copy of the original bonuses array
  const simulatedBonuses = JSON.parse(JSON.stringify(originalBonuses));

  // Create a map for quicker lookups
  const bonusMap = new Map();
  simulatedBonuses.forEach((bonus) => {
    bonusMap.set(bonus.id, bonus);
  });

  // Apply bonus changes
  bonusChanges.forEach((change) => {
    if (bonusMap.has(change.id)) {
      const bonus = bonusMap.get(change.id);
      bonus.points = Number(change.simulatedPoints);
    }
  });

  // Apply malus changes
  malusChanges.forEach((change) => {
    if (bonusMap.has(change.id)) {
      const malus = bonusMap.get(change.id);
      malus.points = Number(change.simulatedPoints);

      // Update penalty months if applicable
      if (
        change.simulatedPenaltyMonths !== undefined &&
        malus.bonus_penalty_months !== null
      ) {
        malus.bonus_penalty_months = Number(change.simulatedPenaltyMonths);
      }
    }
  });

  return simulatedBonuses;
};

// Formatting helpers
const formatCurrency = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

const formatCurrencyDifference = (value) => {
  const prefix = value > 0 ? "+" : "";
  return (
    prefix +
    new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value)
  );
};

const formatDifference = (value, decimals = 0) => {
  const prefix = value > 0 ? "+" : "";
  return prefix + value.toFixed(decimals);
};

// Watch for period changes
watch(selectedPeriod, () => {
  if (selectedPeriod.value) {
    // Store current simulation settings before period change
    const previousSimulationType = simulatedData.value.simulationType;
    const previousSimulationData = simulatedData.value.simulationData;

    loadSimulationData().then(() => {
      // After loading new data, reapply simulation if one was active
      if (previousSimulationType && previousSimulationData) {
        reapplySimulation(previousSimulationType, previousSimulationData);
      }
    });
  }
});

onMounted(() => {
  // Set the most recent month as default
  if (availablePeriods.value.length > 0) {
    selectedPeriod.value = availablePeriods.value[0].value;
  }
});

// Helper function to get class for differences
const getDiffClass = (value, isPoints = false) => {
  if (value === 0) return "";

  if (isPoints) {
    // For points, positive is good
    return value > 0 ? "success--text" : "error--text";
  } else {
    // For wages, negative is good (cost saving)
    return value < 0 ? "success--text" : "error--text";
  }
};
</script>

<style scoped>
.bonus-simulator {
  background-color: #f8f9fa;
}

.summary-card {
  transition: all 0.2s ease;
}

.summary-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.level-changed {
  background-color: #fff59d;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}
</style>
