import {
  format,
  startOfMonth,
  endOfMonth,
  isFriday,
  isSaturday,
  isSunday,
  addMonths,
  isWithinInterval,
  startOfDay,
  parseISO,
  isValid,
} from "date-fns";
import { supabase } from "@/supabase";

/**
 * Service to handle bonus calculation for employees
 */
class BonusCalculatorService {
  constructor() {
    // Constants for bonus IDs
    this.EMPLOYEE_DATA_MATCHING_CITY_ID = 5; // ID to match employee city
    this.WEEKEND_BONUS_ID = 5; // ID for "Dienst am Wochenende" bonus
    this.SHIFT_OTHER_CITY_BONUS_ID = 23; // ID für "Einsatz am Außenstandort" bonus
    this.TRAINING_SHIFT_BONUS_ID = 29; // ID for "Einarbeitung" bonus
    this.FAILED_CHECK_MALUS_ID = 17; // ID for "Nichtbestehen des Mitarbeiterchecks" malus
    this.NO_MISSED_SHIFT_BONUS_ID = 1; // ID for "Kein Ausfall (pro Monat)" bonus
    this.NO_LATE_BONUS_ID = 2; // ID for "Keine Verspätung (pro Monat)" bonus
  }

  // Individual employee calculation methods

  async calculateBonusForEmployee(
    employeeId,
    startDate,
    endDate,
    usePublicShifts = false,
  ) {
    // Main function to calculate bonus for a single employee
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    const isCurrentMonth =
      format(new Date(), "yyyy-MM") === format(startDate, "yyyy-MM");

    try {
      // 1. Get employee's city ID
      const employeeCityId = await this.fetchEmployeeCityId(employeeId);
      if (!employeeCityId) {
        return { error: "No city assigned to employee" };
      }

      // 2. Check if bonus system exists for this city
      const { hasBonusSystem, cityName } =
        await this.checkBonusLevelsForCity(employeeCityId);
      if (!hasBonusSystem) {
        return {
          hasBonusSystem: false,
          cityName,
          employeeCityId,
        };
      }

      // 3. Fetch all needed data
      const {
        bonuses,
        employeeHotels,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        penaltyEvents,
        bonusHotelPoints,
      } = await this.fetchBonusDataForEmployee(
        employeeId,
        formattedStartDate,
        formattedEndDate,
        usePublicShifts,
      );

      // 4. Check if there's an active penalty
      const penaltyStatus = this.checkIfPenaltyActive(penaltyEvents, startDate);

      // 5. Process all data
      const shiftsWithBonus = this.calculateShiftBonuses(
        shifts,
        employeeHotels,
        bonuses,
        employeeCityId,
        bonusHotelPoints,
      );
      const automaticEvents = this.processAutomaticEvents(dateEvents, bonuses);
      const monthlyBonuses = this.processMonthlyBonuses(
        dateEvents,
        bonuses,
        shifts,
      );
      const failedChecks = this.processEmployeeChecks(employeeChecks, bonuses);

      // 6. Calculate totals
      const totals = this.calculateTotals(
        shiftsWithBonus,
        manualEvents,
        automaticEvents,
        monthlyBonuses,
        failedChecks,
        isCurrentMonth,
      );

      // 7. Return complete result
      return {
        hasBonusSystem: true,
        cityName,
        employeeCityId,
        shiftsWithBonus,
        manualEvents,
        automaticEvents,
        monthlyBonuses,
        failedChecks,
        ...penaltyStatus,
        ...totals,
      };
    } catch (error) {
      console.error("Error calculating bonus for employee:", error);
      throw error;
    }
  }

  async fetchBonusDataForEmployee(
    employeeId,
    startDate,
    endDate,
    usePublicShifts = false,
  ) {
    try {
      // First fetch basic data in parallel
      const [
        bonuses,
        employeeHotels,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        { penaltyEvents },
      ] = await Promise.all([
        this.fetchBonuses(),
        this.fetchEmployeeHotelQualifications(employeeId),
        this.fetchShifts(employeeId, startDate, endDate, usePublicShifts),
        this.fetchManualBonusEvents(employeeId, startDate, endDate),
        this.fetchDateEvents(employeeId, startDate, endDate, usePublicShifts),
        this.fetchEmployeeChecks(employeeId, startDate, endDate),
        this.fetchPenaltyBonuses(employeeId, usePublicShifts),
      ]);

      const hotelIds = shifts.map((shift) => shift.hotels?.id).filter(Boolean);
      const bonusHotelPoints = await this.fetchBonusHotelPoints(hotelIds);

      return {
        bonuses,
        employeeHotels,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        penaltyEvents,
        bonusHotelPoints,
      };
    } catch (error) {
      console.error("Error fetching bonus data for employee:", error);
      throw error;
    }
  }

  async fetchEmployeeCityId(employeeId) {
    try {
      const { data, error } = await supabase
        .from("employee_data_matching")
        .select("value_id")
        .eq("employee_id", employeeId)
        .eq("key_id", this.EMPLOYEE_DATA_MATCHING_CITY_ID);

      if (error) throw error;

      if (data && data.length > 0) {
        return data[0].value_id;
      }
      return null;
    } catch (error) {
      console.error("Error fetching employee city:", error);
      throw error;
    }
  }

  async checkBonusLevelsForCity(cityId) {
    try {
      // First fetch city name
      const { data: cityData, error: cityError } = await supabase
        .from("cities")
        .select("city")
        .eq("id", cityId);

      if (cityError) throw cityError;

      let cityName = "diese Stadt";
      if (cityData && cityData.length > 0) {
        cityName = cityData[0].city;
      }

      // Check if bonus levels exist for this city
      const { count, error: bonusLevelsError } = await supabase
        .from("bonus_levels")
        .select("*", { count: "exact", head: true })
        .eq("city_id", cityId);

      if (bonusLevelsError) throw bonusLevelsError;

      // Return if bonus system exists and the city name
      return {
        hasBonusSystem: count > 0,
        cityName,
      };
    } catch (error) {
      console.error("Error checking bonus levels for city:", error);
      throw error;
    }
  }

  async fetchBonuses() {
    try {
      const { data, error } = await supabase
        .from("bonuses")
        .select("*")
        .is("deleted_at", null);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching bonuses:", error);
      throw error;
    }
  }

  async fetchEmployeeHotelQualifications(employeeId) {
    try {
      const { data, error } = await supabase
        .from("employees_hotels")
        .select("*")
        .eq("employee_id", employeeId);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching employee hotel qualifications:", error);
      throw error;
    }
  }

  async fetchShifts(employeeId, startDate, endDate, usePublicShifts = false) {
    const tableName = usePublicShifts ? "shifts_public" : "shifts";
    try {
      const { data, error } = await supabase
        .from(tableName)
        .select(
          "*, hotels(id, name, city_id, bonus_points_senior, bonus_points_clerk)",
        )
        .eq("employee_id", employeeId)
        .eq("check_id", 1)
        .gte("date", startDate)
        .lte("date", endDate);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching shifts:", error);
      throw error;
    }
  }

  async fetchManualBonusEvents(employeeId, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("bonus_employee_events")
        .select("*, bonus:bonuses(*)")
        .eq("employee_id", employeeId)
        .gte("event_date", startDate)
        .lte("event_date", endDate)
        .is("deleted_at", null);

      if (error) throw error;

      return (
        data.map((event) => {
          // Get the appropriate bonus value for this event's date
          const bonusValue = this.getBonusValueForDate(
            event.bonus,
            event.event_date,
          );

          return {
            id: event.id,
            event_date: event.event_date,
            bonus_id: event.bonus.id,
            bonus_name: event.bonus.name,
            points: bonusValue.points,
            is_positive: event.bonus.is_positive,
            comment: event.comment,
            extra_points: event.extra_points,
          };
        }) || []
      );
    } catch (error) {
      console.error("Error fetching manual events:", error);
      throw error;
    }
  }

  async fetchDateEvents(
    employeeId,
    startDate,
    endDate,
    usePublicShifts = false,
  ) {
    const tableName = usePublicShifts
      ? "employee_date_event_matching_public"
      : "employee_date_event_matching";
    try {
      // First get all event keys with bonus associations
      const { data: eventKeys, error: eventKeysError } = await supabase
        .from("employee_date_events_keys")
        .select("id, value, name, bonus_id")
        .or("bonus_id.not.is.null,value.eq.is_absent");

      if (eventKeysError) throw eventKeysError;
      if (!eventKeys || eventKeys.length === 0) {
        return [];
      }

      // Get all matching events for this employee and date range
      const { data: events, error: eventsError } = await supabase
        .from(tableName)
        .select("*")
        .eq("employee_id", employeeId)
        .gte("date", startDate)
        .lte("date", endDate)
        .eq("is_activated", true);

      if (eventsError) throw eventsError;
      if (!events || events.length === 0) {
        return [];
      }

      // Process all matching events by joining with the event keys manually
      const dateEvents = [];

      for (const event of events) {
        // For each event, find the corresponding key
        const matchingKey = eventKeys.find(
          (key) => key.id === event.employee_date_event_id,
        );

        // If we found a matching key with a bonus, or employee is absent, add this event
        if (
          matchingKey &&
          (matchingKey.bonus_id || matchingKey.value === "is_absent")
        ) {
          dateEvents.push({
            ...event,
            eventKey: matchingKey,
            is_late: matchingKey.value === "is_late",
            did_not_appear: matchingKey.value === "did_not_appear",
            is_absent_to_late: matchingKey.value === "is_absent_to_late",
          });
        }
      }

      return dateEvents;
    } catch (error) {
      console.error("Error fetching date events:", error);
      throw error;
    }
  }

  async fetchEmployeeChecks(employeeId, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("employee_check_form")
        .select("*, hotels(id, name)")
        .eq("employee_id", employeeId)
        .gte("date", startDate)
        .lte("date", endDate)
        .eq("is_passed", false)
        .eq("is_signed", true);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching employee checks:", error);
      throw error;
    }
  }

  async fetchPenaltyBonuses(employeeId, usePublicShifts = false) {
    const matchingTableName = usePublicShifts
      ? "employee_date_event_matching_public"
      : "employee_date_event_matching";
    try {
      // First, get all bonuses that have a penalty period
      const { data: penaltyBonuses, error: bonusesError } = await supabase
        .from("bonuses")
        .select("*")
        .not("bonus_penalty_months", "is", null)
        .is("deleted_at", null);

      if (bonusesError) throw bonusesError;
      if (!penaltyBonuses || penaltyBonuses.length === 0) {
        return { penaltyBonuses: [], penaltyEvents: [] };
      }

      const penaltyBonusIds = penaltyBonuses.map((bonus) => bonus.id);
      let penaltyEvents = [];

      // 1. First source: direct penalty events from bonus_employee_events
      const { data: directPenaltyData, error: directPenaltyError } =
        await supabase
          .from("bonus_employee_events")
          .select("*, bonus:bonuses(*)")
          .eq("employee_id", employeeId)
          .in("bonus_event_id", penaltyBonusIds)
          .is("deleted_at", null);

      if (directPenaltyError) throw directPenaltyError;

      if (directPenaltyData && directPenaltyData.length > 0) {
        const directPenaltyEvents = directPenaltyData.map((event) => {
          const relatedBonus = event.bonus;
          // Get version-specific values for this event date
          const bonusValue = this.getBonusValueForDate(
            relatedBonus,
            event.event_date,
          );

          return {
            ...event,
            penalty_months: bonusValue.bonus_penalty_months || 0,
            name: relatedBonus.name,
            source: "direct",
          };
        });

        penaltyEvents.push(...directPenaltyEvents);
      }

      // 2. Second source: Get event keys that correspond to penalty bonuses
      const { data: penaltyEventKeys, error: eventKeysError } = await supabase
        .from("employee_date_events_keys")
        .select("id, value, name, bonus_id")
        .in("bonus_id", penaltyBonusIds);

      if (eventKeysError) throw eventKeysError;

      if (penaltyEventKeys && penaltyEventKeys.length > 0) {
        const penaltyEventKeyIds = penaltyEventKeys.map((key) => key.id);

        // Get all events that match these penalty event keys
        const { data: dateEvents, error: matchingEventsError } = await supabase
          .from(matchingTableName)
          .select("*")
          .eq("employee_id", employeeId)
          .eq("is_activated", true)
          .in("employee_date_event_id", penaltyEventKeyIds);

        if (matchingEventsError) throw matchingEventsError;

        if (dateEvents && dateEvents.length > 0) {
          for (const event of dateEvents) {
            // Find the corresponding event key
            const eventKey = penaltyEventKeys.find(
              (key) => key.id === event.employee_date_event_id,
            );

            if (eventKey) {
              const relatedBonus = penaltyBonuses.find(
                (b) => b.id === eventKey.bonus_id,
              );

              if (relatedBonus) {
                // Use version-specific values based on event date
                const bonusValue = this.getBonusValueForDate(
                  relatedBonus,
                  event.date,
                );

                const penaltyEvent = {
                  id: event.id,
                  employee_id: event.employee_id,
                  event_date: event.date,
                  bonus_event_id: eventKey.bonus_id,
                  penalty_months:
                    bonusValue.bonus_penalty_months ||
                    relatedBonus.bonus_penalty_months,
                  name: relatedBonus.name,
                  source: "date_event",
                  key_name: eventKey.name,
                };

                penaltyEvents.push(penaltyEvent);
              }
            }
          }
        }
      }

      // Sort all penalty events by date
      penaltyEvents.sort(
        (a, b) => new Date(b.event_date) - new Date(a.event_date),
      );

      return { penaltyBonuses, penaltyEvents };
    } catch (error) {
      console.error("Error fetching penalty bonuses:", error);
      throw error;
    }
  }

  async fetchBonusHotelPoints(hotelIds) {
    try {
      if (!hotelIds || hotelIds.length === 0) return [];

      const { data, error } = await supabase
        .from("bonus_hotel_points")
        .select("*")
        .in("hotel_id", hotelIds);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching bonus hotel points:", error);
      throw error;
    }
  }

  getHotelBonusPointsForDate(bonusHotelPoints, hotelId, shiftDate) {
    if (!bonusHotelPoints || !hotelId)
      return { clerk_points: null, senior_points: null };

    const hotelPointsData = bonusHotelPoints.filter(
      (p) => p.hotel_id === hotelId,
    );
    if (hotelPointsData.length === 0)
      return { clerk_points: null, senior_points: null };

    const date =
      typeof shiftDate === "string" ? parseISO(shiftDate) : shiftDate;
    if (!isValid(date)) return { clerk_points: null, senior_points: null };

    // Sort points by effective date - newest first
    const sortedPoints = [...hotelPointsData].sort(
      (a, b) => new Date(b.effective_from) - new Date(a.effective_from),
    );

    // Find the applicable current version
    for (const pointSet of sortedPoints) {
      const effectiveFrom = parseISO(pointSet.effective_from);

      if (date >= effectiveFrom) {
        return {
          clerk_points: pointSet.clerk_points,
          senior_points: pointSet.senior_points,
        };
      }
    }

    // If no current version applies, check historical values
    for (const pointSet of sortedPoints) {
      if (
        Array.isArray(pointSet.historical_values) &&
        pointSet.historical_values.length > 0
      ) {
        for (const historicalValue of pointSet.historical_values) {
          const histFrom = parseISO(historicalValue.effective_from);
          const histTo = parseISO(historicalValue.effective_to);

          if (isWithinInterval(date, { start: histFrom, end: histTo })) {
            return {
              clerk_points: historicalValue.clerk_points,
              senior_points: historicalValue.senior_points,
            };
          }
        }
      }
    }

    // If no match found, return null values
    return { clerk_points: null, senior_points: null };
  }

  calculateShiftBonuses(
    shifts,
    employeeHotels,
    bonuses,
    employeeCityId,
    bonusHotelPoints = [],
  ) {
    const shiftsWithBonus = shifts.map((shift) => {
      const hotel = shift.hotels;
      const isShiftWeekend = this.isWeekendShift(new Date(shift.date));
      const isExternalCity = employeeCityId && hotel.city_id !== employeeCityId;
      const isTraining = shift.is_training;

      // Check if employee is qualified as senior for this hotel
      const hotelQualification = employeeHotels.find(
        (eh) => eh.hotel_id === hotel.id,
      );

      const isSeniorQualified =
        hotelQualification && hotelQualification.is_senior;

      // Get versioned bonus points for this hotel and date
      const hotelBonusPoints = this.getHotelBonusPointsForDate(
        bonusHotelPoints,
        hotel.id,
        shift.date,
      );

      let points = 0;
      let usedSeniorBonus = false;

      // If it's a training shift, use training bonus points
      if (isTraining) {
        const trainingBonusObj = bonuses.find(
          (b) => b.id === this.TRAINING_SHIFT_BONUS_ID,
        );
        const versionedBonus = trainingBonusObj
          ? this.getBonusValueForDate(trainingBonusObj, shift.date)
          : null;
        points = versionedBonus ? versionedBonus.points : 0;
      } else {
        // Normal shift points calculation
        const seniorPoints = hotelBonusPoints.senior_points ?? 0;
        const clerkPoints = hotelBonusPoints.clerk_points ?? 0;

        // Determine points based on role and qualification
        if (shift.is_senior) {
          points = seniorPoints;
        } else {
          // If employee is a clerk but qualified as senior, use senior points
          if (isSeniorQualified && seniorPoints) {
            points = seniorPoints;
            usedSeniorBonus = true;
          } else {
            // Otherwise use clerk points or fallback to senior points
            points = clerkPoints || seniorPoints || 0;
          }
        }
      }

      // Add weekend bonus if applicable
      let weekendBonus = 0;
      if (isShiftWeekend) {
        const weekendBonusObj = bonuses.find(
          (b) => b.id === this.WEEKEND_BONUS_ID,
        );
        // Use versioned bonus points based on shift date
        const versionedBonus = weekendBonusObj
          ? this.getBonusValueForDate(weekendBonusObj, shift.date)
          : null;
        weekendBonus = versionedBonus ? versionedBonus.points : 0;
      }

      // Add external city bonus if applicable
      let externalCityBonus = 0;
      if (isExternalCity) {
        const externalCityBonusObj = bonuses.find(
          (b) => b.id === this.SHIFT_OTHER_CITY_BONUS_ID,
        );
        // Use versioned bonus points based on shift date
        const versionedBonus = externalCityBonusObj
          ? this.getBonusValueForDate(externalCityBonusObj, shift.date)
          : null;
        externalCityBonus = versionedBonus ? versionedBonus.points : 0;
      }

      return {
        ...shift,
        date: shift.date,
        hotel_name: hotel.name,
        points,
        isWeekend: isShiftWeekend,
        weekendBonus: isShiftWeekend ? weekendBonus : 0,
        isExternalCity: isExternalCity,
        externalCityBonus: isExternalCity ? externalCityBonus : 0,
        usedSeniorBonus,
        isTraining,
      };
    });

    // Sort the shifts by date in ascending order
    return shiftsWithBonus.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
  }

  processAutomaticEvents(dateEvents, bonuses) {
    if (!dateEvents || dateEvents.length === 0) {
      return [];
    }

    if (dateEvents.length === 0) {
      return [];
    }

    // Group events by bonus ID
    const groupedEvents = {};

    dateEvents.forEach((event) => {
      const bonusId = event.eventKey.bonus_id;

      if (!groupedEvents[bonusId]) {
        const bonusInfo = bonuses.find((b) => b.id === bonusId);
        if (bonusInfo) {
          // Use bonus value for the event date
          const bonusValue = this.getBonusValueForDate(bonusInfo, event.date);
          groupedEvents[bonusId] = {
            id: bonusId,
            name: bonusInfo.name,
            points: bonusValue.points,
            is_positive: bonusInfo.is_positive,
            dates: [],
            events: [],
          };
        }
      }

      if (groupedEvents[bonusId]) {
        // Check if this exact date already exists in the array to prevent duplicates
        const dateExists = groupedEvents[bonusId].dates.some(
          (existingDate) => existingDate === event.date,
        );

        if (!dateExists) {
          groupedEvents[bonusId].dates.push(event.date);
          groupedEvents[bonusId].events.push(event);
        }
      }
    });

    // Convert to array and sort by positive/negative and then by name
    return Object.values(groupedEvents).sort((a, b) => {
      // First sort by positive/negative
      if (a.is_positive !== b.is_positive) {
        return a.is_positive ? -1 : 1;
      }
      // Then by name
      return a.name.localeCompare(b.name);
    });
  }

  processMonthlyBonuses(dateEvents, bonuses, shifts) {
    const monthlyBonuses = [];

    // Determine the month-end date from shifts or dateEvents
    let monthEndDate;
    if (shifts.length > 0) {
      // Sort dates and get the latest one from shifts
      const sortedDates = [...shifts].sort(
        (a, b) => new Date(b.date) - new Date(a.date),
      );
      monthEndDate = new Date(sortedDates[0].date);
    } else if (dateEvents.length > 0) {
      // Sort dates and get the latest one from dateEvents
      const sortedDates = [...dateEvents].sort(
        (a, b) => new Date(b.date) - new Date(a.date),
      );
      monthEndDate = new Date(sortedDates[0].date);
    } else {
      // Fallback to current date if no dates available
      monthEndDate = new Date();
    }

    // Process "No missed shifts" monthly bonus
    // Check for date events with specific event keys
    const missedShiftEvents = dateEvents.filter((event) => {
      const value = event.eventKey.value;
      return (
        value === "did_not_appear" ||
        value === "is_absent_to_late" ||
        value === "is_absent"
      );
    });

    const noMissedShiftsBonus = bonuses.find(
      (b) => b.id === this.NO_MISSED_SHIFT_BONUS_ID,
    );

    if (noMissedShiftsBonus && missedShiftEvents.length === 0) {
      const bonusValue = this.getBonusValueForDate(
        noMissedShiftsBonus,
        monthEndDate,
      );

      monthlyBonuses.push({
        name: noMissedShiftsBonus.name,
        points: bonusValue.points,
        is_positive: noMissedShiftsBonus.is_positive,
      });
    }

    // Process "No late arrivals" monthly bonus
    const lateEvents = dateEvents.filter(
      (event) =>
        this.hasShiftOnDate(shifts, event.date) &&
        event.eventKey.value === "is_late",
    );

    const noLateBonus = bonuses.find((b) => b.id === this.NO_LATE_BONUS_ID);

    if (noLateBonus && lateEvents.length === 0) {
      const bonusValue = this.getBonusValueForDate(noLateBonus, monthEndDate);

      monthlyBonuses.push({
        name: noLateBonus.name,
        points: bonusValue.points,
        is_positive: noLateBonus.is_positive,
      });
    }

    return monthlyBonuses;
  }

  processEmployeeChecks(employeeChecks, bonuses) {
    const failedCheckMalus = bonuses.find(
      (b) => b.id === this.FAILED_CHECK_MALUS_ID,
    );

    if (failedCheckMalus && employeeChecks.length > 0) {
      return employeeChecks.map((check) => {
        // Get the appropriate bonus value for this check's date
        const bonusValue = this.getBonusValueForDate(
          failedCheckMalus,
          check.date,
        );

        return {
          id: check.id,
          date: check.date,
          hotel_id: check.hotel_id,
          hotel_name: check.hotels?.name || "",
          points: bonusValue.points,
        };
      });
    } else {
      return [];
    }
  }

  checkIfPenaltyActive(penaltyEvents, selectedMonthDate) {
    if (!penaltyEvents || penaltyEvents.length === 0) {
      return { hasPenalty: false };
    }

    // Check each penalty event to see if the selected month falls within its penalty period
    for (const event of penaltyEvents) {
      const eventDate = startOfMonth(new Date(event.event_date));
      const penaltyEndDate = endOfMonth(
        addMonths(eventDate, event.penalty_months - 1), // Adjusted to include current month in count
      );

      // Check if selected month is within the penalty period
      if (
        isWithinInterval(selectedMonthDate, {
          start: eventDate,
          end: penaltyEndDate,
        })
      ) {
        // Return the details of the active penalty
        return {
          hasPenalty: true,
          penaltyStartDate: eventDate,
          penaltyEndDate,
          penaltyMonths: event.penalty_months,
          penaltyBonusName: event.name,
        };
      }
    }

    return { hasPenalty: false };
  }

  calculateTotals(
    shiftsWithBonus,
    manualEvents,
    automaticEvents,
    monthlyBonuses,
    failedChecks,
    isCurrentMonth = false,
  ) {
    let positivePoints = 0;
    let negativePoints = 0;
    let confirmedPositivePoints = 0;
    let confirmedNegativePoints = 0;
    let pendingPositivePoints = 0;
    let pendingNegativePoints = 0;
    const today = new Date();

    // Calculate points from shifts
    shiftsWithBonus.forEach((shift) => {
      const shiftPoints =
        shift.points +
        (shift.weekendBonus || 0) +
        (shift.externalCityBonus || 0);

      positivePoints += shiftPoints;

      if (isCurrentMonth && this.isFutureDate(shift.date)) {
        pendingPositivePoints += shiftPoints;
      }
    });

    // Calculate points from manual events
    manualEvents.forEach((event) => {
      if (event.is_positive) {
        positivePoints += event.points + event.extra_points;
        if (isCurrentMonth && this.isFutureDate(event.event_date)) {
          pendingPositivePoints += event.points + event.extra_points;
        }
      } else {
        negativePoints += event.points;
        if (isCurrentMonth && this.isFutureDate(event.event_date)) {
          pendingNegativePoints += event.points;
        }
      }
    });

    // Calculate points from automatic events
    automaticEvents.forEach((eventGroup) => {
      const groupPoints = eventGroup.points * eventGroup.dates.length;

      if (eventGroup.is_positive) {
        positivePoints += groupPoints;

        if (isCurrentMonth) {
          // Count pending points for future dates
          const pendingDates = eventGroup.dates.filter((date) =>
            this.isFutureDate(date),
          ).length;
          pendingPositivePoints += eventGroup.points * pendingDates;
        }
      } else {
        negativePoints += groupPoints;

        if (isCurrentMonth) {
          // Count pending points for future dates
          const pendingDates = eventGroup.dates.filter((date) =>
            this.isFutureDate(date),
          ).length;
          pendingNegativePoints += eventGroup.points * pendingDates;
        }
      }
    });

    // Calculate points from monthly bonuses
    monthlyBonuses.forEach((bonus) => {
      if (bonus.is_positive) {
        positivePoints += bonus.points;
        if (isCurrentMonth) {
          pendingPositivePoints += bonus.points; // Monthly bonuses are always pending in current month
        }
      } else {
        negativePoints += bonus.points;
        if (isCurrentMonth) {
          pendingNegativePoints += bonus.points;
        }
      }
    });

    // Calculate points from failed checks
    failedChecks.forEach((check) => {
      negativePoints += check.points;
      if (isCurrentMonth && this.isFutureDate(check.date)) {
        pendingNegativePoints += check.points;
      }
    });

    // Calculate confirmed points for current month
    confirmedPositivePoints = positivePoints - pendingPositivePoints;
    confirmedNegativePoints = negativePoints - pendingNegativePoints;

    return {
      positivePoints,
      negativePoints,
      totalPoints: positivePoints - negativePoints,
      confirmedPositivePoints,
      confirmedNegativePoints,
      confirmedTotalPoints: confirmedPositivePoints - confirmedNegativePoints,
      pendingPositivePoints,
      pendingNegativePoints,
      pendingTotalPoints: pendingPositivePoints - pendingNegativePoints,
    };
  }

  // Utility functions
  hasShiftOnDate(shifts, dateString) {
    return shifts.some((shift) => shift.date === dateString);
  }

  isWeekendShift(date) {
    return isFriday(date) || isSaturday(date) || isSunday(date);
  }

  isFutureDate(dateString) {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date > today;
  }

  formatDate(dateString) {
    return format(new Date(dateString), "dd.MM.yyyy");
  }

  // UI helper methods for calculations
  calculateShiftBonusTotal(shifts) {
    return shifts.reduce((total, shift) => {
      return (
        total +
        shift.points +
        (shift.weekendBonus || 0) +
        (shift.externalCityBonus || 0)
      );
    }, 0);
  }

  calculateManualEventsTotal(events) {
    return events.reduce((total, event) => {
      return event.is_positive
        ? total + event.points + event.extra_points
        : total - event.points;
    }, 0);
  }

  calculateAutomaticEventsTotal(events) {
    return events.reduce((total, event) => {
      return event.is_positive
        ? total + event.dates.length * event.points
        : total - event.dates.length * event.points;
    }, 0);
  }

  calculateMonthlyBonusTotal(bonuses) {
    return bonuses.reduce((total, bonus) => {
      return bonus.is_positive ? total + bonus.points : total - bonus.points;
    }, 0);
  }

  calculateCheckBonusTotal(checks) {
    return checks.reduce((total, check) => total - check.points, 0);
  }

  // Pending calculation methods
  getPendingShiftBonusTotal(shifts, isCurrentMonth) {
    if (!isCurrentMonth) return 0;

    return shifts
      .filter((shift) => this.isFutureDate(shift.date))
      .reduce((total, shift) => {
        return (
          total +
          shift.points +
          (shift.weekendBonus || 0) +
          (shift.externalCityBonus || 0)
        );
      }, 0);
  }

  getConfirmedShiftBonusTotal(shifts, isCurrentMonth) {
    if (!isCurrentMonth) return this.calculateShiftBonusTotal(shifts);

    return shifts
      .filter((shift) => !this.isFutureDate(shift.date))
      .reduce((total, shift) => {
        return (
          total +
          shift.points +
          (shift.weekendBonus || 0) +
          (shift.externalCityBonus || 0)
        );
      }, 0);
  }

  getPendingManualEventsTotal(events, isCurrentMonth) {
    if (!isCurrentMonth) return 0;

    return events
      .filter((event) => this.isFutureDate(event.event_date))
      .reduce((total, event) => {
        return event.is_positive
          ? total + event.points + event.extra_points
          : total - event.points;
      }, 0);
  }

  getConfirmedManualEventsTotal(events, isCurrentMonth) {
    if (!isCurrentMonth) return this.calculateManualEventsTotal(events);

    return events
      .filter((event) => !this.isFutureDate(event.event_date))
      .reduce((total, event) => {
        return event.is_positive
          ? total + event.points + event.extra_points
          : total - event.points;
      }, 0);
  }

  getPendingAutoEventsTotal(eventGroups, isCurrentMonth) {
    if (!isCurrentMonth) return 0;

    return eventGroups.reduce((total, eventGroup) => {
      const pendingDates = eventGroup.dates.filter((date) =>
        this.isFutureDate(date),
      );
      const pendingPoints = pendingDates.length * eventGroup.points;
      return eventGroup.is_positive
        ? total + pendingPoints
        : total - pendingPoints;
    }, 0);
  }

  getConfirmedAutoEventsTotal(eventGroups, isCurrentMonth) {
    if (!isCurrentMonth) return this.calculateAutomaticEventsTotal(eventGroups);

    return eventGroups.reduce((total, eventGroup) => {
      const confirmedDates = eventGroup.dates.filter(
        (date) => !this.isFutureDate(date),
      );
      const confirmedPoints = confirmedDates.length * eventGroup.points;
      return eventGroup.is_positive
        ? total + confirmedPoints
        : total - confirmedPoints;
    }, 0);
  }

  getPendingMonthlyBonusPoints(bonuses, isCurrentMonth) {
    if (!isCurrentMonth) return 0;
    return bonuses.reduce((total, bonus) => {
      return bonus.is_positive ? total + bonus.points : total - bonus.points;
    }, 0);
  }

  // Event statistics methods
  getWeekendShiftsCount(shifts) {
    return shifts.filter((shift) => shift.isWeekend).length;
  }

  getSeniorShiftsCount(shifts) {
    return shifts.filter((shift) => shift.is_senior).length;
  }

  getAveragePointsPerShift(shifts) {
    if (shifts.length === 0) return 0;

    const totalPoints = shifts.reduce((sum, shift) => {
      return (
        sum +
        shift.points +
        (shift.weekendBonus || 0) +
        (shift.externalCityBonus || 0)
      );
    }, 0);

    return totalPoints / shifts.length;
  }

  getTotalAutomaticEventCount(eventGroups) {
    return eventGroups.reduce((total, event) => {
      return total + event.dates.length;
    }, 0);
  }

  getPositiveAutomaticEventCount(eventGroups) {
    return eventGroups
      .filter((event) => event.is_positive)
      .reduce((total, event) => {
        return total + event.dates.length;
      }, 0);
  }

  getNegativeAutomaticEventCount(eventGroups) {
    return eventGroups
      .filter((event) => !event.is_positive)
      .reduce((total, event) => {
        return total + event.dates.length;
      }, 0);
  }

  /**
   * Bulk calculation methods
   *
   */

  async calculateBonusForMultipleEmployees(employeeIds, startDate, endDate) {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    const isCurrentMonth =
      format(new Date(), "yyyy-MM") === format(startDate, "yyyy-MM");

    try {
      // 1. Fetch all data for all employees at once
      const {
        bonuses,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        employeeHotels,
        bonusHotelPoints,
      } = await this.fetchBulkBonusData(
        employeeIds,
        formattedStartDate,
        formattedEndDate,
      );

      // 2. Fetch all employee city IDs at once
      const employeeCityMap = await this.fetchBulkEmployeeCityIds(employeeIds);

      // 3. Get unique city IDs for bonus level check
      const uniqueCityIds = [
        ...new Set(Object.values(employeeCityMap).filter((id) => id !== null)),
      ];

      // 4. Check bonus levels for all cities at once
      const cityBonusInfo =
        await this.checkBulkBonusLevelsForCities(uniqueCityIds);

      // 5. Fetch penalty bonuses for all employees at once
      const penaltyBonusesMap = await this.fetchBulkPenaltyBonuses(employeeIds);

      // 6. Process data for each employee
      const results = {};

      for (const employeeId of employeeIds) {
        // Get employee-specific data
        const employeeCityId = employeeCityMap[employeeId];
        if (!employeeCityId) {
          results[employeeId] = { error: "No city assigned to employee" };
          continue;
        }

        const cityInfo = cityBonusInfo[employeeCityId];
        if (!cityInfo || !cityInfo.hasBonusSystem) {
          results[employeeId] = {
            hasBonusSystem: false,
            cityName: cityInfo ? cityInfo.cityName : "diese Stadt",
            employeeCityId,
          };
          continue;
        }

        // Filter data for this employee
        const employeeShifts = shifts.filter(
          (shift) => shift.employee_id === employeeId,
        );
        const employeeManualEvents = manualEvents.filter(
          (event) => event.employee_id === employeeId,
        );
        const employeeDateEvents = dateEvents.filter(
          (event) => event.employee_id === employeeId,
        );
        const employeeCheckResults = employeeChecks.filter(
          (check) => check.employee_id === employeeId,
        );
        const employeeHotelQualifications = employeeHotels.filter(
          (qual) => qual.employee_id === employeeId,
        );

        // Get penalty data for this employee
        const penaltyEvents = penaltyBonusesMap[employeeId] || [];
        const penaltyStatus = this.checkIfPenaltyActive(
          penaltyEvents,
          startDate,
        );

        // Process employee data
        const shiftsWithBonus = this.calculateShiftBonuses(
          employeeShifts,
          employeeHotelQualifications,
          bonuses,
          employeeCityId,
          bonusHotelPoints,
        );
        const automaticEvents = this.processAutomaticEvents(
          employeeDateEvents,
          bonuses,
        );
        const monthlyBonuses = this.processMonthlyBonuses(
          employeeDateEvents,
          bonuses,
          employeeShifts,
        );
        const failedChecks = this.processEmployeeChecks(
          employeeCheckResults,
          bonuses,
        );

        // Calculate totals for this employee
        const totals = this.calculateTotals(
          shiftsWithBonus,
          employeeManualEvents,
          automaticEvents,
          monthlyBonuses,
          failedChecks,
          isCurrentMonth,
        );

        // Store results for this employee
        results[employeeId] = {
          hasBonusSystem: true,
          cityName: cityInfo.cityName,
          employeeCityId,
          shiftsWithBonus,
          manualEvents: employeeManualEvents,
          automaticEvents,
          monthlyBonuses,
          failedChecks,
          ...penaltyStatus,
          ...totals,
        };
      }

      return results;
    } catch (error) {
      console.error("Error calculating bonus for multiple employees:", error);
      throw error;
    }
  }

  /**
   * Simulates bonus calculation with optional custom parameters
   * @param {Object} config - Configuration object
   * @param {Array} config.employeeIds - Array of employee IDs to calculate for
   * @param {Date} config.startDate - Start date for calculation
   * @param {Date} config.endDate - End date for calculation
   * @param {Array} [config.simulatedBonuses] - Optional custom bonus/malus values
   * @param {Array} [config.simulatedHotelPoints] - Optional custom hotel points
   * @param {Array} [config.simulatedBonusLevels] - Optional custom bonus levels
   * @param {Object} [config.simulatedCityMap] - Optional custom employee city mappings
   * @returns {Object} Calculation results for each employee
   */
  async simulateBonusCalculation({
    employeeIds,
    startDate,
    endDate,
    simulatedBonuses = null,
    simulatedHotelPoints = null,
    simulatedBonusLevels = null,
    simulatedCityMap = null,
  }) {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    const isCurrentMonth =
      format(new Date(), "yyyy-MM") === format(startDate, "yyyy-MM");

    try {
      // 1. Fetch all data for all employees at once
      const {
        bonuses,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        employeeHotels,
        bonusHotelPoints,
      } = await this.fetchBulkBonusData(
        employeeIds,
        formattedStartDate,
        formattedEndDate,
      );

      // 2. Fetch all employee city IDs at once (or use simulated values)
      const employeeCityMap =
        simulatedCityMap || (await this.fetchBulkEmployeeCityIds(employeeIds));

      // 3. Get unique city IDs for bonus level check
      const uniqueCityIds = [
        ...new Set(Object.values(employeeCityMap).filter((id) => id !== null)),
      ];

      // 4. Check bonus levels for all cities at once (or create city info from simulated levels)
      let cityBonusInfo;

      if (simulatedBonusLevels) {
        // Create inline city bonus info from simulated bonus levels
        cityBonusInfo = {};

        // Initialize with default values - all cities have bonus system
        uniqueCityIds.forEach((cityId) => {
          cityBonusInfo[cityId] = {
            hasBonusSystem: true,
          };
        });

        // Update city names if available in the simulated data
        if (simulatedBonusLevels.length > 0) {
          simulatedBonusLevels.forEach((level) => {
            if (
              level.city_name &&
              level.city_id &&
              cityBonusInfo[level.city_id]
            ) {
              cityBonusInfo[level.city_id].cityName = level.city_name;
            }
          });
        }
      } else {
        // Use real bonus levels data
        cityBonusInfo = await this.checkBulkBonusLevelsForCities(uniqueCityIds);
      }

      // 5. Fetch penalty bonuses for all employees at once
      const penaltyBonusesMap = await this.fetchBulkPenaltyBonuses(employeeIds);

      // 6. Apply simulated values where needed
      const effectiveBonuses = simulatedBonuses || bonuses;

      // Create effective hotel points by merging original and simulated points
      let effectiveHotelPoints = [...bonusHotelPoints];

      if (simulatedHotelPoints && simulatedHotelPoints.length > 0) {
        // Map to track processed hotels
        const processedHotels = new Map();

        // First add all original points to the map
        for (const point of bonusHotelPoints) {
          if (point.hotel_id) {
            processedHotels.set(point.hotel_id, true);
          }
        }

        // Then process each simulated point
        for (const simPoint of simulatedHotelPoints) {
          const hotelId = simPoint.hotel_id;

          if (!hotelId) {
            console.warn("Skipping hotel point without hotel_id:", simPoint);
            continue;
          }

          // Find existing point for this hotel
          const existingIndex = effectiveHotelPoints.findIndex(
            (p) => p.hotel_id === hotelId,
          );

          if (existingIndex >= 0) {
            // Update existing hotel point
            effectiveHotelPoints[existingIndex] = {
              ...effectiveHotelPoints[existingIndex],
              clerk_points:
                simPoint.clerk_points !== undefined
                  ? Number(simPoint.clerk_points)
                  : effectiveHotelPoints[existingIndex].clerk_points,
              senior_points:
                simPoint.senior_points !== undefined
                  ? Number(simPoint.senior_points)
                  : effectiveHotelPoints[existingIndex].senior_points,
            };
            processedHotels.set(hotelId, true);
          } else {
            // Add new hotel point
            effectiveHotelPoints.push({
              hotel_id: hotelId,
              clerk_points: Number(simPoint.clerk_points || 0),
              senior_points: Number(simPoint.senior_points || 0),
              effective_from:
                simPoint.effective_from || format(startDate, "yyyy-MM-dd"),
            });
            processedHotels.set(hotelId, true);
          }
        }
      }

      // 7. Process data for each employee
      const results = {};

      for (const employeeId of employeeIds) {
        // Get employee-specific data
        const employeeCityId = employeeCityMap[employeeId];
        if (!employeeCityId) {
          results[employeeId] = { error: "No city assigned to employee" };
          continue;
        }

        const cityInfo = cityBonusInfo[employeeCityId];
        if (!cityInfo || !cityInfo.hasBonusSystem) {
          results[employeeId] = {
            hasBonusSystem: false,
            cityName: cityInfo ? cityInfo.cityName : "diese Stadt",
            employeeCityId,
          };
          continue;
        }

        // Filter data for this employee
        const employeeShifts = shifts.filter(
          (shift) => shift.employee_id === employeeId,
        );
        const employeeManualEvents = manualEvents.filter(
          (event) => event.employee_id === employeeId,
        );
        const employeeDateEvents = dateEvents.filter(
          (event) => event.employee_id === employeeId,
        );
        const employeeCheckResults = employeeChecks.filter(
          (check) => check.employee_id === employeeId,
        );
        const employeeHotelQualifications = employeeHotels.filter(
          (qual) => qual.employee_id === employeeId,
        );

        // Get penalty data for this employee
        const penaltyEvents = penaltyBonusesMap[employeeId] || [];
        const penaltyStatus = this.checkIfPenaltyActive(
          penaltyEvents,
          startDate,
        );

        // Process employee data with effective (potentially simulated) values
        const shiftsWithBonus = this.calculateShiftBonuses(
          employeeShifts,
          employeeHotelQualifications,
          effectiveBonuses,
          employeeCityId,
          effectiveHotelPoints,
        );
        const automaticEvents = this.processAutomaticEvents(
          employeeDateEvents,
          effectiveBonuses,
        );
        const monthlyBonuses = this.processMonthlyBonuses(
          employeeDateEvents,
          effectiveBonuses,
          employeeShifts,
        );
        const failedChecks = this.processEmployeeChecks(
          employeeCheckResults,
          effectiveBonuses,
        );

        // Calculate totals for this employee
        const totals = this.calculateTotals(
          shiftsWithBonus,
          employeeManualEvents,
          automaticEvents,
          monthlyBonuses,
          failedChecks,
          isCurrentMonth,
        );

        // Store results for this employee
        results[employeeId] = {
          hasBonusSystem: true,
          cityName: cityInfo.cityName,
          employeeCityId,
          shiftsWithBonus,
          manualEvents: employeeManualEvents,
          automaticEvents,
          monthlyBonuses,
          failedChecks,
          ...penaltyStatus,
          ...totals,
        };
      }

      return results;
    } catch (error) {
      console.error("Error simulating bonus calculation:", error);
      throw error;
    }
  }

  async fetchBulkEmployeeCityIds(employeeIds) {
    try {
      const { data, error } = await supabase
        .from("employee_data_matching")
        .select("employee_id, value_id")
        .in("employee_id", employeeIds)
        .eq("key_id", this.EMPLOYEE_DATA_MATCHING_CITY_ID);

      if (error) throw error;

      // Create a map of employee ID to city ID
      const employeeCityMap = {};
      employeeIds.forEach((id) => (employeeCityMap[id] = null)); // Initialize all to null

      if (data && data.length > 0) {
        data.forEach((item) => {
          employeeCityMap[item.employee_id] = item.value_id;
        });
      }

      return employeeCityMap;
    } catch (error) {
      console.error("Error fetching bulk employee city IDs:", error);
      throw error;
    }
  }

  async checkBulkBonusLevelsForCities(cityIds) {
    try {
      // First fetch all city names
      const { data: citiesData, error: citiesError } = await supabase
        .from("cities")
        .select("id, city")
        .in("id", cityIds);

      if (citiesError) throw citiesError;

      // Create a map of city ID to name
      const cityNameMap = {};
      if (citiesData && citiesData.length > 0) {
        citiesData.forEach((city) => {
          cityNameMap[city.id] = city.city;
        });
      }

      // Check which cities have bonus levels - fetch all levels and count them per city
      const { data: bonusLevelsData, error: bonusLevelsError } = await supabase
        .from("bonus_levels")
        .select("city_id")
        .in("city_id", cityIds);

      if (bonusLevelsError) throw bonusLevelsError;

      // Count bonus levels per city manually
      const cityBonusCount = {};
      if (bonusLevelsData && bonusLevelsData.length > 0) {
        bonusLevelsData.forEach((item) => {
          if (cityBonusCount[item.city_id]) {
            cityBonusCount[item.city_id]++;
          } else {
            cityBonusCount[item.city_id] = 1;
          }
        });
      }

      // Create a map of city ID to bonus system status
      const cityBonusInfo = {};
      cityIds.forEach((cityId) => {
        cityBonusInfo[cityId] = {
          hasBonusSystem: (cityBonusCount[cityId] || 0) > 0,
          cityName: cityNameMap[cityId] || "diese Stadt",
        };
      });

      return cityBonusInfo;
    } catch (error) {
      console.error("Error checking bulk bonus levels for cities:", error);
      throw error;
    }
  }

  async fetchBulkPenaltyBonuses(employeeIds) {
    try {
      // First, get all bonuses that have a penalty period
      const { data: penaltyBonuses, error: bonusesError } = await supabase
        .from("bonuses")
        .select("*")
        .not("bonus_penalty_months", "is", null)
        .is("deleted_at", null);

      if (bonusesError) throw bonusesError;
      if (!penaltyBonuses || penaltyBonuses.length === 0) {
        return {};
      }

      const penaltyBonusIds = penaltyBonuses.map((bonus) => bonus.id);

      // Create a map to store penalty events for each employee
      const penaltyEventsMap = {};
      employeeIds.forEach((id) => (penaltyEventsMap[id] = []));

      // 1. First source: direct penalty events from bonus_employee_events
      const { data: penaltyEventsData, error: penaltyEventsError } =
        await supabase
          .from("bonus_employee_events")
          .select("*, bonus:bonuses(*)")
          .in("employee_id", employeeIds)
          .in("bonus_event_id", penaltyBonusIds)
          .is("deleted_at", null);

      if (penaltyEventsError) throw penaltyEventsError;

      if (penaltyEventsData && penaltyEventsData.length > 0) {
        penaltyEventsData.forEach((event) => {
          const relatedBonus = event.bonus;

          if (relatedBonus && event.employee_id) {
            // Get version-specific bonus values for this event date
            const bonusValue = this.getBonusValueForDate(
              relatedBonus,
              event.event_date,
            );

            const penaltyEvent = {
              ...event,
              penalty_months:
                bonusValue.bonus_penalty_months ||
                relatedBonus.bonus_penalty_months ||
                0,
              name: relatedBonus.name,
              source: "direct",
            };

            penaltyEventsMap[event.employee_id].push(penaltyEvent);
          }
        });
      }

      // 2. Second source: Get event keys that correspond to penalty bonuses
      const { data: eventKeysData, error: eventKeysError } = await supabase
        .from("employee_date_events_keys")
        .select("id, value, name, bonus_id")
        .in("bonus_id", penaltyBonusIds);

      if (eventKeysError) throw eventKeysError;

      if (eventKeysData && eventKeysData.length > 0) {
        const penaltyEventKeys = eventKeysData;
        const penaltyEventKeyIds = penaltyEventKeys.map((key) => key.id);

        // Get all events that match these penalty event keys for all employees
        const { data: matchingEventsData, error: matchingEventsError } =
          await supabase
            .from("employee_date_event_matching")
            .select("*")
            .in("employee_id", employeeIds)
            .eq("is_activated", true)
            .in("employee_date_event_id", penaltyEventKeyIds);

        if (matchingEventsError) throw matchingEventsError;

        if (matchingEventsData && matchingEventsData.length > 0) {
          const dateEvents = matchingEventsData;

          dateEvents.forEach((event) => {
            // Find the corresponding event key
            const eventKey = penaltyEventKeys.find(
              (key) => key.id === event.employee_date_event_id,
            );

            if (eventKey) {
              const relatedBonus = penaltyBonuses.find(
                (b) => b.id === eventKey.bonus_id,
              );

              if (relatedBonus && event.employee_id) {
                // Get version-specific bonus values for this event date
                const bonusValue = this.getBonusValueForDate(
                  relatedBonus,
                  event.date,
                );

                const penaltyEvent = {
                  id: event.id,
                  employee_id: event.employee_id,
                  event_date: event.date,
                  bonus_event_id: eventKey.bonus_id,
                  penalty_months:
                    bonusValue.bonus_penalty_months ||
                    relatedBonus.bonus_penalty_months,
                  name: relatedBonus.name,
                  source: "date_event",
                  key_name: eventKey.name,
                };

                penaltyEventsMap[event.employee_id].push(penaltyEvent);
              }
            }
          });
        }
      }

      // Sort penalty events for each employee by date
      Object.keys(penaltyEventsMap).forEach((employeeId) => {
        penaltyEventsMap[employeeId].sort(
          (a, b) => new Date(b.event_date) - new Date(a.event_date),
        );
      });

      return penaltyEventsMap;
    } catch (error) {
      console.error("Error fetching bulk penalty bonuses:", error);
      throw error;
    }
  }

  async fetchBulkBonusData(employeeIds, startDate, endDate) {
    try {
      // Fetch all data in parallel for better performance
      const [
        bonuses,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        employeeHotels,
      ] = await Promise.all([
        this.fetchBonuses(),
        this.fetchBulkShifts(employeeIds, startDate, endDate),
        this.fetchBulkManualBonusEvents(employeeIds, startDate, endDate),
        this.fetchBulkDateEvents(employeeIds, startDate, endDate),
        this.fetchBulkEmployeeChecks(employeeIds, startDate, endDate),
        this.fetchBulkEmployeeHotelQualifications(employeeIds),
      ]);

      // Now that we have shifts data, fetch bonus hotel points
      const hotelIds = [
        ...new Set(shifts.map((shift) => shift.hotels?.id).filter(Boolean)),
      ];
      const bonusHotelPoints = await this.fetchBonusHotelPoints(hotelIds);

      return {
        bonuses,
        shifts,
        manualEvents,
        dateEvents,
        employeeChecks,
        employeeHotels,
        bonusHotelPoints,
      };
    } catch (error) {
      console.error("Error fetching bulk bonus data:", error);
      throw error;
    }
  }

  async fetchBulkShifts(employeeIds, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("shifts")
        .select(
          "*, hotels(id, name, city_id, bonus_points_senior, bonus_points_clerk)",
        )
        .in("employee_id", employeeIds)
        .eq("check_id", 1)
        .gte("date", startDate)
        .lte("date", endDate);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching bulk shifts:", error);
      throw error;
    }
  }

  async fetchBulkManualBonusEvents(employeeIds, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("bonus_employee_events")
        .select("*, bonus:bonuses(*)")
        .in("employee_id", employeeIds)
        .gte("event_date", startDate)
        .lte("event_date", endDate)
        .is("deleted_at", null);

      if (error) throw error;

      return (
        data.map((event) => {
          // Get the appropriate bonus value for this event's date
          const bonusValue = this.getBonusValueForDate(
            event.bonus,
            event.event_date,
          );

          return {
            id: event.id,
            employee_id: event.employee_id,
            event_date: event.event_date,
            bonus_id: event.bonus.id,
            bonus_name: event.bonus.name,
            points: bonusValue.points, // Use version-specific points
            is_positive: event.bonus.is_positive,
            comment: event.comment,
            extra_points: event.extra_points,
          };
        }) || []
      );
    } catch (error) {
      console.error("Error fetching bulk manual events:", error);
      throw error;
    }
  }

  async fetchBulkDateEvents(employeeIds, startDate, endDate) {
    try {
      // First get all event keys with bonus associations
      const { data: eventKeys, error: eventKeysError } = await supabase
        .from("employee_date_events_keys")
        .select("id, value, name, bonus_id")
        .or("bonus_id.not.is.null,value.eq.is_absent");

      if (eventKeysError) throw eventKeysError;
      if (!eventKeys || eventKeys.length === 0) {
        return [];
      }

      // Get all matching events for these employees and date range
      const { data: events, error: eventsError } = await supabase
        .from("employee_date_event_matching")
        .select("*")
        .in("employee_id", employeeIds)
        .gte("date", startDate)
        .lte("date", endDate)
        .eq("is_activated", true);

      if (eventsError) throw eventsError;
      if (!events || events.length === 0) {
        return [];
      }

      // Process all matching events by joining with the event keys manually
      const dateEvents = [];

      for (const event of events) {
        // For each event, find the corresponding key
        const matchingKey = eventKeys.find(
          (key) => key.id === event.employee_date_event_id,
        );

        // If we found a matching key with a bonus, or employee is absent, add this event
        if (
          matchingKey &&
          (matchingKey.bonus_id || matchingKey.value === "is_absent")
        ) {
          dateEvents.push({
            ...event,
            eventKey: matchingKey,
            is_late: matchingKey.value === "is_late",
            did_not_appear: matchingKey.value === "did_not_appear",
            is_absent_to_late: matchingKey.value === "is_absent_to_late",
          });
        }
      }

      return dateEvents;
    } catch (error) {
      console.error("Error fetching bulk date events:", error);
      throw error;
    }
  }

  async fetchBulkEmployeeChecks(employeeIds, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from("employee_check_form")
        .select("*, hotels(id, name)")
        .in("employee_id", employeeIds)
        .gte("date", startDate)
        .lte("date", endDate)
        .eq("is_passed", false)
        .eq("is_signed", true);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error("Error fetching bulk employee checks:", error);
      throw error;
    }
  }

  async fetchBulkEmployeeHotelQualifications(employeeIds) {
    try {
      const { data, error } = await supabase
        .from("employees_hotels")
        .select("*")
        .in("employee_id", employeeIds);

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error(
        "Error fetching bulk employee hotel qualifications:",
        error,
      );
      throw error;
    }
  }

  async getBonusDataForCity(cityId) {
    try {
      // Get city name
      const { data: cityData, error: cityError } = await supabase
        .from("cities")
        .select("id, city")
        .eq("id", cityId);

      if (cityError) throw cityError;

      let cityName = null;
      if (cityData && cityData.length > 0) {
        cityName = cityData[0].city;
      }

      // Get bonus levels for city
      const { data: bonusLevelsData, error: bonusLevelsError } = await supabase
        .from("bonus_levels")
        .select("*")
        .eq("city_id", cityId);

      if (bonusLevelsError) throw bonusLevelsError;

      return {
        cityId,
        cityName,
        bonusLevels: bonusLevelsData || [],
      };
    } catch (error) {
      console.error("Error getting bonus data for city:", error);
      throw error;
    }
  }

  // Utility function to get correct bonus points for a specific date
  getBonusValueForDate(bonus, eventDate) {
    if (!bonus) return { points: 0 };

    // If no date specified, use the current values
    if (!eventDate) return bonus;

    let date = typeof eventDate === "string" ? parseISO(eventDate) : eventDate;
    date = startOfDay(date);
    if (!isValid(date)) return bonus;

    // Start with the current bonus values as default
    let result = {
      points: bonus.points,
      is_positive: bonus.is_positive,
      bonus_penalty_months: bonus.bonus_penalty_months,
    };

    // Check if current version is applicable
    if (bonus.effective_from) {
      const effectiveFrom = parseISO(bonus.effective_from);
      if (
        date < effectiveFrom &&
        Array.isArray(bonus.historical_values) &&
        bonus.historical_values.length > 0
      ) {
        // Need to check historical values
        for (const historicalValue of bonus.historical_values) {
          const histFrom = parseISO(historicalValue.effective_from);
          const histTo = parseISO(historicalValue.effective_to);

          if (isWithinInterval(date, { start: histFrom, end: histTo })) {
            return {
              ...result,
              points: historicalValue.points,
              bonus_penalty_months:
                historicalValue.bonus_penalty_months ||
                bonus.bonus_penalty_months,
            };
          }
        }
      }
    }

    return result;
  }
}

export default new BonusCalculatorService();
