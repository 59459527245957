<template>
  <v-dialog
    v-model="$store.state.calendarDialogHome"
    class="ma-2"
    width="800px"
  >
    <v-card>
      <v-toolbar color="indigo-darken-1">
        <v-card-title>Termin eintragen</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="$store.state.calendarDialogHome = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-if="editEvent.attendees && editEvent.attendees[0]"
          variant="outlined"
          v-model="editEvent.attendees[0].email"
          density="compact"
          disabled
          label="Einladung geht an"
        ></v-text-field>
        <v-row>
          <v-col cols="12">
            <v-select
              variant="outlined"
              density="compact"
              label="Titel"
              v-model="editEvent.summary"
              :items="eventTypes"
              item-title="summary"
              item-value="summary"
              @update:modelValue="changeGroup(editEvent.summary)"
            ></v-select>
          </v-col>
          <v-col cols="6">
            Datum
            <v-date-picker
              v-model="editEvent.date"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-col>
          <v-col cols="6" align="center">
            <br /><br /><br /><br />
            <v-autocomplete
              label="Start"
              :items="times"
              v-model="editEvent.startTime"
            ></v-autocomplete>
            <v-autocomplete
              label="Ende"
              :items="times"
              v-model="editEvent.endTime"
            ></v-autocomplete>
            <v-autocomplete
              label="Teilnehmer"
              :items="users"
              item-title="full_name"
              item-value="full_name"
              v-if="checkIfSpecificPerson(editEvent)"
              v-model="employeeName"
            ></v-autocomplete>
            <v-row>
              <v-col>
                <v-btn
                  v-if="editEvent.id"
                  color="error"
                  size="small"
                  :loading="loading"
                  @click="deleteEvent()"
                >
                  Termin löschen
                </v-btn> </v-col
              ><v-col>
                <v-btn
                  size="small"
                  v-if="editEvent.summary"
                  @click="createEvent()"
                  color="green"
                  :loading="loading"
                  ><span v-if="editEvent.id">Termin aktualisieren</span
                  ><span v-else>Termin erstellen</span></v-btn
                ></v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
const props = defineProps(["entityId", "entity", "editEvent", "entityType"]);
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      mandatorInfo: {},
      group: true,
      eventTypes: [],
      eventTypesAll: [
        { summary: "Hiring Hour", description: "", group: true, online: true },
        {
          summary: "Vorstellungsgespräch",
          description: "",
          online: true,
          withSpecificPerson: true,
        },
        {
          summary: "Vorstellungsgespräch - Vor Ort",
          description: "",
          online: false,
          withSpecificPerson: true,
        },
        {
          summary: "Schnuppernacht",
          description: "",
          online: false,
        },
        {
          summary: "Basis Seminar - 2 Tage - Online",
          description: "",
          group: true,
          online: true,
          owner: "training@bellcaptain.team",
        },
        {
          summary: "Basis Seminar 1 Tag - Online",
          description: "",
          group: true,
          online: true,
          owner: "training@bellcaptain.team",
        },
        {
          summary: "Basis Seminar Prüfung - Vor Ort",
          description: "",
          online: false,
        },
        {
          summary: "Basis Seminar Prüfung - Online",
          description: "",
          online: true,
        },
        {
          summary: "Vertragsgespräch - Vor Ort",
          description: "",
          online: false,
          withSpecificPerson: true,
        },
        {
          summary: "Vertragsgespräch - Online",
          description: "",
          online: true,
          withSpecificPerson: true,
        },
      ],
      times: [],
      users: [],
      employeeName: "Marko Sender",
      automaticTemplates: {
        Vorstellungsgespräch: 46,
        "Vorstellungsgespräch - Vor Ort": 51,
        "Basis Seminar Prüfung - Vor Ort": 47,
        "Basis Seminar Prüfung - Online": 48,
        "Basis Seminar 1 Tag - Online": 56,
        "Vertragsgespräch - Vor Ort": 53,
        "Vertragsgespräch - Online": 55,
      },
      automaticTemplatesHTS: {
        Vorstellungsgespräch: 73,
        "Vorstellungsgespräch - Vor Ort": 51,
        "Vertragsgespräch - Vor Ort": 74,
        "Vertragsgespräch - Online": 76,
      },
    };
  },
  methods: {
    allowedDates: (val) => {
      const dayOfWeek = new Date(val).getDay();
      // Check if the day is between Monday (1) and Friday (5)
      // return dayOfWeek >= 1 && dayOfWeek <= 5;
      return dayOfWeek >= 0 && dayOfWeek <= 6;
    },
    checkIfSpecificPerson() {
      if (this.editEvent.summary) {
        this.editEvent.withSpecificPerson = this.eventTypesAll.find(
          (item) => item.summary == this.editEvent.summary,
        ).withSpecificPerson;
      }
      return this.editEvent.withSpecificPerson;
    },

    async changeGroup() {
      this.group = false;
      for (var i = 0; i < this.eventTypesAll.length; i++) {
        if (this.eventTypesAll[i].summary == this.editEvent.summary) {
          this.group = this.eventTypesAll[i].group;
        }
      }
    },
    async createEvent() {
      this.loading = true;
      var start = new Date(this.editEvent.date);
      start.setHours(this.editEvent.startTime.split(":")[0]);
      start.setMinutes(this.editEvent.startTime.split(":")[1]);
      var end = new Date(this.editEvent.date);
      end.setHours(this.editEvent.endTime.split(":")[0]);
      end.setMinutes(this.editEvent.endTime.split(":")[1]);
      this.editEvent.start = start;
      this.editEvent.end = end;
      var eventType = this.eventTypesAll.find(
        (item) => item.summary == this.editEvent.summary,
      );
      this.editEvent.online = eventType.online;
      this.editEvent.owner = eventType.owner;
      if (!this.entityType || this.entityType == "recruiting") {
        this.editEvent.recruiting_status_id = this.entity.statusId;
        this.editEvent.user = this.mandatorInfo.recruiting_user;
      }
      this.editEvent.creator_id = this.$store.state.userInfo.id;
      var response = await this.$store.dispatch(
        "updateEventPerBackend",
        this.editEvent,
      );
      var eventDetails = response.data.data;
      if (!this.group && this.editEvent.summary != "Schnuppernacht") {
        this.entity.eventDetails = eventDetails;
        await this.sendEmail();
      }
      if (this.editEvent.summary == "Basis Seminar - 2 Tage - Online") {
        var startDay2 = new Date(this.editEvent.start);
        var endDay2 = new Date(this.editEvent.end);
        startDay2.setDate(startDay2.getDate() + 1);
        endDay2.setDate(endDay2.getDate() + 1);
        var conferenceLink =
          "https://meet.google.com/" + eventDetails.conferenceData.conferenceId;
        var day2Event = {
          summary: "Basis Seminar - Online - Tag 2",
          start: startDay2,
          end: endDay2,
          description: `Bitte unter folgenden <a href="${conferenceLink}">Link</a> einloggen`,
          owner: this.editEvent.owner,
        };
        await this.$store.dispatch("updateEventPerBackend", day2Event);
      }
      this.loading = false;
      this.$store.state.calendarDialogHome = false;
    },
    async sendEmail() {
      this.entity.employeeName = this.employeeName;
      if (this.$store.state.userInfo.mandator_id == 2) {
        var templateId = this.automaticTemplatesHTS[this.editEvent.summary];
      }
      else {
        var templateId = this.automaticTemplates[this.editEvent.summary];
      }
      var queryObject = {
        table: "recruiting_templates",
        where: [{ type: "eq", key: "id", value: templateId }],
      };
      var templateResponse = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var template = templateResponse.data[0];
      var date = await this.$store.dispatch(
        "formatDate",
        new Date(this.editEvent.start),
      );
      this.entity.time = date.split(" ")[1];
      date = date.split(" ")[0];
      var dateDB = new Date(
        date.split(".")[2],
        date.split(".")[1] - 1,
        date.split(".")[0],
      );
      this.entity.date = date;
      this.entity.day = this.$store.state.days[dateDB.getDay()];
      var subject = await this.replaceBody(template.subject, this.entity);
      var body = await this.replaceBody(template.body, this.entity);
      const from = this.mandatorInfo.recruiting_email;
      const user = this.mandatorInfo.recruiting_user;
      var emailObject = {
        from: from,
        friendlyName: from,
        to: this.entity.email,
        subject: subject,
        body: body,
        user: user,
      };
      await this.$store.dispatch("sendEmailPerBackend", emailObject);
      this.updateApplication();
    },
    async replaceBody(body, info) {
      var body = await this.$store.dispatch("replaceBody", {
        body: body,
        info: info,
      });
      return body;
    },
    async deleteEvent() {
      this.loading = true;
      await this.$store.dispatch("deleteEvent", {eventId:this.editEvent.id,entityType:this.entityType});
      this.loading = false;
      this.$store.state.calendarDialogHome = false;
    },
    async updateApplication() {
      var application = this.entity.recruiting_job_candidates[0];
      this.loading = true;

      var queryObject = {
        table: "recruiting_job_candidates",
        id: application.id,
        payload: {
          meeting_status: "invitationSend",
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Terminstatus für ${this.editEvent.summary} auf ${this.$store.state.meetingStatusObject["invitationSend"].label} gesetzt!`,
        },
      });
      this.loading = false;
      this.$store.state.candidateChangeCheck = true;
    },
  },
  async created() {
    const mandatorResponse = await this.$store.dispatch("getSupabaseEntity", {
      table: "mandators",
      where: [{ type: "eq", key: "id", value: this.$store.state.userInfo.mandator_id }],
    });
    this.mandatorInfo = mandatorResponse.data[0];
    this.group = false;
    this.eventTypes = [];
    for (var i = 0; i < this.eventTypesAll.length; i++) {
      if (this.eventTypesAll[i].summary == this.editEvent.summary) {
        this.group = this.eventTypesAll[i].group;
      }
      //if (this.eventTypesAll[i].group == this.group) {
      this.eventTypes.push(this.eventTypesAll[i]);
      //}
    }
    this.users = (
      await this.$store.dispatch("getSupabaseEntity", {
        table: "get_office_users",
        order: {
          key: "full_name",
          order: "asc",
        },
      })
    ).data;
    var quarters = 4;
    var hours = 24;
    for (var i = 6; i < hours; i++) {
      for (var j = 0; j < quarters; j++) {
        var minutes = j == 0 ? "00" : j * 15;

        var time = i + ":" + minutes;
        this.times.push(time);
      }
    }
  },
};
</script>
