<template>
  <v-card class="email-detail">
    <v-card-title class="d-flex justify-space-between align-center pb-1">
      <div class="text-truncate">{{ email.subject }}</div>
      <v-btn
        color="primary"
        :disabled="email.processed"
        @click="markAsProcessed"
        variant="outlined"
      >
        {{ email.processed ? "Bearbeitet" : "Als bearbeitet markieren" }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="email-header">
        <div class="email-header-row">
          <div class="email-header-label">Von:</div>
          <div class="email-header-value">{{ email.from }}</div>
        </div>

        <div class="email-header-row">
          <div class="email-header-label">An:</div>
          <div class="email-header-value">{{ email.to }}</div>
        </div>

        <div class="email-header-row" v-if="email.cc">
          <div class="email-header-label">CC:</div>
          <div class="email-header-value">{{ email.cc }}</div>
        </div>

        <div class="email-header-row" v-if="email.bcc">
          <div class="email-header-label">BCC:</div>
          <div class="email-header-value">{{ email.bcc }}</div>
        </div>

        <div class="email-header-row">
          <div class="email-header-label">Datum:</div>
          <div class="email-header-value">{{ formatDate(email.date) }}</div>
        </div>
      </div>

      <v-divider class="my-3"></v-divider>

      <div class="email-content mt-4">
        <div v-html="email.body"></div>
      </div>

      <div v-if="email.attachments && email.attachments.length" class="mt-4">
        <v-divider class="my-3"></v-divider>
        <div class="text-subtitle-1 mb-2">
          Anhänge ({{ email.attachments.length }})
        </div>
        <v-chip
          v-for="(attachment, index) in email.attachments"
          :key="index"
          class="mr-2 mb-2"
          prepend-icon="mdi-paperclip"
          variant="outlined"
          @click="downloadAttachment(attachment)"
        >
          {{ attachment.filename }}
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EmailDetail",
  props: {
    email: {
      type: Object,
      required: true,
    },
    mandatorInfo: Object,
    user: String,
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleString("de-DE");
    },
    async downloadAttachment(attachment) {
      let user;
      if (this.selectedEmail == "bewerbung@bellcaptain.team") {
        user = "bewerbung@bellcaptain.team";
      } else {
        user = this.mandatorInfo.central_email;
      }
      await this.$store.dispatch("downloadAttachmentPerBackend", {
        attachment: attachment,
        user: this.user,
      });
    },
    markAsProcessed() {
      this.$emit("email-processed", this.email);
    },
  },
};
</script>

<style scoped>
.email-detail {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.email-header {
  margin-bottom: 8px;
}

.email-header-row {
  display: flex;
  margin-bottom: 4px;
  align-items: baseline;
}

.email-header-label {
  width: 60px;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 500;
}

.email-header-value {
  flex-grow: 1;
  word-break: break-word;
}

.email-content {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 4px;
}

.text-content {
  white-space: pre-wrap;
  font-family: inherit;
  margin: 0;
}

:deep(.email-content img) {
  max-width: 100%;
  height: auto;
}
</style>
