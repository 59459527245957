<template>
  <v-dialog
    v-model="$store.state.recruitingView.candidateView.drawer"
    temporary
    location="right"
    class="pa-4"
    color="grey-lighten-4"
    fullscreen
  >
    <v-card color="grey-lighten-3">
      <v-card-text>
        <Candidate
          v-if="$store.state.candidateView.id"
          :applicationCandidateId="$store.state.candidateView.id"
          :applicationJobCandidateId="$store.state.candidateView.jobCandidateId"
          :applications="applications"
          :chosenApplicationIndex="chosenApplicationIndex"
          type="drawer"
        ></Candidate>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-toolbar
    class="px-4 flex-wrap pt-1"
    density="comfortable"
    v-if="!$vuetify.display.mobile"
  >
    <v-select
      class="mt-4"
      label="Jobs"
      multiple
      chips
      clearable
      @update:modelValue="getApplications()"
      :items="selections.jobs"
      density="compact"
      variant="outlined"
      item-title="title"
      item-value="id"
      v-model="$store.state.applicationView.filter.jobs"
    ></v-select>
    <span class="mx-2"></span>
    <v-rating
      class="mb-2"
      length="4"
      v-model="$store.state.applicationView.filter.rating"
      @click="getApplications()"
      clearable
      size="x-small"
    ></v-rating>
    <span class="mx-2"></span>
    <span
      class="mb-2 mr-1"
      v-for="btn in $store.state.applicationView.filter.experienceLevels"
    >
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-btn
            @click="(btn.active = !btn.active), getApplications()"
            variant="elevated"
            size="small"
            :color="btn.active ? 'success' : 'grey-lighten-4'"
            >{{ btn.name }}</v-btn
          >
        </template>
      </v-hover>
    </span>
    <span class="mx-2"></span>
    <v-select
      class="mt-4"
      variant="outlined"
      multiple
      chips
      @update:modelValue="getApplications()"
      density="compact"
      :items="selections.timeModel"
      item-title="name"
      item-value="id"
      label="Zeitmodell"
      clearable
      v-model="$store.state.applicationView.filter.timeModels"
    ></v-select>
    <span class="mx-2"></span>
    <v-select
      class="mt-4"
      v-model="$store.state.applicationView.filter.meetingStatuses"
      clearable
      @update:modelValue="getApplications()"
      multiple
      chips
      label="Meetingstatus"
      variant="outlined"
      density="compact"
      :items="$store.state.meetingStatuses"
      item-title="label"
      item-value="value"
    ></v-select>
    <v-checkbox
      class="mt-3"
      @click="getApplications()"
      color="success"
      label="ToDo Kandidaten"
      v-model="$store.state.applicationView.filter.todo"
    ></v-checkbox>
  </v-toolbar>
  <v-checkbox
    v-else
    @click="getApplications()"
    color="success"
    label="ToDo Kandidaten"
    v-model="$store.state.applicationView.filter.todo"
    class="mb-n14"
  ></v-checkbox>
  <v-row class="mt-2 mx-1 pb-4">
    <v-col cols="12" align="center" v-if="loading">
      <v-progress-linear indeterminate="" height="10px"></v-progress-linear>
    </v-col>
    <v-col cols="12" align="center" class="mt-n2 mb-n4">
      <v-btn-toggle
        divided
        rounded="xl"
        density="compact"
        color="blue-grey-darken-2"
        multiple
        v-model="$store.state.applicationView.filter.chosenPhases"
      >
        <v-btn
          :value="btn"
          v-for="btn in selections.phases"
          @click="showStatusPhases(true)"
          >{{ btn }}</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <v-col cols="12">
      <v-btn icon size="x-small" variant="outlined" @click="getApplications()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn
        :color="status.color"
        rounded="xl"
        :variant="status.chosen ? 'elevated' : 'outlined'"
        class="ma-1"
        size="small"
        v-show="status.show"
        :prepend-icon="status.icon"
        :disabled="status.id == 10"
        @click="
          status.chosen = !status.chosen;
          getApplications();
        "
        v-for="status in $store.state.applicationView.filter.status"
        ><span v-if="!$vuetify.display.mobile">{{ status.name }}</span>
        <v-chip class="mx-1" size="x-small">{{ status.count }}</v-chip>
        <v-chip
          color="red"
          variant="elevated"
          v-if="status.emails > 0"
          size="x-small"
          >{{ status.emails }}<v-icon>mdi-mail</v-icon></v-chip
        >
      </v-btn>
    </v-col>
    <v-col cols="12" align="center" class="mt-n3">
      <v-btn
        rounded="xl"
        class="ma-1"
        size="small"
        @click="createCandidateDialog = true"
        prepend-icon="mdi-plus"
      >
        Erstelle neuen Kandidaten
      </v-btn>
      <v-dialog v-model="createCandidateDialog" width="400">
        <CreateCandidate :selections="selections"></CreateCandidate>
      </v-dialog>
    </v-col>
    <v-col cols="12" v-if="loadEvent">
      <v-progress-linear indeterminate height="10px"></v-progress-linear>
    </v-col>
    <template
      v-for="group in seminarGroups"
      v-if="
        $store.state.applicationView.filter.status.find(
          (status) => status.id === 19 && status.chosen == true,
        )
      "
    >
      <v-col
        cols="12"
        v-if="
          $store.state.applicationView.filter.status.find(
            (status) => status.id === group.status_id,
          )
        "
      >
        <v-card variant="outlined" rounded="xl">
          <v-card-title>{{ group.title }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                v-for="(application, index) in group.candidates"
              >
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <v-col>
                      <v-card
                        v-bind="props"
                        :elevation="isHovering ? '10' : '2'"
                        rounded="xl"
                        @click="openCandidateView(application, index)"
                        :color="application.favorite ? null : application.color"
                        :class="`bg-${application.color}`"
                        :variant="
                          application.favorite ? 'outlined' : 'elevated'
                        "
                      >
                        <v-card-text class="ma-1">
                          <v-row class="mt-n5">
                            <v-col
                              v-if="application.emailReceived"
                              cols="12"
                              align="center"
                            >
                              <v-alert type="info">
                                Neue E-Mail erhalten
                              </v-alert>
                            </v-col>
                            <v-col
                              :cols="application.favorite ? 4 : 6"
                              align="left"
                              class="mt-n2 mb-n6"
                            >
                              <v-rating
                                length="4"
                                size="x-small"
                                v-model="application.rating"
                                color="orange-darken-3"
                                readonly
                              ></v-rating>
                            </v-col>
                            <v-col
                              cols="4"
                              align="center"
                              class="mb-2"
                              v-if="application.favorite"
                            >
                              <v-icon color="amber-darken-2">mdi-crown</v-icon>
                            </v-col>
                            <v-col
                              :cols="application.favorite ? 4 : 6"
                              class="mt-n1 mb-n6"
                              align="right"
                            >
                              <v-chip
                                variant="outlined"
                                color="blue"
                                size="x-small"
                                v-if="
                                  application.recruiting_candidates
                                    .recruiting_experience_level
                                "
                                >{{
                                  application.recruiting_candidates
                                    .recruiting_experience_level.name
                                }}</v-chip
                              >
                              <v-chip
                                variant="outlined"
                                color="blue"
                                size="x-small"
                                v-if="
                                  application.recruiting_candidates
                                    .recruiting_jobs_models
                                "
                                >{{
                                  application.recruiting_candidates
                                    .recruiting_jobs_models.name
                                }}</v-chip
                              >
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <strong>
                                {{
                                  application.recruiting_candidates.first_name +
                                  " " +
                                  application.recruiting_candidates.last_name
                                }}
                              </strong>
                            </v-col>
                            <v-col cols="6" align="right" class="pt-1">
                              <v-chip size="x-small">{{
                                application.created_at.split("T")[0] +
                                ` (${Math.round(
                                  (new Date().getTime() -
                                    new Date(
                                      application.created_at,
                                    ).getTime()) /
                                    (1000 * 60 * 60 * 24),
                                )} Tage)`
                              }}</v-chip>
                              <v-chip
                                size="x-small"
                                variant="elevated"
                                color="error"
                                v-if="application.reminder_count > 0"
                                >{{
                                  application.reminder_count + " Reminder"
                                }}</v-chip
                              >
                              <v-chip
                                class="ma-2"
                                size="x-small"
                                variant="elevated"
                                color="indigo"
                                v-if="application.recruiting_sources"
                                >{{
                                  application.recruiting_sources.name
                                }}</v-chip
                              >
                            </v-col>
                            <v-col cols="12" class="mt-n5">
                              <v-icon>mdi-briefcase</v-icon>
                              {{ application.recruiting_jobs.title }}
                            </v-col>

                            <v-col cols="12" class="mt-n5" align="center">
                              <v-chip size="small" variant="elevated">{{
                                application.recruiting_status.name
                              }}</v-chip>
                              <v-chip
                                size="small"
                                variant="elevated"
                                v-if="
                                  application.recruiting_status?.type ==
                                  'meeting'
                                "
                                :color="
                                  $store.state.meetingStatusObject[
                                    application.meeting_status
                                  ].color
                                "
                                prepend-icon="mdi-calendar"
                              >
                                <v-progress-circular
                                  class="ma-4"
                                  size="x-small"
                                  indeterminate
                                  v-if="loadEvent"
                                ></v-progress-circular>
                                <span v-if="application.event">
                                  {{
                                    new Date(
                                      application.event.start.dateTime,
                                    ).toLocaleString("de") +
                                    " / " +
                                    $store.state.meetingStatusObject[
                                      application.meeting_status
                                    ].label
                                  }}
                                </span>
                                <span v-else
                                  >{{
                                    application.meeting_status
                                      ? $store.state.meetingStatusObject[
                                          application.meeting_status
                                        ].label
                                      : "Noch nicht angesetzt"
                                  }}
                                </span>
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <v-col
      v-else
      cols="12"
      :style="
        !$vuetify.display.mobile ? 'height: 1000px; overflow: auto' : null
      "
    >
      <v-row>
        <v-hover v-for="(application, index) in applications">
          <template v-slot:default="{ isHovering, props }">
            <v-col cols="12" sm="4">
              <v-card
                v-bind="props"
                :elevation="isHovering ? '10' : '2'"
                rounded="xl"
                @click="openCandidateView(application, index)"
                :color="application.favorite ? null : application.color"
                :class="`bg-${application.color}`"
                :variant="application.favorite ? 'outlined' : 'elevated'"
              >
                <v-card-text class="ma-1">
                  <v-row class="mt-n5">
                    <v-col
                      v-if="application.emailReceived"
                      cols="12"
                      align="center"
                    >
                      <v-alert type="info"> Neue E-Mail erhalten </v-alert>
                    </v-col>
                    <v-col
                      :cols="application.favorite ? 4 : 6"
                      align="left"
                      class="mt-n2 mb-n6"
                    >
                      <v-rating
                        length="4"
                        size="x-small"
                        v-model="application.rating"
                        color="orange-darken-3"
                        readonly
                      ></v-rating>
                    </v-col>
                    <v-col
                      cols="4"
                      align="center"
                      class="mb-2"
                      v-if="application.favorite"
                    >
                      <v-icon color="amber-darken-2">mdi-crown</v-icon>
                    </v-col>
                    <v-col
                      :cols="application.favorite ? 4 : 6"
                      class="mt-n1 mb-n6"
                      align="right"
                    >
                      <v-chip
                        variant="outlined"
                        color="blue"
                        size="x-small"
                        v-if="
                          application.recruiting_candidates
                            .recruiting_experience_level
                        "
                        >{{
                          application.recruiting_candidates
                            .recruiting_experience_level.name
                        }}</v-chip
                      >
                      <v-chip
                        variant="outlined"
                        color="blue"
                        size="x-small"
                        v-if="
                          application.recruiting_candidates
                            .recruiting_jobs_models
                        "
                        >{{
                          application.recruiting_candidates
                            .recruiting_jobs_models.name
                        }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <strong>
                        {{
                          application.recruiting_candidates.first_name +
                          " " +
                          application.recruiting_candidates.last_name
                        }}
                      </strong>
                    </v-col>
                    <v-col cols="6" align="right" class="pt-1">
                      <v-chip size="x-small">{{
                        application.created_at.split("T")[0] +
                        ` (${Math.round(
                          (new Date().getTime() -
                            new Date(application.created_at).getTime()) /
                            (1000 * 60 * 60 * 24),
                        )} Tage)`
                      }}</v-chip>
                      <v-chip
                        size="x-small"
                        variant="elevated"
                        color="error"
                        v-if="application.reminder_count > 0"
                        >{{ application.reminder_count + " Reminder" }}</v-chip
                      >
                      <v-chip
                        class="ma-2"
                        size="x-small"
                        variant="elevated"
                        color="indigo"
                        v-if="application.recruiting_sources"
                        >{{ application.recruiting_sources.name }}</v-chip
                      >
                    </v-col>
                    <v-col cols="12" class="mt-n5">
                      <v-icon>mdi-briefcase</v-icon>
                      {{ application.recruiting_jobs.title }}
                    </v-col>
                    <v-col cols="12" class="mt-n5" align="center">
                      <v-chip size="small" variant="elevated">{{
                        application.recruiting_status.name
                      }}</v-chip>
                      <v-chip
                        size="small"
                        variant="elevated"
                        v-if="application.recruiting_status?.type == 'meeting'"
                        :color="
                          $store.state.meetingStatusObject[
                            application.meeting_status
                          ].color
                        "
                        prepend-icon="mdi-calendar"
                      >
                        <v-progress-circular
                          class="ma-4"
                          size="x-small"
                          indeterminate
                          v-if="loadEvent"
                        ></v-progress-circular>
                        <span v-if="application.event">
                          {{
                            new Date(
                              application.event.start.dateTime,
                            ).toLocaleString("de") +
                            " / " +
                            $store.state.meetingStatusObject[
                              application.meeting_status
                            ].label
                          }}
                        </span>
                        <span v-else
                          >{{
                            application.meeting_status
                              ? $store.state.meetingStatusObject[
                                  application.meeting_status
                                ].label
                              : "Noch nicht angesetzt"
                          }}
                        </span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-hover>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import Candidate from "@/components/Candidates/View.vue";
import CreateCandidate from "@/components/Candidates/CreateCandidate.vue";
</script>
<script>
export default {
  data() {
    return {
      loggedInUser: null,
      seminarGroups: [],
      selections: {
        jobs: [],
        status: [],
        timeModel: [],
        sources: [],
        phases: [1, 2, 3, 4],
      },
      eventStatusIds: {
        "Basis Seminar 1 Tag - Online": 19,
        Vorstellungsgespräch: 2,
        "Vertragsgespräch - Online": 12,
        "Vertragsgespräch - Vor Ort": 12,
      },
      loadEvent: false,
      createCandidateDialog: false,
      chosenApplicationIndex: 1,
      countObject: {
        filter: {
          jobs: [],
          experienceLevels: [],
          timeModels: [],
          meetingStatuses: [],
        },
      },
      applications: [],
      loading: false,
    };
  },
  watch: {
    "$store.state.recruitingView.candidateView.drawer"(drawer) {
      if (!drawer) {
        this.$store.state.candidateView.id = null;
        this.getApplications();
      }
    },
  },
  methods: {
    async getSelections() {
      var jobs = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs",
        where: [
          {
            type: "eq",
            key: "mandator_id",
            value: this.loggedInUser?.mandator_id ?? 1,
          },
        ],
      });
      this.selections.jobs = jobs.data;
      this.loadStatus();
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_sources",
      });
      this.selections.sources = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_jobs_models",
      });
      this.selections.timeModel = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_status",
        where: [{ type: "is", key: "is_hidden", value: false }],
      });
      this.selections.status = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_experience_level",
      });
      this.$store.state.applicationView.filter.experienceLevels = [];
      this.selections.experienceLevel = response.data;
      for (var i = 0; i < this.selections.experienceLevel.length; i++) {
        this.$store.state.applicationView.filter.experienceLevels.push({
          active: false,
          name: this.selections.experienceLevel[i].name,
          id: this.selections.experienceLevel[i].id,
        });
      }
    },
    async showStatusPhases(button) {
      var phases = this.$store.state.applicationView.filter.chosenPhases;
      for (
        var i = 0;
        i < this.$store.state.applicationView.filter.status.length;
        i++
      ) {
        var item = this.$store.state.applicationView.filter.status[i];
        var phaseCheck = phases.find((phase) => phase == item.phase_id);
        if (phaseCheck) {
          item.show = true;
        } else {
          item.chosen = false;
          item.show = false;
        }
      }
      localStorage.setItem("chosenStatusPhases", JSON.stringify(phases));
      if (button) {
        this.getApplications();
      }
    },
    async getApplications() {
      this.loading = true;
      await this.sleep();
      var queryObject = {
        select:
          "*,recruiting_status(*),recruiting_sources(*),recruiting_candidates!inner(*,recruiting_jobs_models(*),recruiting_experience_level(*)),recruiting_jobs!inner(*)",
        table: "recruiting_job_candidates",
        where: [
          {
            type: "eq",
            key: "recruiting_jobs.mandator_id",
            value: this.loggedInUser?.mandator_id ?? 1,
          },
          { type: "is", key: "recruiting_jobs.active", value: true },
          {
            type: "neq",
            key: "recruiting_candidates.last_name",
            value: "MERGED",
          },
          {
            type: "is",
            key: "recruiting_candidates.soft_delete",
            value: null,
          },
        ],
        order: {
          key: "modified_at",
          ascending: true,
        },
      };
      queryObject = this.createFilter(queryObject);
      await this.getApplicationCounts();

      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );

      var eventEmails = [];

      response.data.forEach(function (item) {
        if (!item.recruiting_candidates) {
          item.recruiting_candidates = {};
        } else {
          eventEmails.push(item.recruiting_candidates.email);
        }
        var days =
          (new Date().getTime() - new Date(item.status_change_date).getTime()) /
          (1000 * 60 * 60 * 24);
        if (days > 7) {
          item.color = "red-lighten-3";
        } else if (days > 4) {
          item.color = "yellow-lighten-3";
        }
      });
      response.data.sort((a, b) =>
        a.meeting_status < b.meeting_status
          ? 1
          : b.meeting_status < a.meeting_status
          ? -1
          : 0,
      );
      response.data.sort((a, b) => {
        if (a.favorite && !b.favorite) {
          return -1;
        } else if (!a.favorite && b.favorite) {
          return 1;
        } else {
          return 0;
        }
      });
      this.applications = response.data;
      this.addEvents();
      this.addEmails();
      this.loading = false;
    },
    async addEvents() {
      this.loadEvent = true;
      var events = await this.$store.dispatch("checkCalendar", {});
      var groupCheck = {
        "Nicht angesetzt": {
          title: "Nicht angesetzt",
          dateTime: null,
          status_id: 19,
          candidates: [],
        },
      };
      var applicationsCheck = {};
      this.applications.forEach((item, index) => {
        events?.forEach((event) => {
          if (!groupCheck[event.summary + event.start.dateTime]) {
            groupCheck[event.summary + event.start.dateTime] = {
              title:
                event.summary +
                " " +
                new Date(event.start.dateTime).toLocaleDateString("de"),
              dateTime: event.start.dateTime,
              status_id: this.eventStatusIds[event.summary]
                ? this.eventStatusIds[event.summary]
                : null,
              candidates: [],
            };
          }
          if (
            event.attendees?.find(
              (attendee) => attendee.email == item.recruiting_candidates.email,
            )
          ) {
            item.event = event;
            groupCheck[event.summary + event.start.dateTime].candidates.push(
              item,
            );

            if (!applicationsCheck[item.id] && groupCheck[event.summary + event.start.dateTime].status_id == 19) {
              applicationsCheck[item.id] = true;
            }
          }
        });
      });
      this.applications.forEach((item) => {
        if (!applicationsCheck[item.id]) {
          groupCheck["Nicht angesetzt"].candidates.push(item);
        }
      });
      this.seminarGroups = Object.keys(groupCheck).map((key) => {
        return {
          title: groupCheck[key].title,
          candidates: groupCheck[key].candidates,
          status_id: groupCheck[key].status_id,
        };
      });
      this.seminarGroups = this.seminarGroups.filter((group) => {
        return this.$store.state.applicationView.filter.status.find(
          (status) => status.id == group.status_id && status.chosen,
        );
      });
      this.applications.forEach(function (item) {
        item.event = events?.find((event) =>
          event.attendees?.find(
            (attendee) => attendee.email == item.recruiting_candidates.email,
          ),
        );
      });
      console.log(this.applications)
      this.loadEvent = false;
    },
    async addEmails() {
      var canIdArray = [];
      this.applications.forEach(function (item) {
        canIdArray.push(item.candidate_id);
      });
      var queryObject = {
        table: "get_emails",
        where: [
          { type: "eq", key: "entity", value: "recruiting_candidates" },
          { type: "eq", key: "type", value: "received" },
          { type: "not", key: "processed", value: true },
          { type: "in", key: "candidate_id", value: canIdArray },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var emailObject = {};
      response.data.forEach(function (item) {
        emailObject[item.candidate_id] = item;
      });
      for (var i = 0; i < this.applications.length; i++) {
        if (emailObject[this.applications[i].candidate_id]) {
          this.applications[i].emailReceived = true;
        }
      }
    },
    async getApplicationCounts() {
      var queryObject = { 
        table: "count_applications", 
        where: [{
          type: "eq",
          key: "mandator_id",
          value: this.loggedInUser.mandator_id
        }]
      };
      if (this.$store.state.applicationView.filter.todo) {
        queryObject.where.push({ type: "is", key: "is_past_reminder_date", value: true })
      }
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );

      var countData = response.data;
      this.$store.state.applicationView.filter.status.forEach(function (item) {
        item.count = 0;
        item.emails = 0;
      });
      for (var i = 0; i < countData.length; i++) {
        var statusId = countData[i].status_id;
        var ratingCheck =
          this.countObject.filter.rating == 0
            ? true
            : countData[i].rating >= this.countObject.filter.rating;
        var jobCheck =
          Object.keys(this.countObject.filter.jobs).length > 0
            ? this.countObject.filter.jobs[countData[i].job_id]
            : true;
        var meetingStatusCheck =
          Object.keys(this.countObject.filter.meetingStatuses).length > 0
            ? this.countObject.filter.meetingStatuses[
                countData[i].meeting_status
              ]
            : true;

        var experienceCheck =
          Object.keys(this.countObject.filter.experienceLevels).length > 0
            ? this.countObject.filter.experienceLevels[
                countData[i].experience_level_id
              ]
            : true;
        var timeModelCheck =
          Object.keys(this.countObject.filter.timeModels).length > 0
            ? this.countObject.filter.timeModels[countData[i].time_model_id]
            : true;
        if (
          ratingCheck &&
          jobCheck &&
          experienceCheck & timeModelCheck & meetingStatusCheck
        ) {
          for (
            var j = 0;
            j < this.$store.state.applicationView.filter.status.length;
            j++
          ) {
            var matchStatusId =
              this.$store.state.applicationView.filter.status[j].id;
            if (statusId == matchStatusId) {
              this.$store.state.applicationView.filter.status[j].count =
                this.$store.state.applicationView.filter.status[j].count +
                countData[i].count;
              this.$store.state.applicationView.filter.status[j].emails =
                this.$store.state.applicationView.filter.status[j].emails +
                countData[i].email_not_processed;
            }
          }
        }
      }
    },
    createFilter(queryObject) {
      // Check, if candidate is in the job
      var countFilter = {
        jobs: {},
        rating: 0,
        experienceLevels: {},
        timeModels: {},
        meetingStatuses: {},
      };
      if (this.$store.state.applicationView.filter.jobs.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "job_id",
          value: this.$store.state.applicationView.filter.jobs,
        });
        this.$store.state.applicationView.filter.jobs.forEach(function (item) {
          countFilter.jobs[item] = true;
        });
      }

      // Check, if candidate is inside the rating
      if (this.$store.state.applicationView.filter.rating) {
        queryObject.where.push({
          type: "gte",
          key: "rating",
          value: this.$store.state.applicationView.filter.rating,
        });
        countFilter.rating = this.$store.state.applicationView.filter.rating;
      }

      // Check, if candidate is inside the status
      var statusFilter = [];
      this.$store.state.applicationView.filter.status.forEach(function (item) {
        if (item.chosen && item.id != 10) {
          statusFilter.push(item.id);
        }
      });
      localStorage.setItem(
        "applicationStatusFilter",
        JSON.stringify(this.$store.state.applicationView.filter.status),
      );
      queryObject.where.push({
        type: "in",
        key: "status_id",
        value: statusFilter,
      });

      // Check, if candidate has the relevant experience
      var experienceLevels = [];
      this.$store.state.applicationView.filter.experienceLevels.forEach(
        function (item) {
          if (item.active) {
            experienceLevels.push(item.id);
          }
        },
      );
      if (experienceLevels.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "recruiting_candidates.experience_level_id",
          value: experienceLevels,
        });
        experienceLevels.forEach(function (item) {
          countFilter.experienceLevels[item] = true;
        });
      }

      // Check, if candidate is in time model
      if (this.$store.state.applicationView.filter.timeModels.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "recruiting_candidates.time_model_id",
          value: this.$store.state.applicationView.filter.timeModels,
        });
        this.countObject.filter.timeModels =
          this.$store.state.applicationView.filter.timeModels;
        this.$store.state.applicationView.filter.timeModels.forEach(
          function (item) {
            countFilter.timeModels[item] = true;
          },
        );
      }

      // Check, if candidate is in meeting status
      if (this.$store.state.applicationView.filter.meetingStatuses.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "meeting_status",
          value: this.$store.state.applicationView.filter.meetingStatuses,
        });
        this.$store.state.applicationView.filter.meetingStatuses.forEach(
          function (item) {
            countFilter.meetingStatuses[item] = true;
          },
        );
      }
      if (this.$store.state.applicationView.filter.todo) {
        queryObject.where.push({
          type: "lte",
          key: "reminder_date",
          value: new Date().toISOString(),
        });
      }
      this.countObject.filter = countFilter;
      return queryObject;
    },
    async loadStatus() {
      var statusChoice = {};
      this.$store.state.applicationView.filter.status.forEach(function (item) {
        statusChoice[item.id] = item.chosen;
      });
      var status = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_status",
        select: "*,mandator_recruiting_status!inner(*)",
        where: [
          { type: "neq", key: "type", value: "pool" },
          { type: "is", key: "is_hidden", value: false },
          { type: "eq", key:"mandator_recruiting_status.mandator_id", value: this.$store.state.userInfo.mandator_id },
        ],
      });
      status.data.sort((a, b) => a.order - b.order);
      status.data.forEach(function (item) {
        item.chosen = statusChoice[item.id];
      });
      this.$store.state.applicationView.filter.status = status.data;
      this.showStatusPhases();
      await this.getApplicationCounts();
    },
    async openCandidateView(data, index) {
      this.$store.state.candidateView.jobCandidateId = null;
      this.chosenApplicationIndex = index;
      this.$store.state.recruitingView.candidateView.drawer = true;
      await this.sleep();
      this.$store.state.candidateView.id = data.candidate_id;
      this.$store.state.candidateView.jobCandidateId = data.id;
    },
    async sleep() {
      return new Promise((resolve) => setTimeout(resolve, 2));
    },
  },
  async created() {
    this.loading = true;
    this.loggedInUser = await this.$store.dispatch("getUserInfo");
    await this.getSelections();
    this.getApplications();
  },
};
</script>
