<template>
  <v-container>
    <h1 class="text-h4 mb-6">App Bar Management</h1>
    <v-card>
      <v-card-title class="d-flex align-center">
        <span>Manage Menu Items Visibility</span>
      </v-card-title>

      <v-card-text>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-list lines="two">
          <v-list-item
            v-for="(item, index) in appBarList"
            :key="index"
            :value="item"
            :title="item.title"
            :prepend-icon="item.icon"
          >
            <template v-slot:append>
              <v-switch
                v-model="item.visible"
                color="primary"
                hide-details
                density="compact"
                @change="updatePermissions()"
              ></v-switch>
            </template>
            
            <v-avatar 
              start
              :color="item.color" 
              size="small" 
              class="me-4 rounded"
            ></v-avatar>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'; 'vue';
import { supabase } from '@/supabase';

const props = defineProps({
    chosenRoleId:Number
});

const loading = ref(false);
const appBarList = ref([]);

const itemPermissions = ref([]);
const originalPermissions = ref([]);
const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
});

onMounted(async () => {
    await fetchElements();
});

const fetchElements = async () => {
    loading.value = true;
    try {
        const roleAppList = await supabase.from('public_user_roles').select('dashboard_items').eq('id', props.chosenRoleId);
        if (roleAppList.data[0] && roleAppList.data[0].dashboard_items) {
            appBarList.value = roleAppList.data[0].dashboard_items;
        }
        else {
            let appListResponse = await supabase.from('app_bar_list').select('*');
            appListResponse.data.forEach((item) => {
                item.visible = false;
            });
            appBarList.value = appListResponse.data;
        }
    } catch (error) {
        console.error('Error fetching elements:', error);
        snackbar.value = {
            show: true,
            text: 'Failed to load menu items',
            color: 'error'
        };
    } finally {
        loading.value = false;
    }
};

const updatePermissions = async () => {
  // No immediate database update, just UI change
  const response = await supabase.from("public_user_roles")
  .update({
    dashboard_items: appBarList.value
  })
  .eq('id', props.chosenRoleId);
};

const hasChanges = computed(() => {
  return JSON.stringify(itemPermissions.value) !== JSON.stringify(originalPermissions.value);
});

// Watch for role changes to update permissions
watch(() => props.chosenRoleId, async () => {
  if (props.chosenRoleId > 0) {
    await fetchElements();
  }
});
</script>

<style scoped>a
.max-width-200 {
  max-width: 200px;
}
</style>