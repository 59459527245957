<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ props }">
      <v-btn
        color="blue-grey-darken-2"
        v-bind="props"
        outlined
        size="small"
        prepend-icon="mdi-calendar-plus"
      >
        Bonus Ereignis erfassen
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 pa-4">
        Manuelles Bonus/Malus Ereignis erfassen
      </v-card-title>

      <v-card-text class="pt-4">
        <v-form ref="form">
          <v-row>
            <!-- Employee selection with autocomplete -->
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedEmployees"
                :items="employees"
                item-title="full_name"
                item-value="id"
                label="Mitarbeiter auswählen"
                multiple
                chips
                outlined
                :loading="loadingEmployees"
                no-data-text="Keine Mitarbeiter gefunden"
                :rules="[
                  (v) =>
                    (!!v && v.length > 0) ||
                    'Mindestens ein Mitarbeiter muss ausgewählt werden',
                ]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    {{ item.raw.full_name }}
                  </v-chip>
                  <span v-if="index === 3" class="text-grey ml-2">
                    (+{{ selectedEmployees.length - 3 }} weitere)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Bonus/Malus event selection -->
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedEvent"
                :items="groupedBonusEvents"
                item-title="name"
                item-value="id"
                label="Ereignis auswählen"
                outlined
                return-object
                :loading="loadingEvents"
                :rules="[(v) => !!v || 'Ein Ereignis muss ausgewählt werden']"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props" :title="null">
                    <template v-slot:title>
                      {{ item.raw.name }}
                    </template>
                    <template v-slot:append>
                      <v-chip
                        :color="item.raw.is_positive ? 'success' : 'error'"
                        size="small"
                      >
                        {{ item.raw.is_positive ? "Bonus" : "Malus" }}
                      </v-chip>
                    </template>
                  </v-list-item>
                </template>

                <template
                  v-slot:selection="{ item }"
                  class="d-flex align-center"
                >
                  <v-icon
                    :color="item.raw.is_positive ? 'success' : 'error'"
                    size="small"
                    class="mr-2"
                  >
                    {{
                      item.raw.is_positive
                        ? "mdi-check-circle"
                        : "mdi-alert-circle"
                    }}
                  </v-icon>
                  {{ item.raw.name }}
                </template>
              </v-select>
            </v-col>

            <!-- Date selection -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedDate"
                label="Datum des Ereignisses"
                type="date"
                v-bind="props"
                outlined
                :rules="[(v) => !!v || 'Datum muss ausgewählt werden']"
              />
            </v-col>

            <!-- Special bonus field -->
            <v-col cols="12" md="6" v-if="selectedEvent && selectedEvent.id === SPECIAL_BONUS_ID">
              <v-text-field
                v-model="extraBonusPoints"
                label="Sonderbonus Punkte"
                hide-details
                type="number"
                outlined
                :rules="[(v) => !!v || 'Punkte müssen angegeben werden']"
              ></v-text-field>
            </v-col>

            <!-- Comment field -->
            <v-col cols="12">
              <v-textarea
                v-model="comment"
                label="Kommentar (optional)"
                outlined
                auto-grow
                rows="3"
                placeholder="Zusätzliche Informationen oder Notizen zu diesem Ereignis"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn color="grey-darken-1" text @click="closeDialog">
          Abbrechen
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="saving"
          :disabled="!isFormValid || saving"
          @click="saveEvents"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { supabase } from "@/supabase";
import useSnackbar from "@/utils/useSnackbar";

const props = defineProps({
  refreshData: {
    type: Function,
    default: () => {},
  },
  preselectedDate: {
    type: String,
    default: null,
  },
  preselectedEmployeeId: {
    type: [Number, String],
    default: null,
  },
});

const SPECIAL_BONUS_ID = 31; // ID for "Sonderbonus", so that we can give extra points

// Use the global snackbar
const { showSuccess, showError } = useSnackbar();

// Dialog state
const dialog = ref(false);
const dateMenu = ref(false);

// Form data
const form = ref(null);
const selectedEmployees = ref([]);
const selectedEvent = ref(null);
const selectedDate = ref(new Date().toISOString().substr(0, 10));
const comment = ref("");
const extraBonusPoints = ref(null);

// Data loading
const employees = ref([]);
const bonusEvents = ref([]);
const loadingEmployees = ref(false);
const loadingEvents = ref(false);
const saving = ref(false);

// Group bonus events by type (Bonus/Malus)
const groupedBonusEvents = computed(() => {
  return bonusEvents.value;
});

// Form validation
const isFormValid = computed(() => {
  return (
    selectedEmployees.value.length > 0 &&
    selectedEvent.value &&
    selectedDate.value && 
    (selectedEvent.value.id !== SPECIAL_BONUS_ID || extraBonusPoints.value)
  );
});

// Reset form
const resetForm = () => {
  if (form.value) {
    form.value.reset();
  }
  // If we have preselected values, use them when resetting
  if (props.preselectedEmployeeId) {
    selectedEmployees.value = [props.preselectedEmployeeId];
  } else {
    selectedEmployees.value = [];
  }

  selectedEvent.value = null;
  comment.value = ""; // Reset comment

  if (props.preselectedDate) {
    selectedDate.value = props.preselectedDate;
  } else {
    selectedDate.value = new Date().toISOString().substr(0, 10);
  }
};

// Close dialog
const closeDialog = () => {
  dialog.value = false;
  resetForm();
};

// Load employees from Supabase
const fetchEmployees = async () => {
  loadingEmployees.value = true;
  try {
    const { data, error } = await supabase
      .from("employees")
      .select("id, first_name, last_name")
      // .eq("status", "Aktiv")
      // .eq("is_visible", true)
      .order("last_name", { ascending: true });

    if (error) throw error;

    employees.value = data.map((emp) => ({
      id: emp.id,
      full_name: `${emp.last_name}, ${emp.first_name}`,
    }));
  } catch (error) {
    console.error("Error fetching employees:", error);
    showError("Fehler beim Laden der Mitarbeiter");
  } finally {
    loadingEmployees.value = false;
  }
};

// Load bonus/malus events from Supabase (only those requiring manual input)
const fetchBonusEvents = async () => {
  loadingEvents.value = true;
  try {
    const { data, error } = await supabase
      .from("bonuses")
      .select("id, name, points, is_positive, requires_manual_input")
      .eq("requires_manual_input", true)
      .is("deleted_at", null)
      .order("is_positive", { ascending: false })
      .order("name");

    if (error) throw error;

    bonusEvents.value = data;
  } catch (error) {
    console.error("Error fetching bonus events:", error);
    showError("Fehler beim Laden der Ereignisse");
  } finally {
    loadingEvents.value = false;
  }
};

// Save bonus/malus events for selected employees
const saveEvents = async () => {
  if (!isFormValid.value) return;

  saving.value = true;
  try {
    // Prepare events for all selected employees
    const events = selectedEmployees.value.map((employeeId) => ({
      employee_id: employeeId,
      bonus_event_id: selectedEvent.value.id,
      event_date: selectedDate.value,
      comment: comment.value,
      created_at: new Date().toISOString(),
      extra_points: selectedEvent.value.id === SPECIAL_BONUS_ID ? extraBonusPoints.value : null,
    }));

    const { error } = await supabase
      .from("bonus_employee_events")
      .insert(events);

    if (error) throw error;

    showSuccess(`${events.length} Ereignis(se) erfolgreich gespeichert`);
    closeDialog();

    // Call refresh function if provided
    if (props.refreshData) {
      props.refreshData();
    }
  } catch (error) {
    console.error("Error saving events:", error);
    showError("Fehler beim Speichern der Ereignisse");
  } finally {
    saving.value = false;
  }
};

// Apply preselected values when they exist
onMounted(() => {
  if (props.preselectedDate) {
    selectedDate.value = props.preselectedDate;
  }

  if (props.preselectedEmployeeId) {
    selectedEmployees.value = [props.preselectedEmployeeId];
  }
});

// Load data when dialog is opened
watch(dialog, (newValue) => {
  if (newValue) {
    fetchEmployees();
    fetchBonusEvents();

    // Apply preselected values again when dialog opens
    if (props.preselectedDate) {
      selectedDate.value = props.preselectedDate;
    }

    if (props.preselectedEmployeeId) {
      selectedEmployees.value = [props.preselectedEmployeeId];
    }
  }
});
</script>

<style scoped>
.v-card-actions {
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
