<template>
  <v-navigation-drawer
    location="right"
    rail
    :rail-width="railWidth"
    v-if="!onlyPlan"
    v-model="drawer"
  >
    <v-slider class="mx-12" min="400" max="1000" v-model="railWidth"></v-slider>
    <EditCard
      @refresh-data="handleDataRefresh"
      :snackbar="snackbar"
      :employees="employees"
      :cityHotels="cityHotels"
      :allEventTypes="allEventTypes"
      :eventEdit="eventEdit"
      :type="type"
      :events="events"
      :smallScreen="smallScreen"
      :employeeDropdown="employeeDropdown"
      :addedEventInfo="addedEventInfo"
      :drawer="drawer"
    ></EditCard>
  </v-navigation-drawer>
  <div>
    <v-row class="mx-1">
      <v-col xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card
          elevation="10"
          class="full-height-card"
          style="overflow-y: auto"
        >
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  <v-chip class="mb-1" color="indigo-darken-4">{{
                    "KW " + getISOWeek(weekStartDate)
                  }}</v-chip>
                  Schichtplan für Woche
                  {{
                    weekStartDate.toLocaleDateString() +
                    " - " +
                    weekEndDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-select
                  :items="cities"
                  density="compact"
                  label="Stadt"
                  v-model="chosenCity"
                  item-value="id"
                  item-title="city"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  clearable
                  density="compact"
                  :items="cityHotels[chosenCity]"
                  label="Hotel"
                  v-model="chosenHotel"
                  item-value="id"
                  item-title="name"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" v-if="type == 'employees'">
                <v-text-field
                  v-model="chosenEmployee"
                  label="Mitarbeiter"
                  density="compact"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :color="chosenLevel == level ? 'success' : null"
                  v-for="level in ['Senior', 'Clerk', 'Alle']"
                  @click="chosenLevel = level"
                  :key="level"
                  size="x-small"
                  :value="level"
                  >{{ level }}</v-btn
                >
              </v-col>
              <v-col cols="2" v-if="type == 'employees'">
                <v-btn-toggle v-model="isPublic" mandatory>
                  <v-btn :value="true" size="small" @click="drawer = false"
                    >Öffentlich</v-btn
                  >
                  <v-btn :value="false" size="small">Intern</v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="2" align="right">
                <v-btn @click="drawer = !drawer">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="!onlyPlan" class="mt-n8">
                <tables
                  @editEvent="editEvent"
                  @change-date-range="handleDateRangeChange"
                  @update:eventFilter="updateEventFilter"
                  @refresh-data="handleDataRefresh"
                  :eventFilter="eventFilter"
                  :tableLoading="tableLoading"
                  :type="type"
                  :search="search"
                  :dayRange="dayRange"
                  :items="items"
                  :allEventTypes="allEventTypes"
                  :headers="headers"
                  :snackbar="snackbar"
                  :publicCompareArray="publicCompareArray"
                  :compareLoading="compareLoading"
                ></tables>
              </v-col>
              <v-dialog
                v-model="onlyPlan"
                transition="dialog-bottom-transition"
              >
                <v-toolbar density="compact">
                  <v-row class="ma-2">
                    <v-col align="left">Schichtplan</v-col>
                    <v-col align="right">
                      <v-btn @click="onlyPlan = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
                <v-container class="fullscreen-container pa-0" fluid>
                  <v-row class="fullscreen-row">
                    <v-col
                      xl="8"
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="scrollable-col"
                    >
                      <div class="table-container">
                        <tables
                          v-if="onlyPlan"
                          @editEvent="editEvent"
                          @change-date-range="handleDateRangeChange"
                          @update:eventFilter="updateEventFilter"
                          :eventFilter="eventFilter"
                          :tableLoading="tableLoading"
                          :type="type"
                          :search="search"
                          :dayRange="dayRange"
                          :items="items"
                          :allEventTypes="allEventTypes"
                          :headers="headers"
                          :employees="employees"
                        ></tables>
                      </div>
                    </v-col>
                    <v-col
                      xl="4"
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      v-if="onlyPlan"
                      class="scrollable-col"
                    >
                      <EditCard
                        @refresh-data="handleDataRefresh"
                        :snackbar="snackbar"
                        :employees="employees"
                        :cityHotels="cityHotels"
                        :allEventTypes="allEventTypes"
                        :eventEdit="eventEdit"
                        :isPublic="isPublic"
                        :type="type"
                        :events="events"
                        :smallScreen="smallScreen"
                        :employeeDropdown="employeeDropdown"
                        :addedEventInfo="addedEventInfo"
                      ></EditCard>
                    </v-col>
                  </v-row>
                </v-container>
              </v-dialog>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="eventEdit.dialog" width="1000">
      <EditCard
        @refresh-data="handleDataRefresh"
        :snackbar="snackbar"
        :employees="employees"
        :cityHotels="cityHotels"
        :allEventTypes="allEventTypes"
        :eventEdit="eventEdit"
        :type="type"
        :smallScreen="smallScreen"
        :events="events"
        :employeeDropdown="employeeDropdown"
        :addedEventInfo="addedEventInfo"
      ></EditCard>
    </v-dialog>
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>{{ snackbar.data }}</div>
    <div>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>{{ snackbar.data }}</div>
    <div v-if="snackbar.error">{{ snackbar.error }}</div>
    <div v-else>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
  <v-dialog v-model="snackbar.errorShow" width="600px" persistent>
    <v-card>
      <v-alert type="error">
        <v-card-title> Fehler </v-card-title>
        <v-card-text>
          {{ snackbar.error }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="snackbar.errorShow = false"> Close </v-btn>
        </v-card-actions>
      </v-alert>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { getISOWeek, parseISO, format } from "date-fns";
import { de } from "date-fns/locale";
import { useDisplay } from "vuetify";
import ManualEventsDialog from "@/components/Settings/Employees/Bonus/ManualEventsDialog.vue";
import Tables from "./EmployeeHotelEntries/Tables.vue";
import EditCard from "./EmployeeHotelEntries/EditCard.vue";
const { mobile, xs, sm, md } = useDisplay();
</script>
<script>
export default {
  name: "Shifts",
  props: {
    type: String,
    locationFilter: Array,
  },
  data() {
    return {
      preciseTable: false,
      tableLoading: false,
      today: new Date(),
      forwardDays: 6,
      onlyPlan: false,
      backDays: 3,
      dayRange: 10,
      dateRange: [],
      drawer: false,
      railWidth: 600,
      publicCompareArray: [],
      compareLoading: false,
      isPublic: false,
      hotels: [],
      hotelDropdown: [],
      cityHotels: {},
      items: [],
      snackbar: {},
      employeeDropdown: [],
      startDate: new Date(),
      endDate: new Date(),
      eventEdit: {
        dialog: false,
        hotels: [],
        eventData: {},
      },
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      employees: [],
      eventFilter: null,
      tableLoading: false,
      headers: [],
      hotels: [],
      chosenCity: 1,
      chosenHotel: null,
      chosenLevel: "Alle",
      chosenEmployee: null,
      search: null,
      cities: [],
      cityEmployees: [],
      allEventTypes: [],
      addedEventInfo: [],
      events: [],
      smallScreen: true,
    };
  },
  watch: {
    railWidth(newValue) {
      localStorage.setItem("railWidth", newValue);
    },
    drawer(newValue) {
      localStorage.setItem("drawer", newValue);
    },
    chosenCity(newCity) {
      this.chosenHotel = null;
      this.chosenLevel = "Alle";
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    chosenHotel(newHotel) {
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    chosenLevel(newLevel) {
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    chosenEmployee(newEmployee) {
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    isPublic(newValue) {
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    "eventEdit.eventData": {
      handler(newValue, oldValue) {
        if (newValue.hotel_id && this.type == "employees") {
          this.checkHotelData(newValue.hotel_id); // Aufruf der Methode zur Überprüfung der neuen hotel_id
        }
        if (newValue.employee_id && this.type == "hotels") {
          this.checkEmployeeData(newValue.employee_id);
        }
      },
      deep: true,
    },
    eventFilter: {
      handler(newValue) {
        if (this.type == "employees") {
          this.getEmployees();
        } else if (this.type == "hotels") {
          this.getHotels();
        }
      },
    },
  },
  computed: {
    weekStartDate() {
      let date = new Date(this.startDate);
      date.setDate(this.startDate.getDate() + this.backDays);
      return date;
    },
    weekEndDate() {
      let date = new Date(this.endDate);
      date.setDate(this.endDate.getDate() - this.forwardDays + 6);
      return date;
    },
  },
  methods: {
    async getEvents() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "*",
        table: "employee_date_events_keys",
        order: { key: "id", ascending: true },
      });
      this.events = [];
      this.addedEventInfo = [];
      this.allEventTypes = response.data;
      response.data.forEach((event) => {
        if (event.is_addable) {
          this.addedEventInfo.push(event);
        } else {
          this.events.push(event);
        }
      });
    },
    async checkHotelData(newValue) {
      if (newValue) {
        let queryObject = {
          select:
            "id,is_senior,hotel_id,employee_id,employees(name),hotels(name),date",
          table: this.isPublic ? "shifts_public" : "shifts",
          where: [
            { type: "eq", key: "hotel_id", value: newValue },
            { type: "gte", key: "employee_id", value: 0 },
            {
              type: "eq",
              key: "is_senior",
              value: this.eventEdit.eventData.is_senior,
            },
            { type: "eq", key: "check_id", value: 1 },
          ],
        };
        if (this.eventEdit.eventData.dateRange.length == 1) {
          queryObject.where.push({
            type: "eq",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
        }
        if (this.eventEdit.eventData.dateRange.length > 1) {
          let endDate = new Date(this.eventEdit.eventData.dateRange[1]);
          endDate.setHours(endDate.getHours() + 6);
          queryObject.where.push({
            type: "gte",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
          queryObject.where.push({
            type: "lte",
            key: "date",
            value: endDate.toISOString(),
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.eventEdit.shiftArray = response.data;
      }
    },
    async checkEmployeeData(newValue) {
      if (newValue) {
        let queryObject = {
          select:
            "id,is_senior,hotel_id,employee_id,employees(name),hotels(name),date",
          table: this.isPublic ? "shifts_public" : "shifts",
          where: [
            { type: "eq", key: "employee_id", value: newValue },
            { type: "gte", key: "hotel_id", value: 0 },
            { type: "eq", key: "check_id", value: 1 },
          ],
        };
        if (this.eventEdit.eventData.dateRange.length == 1) {
          queryObject.where.push({
            type: "eq",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
        }
        if (this.eventEdit.eventData.dateRange.length > 1) {
          let endDate = new Date(this.eventEdit.eventData.dateRange[1]);
          endDate.setHours(endDate.getHours() + 6);
          queryObject.where.push({
            type: "gte",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
          queryObject.where.push({
            type: "lte",
            key: "date",
            value: endDate.toISOString(),
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.eventEdit.shiftArray = response.data;
      }
    },
    handleDateRangeChange(range) {
      this.forwardDays = range.forwardDays;
      this.backDays = range.backDays;
      this.dayRange = range.dayRange;
      this.getNextMonday(); // If this method exists in the parent
      this.handleDataRefresh(this.type);
    },
    async getCities() {
      let queryObject = {
        select: "id,city",
        table: "cities",
        where: [{ type: "is", key: "hotel_city", value: true }],
      };
      if (this.locationFilter.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "id",
          value: this.locationFilter,
        });
        this.chosenCity = this.locationFilter[0];
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.cities = response.data;
    },
    async getHotelDropdown() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,name,short_name,city_id",
        table: "hotels",
        where: [{ type: "eq", key: "email", value: true }],
      });
      this.cityHotels = {
        "Alle Hotels": response.data,
      };
      response.data.forEach((hotel) => {
        if (!this.cityHotels[hotel.city_id]) {
          this.cityHotels[hotel.city_id] = [];
        }
        this.cityHotels[hotel.city_id].push(hotel);
      });
    },
    async getEmployeeDropdown() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,first_name,last_name",
        table: "employees",
        where: [{ type: "neq", key: "status", value: "Inaktiv" }],
      });
      response.data.forEach((employee) => {
        employee.show_name = employee.last_name + ", " + employee.first_name;
      });
      this.employeeDropdown = response.data;
    },
    async getHotels() {
      this.hotels = [];
      let queryObject = {
        select: "id,name,short_name,city_id",
        table: "hotels",
        where: [{ type: "eq", key: "email", value: true }],
        order: { key: "name", ascending: true },
      };
      queryObject = {
        select: `id,work_start,work_end,name,short_name,bonus_points_clerk,bonus_points_senior,shift_days_senior,shift_days_clerk,city_id,cities(city),shifts:${this.isPublic ? "shifts_public" : "shifts"}(*,hotels(name),hotel_performance_records(id),employees(id,first_name,last_name,name))`,
        table: "hotels",
        where: [
          { type: "eq", key: "email", value: true },
          {
            type: "gte",
            key: "shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "shifts.date",
            value: this.endDate.toISOString(),
          },
          { type: "eq", key: "shifts.check_id", value: 1 },
          {
            type: "is",
            key: "shifts.is_training",
            value: false,
          },
        ],
      };
      if (this.chosenCity) {
        queryObject.where.push({
          type: "eq",
          key: "city_id",
          value: this.chosenCity,
        });
      }
      if (this.chosenHotel) {
        queryObject.where.push({
          type: "eq",
          key: "id",
          value: this.chosenHotel,
        });
      }

      if (this.locationFilter.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "city_id",
          value: this.locationFilter,
        });
      }

      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((hotel) => {
        hotel.is_senior = true;
        if (hotel.bonus_points_clerk > 0) {
          let clerkShift = JSON.parse(JSON.stringify(hotel));
          hotel.is_senior = false;
          response.data.push(clerkShift);
        }
      });
      response.data.forEach((hotel) => {
        hotel.shifts = hotel.shifts.filter((shift) => {
          return shift.is_senior == hotel.is_senior;
        });
        hotel.shifts.sort((a, b) => {
          return a.date.localeCompare(b.date);
        });

        let startPoint = new Date(this.startDate);
        startPoint.setDate(startPoint.getDate() + 2);
        let checkCurrentWeek = hotel.shifts.filter((shift) => {
          return new Date(shift.date) >= startPoint;
        });

        for (var i = 4; i < this.headers.length; i++) {
          var day = this.headers[i].date.getDay();
          if (
            !checkCurrentWeek.some(
              (shift) => new Date(shift.date).getDay() === day,
            )
          ) {
            hotel.shifts.push({
              hotel_id: hotel.id,
              date: this.headers[i].date,
              is_senior: hotel.is_senior,
              is_training: false,
              employee_id: null,
            });
          }
        }
      });

      response.data.forEach((hotel) => {
        let level = hotel.is_senior ? "Senior" : "Clerk";
        let showName = hotel.short_name + " (" + level + ")";
        let hotelObject = {
          id: hotel.id + level,
          hotel_id: hotel.id,
          show_name: showName,
          city: hotel.cities.city,
          is_senior: hotel.is_senior,
          link: `/Hotels/${hotel.id}`,
        };
        this.hotels.push(hotelObject);
        this.extractHotelWeekDates(hotel, hotelObject);
      });
      this.hotels.sort((a, b) => {
        return a.show_name.localeCompare(b.show_name);
      });
      if (this.chosenLevel && this.chosenLevel != "Alle") {
        const levelCheck = this.chosenLevel == "Senior" ? true : false;
        this.hotels = this.hotels.filter((hotel) => {
          return hotel.is_senior == levelCheck;
        });
      }
      this.hotels = [
        ...new Map(this.hotels.map((hotel) => [hotel.id, hotel])).values(),
      ];
      this.items = this.hotels;
    },
    async getEmployees() {
      this.compareShifts();
      this.tableLoading = true;
      this.employees = [];
      let shiftCheck = [];
      let usePublic = this.isPublic;
      let queryObject = {
        select: `id,first_name,last_name,status,employees_hotels(*,hotels(id,city_id)),shift_days,employee_data_matching(*,employee_data_keys(*)),shifts:${this.isPublic ? "shifts_public" : "shifts"}(*,hotels(id,name,short_name)),employee_date_event_matching:${this.isPublic ? "employee_date_event_matching_public" : "employee_date_event_matching"}!employee_id(*,keyInfo:employee_date_events_keys(*),hotels(short_name))`,
        table: "employees",
        where: [
          { type: "neq", key: "status", value: "Inaktiv" },
          {
            type: "in",
            key: "employee_data_matching.key_id",
            value: [5, 6, 7, 30, 59],
          },
          {
            type: "gte",
            key: "shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "shifts.date",
            value: this.endDate.toISOString(),
          },
          { type: "eq", key: "shifts.check_id", value: 1 },
          {
            type: "gte",
            key: "employee_date_event_matching.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "employee_date_event_matching.date",
            value: this.endDate.toISOString(),
          },
          {
            type: "is",
            key: "employee_date_event_matching.is_activated",
            value: true,
          },
        ],
      };

      // Add eventFilter to query if set
      if (this.eventFilter) {
        let eventId = this.allEventTypes.find((event) => {
          return event.value == this.eventFilter;
        }).id;
        queryObject.where.push({
          type: "eq",
          key: `employee_date_event_matching.employee_date_event_id`,
          value: eventId,
        });
      }

      if (this.chosenLevel && this.chosenLevel != "Alle") {
        if (this.chosenLevel == "Senior") {
          queryObject.where.push({
            type: "is",
            key: "employees_hotels.is_senior",
            value: true,
          });
        } else if (this.chosenLevel == "Clerk") {
          queryObject.where.push({
            type: "is",
            key: "employees_hotels.is_clerk",
            value: true,
          });
        }
      }

      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );

      response.data = response.data.filter((employee) => {
        return (
          !this.chosenEmployee ||
          JSON.stringify(employee)
            .toLowerCase()
            .includes(this.chosenEmployee.toLowerCase())
        );
      });

      response.data = response.data.filter((employee) => {
        return employee.employee_data_matching.some((data) => {
          return data.key_id === 6 && data.value_id === 2;
        });
      });
      response.data.forEach((employee) => {
        employee.link = `/Mitarbeiter/${employee.id}`;
        shiftCheck = shiftCheck.concat(employee.shifts);
        employee.show_name = employee.last_name + ", " + employee.first_name;
        let city = this.cities.find((city) => {
          return employee.employee_data_matching.some((data) => {
            return data.key_id === 5 && data.value_id === city.id;
          });
        });
        employee.city_id = city ? city.id : null;
        employee.city = city ? city.city : null;
      });
      if (this.chosenCity) {
        response.data = response.data.filter((employee) => {
          return (
            employee.city_id == this.chosenCity ||
            employee.employees_hotels.find(
              (hotel) => hotel.hotels.city_id == this.chosenCity,
            )
          );
        });
      } else if (this.chosenHotel) {
        response.data = response.data.filter((employee) => {
          return employee.employees_hotels.some(
            (hotel) => hotel.hotels.id == this.chosenHotel,
          );
        });
      }
      this.employees = [];
      response.data.forEach((employee) => {
        let employeeObject = {
          id: employee.id,
          employee_id: employee.id,
          tableId: employee.id,
          show_name: employee.show_name,
          city: employee.city,
          link: "/Mitarbeiter/" + employee.id,
        };
        this.extractWeekDates(employee, employeeObject, shiftCheck);

        // Add event_type property to items for proper filtering
        for (let i = 1; i <= this.dayRange; i++) {
          if (employeeObject[i]) {
            employeeObject[i].event_type = employeeObject[i].value;
            if (employeeObject[i].addedEvents) {
              employeeObject[i].addedEvents.forEach((event) => {
                event.event_type = event.value;
              });
            }
          }
        }

        this.employees.push(employeeObject);
      });
      this.employees.sort((a, b) => {
        return a.show_name.localeCompare(b.show_name);
      });
      this.items = this.employees;
      this.tableLoading = false;
    },
    async compareShifts() {
      this.compareLoading = true;
      let queryObject = {
        select: "*",
        table: "shifts",
        where: [
          {
            type: "gte",
            key: "date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "date",
            value: this.endDate.toISOString(),
          },
          { type: "eq", key: "check_id", value: 1 },
        ],
      };
      const realResponse = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const shiftsReal = realResponse.data;
      queryObject.table = "shifts_public";
      const publicResponse = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const shiftsPublic = publicResponse.data;
      const days = this.backDays + this.forwardDays;
      queryObject.where.pop();
      queryObject.table = "employee_date_event_matching";
      const realResponseEvents = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const eventsReal = realResponseEvents.data;
      queryObject.table = "employee_date_event_matching_public";
      const publicResponseEvents = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.publicCompareArray = [];

      for (let i = 0; i <= days; i++) {
        let dayArray = {
          shiftsReal: [],
          shiftsPublic: [],
          eventsReal: [],
          eventsPublic: [],
          areEqual: false,
        };
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        shiftsReal.forEach((shift) => {
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            dayArray.shiftsReal.push(shift);
          }
        });
        shiftsPublic.forEach((shift) => {
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            dayArray.shiftsPublic.push(shift);
          }
        });
        eventsReal.forEach((event) => {
          if (
            new Date(event.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            dayArray.eventsReal.push(event);
          }
        });
        publicResponseEvents.data.forEach((event) => {
          if (
            new Date(event.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            dayArray.eventsPublic.push(event);
          }
        });

        // Compare the arrays and set areEqual property
        dayArray.areEqual = this.compareShiftArrays(
          dayArray.shiftsReal,
          dayArray.shiftsPublic,
          dayArray.eventsReal,
          dayArray.eventsPublic,
        );
        dayArray.date = date.toLocaleDateString();

        this.publicCompareArray.push(dayArray);
      }
      this.compareLoading = false;
    },

    compareShiftArrays(realShifts, publicShifts, realEvents, publicEvents) {
      // Check if both shift arrays have different lengths, they can't be equal
      if (realShifts.length !== publicShifts.length) {
        return false;
      }

      // Check if both event arrays have different lengths, they can't be equal
      if (realEvents.length !== publicEvents.length) {
        return false;
      }

      // Sort shifts arrays for consistent comparison
      const sortedRealShifts = [...realShifts].sort((a, b) => {
        // Sort by employee_id and hotel_id to ensure consistent order
        if (a.employee_id !== b.employee_id) {
          return a.employee_id - b.employee_id;
        }
        return a.hotel_id - b.hotel_id;
      });

      const sortedPublicShifts = [...publicShifts].sort((a, b) => {
        if (a.employee_id !== b.employee_id) {
          return a.employee_id - b.employee_id;
        }
        return a.hotel_id - b.hotel_id;
      });

      // Sort events arrays for consistent comparison
      const sortedRealEvents = [...realEvents].sort((a, b) => {
        // Sort by employee_id and employee_date_event_id to ensure consistent order
        if (a.employee_id !== b.employee_id) {
          return a.employee_id - b.employee_id;
        }
        if (a.employee_date_event_id !== b.employee_date_event_id) {
          return a.employee_date_event_id - b.employee_date_event_id;
        }
        return a.hotel_id - b.hotel_id;
      });

      const sortedPublicEvents = [...publicEvents].sort((a, b) => {
        if (a.employee_id !== b.employee_id) {
          return a.employee_id - b.employee_id;
        }
        if (a.employee_date_event_id !== b.employee_date_event_id) {
          return a.employee_date_event_id - b.employee_date_event_id;
        }
        return a.hotel_id - b.hotel_id;
      });

      // Compare each shift in the arrays
      for (let i = 0; i < sortedRealShifts.length; i++) {
        const realShift = sortedRealShifts[i];
        const publicShift = sortedPublicShifts[i];

        // Compare every property
        const shiftKeysToCheck = [
          "employee_id",
          "hotel_id",
          "date",
          "is_senior",
          "note",
          "is_booked",
          "special_start_time",
          "special_end_time",
        ];
        for (const key of shiftKeysToCheck) {
          // Special handling for Date objects
          if (key === "date") {
            if (
              new Date(realShift[key]).getTime() !==
              new Date(publicShift[key]).getTime()
            ) {
              return false;
            }
            continue;
          }

          // For all other properties, do a strict equality check
          if (realShift[key] !== publicShift[key]) {
            return false;
          }
        }
      }

      // Compare each event in the arrays
      for (let i = 0; i < sortedRealEvents.length; i++) {
        const realEvent = sortedRealEvents[i];
        const publicEvent = sortedPublicEvents[i];

        // Compare specific keys for events
        const eventKeysToCheck = [
          "employee_id",
          "employee_date_event_id",
          "date",
          "note",
          "hotel_id",
          "is_activated",
          "is_senior",
          "created_by",
        ];
        for (const key of eventKeysToCheck) {
          // Special handling for Date objects
          if (key === "date") {
            if (
              new Date(realEvent[key]).getTime() !==
              new Date(publicEvent[key]).getTime()
            ) {
              return false;
            }
            continue;
          }

          // For all other properties, do a strict equality check
          if (realEvent[key] !== publicEvent[key]) {
            return false;
          }
        }
      }

      // If we made it here, both shifts and events arrays are equal
      return true;
    },
    extractHotelWeekDates(hotel, hotelObject) {
      const dates = [];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        let index = i + 1;
        let addedEventInfoArray = [];
        let startTime = hotel.work_start;
        let endTime = hotel.work_end;
        let isDifferentTime = null;
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        let pushedAlready = false;
        hotel.shifts.forEach((shift) => {
          let weekDay = new Date(shift.date).getDay();
          let bookingDay = false;
          if (hotel.is_senior) {
            bookingDay = hotel.shift_days_senior.find((day) => day == weekDay);
          } else {
            bookingDay = hotel.shift_days_clerk.find((day) => day == weekDay);
          }
          let eventName;
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            if (shift.employees) {
              eventName = shift.employees.last_name;
            } else if (shift.is_booked || shift.is_on_demand) {
              eventName = "!!! Buchung !!!";
            } else if (bookingDay >= 0 && shift.is_booked != false) {
              eventName = "Buchungstag";
            }

            if (
              shift.special_start_time &&
              startTime != shift.special_start_time
            ) {
              startTime = shift.special_start_time;
              isDifferentTime = true;
            }
            if (shift.special_end_time && endTime != shift.special_end_time) {
              endTime = shift.special_end_time;
              isDifferentTime = true;
            }

            hotelObject[index] = {
              date: date.toLocaleDateString("de-DE"),
              real_date: date,
              event: eventName,
              employee_id: shift.employees ? shift.employees.id : null,
              hotel_id: shift.hotel_id,
              is_shift: true,
              is_senior: shift.is_senior,
              shift_id: shift.id,
              note: shift.note,
              special_start_time: startTime,
              special_end_time: endTime,
              isDifferentTime: isDifferentTime,
              shift: shift,
              performanceRecordId:
                shift.hotel_performance_records &&
                shift.hotel_performance_records[0]
                  ? shift.hotel_performance_records[0].id
                  : null,
            };
            if (eventName == "Buchungstag") {
              hotelObject[index].color = "red";
            }
            pushedAlready = true;
          }
        });
        if (!pushedAlready) {
          hotelObject[index] = {
            date: date.toLocaleDateString(),
            real_date: date,
            event: "Offen",
            icon: null,
          };
        }
      }
      return dates;
    },
    extractWeekDates(employee, employeeObject, shiftCheck) {
      const dates = [];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        let index = i + 1;
        let addedEventInfoArray = [];
        let checkDuplicateObject = {};
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        let addedEvents = employee.employee_date_event_matching.filter(
          (event) => {
            return (
              event.keyInfo.is_addable &&
              new Date(event.date).toLocaleDateString() ==
                new Date(date).toLocaleDateString()
            );
          },
        );
        let pushedAlready = false;
        employee.shifts.forEach((shift) => {
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            let eventName = shift.is_senior
              ? "S/" + shift.hotels.short_name
              : "c/" + shift.hotels.short_name;
            employeeObject[index] = {
              date: date.toLocaleDateString("de-DE"),
              real_date: date,
              event: eventName,
              hotel_id: shift.hotels.id,
              employee_id: employee.id,
              icon: "mdi-domain",
              is_shift: true,
              shift_id: shift.id,
              is_senior: shift.is_senior,
              note: shift.note,
            };
            pushedAlready = true;
          }
        });
        employee.employee_date_event_matching.forEach((event) => {
          if (
            new Date(event.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            let eventName = null;
            let icon = null;
            let value = null;
            let color = null;
            let toolTip = null;
            let event_type = null;
            let is_not_filled = null;
            let level = null;
            let hotelName = null;
            let eventObject = {};

            if (event.keyInfo && !event.keyInfo.is_addable) {
              eventObject = event.keyInfo;
            }

            if (eventObject) {
              eventName = eventObject.name;
              icon = eventObject.icon;
              value = eventObject.value;
              event_type = eventObject.value;
              color = eventObject.color;
              /*if (eventObject.id == 2) {
                toolTip = event.updated_by_employee
                ? `${
                    employee.show_name
                  } hat sich am ${this.formatFreeWishEntered(
                    event.updated_by_employee,
                  )} Frei gewünscht. ${
                    event.note
                      ? `<br/> Begründung: ${event.note}`
                      : ""
                  }`
                : null;
              }
                */

              if (eventObject.value == "is_absent" && event.hotel_id) {
                let filledShift = shiftCheck.find((shift) => {
                  return (
                    shift.date == event.date && shift.hotel_id == event.hotel_id
                  );
                });
                if (!filledShift) {
                  level = event.is_senior ? "S/" : "c/";
                  hotelName = event.hotels.short_name;
                  eventName = eventObject.name + " (" + level + hotelName + ")";
                  color = eventObject.color;
                  is_not_filled = true;
                }
              }
            }

            if (eventName && !pushedAlready) {
              employeeObject[index] = {
                date: date.toLocaleDateString(),
                real_date: date,
                event: eventName,
                shift_id: event.shift_id,
                hotelName,
                employee_id: employee.id,
                is_not_filled,
                level,
                icon,
                value,
                event_type,
                color,
                toolTip,
              };
              pushedAlready = true;
            }

            for (var i = 0; i < addedEvents.length; i++) {
              if (!checkDuplicateObject[addedEvents[i].keyInfo.id]) {
                checkDuplicateObject[addedEvents[i].keyInfo.id] = true;
                addedEventInfoArray.push(addedEvents[i].keyInfo);
              }
            }
          }
          if (employeeObject[index]) {
            employeeObject[index].addedEvents = addedEventInfoArray;
          }
        });
        if (!pushedAlready) {
          employeeObject[index] = {
            employee_id: employee.id,
            hotel_id: null,
            date: date.toLocaleDateString(),
            real_date: date,
            event: "Offen",
            icon: null,
            addedEvents: addedEventInfoArray,
          };
        }
      }
      return dates;
    },
    async editEvent(eventData) {
      const { item, value, n } = eventData;
      // Only open dialog on smaller screens
      this.eventEdit.dialog = !this.drawer && !this.isPublic;
      this.eventEdit.shiftArray = [];
      this.eventEdit.firstHotelId = value.hotel_id;
      this.eventEdit.firstEmployeeId = value.employee_id;

      let dateRangePart = new Date(value.real_date);
      dateRangePart.setHours(15);

      if (value.is_senior == undefined && this.chosenLevel != "Alle") {
        value.is_senior = this.chosenLevel == "Senior" ? true : false;
      }
      if (this.type == "hotels") {
        item.employee_id = value.employee_id;
      }
      this.eventEdit.eventData = {
        shift_id: value.shift_id,
        employee_id: item.employee_id,
        name: item.show_name,
        date: value.date,
        shift_id: value.shift_id,
        is_senior:
          value.is_senior || value.is_senior == undefined ? true : false,
        formattedDate: value.real_date.toISOString().split("T")[0],
        dateRange: [dateRangePart],
        event: value.event,
        hotel_id: value.hotel_id,
        special_start_time: value.special_start_time,
        special_end_time: value.special_end_time,
        isDifferentTime: value.isDifferentTime,
        icon: value.icon,
        hotels: [],
        note: value.note,
      };
      if (this.type == "employees") {
        await this.checkHotelDropdown();
      } else {
        await this.checkEmployeeDropdown();
      }

      if (value.addedEvents) {
        value.addedEvents.forEach((event) => {
          this.eventEdit.eventData[event.value] = true;
        });
      }
      this.eventEdit.eventData[value.value] = true;
    },
    handleDataRefresh(type) {
      //this.checkHotelData(this.eventEdit.eventData.hotel_id);
      //this.checkEmployeeData(this.eventEdit.eventData.employee_id);
      if (type === "hotels") {
        this.getHotels();
      } else if (type === "employees") {
        this.getEmployees();
      }
    },
    async changeDays(type) {
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    },
    async checkEmployeeDropdown() {
      const value = this.eventEdit.eventData;
      let queryObject = {
        select: `
          id,
          employees!inner (
            id, 
            first_name, 
            last_name,
            employee_date_event_matching!employee_id (
              *,
              employee_date_events_keys (*)
            ),
            shifts:${this.isPublic ? "shifts_public" : "shifts"} (
              id,
              hotel_id,
              date,
              hotels (
                id,
                name,
                short_name
              )
            )
          )
        `,
        table: "employees_hotels",
        where: [
          { type: "eq", key: "hotel_id", value: value.hotel_id },
          { type: "neq", key: "employees.status", value: "Inaktiv" },
          { type: "not", key: "is_blocked", value: true },
          {
            type: "eq",
            key: "employees.shifts.date",
            value: value.formattedDate,
          },
          {
            type: "eq",
            key: "employees.shifts.check_id",
            value: 1,
          },
          {
            type: "eq",
            key: "employees.employee_date_event_matching.date",
            value: value.formattedDate,
          },
          {
            type: "is",
            key: "employees.employee_date_event_matching.is_activated",
            value: true,
          },
        ],
      };
      if (value.is_senior == false) {
        queryObject.where.push({ type: "eq", key: "is_clerk", value: true });
      } else {
        queryObject.where.push({ type: "eq", key: "is_senior", value: true });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.eventEdit.employees = response.data.map((employee) => {
        let icon = null;
        let color = null;
        let toolTip = "Verfügbar";
        let hotel = null;
        if (employee.employees.shifts.length > 0) {
          icon = "mdi-domain";
          toolTip = employee.employees.shifts[0].hotels.name;
          hotel = employee.employees.shifts[0].hotel_short_name;
          color = "grey";
        }
        if (employee.employees.employee_date_event_matching.length > 0) {
          let key =
            employee.employees.employee_date_event_matching[0]
              .employee_date_events_keys;
          icon = key.icon;
          color = key.color;
          toolTip = key.name;
          color = key.color ? key.color : "blue-grey";
        }
        return {
          id: employee.employees.id,
          name:
            employee.employees.last_name + ", " + employee.employees.first_name,
          short_name:
            employee.employees.last_name + ", " + employee.employees.first_name,
          icon: icon,
          color: color,
          hotel: hotel,
          toolTip: toolTip,
        };
      });
      this.eventEdit.employees.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      let searchEmployee = this.eventEdit.employees.find((employee) => {
        if (employee.id === value.employee_id) {
          return employee;
        }
      });
      if (!searchEmployee) {
        this.eventEdit.eventData.employee_id = null;
      }
    },
    async checkHotelDropdown() {
      const value = this.eventEdit.eventData;
      let queryObject = {
        select: "id,hotels!inner(id,name,short_name)",
        table: "employees_hotels",
        where: [
          { type: "eq", key: "employee_id", value: value.employee_id },
          { type: "is", key: "hotels.email", value: true },
          { type: "not", key: "is_blocked", value: true },
        ],
      };
      if (value.is_senior == false) {
        queryObject.where.push({ type: "eq", key: "is_clerk", value: true });
      } else {
        queryObject.where.push({ type: "eq", key: "is_senior", value: true });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.eventEdit.hotels = response.data.map((hotel) => {
        return {
          id: hotel.hotels.id,
          name: hotel.hotels.name,
          short_name: hotel.hotels.short_name,
        };
      });
      let searchHotel = this.eventEdit.hotels.find((hotel) => {
        if (hotel.id === value.hotel_id) {
          return hotel;
        }
      });
      if (!searchHotel) {
        this.eventEdit.eventData.hotel_id = null;
      }
    },
    addHeaders() {
      this.headers = [
        {
          title: "Name",
          align: "start",
          sortable: false,
          value: "show_name",
        },
      ];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        const dateOptions = {
          weekday: "short",
          day: "2-digit",
          month: "2-digit",
        };
        this.headers.push({
          title: date.toLocaleDateString("de-DE", dateOptions),
          date: date,
          value: (i + 1).toString(),
          align: "center",
        });
      }
    },
    formatFreeWishEntered(dateString) {
      const date = parseISO(dateString);
      return format(date, "EEEE, dd.MM.yyyy, 'um' HH:mm 'Uhr'", { locale: de });
    },
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      let daysUntilMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      if (this.type == "hotels") {
        daysUntilMonday = -((currentDay + 6) % 7);
      }
      this.today.setDate(this.today.getDate() + daysUntilMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
    },
    updateEventFilter(newFilter) {
      this.eventFilter = newFilter;
    },
  },

  mounted() {
    if (localStorage.getItem("drawer")) {
      const drawer = localStorage.getItem("drawer") == "true" ? true : false;
      this.drawer = drawer;
    }
    if (localStorage.getItem("railWidth")) {
      this.railWidth = localStorage.getItem("railWidth");
    }
    this.getCities();
    this.getEvents();
    this.getHotelDropdown();
    this.getEmployeeDropdown();

    if (this.preciseTable) {
      this.forwardDays = 27;
      this.backDays = 10;
      this.dayRange = 38;
      this.getNextMonday();
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    } else {
      this.forwardDays = 6;
      this.backDays = 3;
      this.dayRange = 10;
      this.getNextMonday();
      if (this.type == "hotels") {
        this.getHotels();
      } else {
        this.getEmployees();
      }
    }
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 2px;
  text-align: center;
}

:deep(.v-data-table-virtual th),
:deep(.v-data-table-virtual td) {
  border: 1px solid black;
}

.full-height-card {
  height: calc(
    100vh - 50px
  ) !important; /* Subtract some pixels for padding/margin */
  min-height: 500px; /* Minimum height for smaller screens */
}

.tableFixHead {
  overflow: auto;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.tableFixHead thead th {
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: white;
}
.sticky-col {
  position: sticky;
  left: -20px;
  border: 4px solid black;
  z-index: 1001; /* Höher als der Header */
  background-color: white; /* Sonst wird es transparent */
  box-shadow: 4px 0 0 black;
  text-align: left;
}
/* Fullscreen dialog styles */
.fullscreen-container {
  height: calc(100vh - 1px); /* Account for toolbar height */
  overflow: hidden;
}

.fullscreen-row {
  height: 100%;
  overflow: hidden;
}

.scrollable-col {
  height: 100%;
  overflow-y: auto;
  padding: 0px;
}

.table-container {
  height: 100%;
  overflow-y: auto;
}

/* Your component-specific styles go here */
</style>
