<template>
  <div class="bonus-malus-simulator">
    <v-alert v-if="isLoading" type="info" class="mb-4">
      <v-progress-linear
        indeterminate
        color="blue-grey-lighten-1"
        class="mb-1"
      ></v-progress-linear>
      Daten werden geladen...
    </v-alert>

    <v-row>
      <v-col cols="12" md="6">
        <!-- Bonuses Table -->
        <v-card outlined class="mb-3">
          <v-card-title class="py-2 d-flex justify-space-between align-center">
            <div>
              <v-icon color="success" small class="mr-2">mdi-check</v-icon>
              Bonus Ereignisse
            </div>
            <v-btn
              size="small"
              color="success"
              variant="outlined"
              @click="resetBonuses"
              :disabled="!hasChangesInBonuses"
            >
              <v-icon small left>mdi-restore</v-icon>
              Zurücksetzen
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-0 pt-0">
            <v-table density="compact" v-if="bonuses.length > 0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th class="text-center">Original Punkte</th>
                  <th class="text-center">Simulation</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bonus in bonuses" :key="bonus.id">
                  <td class="name-cell">{{ bonus.name }}</td>
                  <td class="text-center original-cell">
                    {{ bonus.points }}
                  </td>
                  <td class="simulation-cell">
                    <v-text-field
                      v-model="getSimulatedBonus(bonus.id).simulatedPoints"
                      type="number"
                      min="0"
                      dense
                      hide-details
                      class="simulation-input"
                      :class="{
                        'changed-value': hasValueChanged(
                          bonus.points,
                          getSimulatedBonus(bonus.id).simulatedPoints,
                        ),
                      }"
                      @blur="updateAndPropagateChanges"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <div v-else class="text-center py-3 grey--text">
              Keine Bonusereignisse gefunden
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <!-- Maluses Table -->
        <v-card outlined class="mb-3">
          <v-card-title class="py-2 d-flex justify-space-between align-center">
            <div>
              <v-icon color="error" small class="mr-2">mdi-alert-circle</v-icon>
              Malus Ereignisse
            </div>
            <v-btn
              size="small"
              color="error"
              variant="outlined"
              @click="resetMaluses"
              :disabled="!hasChangesInMaluses"
            >
              <v-icon small left>mdi-restore</v-icon>
              Zurücksetzen
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-0 pt-0">
            <v-table density="compact" v-if="maluses.length > 0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th class="text-center">Original Punkte</th>
                  <th class="text-center">Simulation</th>
                  <th class="text-center" v-if="hasBonusPenalty">
                    Bonussperre
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="malus in maluses" :key="malus.id">
                  <td class="name-cell">{{ malus.name }}</td>
                  <td class="text-center original-cell">
                    {{ malus.points }}
                  </td>
                  <td class="simulation-cell">
                    <v-text-field
                      v-model="getSimulatedMalus(malus.id).simulatedPoints"
                      type="number"
                      max="0"
                      dense
                      prefix="-"
                      hide-details
                      class="simulation-input"
                      :class="{
                        'changed-value': hasValueChanged(
                          malus.points,
                          getSimulatedMalus(malus.id).simulatedPoints,
                        ),
                      }"
                      @blur="updateAndPropagateChanges"
                    ></v-text-field>
                  </td>
                  <td class="text-center" v-if="hasBonusPenalty">
                    <v-text-field
                      v-if="malus.bonus_penalty_months !== null"
                      v-model="
                        getSimulatedMalus(malus.id).simulatedPenaltyMonths
                      "
                      type="number"
                      min="0"
                      dense
                      hide-details
                      class="simulation-input"
                      :class="{
                        'changed-value': hasValueChanged(
                          malus.bonus_penalty_months,
                          getSimulatedMalus(malus.id).simulatedPenaltyMonths,
                        ),
                      }"
                      @blur="updateAndPropagateChanges"
                    ></v-text-field>
                    <span v-else>-</span>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <div v-else class="text-center py-3 grey--text">
              Keine Malusereignisse gefunden
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from "vue";

// Define props
const props = defineProps({
  bonusData: {
    type: Array,
    default: () => [],
  },
  employeeData: {
    type: Array,
    default: () => [],
  },
  bonusLevels: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

// Define emits
const emit = defineEmits(["update:simulation"]);

// Simulation data
const simulatedBonuses = ref([]);
const simulatedMaluses = ref([]);

// Computed properties
const bonuses = computed(() => {
  return props.bonusData.filter((item) => item.is_positive === true);
});

const maluses = computed(() => {
  return props.bonusData.filter((item) => item.is_positive === false);
});

const hasBonusPenalty = computed(() => {
  return maluses.value.some((malus) => malus.bonus_penalty_months !== null);
});

const hasChangesInBonuses = computed(() => {
  return simulatedBonuses.value.some((bonus) => bonus.hasChanged);
});

const hasChangesInMaluses = computed(() => {
  return simulatedMaluses.value.some((malus) => malus.hasChanged);
});

const changedBonusCount = computed(() => {
  return simulatedBonuses.value.filter((bonus) => bonus.hasChanged).length;
});

const changedMalusCount = computed(() => {
  return simulatedMaluses.value.filter((malus) => malus.hasChanged).length;
});

// Initialize simulation data
const initializeSimulation = () => {
  // Create a map for quick lookups
  const existingBonusMap = new Map();
  simulatedBonuses.value.forEach((bonus) => {
    existingBonusMap.set(bonus.id, bonus);
  });

  // Initialize or update bonuses simulation array
  simulatedBonuses.value = bonuses.value.map((bonus) => {
    const existing = existingBonusMap.get(bonus.id);
    return {
      id: bonus.id,
      originalPoints: Number(bonus.points),
      simulatedPoints: existing
        ? existing.simulatedPoints
        : Number(bonus.points),
      hasChanged: existing ? existing.hasChanged : false,
    };
  });

  // Similar for maluses
  const existingMalusMap = new Map();
  simulatedMaluses.value.forEach((malus) => {
    existingMalusMap.set(malus.id, malus);
  });

  // Initialize maluses simulation array
  simulatedMaluses.value = maluses.value.map((malus) => {
    const existing = existingMalusMap.get(malus.id);
    return {
      id: malus.id,
      originalPoints: Number(malus.points),
      simulatedPoints: existing
        ? existing.simulatedPoints
        : Number(malus.points),
      originalPenaltyMonths:
        malus.bonus_penalty_months !== null
          ? Number(malus.bonus_penalty_months)
          : null,
      simulatedPenaltyMonths: existing
        ? existing.simulatedPenaltyMonths
        : malus.bonus_penalty_months !== null
          ? Number(malus.bonus_penalty_months)
          : null,
      hasChanged: existing ? existing.hasChanged : false,
    };
  });

  updateAndPropagateChanges();
};

// Reset just bonuses
const resetBonuses = () => {
  simulatedBonuses.value = bonuses.value.map((bonus) => ({
    id: bonus.id,
    originalPoints: Number(bonus.points),
    simulatedPoints: Number(bonus.points),
    hasChanged: false,
  }));
  updateAndPropagateChanges();
};

// Reset just maluses
const resetMaluses = () => {
  simulatedMaluses.value = maluses.value.map((malus) => ({
    id: malus.id,
    originalPoints: Number(malus.points),
    simulatedPoints: Number(malus.points),
    originalPenaltyMonths:
      malus.bonus_penalty_months !== null
        ? Number(malus.bonus_penalty_months)
        : null,
    simulatedPenaltyMonths:
      malus.bonus_penalty_months !== null
        ? Number(malus.bonus_penalty_months)
        : null,
    hasChanged: false,
  }));
  updateAndPropagateChanges();
};

// Check if value has changed
const hasValueChanged = (original, simulated) => {
  return Number(original) !== Number(simulated);
};

// Update simulation results and propagate changes to parent
const updateAndPropagateChanges = () => {
  // Update hasChanged status for bonuses
  simulatedBonuses.value.forEach((bonus, index) => {
    const originalPoints = bonuses.value[index]?.points || 0;
    bonus.hasChanged = hasValueChanged(originalPoints, bonus.simulatedPoints);
  });

  // Update hasChanged status for maluses
  simulatedMaluses.value.forEach((malus, index) => {
    const originalPoints = maluses.value[index]?.points || 0;
    const originalPenaltyMonths = maluses.value[index]?.bonus_penalty_months;
    malus.hasChanged =
      hasValueChanged(originalPoints, malus.simulatedPoints) ||
      hasValueChanged(originalPenaltyMonths, malus.simulatedPenaltyMonths);
  });

  // Format the data and propagate to parent immediately
  const simulationData = {
    type: "bonus-events",
    data: {
      bonuses: simulatedBonuses.value.filter((bonus) => bonus.hasChanged),
      maluses: simulatedMaluses.value.filter((malus) => malus.hasChanged),
      summary: {
        employeesAffected: props.employeeData.length,
        changedItems: [
          ...simulatedBonuses.value
            .filter((bonus) => bonus.hasChanged)
            .map((bonus) => ({ type: "bonus", ...bonus })),
          ...simulatedMaluses.value
            .filter((malus) => malus.hasChanged)
            .map((malus) => ({ type: "malus", ...malus })),
        ],
      },
    },
  };

  // Emit updated simulation to parent
  emit("update:simulation", simulationData);
};

// Helper methods to find simulation objects by ID
const getSimulatedBonus = (id) => {
  const bonus = simulatedBonuses.value.find((item) => item.id === id);
  if (!bonus) {
    // Return a default object if not found (shouldn't happen, but prevents errors)
    return { simulatedPoints: 0, hasChanged: false };
  }
  return bonus;
};

const getSimulatedMalus = (id) => {
  const malus = simulatedMaluses.value.find((item) => item.id === id);
  if (!malus) {
    // Return a default object if not found
    return {
      simulatedPoints: 0,
      simulatedPenaltyMonths: null,
      hasChanged: false,
    };
  }
  return malus;
};

// Watch for changes in bonus data
watch(
  () => props.bonusData,
  (newData) => {
    if (newData && newData.length > 0) {
      nextTick(() => {
        initializeSimulation();
      });
    }
  },
  { immediate: true, deep: true },
);

// Initialize on component mount
onMounted(() => {
  if (props.bonusData.length > 0) {
    initializeSimulation();
  }
});
</script>

<style scoped>
.bonus-malus-simulator {
  padding: 0 1rem;
}

.simulation-input {
  max-width: 100px;
  margin: 0 auto;
}

.changed-value {
  background-color: rgba(128, 203, 196, 0.2);
}

.original-cell {
  font-weight: 600;
}

.name-cell {
  max-width: 300px;
  white-space: normal;
  word-break: break-word;
}
</style>
