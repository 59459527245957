<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-flex flex-wrap align-center">
        <span class="text-h5">Monatlicher Bonus Bericht</span>
        <v-spacer></v-spacer>

        <!-- Improved filter layout -->
        <div class="d-flex flex-wrap align-center">
          <!-- Month selector -->
          <v-select
            v-model="date"
            :items="monthFilter"
            label="Monat"
            @update:model-value="loadBonusData"
            dense
            hide-details
            class="mr-4"
            style="width: 180px"
            prepend-icon="mdi-calendar"
          ></v-select>

          <!-- City selector - Updated with clickable "Alle Städte" option -->
          <v-select
            v-model="selectedCity"
            :items="cities"
            item-title="city"
            item-value="id"
            label="Stadt"
            hide-details
            class="mr-4"
            clearable
            @update:model-value="loadBonusData"
            style="width: 180px"
          >
          </v-select>

          <!-- Export CSV button -->
          <v-btn
            color="success"
            @click="exportToCsv"
            :disabled="loading || bonusData.length === 0"
            class="mr-2"
          >
            <v-icon left>mdi-file-export</v-icon>
            CSV
          </v-btn>

          <!-- Print button -->
          <v-btn
            color="info"
            @click="printTable"
            :disabled="loading || bonusData.length === 0"
          >
            <v-icon left>mdi-printer</v-icon>
            Drucken
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="bonusData"
          :loading="loading"
          :search="search"
          :sort-by="['totalPoints']"
          :sort-desc="[true]"
          multi-sort
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100, -1],
          }"
          :items-per-page="20"
        >
          <!-- Search field -->
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Suchen"
              prepend-icon="mdi-magnify"
              hide-details
              class="mx-4 mt-2"
            ></v-text-field>
          </template>

          <!-- Employee column -->
          <template v-slot:item.employeeName="{ item }">
            <div class="d-flex align-center flex-wrap">
              <div>
                <a
                  :href="`/Mitarbeiter/${item.employeeId}`"
                  target="_blank"
                  class="text-decoration-none text-primary"
                >
                  {{ item.employeeName }}
                </a>
                <v-chip
                  v-if="item.position"
                  size="x-small"
                  :color="getPositionColor(item.position)"
                  class="mr-2"
                >
                  {{ item.position }}
                </v-chip>
                <span
                  v-if="item.city"
                  class="text-caption text-medium-emphasis ml-1"
                  >({{ item.city }})</span
                >
                <div class="d-flex align-center mt-1">
                  <div v-if="item.hasPenalty" class="text-caption red--text">
                    <v-icon
                      x-small
                      color="red"
                      v-tooltip.bottom="item.penaltyBonusName"
                    >
                      mdi-alert-circle
                    </v-icon>
                    <span>Bonussperre</span>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- Points columns with color indicators -->
          <template v-slot:item.shiftPoints="{ item }">
            <span>{{ item.shiftPoints }}</span>
          </template>

          <template v-slot:item.automaticPoints="{ item }">
            <span>{{ item.automaticPoints }}</span>
          </template>

          <template v-slot:item.manualPoints="{ item }">
            <span>{{ item.manualPoints }}</span>
          </template>

          <template v-slot:item.checkPoints="{ item }">
            <span>{{ item.checkPoints }}</span>
          </template>

          <template v-slot:item.monthlyPoints="{ item }">
            <span>{{ item.monthlyPoints }}</span>
          </template>

          <template v-slot:item.totalPoints="{ item }">
            <v-chip
              :color="
                item.totalPoints > 0
                  ? 'success'
                  : item.totalPoints < 0
                    ? 'error'
                    : 'warning'
              "
              dark
              small
              label
            >
              {{ item.totalPoints }}
            </v-chip>
          </template>

          <template v-slot:item.bonusLevel="{ item }">
            <div class="d-flex align-center">
              <v-chip
                v-if="item.bonusLevel"
                :color="getBonusLevelColor(item.bonusLevel)"
                dark
                small
                label
                class="mr-2"
              >
                {{ item.bonusLevel.level_name }}
              </v-chip>
              <span v-else>-</span>
              <span v-if="item.hourlyWage" class="ml-1 text-caption">
                {{ item.hourlyWage }}€/h
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  format,
  startOfDay,
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  parseISO,
  addMonths,
  isEqual,
  isAfter,
  isBefore,
} from "date-fns";
import { de } from "date-fns/locale";
import BonusCalculatorService from "@/services/BonusCalculatorService";
import { mapGetters } from "vuex";

export default {
  name: "MonthlyBonusReport",

  data() {
    return {
      loading: false,
      date: format(new Date(), "yyyy-MM"),
      dateFormatted: "",
      search: "",
      selectedCity: null,
      cities: [],
      bonusData: [],
      bonusLevels: [],
      headers: [
        { title: "Mitarbeiter", value: "employeeName", width: "25%" },
        { title: "Schicht Punkte", value: "shiftPoints", align: "center" },
        {
          title: "Automatische Punkte",
          value: "automaticPoints",
          align: "center",
        },
        { title: "Manuelle Punkte", value: "manualPoints", align: "center" },
        { title: "Mitarbeiterchecks", value: "checkPoints", align: "center" },
        { title: "Monatliche Punkte", value: "monthlyPoints", align: "center" },
        {
          title: "Gesamtpunkte",
          value: "totalPoints",
          align: "center",
          sort: (a, b) => parseInt(a) - parseInt(b),
        },
        { title: "Bonus Stufe", value: "bonusLevel", align: "center" },
      ],
      citiesWithBonus: [],
      EMPLOYEE_DATA_MATCHING_CITY_ID: 5,
      EMPLOYEE_DATA_MATCHING_POSITION_ID: 7,
      positionMap: {
        Bronze: "bronze",
        Silber: "silver",
        Gold: "gold",
        Shiftleader: "shiftleader",
      },
    };
  },

  computed: {
    monthFilter() {
      // Start from August 2023
      const startMonth = new Date(2023, 7);
      const currentMonth = new Date();
      const months = [];

      // Add months from August 2023 to current date
      let monthDate = new Date(startMonth);
      while (monthDate <= currentMonth) {
        const value = format(monthDate, "yyyy-MM");
        const title = format(monthDate, "MMMM yyyy", { locale: de });

        months.push({
          value: value,
          title: title,
        });

        monthDate = addMonths(monthDate, 1);
      }

      return months;
    },

    formattedDateRange() {
      const startDate = startOfMonth(parseISO(this.date));
      const endDate = endOfMonth(parseISO(this.date));

      return `${format(startDate, "d. MMM", { locale: de })} - ${format(endDate, "d. MMM yyyy", { locale: de })}`;
    },
  },

  watch: {
    date() {
      this.dateFormatted = format(parseISO(this.date), "MMMM yyyy", {
        locale: de,
      });
    },
  },

  async mounted() {
    this.dateFormatted = format(parseISO(this.date), "MMMM yyyy", {
      locale: de,
    });
    await this.loadCities();
    await this.loadBonusData();
  },

  methods: {
    async loadCities() {
      try {
        // First get the city IDs that have bonus levels
        const bonusLevelsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "bonus_levels",
            select: "city_id",
            where: [{ type: "not", key: "city_id", value: null }],
          },
        );

        if (
          !bonusLevelsResponse.data ||
          bonusLevelsResponse.data.length === 0
        ) {
          this.cities = [];
          return;
        }

        // Extract unique city IDs
        const cityIds = [
          ...new Set(bonusLevelsResponse.data.map((item) => item.city_id)),
        ];

        // Save this for later use when no city is selected
        this.citiesWithBonus = cityIds;

        // Then fetch the city details for those IDs
        const citiesResponse = await this.$store.dispatch("getSupabaseEntity", {
          table: "cities",
          select: "*",
          where: [{ type: "in", key: "id", value: cityIds }],
          order: { key: "city", order: "asc" },
        });

        this.cities = citiesResponse.data || [];
      } catch (error) {
        console.error("Error loading cities:", error);
        this.$store.commit("setSnackbar", {
          message: "Fehler beim Laden der Städte",
          color: "error",
        });
      }
    },

    async loadBonusLevels(cityId) {
      try {
        if (!cityId) return [];

        const cityBonusData =
          await BonusCalculatorService.getBonusDataForCity(cityId);
        return cityBonusData.bonusLevels || [];
      } catch (error) {
        console.error("Error loading bonus levels:", error);
        return [];
      }
    },

    getBonusLevelColor(level) {
      // Simple color scheme based on index or level name
      const colors = {
        Bronze: "amber-darken-2", // Bronze
        Silber: "grey-lighten-1", // Silver
        Gold: "amber-accent-3", // Gold
        Platin: "blue-grey-lighten-1", // Platinum
        Diamant: "cyan-lighten-1", // Diamond
      };

      // Try to match by name, fallback to a default color
      const levelName = level.level_name;
      for (const [key, color] of Object.entries(colors)) {
        if (levelName.includes(key)) {
          return color;
        }
      }

      // Default color if no matches
      return "primary";
    },

    getPositionColor(position) {
      const positionColors = {
        Bronze: "amber-darken-1",
        Silber: "grey-lighten-1",
        Gold: "amber-accent-3",
        Shiftleader: "purple-lighten-1",
      };

      return positionColors[position] || "primary";
    },

    getPositionKey(position) {
      if (!position) return "";
      return this.positionMap[position] || position.toLowerCase();
    },

    calculateHourlyWage(bonusLevel, position) {
      if (!bonusLevel || !position) return null;

      const positionKey = `hourly_wage_${this.getPositionKey(position)}`;
      const wage = bonusLevel[positionKey];

      return wage ? wage.toFixed(2) : null;
    },

    async loadBonusData() {
      if (!this.date) return;

      this.loading = true;
      try {
        // Calculate date range for the selected month
        const startDate = startOfMonth(parseISO(this.date));
        const endDate = endOfMonth(parseISO(this.date));

        // Get employees either for the selected city or all cities with bonus systems
        let employeeIds = [];
        let cityMap = {};

        if (this.selectedCity) {
          // Get employees for the selected city using employee_data_matching
          const employeeDataResponse = await this.$store.dispatch(
            "getSupabaseEntity",
            {
              table: "employee_data_matching",
              select: "employee_id",
              where: [
                {
                  type: "eq",
                  key: "key_id",
                  value: this.EMPLOYEE_DATA_MATCHING_CITY_ID,
                },
                { type: "eq", key: "value_id", value: this.selectedCity },
              ],
            },
          );

          if (
            employeeDataResponse.data &&
            employeeDataResponse.data.length > 0
          ) {
            employeeIds = employeeDataResponse.data.map(
              (item) => item.employee_id,
            );

            // Create a simple city map for all employees in this city
            employeeIds.forEach((id) => {
              cityMap[id] = this.selectedCity;
            });
          }

          // Load bonus levels for the selected city
          this.bonusLevels = await this.loadBonusLevels(this.selectedCity);
        } else {
          // If no city selected, get employees from all cities with bonus systems
          const employeeDataResponse = await this.$store.dispatch(
            "getSupabaseEntity",
            {
              table: "employee_data_matching",
              select: "employee_id, city_id:value_id",
              where: [
                {
                  type: "eq",
                  key: "key_id",
                  value: this.EMPLOYEE_DATA_MATCHING_CITY_ID,
                },
                { type: "in", key: "value_id", value: this.citiesWithBonus },
              ],
            },
          );

          if (
            employeeDataResponse.data &&
            employeeDataResponse.data.length > 0
          ) {
            employeeIds = employeeDataResponse.data.map(
              (item) => item.employee_id,
            );

            // Create a map of employee city IDs for later use
            employeeDataResponse.data.forEach((item) => {
              cityMap[item.employee_id] = item.city_id;
            });
          }

          // Load bonus levels for all cities with bonus systems at once
          const cityBonusData = await this.loadAllCitiesBonusLevels(
            this.citiesWithBonus,
          );
          this.allCitiesBonusLevels = cityBonusData;
        }

        if (employeeIds.length === 0) {
          this.bonusData = [];
          this.loading = false;
          return;
        }

        // Get employee details in one query
        const employeesResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "employees",
            select: "id, first_name, last_name",
            where: [
              { type: "in", key: "id", value: employeeIds },
              { type: "eq", key: "status", value: "Aktiv" },
              { type: "not", key: "bonus_eligible", value: false },
            ],
            order: { key: "last_name", order: "asc" },
          },
        );

        const employees = employeesResponse.data || [];
        if (employees.length === 0) {
          this.bonusData = [];
          this.loading = false;
          return;
        }

        // Fetch employee positions in one query
        const positionsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "employee_data_matching",
            select: "employee_id, value_text",
            where: [
              { type: "in", key: "employee_id", value: employeeIds },
              {
                type: "eq",
                key: "key_id",
                value: this.EMPLOYEE_DATA_MATCHING_POSITION_ID,
              },
            ],
          },
        );

        // Create a map of employee positions
        const employeePositions = {};
        if (positionsResponse.data && positionsResponse.data.length > 0) {
          positionsResponse.data.forEach((item) => {
            employeePositions[item.employee_id] = item.value_text;
          });
        }

        // Get city names in one query if we need multiple cities
        let cityNames = {};
        if (!this.selectedCity) {
          const uniqueCityIds = [...new Set(Object.values(cityMap))];
          const citiesResponse = await this.$store.dispatch(
            "getSupabaseEntity",
            {
              table: "cities",
              select: "id, city",
              where: [{ type: "in", key: "id", value: uniqueCityIds }],
            },
          );

          if (citiesResponse.data && citiesResponse.data.length > 0) {
            citiesResponse.data.forEach((city) => {
              cityNames[city.id] = city.city;
            });
          }
        }

        // Perform bulk bonus calculation for all employees
        const bonusResults =
          await BonusCalculatorService.calculateBonusForMultipleEmployees(
            employeeIds,
            startDate,
            endDate,
          );

        // Process results into display format
        const processedData = [];

        for (const employee of employees) {
          const result = bonusResults[employee.id] || {};
          const position = employeePositions[employee.id];

          // Skip employees without a position that can have bonus or without a bonus system
          if (!position || !result.hasBonusSystem) {
            continue;
          }

          // Check if position is eligible for bonus (exists in positionMap)
          const positionKey = this.getPositionKey(position);
          if (
            !Object.values(this.positionMap).includes(positionKey) &&
            !Object.keys(this.positionMap).includes(position)
          ) {
            continue;
          }

          // Calculate points for each category
          const shiftPoints = this.calculateTotalShiftPoints(
            result.shiftsWithBonus || [],
          );
          const automaticPoints = this.calculateTotalAutomaticPoints(
            result.automaticEvents || [],
          );
          const manualPoints = this.calculateTotalManualPoints(
            result.manualEvents || [],
          );
          const checkPoints = this.calculateTotalCheckPoints(
            result.failedChecks || [],
          );
          const monthlyPoints = this.calculateTotalMonthlyPoints(
            result.monthlyBonuses || [],
          );
          const totalPoints = result.totalPoints || 0;

          // Get city name
          const cityId = cityMap[employee.id];
          const cityName = this.selectedCity
            ? this.cities.find((c) => c.id === this.selectedCity)?.city
            : cityNames[cityId] || "";

          // Get city-specific bonus levels
          let employeeBonusLevels = this.selectedCity
            ? this.bonusLevels
            : this.allCitiesBonusLevels[cityId] || [];

          // Determine bonus level
          const bonusLevel = this.determineBonusLevel(
            totalPoints,
            employeeBonusLevels,
          );

          // Calculate hourly wage based on position and bonus level
          const hourlyWage = result.hasPenalty
            ? this.calculateHourlyWage(employeeBonusLevels[0], position) // First level during penalty
            : this.calculateHourlyWage(bonusLevel, position);

          processedData.push({
            employeeId: employee.id,
            employeeName: `${employee.last_name}, ${employee.first_name}`,
            lastName: employee.last_name,
            firstName: employee.first_name,
            position: position,
            city: cityName,
            shiftPoints,
            automaticPoints,
            manualPoints,
            checkPoints,
            monthlyPoints,
            totalPoints,
            positivePoints: result.positivePoints || 0,
            negativePoints: result.negativePoints || 0,
            hasBonusSystem: true,
            bonusLevel,
            hourlyWage,
            hasPenalty: result.hasPenalty || false,
            penaltyBonusName: result.penaltyBonusName || "",
          });
        }

        // Sort by last name then first name
        this.bonusData = processedData.sort(
          (a, b) =>
            a.lastName.localeCompare(b.lastName) ||
            a.firstName.localeCompare(b.firstName),
        );
      } catch (error) {
        console.error("Error loading bonus data:", error);
        this.$store.commit("setSnackbar", {
          message: "Fehler beim Laden der Bonusdaten",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async loadAllCitiesBonusLevels(cityIds) {
      try {
        if (!cityIds || cityIds.length === 0) return {};

        const bonusLevelsByCity = {};

        // Fetch all bonus levels for all cities in one go
        const bonusLevelsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "bonus_levels",
            select: "*",
            where: [{ type: "in", key: "city_id", value: cityIds }],
            order: { key: "min_points", order: "asc" },
          },
        );

        if (bonusLevelsResponse.data && bonusLevelsResponse.data.length > 0) {
          // Group by city_id
          bonusLevelsResponse.data.forEach((level) => {
            const cityId = level.city_id;
            if (!bonusLevelsByCity[cityId]) {
              bonusLevelsByCity[cityId] = [];
            }
            bonusLevelsByCity[cityId].push(level);
          });
        }

        return bonusLevelsByCity;
      } catch (error) {
        console.error("Error loading bonus levels for multiple cities:", error);
        return {};
      }
    },

    determineBonusLevel(points, bonusLevels) {
      if (!bonusLevels || bonusLevels.length === 0) return null;

      // Get the last day of the selected month
      const selectedDate = startOfDay(parseISO(this.date));

      // Filter applicable levels based on date
      const applicableLevels = [];

      bonusLevels.forEach((level) => {
        const effectiveFrom = parseISO(level.effective_from);

        // Check if current level is applicable
        if (
          isEqual(selectedDate, effectiveFrom) ||
          isAfter(selectedDate, effectiveFrom)
        ) {
          applicableLevels.push({ ...level });
        }

        // Check historical values if present
        if (level.historical_values && Array.isArray(level.historical_values)) {
          level.historical_values.forEach((history) => {
            const historyFrom = parseISO(history.effective_from);
            const historyTo = history.effective_to
              ? parseISO(history.effective_to)
              : null;

            if (
              (isEqual(selectedDate, historyFrom) ||
                isAfter(selectedDate, historyFrom)) &&
              (!historyTo ||
                isEqual(selectedDate, historyTo) ||
                isBefore(selectedDate, historyTo))
            ) {
              // Create a merged level from history data
              const historicalLevel = {
                ...level,
                min_points: history.min_points,
                hourly_wage_bronze: history.hourly_wage_bronze,
                hourly_wage_silver: history.hourly_wage_silver,
                hourly_wage_gold: history.hourly_wage_gold,
                hourly_wage_shiftleader: history.hourly_wage_shiftleader,
                level_name: level.level_name, // Keep original name
                id: level.id, // Keep original ID
                effective_from: history.effective_from,
                effective_to: history.effective_to,
              };
              applicableLevels.push(historicalLevel);
            }
          });
        }
      });

      // If no applicable levels found, return null
      if (applicableLevels.length === 0) return null;

      // Sort applicable levels by min_points (ascending)
      const sortedLevels = [...applicableLevels].sort(
        (a, b) => a.min_points - b.min_points,
      );

      // Find the highest level that the points qualify for from applicable levels
      const matchingLevel = [...sortedLevels]
        .sort((a, b) => b.min_points - a.min_points)
        .find((level) => points >= level.min_points);

      // If no matching level found and points are negative or zero, return the lowest level
      if (!matchingLevel && points < 0 && sortedLevels.length > 0) {
        return sortedLevels[0]; // Return the first (lowest) level
      }

      return matchingLevel || null;
    },

    calculateTotalShiftPoints(shifts) {
      return shifts.reduce((total, shift) => {
        return (
          total +
          shift.points +
          (shift.weekendBonus || 0) +
          (shift.externalCityBonus || 0)
        );
      }, 0);
    },

    calculateTotalAutomaticPoints(events) {
      return events.reduce((total, event) => {
        const eventPoints = event.points * event.dates.length;
        return event.is_positive ? total + eventPoints : total - eventPoints;
      }, 0);
    },

    calculateTotalManualPoints(events) {
      return events.reduce((total, event) => {
        return event.is_positive ? total + event.points : total - event.points;
      }, 0);
    },

    calculateTotalCheckPoints(checks) {
      return checks.reduce((total, check) => total - check.points, 0);
    },

    calculateTotalMonthlyPoints(bonuses) {
      return bonuses.reduce((total, bonus) => {
        return bonus.is_positive ? total + bonus.points : total - bonus.points;
      }, 0);
    },

    /**
     * Exports the current table data to a CSV file
     */
    exportToCsv() {
      // Filter and map the data we want to export
      const dataToExport = this.bonusData.map((item) => ({
        Mitarbeiter: item.employeeName,
        Position: item.position || "",
        Stadt: item.city || "",
        "Schicht Punkte": item.shiftPoints,
        "Automatische Punkte": item.automaticPoints,
        "Manuelle Punkte": item.manualPoints,
        Mitarbeiterchecks: item.checkPoints,
        "Monatliche Punkte": item.monthlyPoints,
        Gesamtpunkte: item.totalPoints,
        "Bonus Stufe": item.bonusLevel ? item.bonusLevel.level_name : "-",
        Stundenlohn: item.hourlyWage ? `${item.hourlyWage}€/h` : "-",
        Bonussperre: item.hasPenalty ? "Ja" : "Nein",
      }));

      // Generate CSV content
      let csvContent = "data:text/csv;charset=utf-8,";

      // Add headers
      const headers = Object.keys(dataToExport[0]);
      csvContent += headers.join(";") + "\r\n";

      // Add rows
      dataToExport.forEach((row) => {
        const rowData = headers.map((header) => {
          // Handle special characters and ensure proper CSV format
          const cellData =
            row[header] !== null && row[header] !== undefined
              ? row[header].toString()
              : "";
          return `"${cellData.replace(/"/g, '""')}"`;
        });
        csvContent += rowData.join(";") + "\r\n";
      });

      // Create download link
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);

      // Set filename with city and month info
      const cityName = this.selectedCity
        ? this.cities.find((city) => city.id === this.selectedCity)?.city
        : "Alle";
      const month = format(parseISO(this.date), "yyyy-MM");
      link.setAttribute("download", `Bonus-Bericht_${cityName}_${month}.csv`);

      // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.$store.commit("setSnackbar", {
        message: "CSV-Export erfolgreich",
        color: "success",
      });
    },

    /**
     * Handles printing the bonus report table with improved layout
     */
    printTable() {
      // Add a small delay to ensure table is fully rendered before print
      setTimeout(() => {
        window.print();
      }, 200);
    },
  },
};
</script>
<style>
/* Non-scoped styles to ensure they apply to all elements when printing */
@media print {
  /* Hide non-essential elements when printing */
  .v-btn,
  .v-select,
  .v-text-field,
  .v-navigation-drawer,
  .v-toolbar,
  .v-input {
    display: none !important;
  }

  /* Force all columns to display and fit on page */
  .v-data-table .v-data-table__wrapper table {
    width: 100% !important;
    table-layout: fixed !important;
    page-break-inside: auto !important;
    display: table !important;
  }

  /* Ensure all table cells are visible */
  .v-data-table th,
  .v-data-table td {
    display: table-cell !important;
    page-break-inside: avoid !important;
    overflow-wrap: break-word !important;
  }

  /* Prevent any cut-offs for columns */
  .v-data-table tr {
    display: table-row !important;
    page-break-inside: avoid !important;
  }

  /* Remove any external margins that could cause empty pages */
  body,
  html {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }

  /* Hide header and footer added by the browser */
  @page {
    size: landscape;
    margin: 0.5cm;
  }

  /* Position the table at the top of the page */
  .v-application {
    padding-top: -200 !important;
    margin-top: -200 !important;
  }

  /* Make v-container start at top of page */
  .v-container {
    width: 100% !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Remove card spacing */
  .v-card {
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Remove padding from card content */
  .v-card-text {
    padding: 0 !important;
    padding-top: 4px !important;
  }

  /* Reset any default spacing from Vuetify */
  .v-main {
    padding: 0 !important;
  }
}
</style>

<style scoped>
/* Add some responsive adjustments for small screens */
@media (max-width: 600px) {
  .v-select {
    width: 100% !important;
    margin-bottom: 8px;
  }
}
</style>
