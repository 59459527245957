<template>
  <v-card rounded elevation="2" class="mb-3">
    <v-card-title class="d-flex align-center py-2">
      <v-icon color="primary" class="mr-2">mdi-account-group</v-icon>
      Bonusberechtigung - Mitarbeiter
    </v-card-title>

    <v-card-text class="pt-2">
      <v-row>
        <v-col cols="12" sm="8">
          <div class="d-flex align-center mb-3">
            <v-text-field
              v-model="search"
              label="Mitarbeiter suchen"
              prepend-inner-icon="mdi-magnify"
              density="compact"
              variant="outlined"
              hide-details
              class="mr-2"
            ></v-text-field>

            <!-- City filter dropdown -->
            <v-select
              v-model="selectedCity"
              :items="cities"
              item-title="city"
              item-value="id"
              label="Stadt"
              hide-details
              density="compact"
              variant="outlined"
              class="ml-2 mr-2"
              style="max-width: 200px"
              clearable
              @update:model-value="fetchEmployees"
            ></v-select>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items="filteredEmployees"
            :loading="loading"
            density="compact"
            hover
            class="elevation-1 employee-table"
          >
            <!-- Employee name -->
            <template v-slot:item.name="{ item }">
              {{ item.last_name }}, {{ item.first_name }}
            </template>

            <!-- Position -->
            <template v-slot:item.position="{ item }">
              <v-chip
                v-if="item.position"
                size="small"
                :color="getPositionColor(item.position)"
                class="mr-2"
              >
                {{ item.position }}
              </v-chip>
              <span v-else>-</span>
            </template>

            <!-- City -->
            <template v-slot:item.city="{ item }">
              {{ item.city || "-" }}
            </template>

            <!-- Eligibility toggle -->
            <template v-slot:item.bonus_eligible="{ item }">
              <v-switch
                v-model="item.bonus_eligible"
                color="success"
                hide-details
                :loading="updateLoadingMap[item.id]"
                density="compact"
                class="ma-0 pa-0"
                @change="updateEmployeeEligibility(item)"
              ></v-switch>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { format, parseISO } from "date-fns";
import { supabase } from "@/supabase";
import useSnackbar from "@/utils/useSnackbar";

// Get snackbar functionality
const { showSuccess, showError } = useSnackbar();

// Constants for employee data matching
const EMPLOYEE_DATA_MATCHING_CITY_ID = 5;
const EMPLOYEE_DATA_MATCHING_POSITION_ID = 7;

// Data table setup
const headers = [
  { title: "Name", key: "name", sortable: true },
  { title: "Position", key: "position", sortable: true },
  { title: "Stadt", key: "city", sortable: true },
  { title: "Bonusberechtigt", key: "bonus_eligible", sortable: true },
];

const employees = ref([]);
const loading = ref(false);
const search = ref("");
const itemsPerPage = ref(10);
const updateLoadingMap = ref({});
const cities = ref([]);
const selectedCity = ref(null);

// Filter employees based on search
const filteredEmployees = computed(() => {
  if (!search.value) return employees.value;

  const searchLower = search.value.toLowerCase();
  return employees.value.filter((emp) => {
    return (
      emp.first_name?.toLowerCase().includes(searchLower) ||
      emp.last_name?.toLowerCase().includes(searchLower) ||
      emp.city?.toLowerCase().includes(searchLower) ||
      emp.position?.toLowerCase().includes(searchLower)
    );
  });
});

// Get position color styling
const getPositionColor = (position) => {
  const positionColors = {
    Bronze: "amber-darken-1",
    Silber: "grey-lighten-1",
    Gold: "amber-accent-3",
    Shiftleader: "purple-lighten-1",
  };

  return positionColors[position] || "primary";
};

// Fetch cities with bonus levels
const loadCities = async () => {
  try {
    // First get the city IDs that have bonus levels
    const { data: bonusLevelsData, error: bonusLevelsError } = await supabase
      .from("bonus_levels")
      .select("city_id")
      .not("city_id", "is", null);

    if (bonusLevelsError) throw bonusLevelsError;

    if (!bonusLevelsData || bonusLevelsData.length === 0) {
      cities.value = [];
      return;
    }

    // Extract unique city IDs
    const cityIds = [...new Set(bonusLevelsData.map((item) => item.city_id))];

    // Then fetch the city details for those IDs
    const { data: citiesData, error: citiesError } = await supabase
      .from("cities")
      .select("*")
      .in("id", cityIds)
      .order("city", { ascending: true });

    if (citiesError) throw citiesError;

    cities.value = citiesData || [];
  } catch (error) {
    console.error("Error loading cities:", error);
    showError("Fehler beim Laden der Städte");
  }
};

// Fetch all employees with city and position data
const fetchEmployees = async () => {
  loading.value = true;

  try {
    let query = supabase
      .from("employees")
      .select("id, first_name, last_name, bonus_eligible")
      .eq("is_visible", true)
      .eq("status", "Aktiv")
      .order("last_name", { ascending: true });

    // Get employees for the selected city using employee_data_matching

    const { data: employeeDataMatching, error: matchingError } = await supabase
      .from("employee_data_matching")
      .select("employee_id")
      .eq("key_id", EMPLOYEE_DATA_MATCHING_CITY_ID)
      .in(
        "value_id",
        selectedCity.value
          ? [selectedCity.value]
          : cities.value.map((c) => c.id),
      );

    if (matchingError) throw matchingError;

    if (employeeDataMatching && employeeDataMatching.length > 0) {
      const cityEmployeeIds = employeeDataMatching.map(
        (item) => item.employee_id,
      );
      query = query.in("id", cityEmployeeIds);
    }

    const { data, error } = await query;
    if (error) throw error;

    // Get city and position data for each employee
    const enrichedEmployees = await enrichEmployeesWithData(data);
    console.log("Enriched Employees:", enrichedEmployees);
    employees.value = enrichedEmployees;
  } catch (error) {
    console.error("Error fetching employees:", error);
    showError("Fehler beim Laden der Mitarbeiter");
  } finally {
    loading.value = false;
  }
};

// Enrich employees with city and position data
const enrichEmployeesWithData = async (employeesList) => {
  if (!employeesList || employeesList.length === 0) return [];

  const employeeIds = employeesList.map((e) => e.id);

  try {
    // Fetch city data
    const { data: cityMatches, error: cityError } = await supabase
      .from("employee_data_matching")
      .select("employee_id, value_id")
      .eq("key_id", EMPLOYEE_DATA_MATCHING_CITY_ID)
      .in("employee_id", employeeIds);

    if (cityError) throw cityError;

    // Get city names from the cities table
    let cityMap = {};
    if (cityMatches && cityMatches.length > 0) {
      const cityIds = [
        ...new Set(cityMatches.map((match) => match.value_id).filter(Boolean)),
      ];

      if (cityIds.length > 0) {
        const { data: citiesData, error: citiesError } = await supabase
          .from("cities")
          .select("id, city")
          .in("id", cityIds);

        if (citiesError) throw citiesError;

        // Create a lookup map for city names by ID
        const cityNameMap = {};
        citiesData?.forEach((city) => {
          cityNameMap[city.id] = city.city;
        });

        // Map employee IDs to city names
        cityMatches.forEach((match) => {
          cityMap[match.employee_id] = cityNameMap[match.value_id] || null;
        });
      }
    }

    // Fetch position data
    const { data: positionMatches, error: positionError } = await supabase
      .from("employee_data_matching")
      .select("employee_id, value_text")
      .eq("key_id", EMPLOYEE_DATA_MATCHING_POSITION_ID)
      .in("employee_id", employeeIds);

    if (positionError) throw positionError;

    const positionMap = {};
    positionMatches?.forEach((match) => {
      positionMap[match.employee_id] = match.value_text;
    });

    // Enrich employee data
    return employeesList.map((employee) => ({
      ...employee,
      city: cityMap[employee.id] || null,
      position: positionMap[employee.id] || null,
    }));
  } catch (error) {
    console.error("Error enriching employee data:", error);
    return employeesList;
  }
};

// Update a single employee's eligibility status
const updateEmployeeEligibility = async (employee) => {
  updateLoadingMap.value[employee.id] = true;

  try {
    const { error } = await supabase
      .from("employees")
      .update({
        bonus_eligible: employee.bonus_eligible,
        modified_at: new Date().toISOString(),
      })
      .eq("id", employee.id);

    if (error) {
      throw error;
    }

    // Update was successful
    showSuccess(
      `Bonusberechtigung für ${employee.first_name} ${employee.last_name} aktualisiert`,
    );
  } catch (error) {
    console.error("Error updating employee eligibility:", error);
    showError(
      `Fehler bei der Aktualisierung der Bonusberechtigung für ${employee.first_name} ${employee.last_name}`,
    );

    // Revert the UI state on error
    employee.bonus_eligible = !employee.bonus_eligible;
  } finally {
    updateLoadingMap.value[employee.id] = false;
  }
};

// Initialize component
onMounted(async () => {
  await loadCities();
  await fetchEmployees();
});
</script>

<style scoped>
.employee-table :deep(th) {
  white-space: nowrap;
}
</style>
