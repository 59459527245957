<template>
  <v-card
    class="ma-4 pa-4 elevation-4"
    flat
    color="grey-lighten-4"
    rounded="xl"
    v-if="adminMode"
  >
    <v-card-title>Admin Settings</v-card-title>
    <v-select
      label="Users"
      :items="users"
      item-title="full_name"
      return-object
      @update:modelValue="changeUser"
    ></v-select>
  </v-card>
  <v-card
    class="ma-4 pa-4 elevation-4"
    flat
    color="grey-lighten-4"
    rounded="xl"
  >
    <v-card-title>User Data</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-avatar :image="user.imageUrl" size="124"></v-avatar>
        </v-col>
        <v-col cols="9">
          <v-file-input
            label="Upload Image"
            @change="uploadAvatar"
            accept="image/*"
            :loading="uploading"
          ></v-file-input>
        </v-col>
        <v-col cols="3">
          <v-text-field label="ID" v-model="user.id" disabled></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="First Name"
            v-model="user.first_name"
            :disabled="!adminMode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Last Name"
            v-model="user.last_name"
            :disabled="!adminMode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Full Name"
            v-model="user.full_name"
            :disabled="!adminMode"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Position"
            v-model="user.occupation"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field label="Phone" v-model="user.phone"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field label="Email" v-model="user.email"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="saveUserData()" :loading="user.loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-card
    class="ma-4 pa-4 elevation-4"
    flat
    color="grey-lighten-4"
    rounded="xl"
    v-if="$store.state.username == user.email"
  >
    <v-card-title>Login Settings</v-card-title>
    <v-card-text>
      <form @submit.prevent="resetPassword()">
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="New Password"
              type="password"
              v-model="newPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Check Password"
              type="password"
              v-model="newPasswordCheck"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              type="submit"
              @click="resetPassword()"
              :disabled="!newPassword || newPassword != newPasswordCheck"
              :loading="newPasswordLoading"
              >Reset Password</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-card-text>
  </v-card>
  <signature-card :user="user"></signature-card>
</template>

<script setup>
import { ref, toRefs, watch } from "vue";
import { supabase } from "../supabase";
import SignatureCard from "@/components/UserSettings/Signature.vue";
</script>
<script>
export default {
  data() {
    return {
      adminMode: false,
      mobile: null,
      user: {},
      sessionUser: {},
      newPassword: null,
      newPasswordCheck: null,
      users: [],
      newPasswordLoading: false,
      uploading: false,
    };
  },
  async created() {
    this.mobile = this.$vuetify.display.mobile;
    await this.getDBUser();
    await this.getSessionUser();
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
    });
    this.users = response.data;
    if (this.user.admin) {
      this.adminMode = true;
    }

    var response = await this.$store.dispatch("getUserAvatar", this.user.id);
    this.user.image = response.data;
    if (this.user.image) {
      this.user.imageUrl = URL.createObjectURL(response.data);
    }
  },
  methods: {
    async getDBUser() {
      var queryObject = {
        table: "public_users",
        where: [
          { type: "eq", key: "email", value: this.$store.state.username },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.user = response.data[0];
    },
    async getSessionUser() {
      this.sessionUser = await supabase.auth.getUser();
    },
    async resetPassword() {
      if (this.newPassword === this.newPasswordCheck) {
        this.newPasswordLoading = true;
        await supabase.auth.updateUser({ password: this.newPassword });
        this.newPasswordLoading = false;
      } else {
        alert("Password is not the same");
      }
    },
    async saveUserData() {
      this.user.loading = true;
      var payload = {
        email: this.user.email,
        occupation: this.user.occupation,
        phone: this.user.phone,
      };
      var queryObject = {
        table: "public_users",
        id: this.user.id,
        payload: payload,
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.user.loading = false;
    },
    async changeUser(response) {
      this.user = response;
      var response = await this.$store.dispatch("getUserAvatar", this.user.id);
      if (response.data) {
        this.user.imageUrl = URL.createObjectURL(response.data);
      }
    },
    async uploadAvatar(evt) {
      const files = ref();
      files.value = evt.target.files;
      try {
        this.uploading = true;
        if (!files.value || files.value.length === 0) {
          throw new Error("You must select an image to upload.");
        }

        const file = files.value[0];
        const fileExt = file.name.split(".").pop();
        var filePath = this.user.id + "/avatar." + fileExt;

        if (this.user.imageUrl) {
          await supabase.storage.from("avatars").remove(this.user.image.name);
        }
        var response = await supabase.storage
          .from("avatars")
          .upload(filePath, file);
      } catch (error) {
        alert(error.message);
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<style>
.mobile {
  font-size: 7pt;
}
</style>
