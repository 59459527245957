<template>
    <v-col>
      <v-btn
        :color="chosenCategory == 'All' ? 'blue-grey-darken-1' : null"
        @click="chosenCategory = 'All'"
        >Alle <v-chip class="ml-1" size="small">{{ notes.length }}</v-chip></v-btn
      >
      <template v-for="category in taskCategories">
        <v-btn
          v-if="category.count > 0"
          :color="category.name == chosenCategory ? 'blue-grey-darken-1' : null"
          class="mx-1"
          rounded="lg"
          size="small"
          :key="category.id"
          text
          @click="chosenCategory = category.name"
        >
          {{ category.name }}
          <v-chip class="ml-1" size="x-small">{{ category.count }}</v-chip>
        </v-btn>
      </template>
    </v-col>
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-btn
          icon
          class="ml-4 my-2"
          @click="
            newAction.activated = !newAction.activated;
            newAction.object.role_id = defaultRoleId;
            newAction.object.category_id = 7;
          "
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-card
          v-bind="props"
          :elevation="isHovering ? '10' : '2'"
          class="ma-4"
          rounded="xl"
          v-if="newAction.activated"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  variant="outlined"
                  :items="noteTemplates"
                  item-title="name"
                  return-object
                  label="Vorlagen"
                  @update:modelValue="loadNoteTemplate"
                ></v-select>
                <v-text-field
                  variant="outlined"
                  label="Betreff"
                  v-model="newAction.object.action"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card :class="newAction.edit ? null : 'quill-container'">
                  <QuillEditor
                    toolbar="essential"
                    v-model:content="newAction.object.text"
                    contentType="html"
                    placeholder="Notes"
                  />
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-autocomplete
                  variant="outlined"
                  label="Berechtigung"
                  :items="noteRoles.write"
                  item-title="name"
                  item-value="id"
                  v-model="newAction.object.role_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6">
                <v-autocomplete
                  variant="outlined"
                  label="Kategorie"
                  :items="taskCategories"
                  item-title="name"
                  item-value="id"
                  v-model="newAction.object.category_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" v-if="false">
                <v-select
                  variant="outlined"
                  multiple
                  label="Teilnehmer"
                  :items="users"
                  item-title="full_name"
                  item-value="id"
                  chips
                  v-model="newAction.object.listeners"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="saveNote(newAction.object)"
              :loading="newAction.loading"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-hover>
    <div class="noteHeight" v-if="notes.length > 0">
      <v-timeline side="end">
        <template v-for="note in notes">
          <v-timeline-item
            :key="note.id"
            v-if="note.category == chosenCategory || chosenCategory == 'All'"
            :dot-color="
              note.recruiting_activities
                ? note.recruiting_activities.color
                : 'grey'
            "
            :icon="
              note.recruiting_activities
                ? note.recruiting_activities.icon
                : 'mdi-note'
            "
            class="mobile"
          >
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card v-bind="props" :elevation="isHovering ? '10' : '2'">
                  <v-card-title>
                    <v-row>
                      <v-col align="left">
                        <span v-if="!note.edit">{{ note.action }}</span>
                        <v-text-field v-else v-model="note.action"></v-text-field>
                      </v-col>
                      <v-col align="right">
                        <v-btn
                          color="error"
                          size="small"
                          flat
                          v-if="note.edit"
                          @click="deleteNote(note.id)"
                          :loading="newAction.loading"
                        >
                          <v-icon>mdi-delete</v-icon></v-btn
                        >
                        <v-btn
                          size="small"
                          flat
                          v-if="note.edit"
                          @click="saveNote(note, note.id, note.sipgate_id)"
                          :loading="newAction.loading"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="isHovering || note.edit"
                          size="small"
                          flat
                          @click="note.edit = !note.edit"
                        >
                          <v-icon
                            :icon="note.edit ? 'mdi-pencil-off' : 'mdi-pencil'"
                          ></v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-icon>mdi-account</v-icon
                        >{{
                          note.public_users
                            ? note.public_users.first_name +
                              " " +
                              note.public_users.last_name
                            : "Undefinierter Nutzer"
                        }}
                      </v-col>
                      <v-col>
                        <v-icon>mdi-calendar</v-icon>{{ note.showDate }}
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                  <template v-if="note.edit">
                    <div v-if="note.edit" class="pa-2">
                      <Quill-Editor
                        toolbar="essential"
                        v-model:content="note.text"
                        contentType="html"
                        placeholder="Notes"
                      />
                    </div>
                    <v-autocomplete
                      class="py-2 px-4"
                      variant="outlined"
                      label="Kategorie"
                      :items="taskCategories"
                      item-title="name"
                      item-value="id"
                      v-model="note.category_id"
                    ></v-autocomplete>
                    <v-autocomplete
                      class="mt-n2 px-4"
                      variant="outlined"
                      label="Berechtigung"
                      :items="noteRoles.write"
                      item-title="name"
                      item-value="id"
                      v-model="note.role_id"
                    ></v-autocomplete>
                  </template>
                  <v-card-text v-else v-html="note.text"></v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-timeline-item>
        </template>
      </v-timeline>
    </div>
  </template>
  
  <script setup>
  import TaskCategories from "@/components/Settings/General/TaskCategories.vue";
  import { QuillEditor } from "@vueup/vue-quill";
  import { mapState } from "vuex";
  const props = defineProps({
    hotelObject: Object,
    users: Array,
  });
  </script>
  
  <script>
  export default {
    data() {
      return {
        noteRoles: {},
        taskCategories: [],
        categoryMatching: {},
        chosenCategory: "All",
        notesInCategories: [],
        notes: [],
        noteTemplates: [],
        newAction: {
          loading: false,
          object: {},
        },
        defaultRoleId: 3,
      };
    },
    methods: {
      async getNoteTemplates() {
        var queryObject = {
          select: "*",
          table: "note_templates",
          where: [
            {
              type: "eq",
              key: "type",
              value: "hotels",
            },
          ],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.noteTemplates = response.data;
      },
      async loadNoteTemplate(template) {
        this.newAction.object.text = template.text;
        this.newAction.object.action = template.action;
        this.newAction.object.is_positive = template.is_positive;
      },
      async getNotes() {
        var idArray = [];
        this.taskCategories.forEach((item) => {
          item.count = 0;
        });
        this.noteRoles.read.forEach((role) => {
          idArray.push(role.id);
        });
        var queryObject = {
          select: "*,public_users(*)",
          table: "notes",
          where: [
            {
              type: "or",
              key: "role_id",
              value: `role_id.in.(${idArray}),role_id.is.null`,
            },
          ],
        };
        queryObject.where.push({
        type: "eq",
        key: "hotel_id",
        value: this.hotelObject.id,
        });

        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );

        const hotelPartnerIds = this.hotelObject.hotel_partners.map((partner) => partner.id);
        var sipgateCalls = await this.$store.dispatch("getSupabaseEntity", {
          select: "*",
          table: "sipgate_calls",
          where: [
            {
              type: "in",
              key: "hotel_partner_id",
              value: hotelPartnerIds,
            },
          ],
        });
  
        var calls = await this.addCalls(sipgateCalls.data);
  
        response.data = response.data.concat(calls);
  
        response.data.sort((a, b) =>
          a.modified_at > b.modified_at
            ? -1
            : b.modified_at > a.modified_at
            ? 1
            : 0,
        );
        for (var i = 0; i < response.data.length; i++) {
          var item = response.data[i];
          if (item.job_candidate_id && item.category_id == 7) {
            item.category_id = 8;
          }
          item.category = !item.category_id
            ? "Sonstiges"
            : this.categoryMatching[item.category_id].name;
          if (this.taskCategories.find((x) => x.id == item.category_id)) {
            this.taskCategories.find((x) => x.id == item.category_id).count++;
          }
          item.showDate = await this.$store.dispatch(
            "formatDate",
            new Date(item.modified_at),
          );
        }
        this.notes = response.data;
      },
      async getTaskCategories() {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: "task_categories",
          select: "*",
        });
        this.taskCategories = response.data;
        this.taskCategories.push({
          name: "Anrufe",
          id: 999,
        });
        this.taskCategories.forEach((item) => {
          item.notes = [];
          item.count = 0;
          this.notesInCategories.push(item);
          this.categoryMatching[item.id] = item;
        });
      },
      async addCalls(sipgateCalls) {
        var calls = [];
        var hangupCauses = {
          normalClearing: "Normaler Anrufabbruch",
          busy: "Besetzt",
          cancel: "Abgebrochen",
          noAnswer: "Keine Antwort",
          congestion: "Technisches Problem",
          notFound: "Nummer nicht gefunden",
          forwarded: "Weitergeleitet",
        };
        sipgateCalls.forEach((call) => {
          if (call.was_answered && call.direction == "in") {
            var action = call.action ? call.action : "Eingeganger Anruf";
            var recruitingActivities = {
              icon: "mdi-phone-incoming",
              color: "green",
            };
            var text = call.text
              ? call.text
              : `${(
                  (new Date(call.end).getTime() -
                    new Date(call.start).getTime()) /
                  1000 /
                  60
                ).toFixed(2)} Minuten Telefonzeit`;
          }
          if (call.was_answered && call.direction == "out") {
            var action = call.action ? call.action : "Ausgehender Anruf";
            var recruitingActivities = {
              icon: "mdi-phone-outgoing",
              color: "green",
            };
            var text = call.text
              ? call.text
              : `${(
                  (new Date(call.end).getTime() -
                    new Date(call.start).getTime()) /
                  1000 /
                  60
                ).toFixed(2)} Minuten Telefonzeit`;
          }
          if (!call.was_answered && call.direction == "in") {
            var action = call.action ? call.action : "Verpasster Anruf";
            var text = call.text ? call.text : null;
            var recruitingActivities = {
              icon: "mdi-phone-missed",
              color: "red",
            };
            if (call.hangup_cause == "forwarded") {
              action = call.action ? call.action : "Weitergeleiteter Anruf";
              var text = call.text ? call.text : null;
              recruitingActivities.icon = "mdi-phone-forward";
              recruitingActivities.color = "warning";
            }
          }
          if (!call.was_answered && call.direction == "out") {
            var action = call.action
              ? call.action
              : "Kandidat wurde nicht erreicht";
            var text = call.text ? call.text : null;
            var recruitingActivities = {
              icon: "mdi-phone-missed",
              color: "red",
            };
            if (call.hangup_cause == "forwarded") {
              action = call.action ? call.action : "Weitergeleiteter Anruf";
              var text = call.text ? call.text : null;
              recruitingActivities.icon = "mdi-phone-forward";
              recruitingActivities.color = "warning";
            }
          }
          call = {
            action: action,
            modified_at: call.start,
            public_users: call.public_users,
            recruiting_activities: recruitingActivities,
            text: text,
            sipgate_id: call.sipgate_id,
            category_id: 999,
          };
          calls.push(call);
        });
        return calls;
      },
      async deleteNote(id) {
        this.newAction.loading = true;
        var queryObject = {
          table: "notes",
          id: id,
        };
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
        this.newAction.loading = false;
        this.newAction.activated = false;
        this.getNotes();
      },
      async saveNote(payload, id, sipgate_id) {
        this.newAction.loading = true;
        payload.modified_at = new Date().toISOString();
        payload.owner_id = this.$store.state.userInfo.id;
        if (id || this.newAction.id) {
          payload = {
            text: payload.text,
            action: payload.action,
            role_id: payload.role_id,
            category_id: payload.category_id,
            modified_at: new Date().toISOString(),
          };
          var queryObject = {
            id: id || this.newAction.id,
            table: "notes",
            payload: payload,
          };
          var response = await this.$store.dispatch(
            "updateSupabaseEntity",
            queryObject,
          );
        } else if (sipgate_id) {
          payload = {
            text: payload.text,
            action: payload.action,
            is_positive: payload.is_positive,
            sipgate_id: sipgate_id,
          };
          var queryObject = {
            table: "sipgate_calls",
            payload: payload,
          };
          var response = await this.$store.dispatch(
            "upsertSupabaseEntity",
            queryObject,
          );
        } else {
          var queryObject = {
            table: "notes",
            payload: payload,
          };
          this.newAction.id = await this.$store.dispatch(
            "createSupabaseEntity",
            queryObject,
          );
        }
        this.newAction = {
          loading: false,
          object: {
            hotel_id: this.hotelObject.id,
          },
        };
        this.getNotes();
      },
    },
    async created() {
      this.noteRoles = await this.$store.dispatch("getNoteRoles");
      await this.getTaskCategories();
      var defaultRole = await this.$store.dispatch("getSupabaseEntity", {
        table: "public_user_note_roles",
        where: [
          {
            type: "is",
            key: "is_employee_default",
            value: true,
          },
        ],
      });
  
      this.defaultRoleId = defaultRole.data[0].id;
  
      this.getNoteTemplates();
      this.getNotes();
      this.newAction = {
        loading: false,
        object: {
          hotel_id: this.hotelObject.id,
        },
      };
    },
  };
  </script>
  
  <style>
  .noteHeight {
    overflow: auto;
    overflow-y: auto;
  }
  </style>
  