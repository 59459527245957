<template>
  <v-card>
    <v-card-title>Erstelle neuen Kandidaten</v-card-title>
    <v-card-text>
      <v-text-field
        label="Vorname"
        variant="outlined"
        v-model="first_name"
      ></v-text-field>
      <v-text-field
        label="Nachname"
        variant="outlined"
        v-model="last_name"
      ></v-text-field>
      <v-autocomplete
        label="Job"
        variant="outlined"
        v-model="job_id"
        :items="selections.jobs"
        item-title="title"
        item-value="id"
      ></v-autocomplete>
      <v-autocomplete
        label="Quelle"
        variant="outlined"
        v-model="source_id"
        :items="selections.sources"
        item-title="name"
        item-value="id"
      ></v-autocomplete>
      <v-autocomplete
        label="Status"
        variant="outlined"
        v-model="status_id"
        :items="selections.status"
        item-title="name"
        item-value="id"
      ></v-autocomplete>
    </v-card-text>
    <v-card-actions v-if="checkInput()">
      <v-btn @click="createNewCandidate()" :loading="loading"
        >Kandidaten erstellen</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    selections: Object,
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      job_id: null,
      source_id: null,
      status_id: 1,
      loading: false,
    };
  },
  methods: {
    checkInput() {
      if (
        this.first_name &&
        this.last_name &&
        this.job_id &&
        this.source_id &&
        this.status_id
      ) {
        return true;
      }
    },
    async createNewCandidate() {
      var queryObject = {
        table: "recruiting_candidates",
        payload: {
          first_name: this.first_name,
          last_name: this.last_name,
          mandator_id: this.$store.state.userInfo.mandator_id,
        },
      };
      var candidateId = await this.$store.dispatch(
        "createSupabaseEntity",
        queryObject,
      );
      var queryObject = {
        table: "recruiting_job_candidates",
        payload: {
          candidate_id: candidateId,
          job_id: this.job_id,
          source_id: this.source_id,
          status_id: this.status_id,
        },
      };
      var jobCandidateId = await this.$store.dispatch(
        "createSupabaseEntity",
        queryObject,
      );
      var queryObject = {
        table: "notes",
        payload: {
          job_candidate_id: jobCandidateId,
          owner_id: this.$store.state.userInfo.id,
          action: "Kandidat erstellt",
          activity_id: 18,
        },
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.$router.push({ path: "/Kandidaten/" + candidateId });
    },
  },
};
</script>
