<template>
  <v-row>
    <v-col cols="12" class="ma-4">
      <v-btn
        v-for="(process, processIndex) in processes"
        @click="openProcess(process, processIndex)"
        >{{ process.name }}</v-btn
      >
      <v-btn
        size="small"
        class="ml-2"
        color="green"
        @click="newProcess.show = !newProcess.show"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-col cols="4">
        <v-text-field
          append-inner-icon="mdi-content-save"
          @click:append-inner="addProcess"
          v-if="newProcess.show"
          v-model="newProcess.name"
          label="Name"
        ></v-text-field>
      </v-col>
    </v-col>
    <v-col cols="6" class="pt-4 separate-scroll-onboarding">
      <v-col cols="12">
        <v-btn
          icon
          color="indigo-darken-2"
          @click="saveSteps()"
          :loading="loading"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn icon color="green-darken-2" @click="addStep()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <draggable
        v-model="
          chosenProcess.employee_onboarding_offboarding_process_step_matching
        "
        item-key="id"
        class="ma-4"
        @change="updateOrder('steps')"
        handle=".drag-handle"
      >
        <template #item="{ element, index }">
          <v-card elevation="3">
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-btn size="small" class="drag-handle">
                    <v-icon>mdi-drag</v-icon>
                  </v-btn>
                  <v-btn size="small" @click="openStep(element, index)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    color="error"
                    size="small"
                    @click="deleteStep(element, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="element.employee_onboarding_offboarding_steps.name"
                    label="Schritt"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    type="number"
                    label="Tage"
                    v-model="element.days"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn-toggle v-model="element.before_after" outlined divided>
                    <v-btn size="x-small" value="Vorher">Vorher</v-btn>
                    <v-btn size="x-small" value="Nachher">Nachher</v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="4" class="mt-n9">
                  <v-switch
                    color="green"
                    label="Email"
                    v-model="
                      element.employee_onboarding_offboarding_steps.email
                    "
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </draggable>
    </v-col>
    <v-col cols="6" class="separate-scroll-onboarding">
      <v-col cols="12" v-if="chosenStep.id">
        <v-btn
          icon
          color="indigo-darken-2"
          @click="saveComponents()"
          :loading="loading"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          color="green-darken-2"
          v-for="button in addArray"
          :prepend-icon="button.content.icon"
          append-icon="mdi-plus-circle"
          @click="addComponent(button.content)"
          >{{ button.label }}</v-btn
        >
      </v-col>
      <v-card v-if="chosenStep.email">
        <v-card-title>{{ chosenStep.name }}</v-card-title>
        <v-card-text>
          <v-text-field
            variant="outlined"
            v-model="chosenStep.email_subject"
          ></v-text-field>
          <QuillEditor
            toolbar="essential"
            v-model:content="chosenStep.email_body"
            contentType="html"
            placeholder="Text"
          />
        </v-card-text>
      </v-card>
      <v-row v-else>
        <v-col cols="12">
          <draggable
            item-key="id"
            v-model="chosenStep.employee_onboarding_offboarding_step_components"
            @change="updateOrder('components')"
            handle=".drag-handle"
          >
            <template #item="{ element, index }">
              <v-col cols="12">
                <v-card elevation="4" class="ma-1">
                  <v-card-title></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="1"
                        class="text-center d-flex flex-column align-center"
                      >
                        <v-btn size="small" class="drag-handle">
                          <v-icon>mdi-drag</v-icon>
                        </v-btn>
                        <v-icon
                          class="mt-2"
                          :icon="types[element.type].icon"
                        ></v-icon>
                      </v-col>
                      <v-col
                        class="mb-16"
                        v-if="types[element.type].type == 'textarea'"
                        :cols="types[element.type].cols"
                      >
                        <QuillEditor
                          toolbar="essential"
                          v-model:content="element.content"
                          contentType="html"
                          placeholder="Text"
                        />
                      </v-col>
                      <v-col
                        v-else-if="types[element.type].type == 'text-field'"
                      >
                        <v-text-field
                          density="compact"
                          v-model="element.content"
                        ></v-text-field>
                      </v-col>
                      <v-col v-else-if="types[element.type].type == 'select'">
                        <v-text-field v-model="element.content"></v-text-field>
                        <v-autocomplete
                          v-if="types[element.type].type == 'select'"
                          density="compact"
                          v-model="element.key_id"
                          :items="keys"
                          item-title="key_name_ger"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col v-else-if="types[element.type].type == 'upload'">
                        <v-text-field v-model="element.content"></v-text-field>
                        <v-autocomplete
                          density="compact"
                          v-model="element.employee_document_category_id"
                          :items="documentCategories"
                          item-title="name"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" align="center">
                        <v-btn
                          color="error"
                          @click="deleteComponent(element, index)"
                          icon
                          size="small"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </draggable>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import draggable from "vuedraggable";
</script>
<script>
export default {
  props: {
    type: String,
  },
  data() {
    return {
      loading: false,
      processes: [],
      newProcess: {},
      chosenProcess: {},
      chosenStep: {},
      processIndex: 0,
      stepIndex: 0,
      keys: [],
      documentCategories: [],
      addArray: [
        {
          label: "Checkbox",
          content: { icon: "mdi-check-circle", type: "checkbox", cols: 6 },
        },
        {
          label: "Text",
          content: { icon: "mdi-text", type: "text", cols: 9 },
        },
        {
          label: "Datenfeld",
          content: { icon: "mdi-account-edit", type: "key_value", cols: 9 },
        },
        {
          label: "Dokumentupload",
          content: {
            icon: "mdi-file-upload",
            type: "document_upload",
            cols: 9,
          },
        },
      ],
      types: {
        checkbox: {
          icon: "mdi-check-circle",
          type: "text-field",
          cols: 6,
        },
        text: {
          icon: "mdi-text",
          type: "textarea",
          cols: 9,
        },
        key_value: {
          icon: "mdi-account-edit",
          type: "select",
          cols: 9,
        },
        document_upload: {
          icon: "mdi-file-upload",
          type: "upload",
          cols: 9,
        },
      },
    };
  },
  methods: {
    async getKeys() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_data_keys",
      });
      this.keys = response.data;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_document_categories",
      });
      this.documentCategories = response.data;
    },
    async getOnboardingProcesses() {
      var queryObject = {
        select:
          "*,employee_onboarding_offboarding_process_step_matching(*,employee_onboarding_offboarding_steps(*,employee_onboarding_offboarding_step_components(*)))",
        table: "employee_onboarding_offboarding_process",
        where: [
          {
            type: "is",
            key: "is_offboarding",
            value: this.type == "offboarding",
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.processes = response.data;
      this.openProcess(this.processes[this.processIndex], this.processIndex);
      this.openStep(
        this.chosenProcess
          .employee_onboarding_offboarding_process_step_matching[
          this.stepIndex
        ],
        this.stepIndex,
      );
    },
    openProcess(process, index) {
      this.processIndex = index;
      process.employee_onboarding_offboarding_process_step_matching =
        this.sortByKey(
          process.employee_onboarding_offboarding_process_step_matching,
          "order",
        );
      this.chosenProcess = process;
      this.chosenStep = {};
    },
    updateOrder(type) {
      if (type == "steps") {
        this.chosenProcess.employee_onboarding_offboarding_process_step_matching.forEach(
          function (item, index) {
            item.order = index + 1;
          },
        );
      } else if (type == "components") {
        this.chosenStep.employee_onboarding_offboarding_step_components.forEach(
          function (item, index) {
            item.order = index + 1;
          },
        );
      }
    },
    openStep(step, index) {
      this.stepIndex = index;
      step.employee_onboarding_offboarding_steps.employee_onboarding_offboarding_step_components =
        this.sortByKey(
          step.employee_onboarding_offboarding_steps
            .employee_onboarding_offboarding_step_components,
          "order",
        );
      this.chosenStep = step.employee_onboarding_offboarding_steps;
    },
    async addProcess() {
      var queryObject = {
        table: "employee_onboarding_offboarding_process",
        payload: {
          name: this.newProcess.name,
        },
      };
      if (this.type == "offboarding") {
        queryObject.payload.is_offboarding = true;
      }
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.newProcess = {};
      this.getOnboardingProcesses();
    },
    async addStep() {
      var object = {
        id: null,
        days: 5,
        process_id: this.chosenProcess.id,
        before_after: "Vorher",
        order:
          this.chosenProcess
            .employee_onboarding_offboarding_process_step_matching.length + 1,
        employee_onboarding_offboarding_steps: {
          name: null,
          email: false,
        },
      };
      this.chosenProcess.employee_onboarding_offboarding_process_step_matching.push(
        object,
      );
      await this.saveSteps();
      this.getOnboardingProcesses();
    },
    async deleteStep(element, index) {
      var queryObject = {
        id: element.id,
        table: "employee_onboarding_offboarding_process_step_matching",
      };
      if (element.id) {
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      }

      this.chosenProcess.employee_onboarding_offboarding_process_step_matching.splice(
        index,
        1,
      );
      this.updateOrder("steps");
    },
    async saveSteps() {
      var table = "employee_onboarding_offboarding_process_step_matching";
      var payload =
        this.chosenProcess
          .employee_onboarding_offboarding_process_step_matching;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      var stepUpsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        var steps = JSON.parse(
          JSON.stringify(payload[i].employee_onboarding_offboarding_steps),
        );
        var employeeOnboardingSteps = JSON.parse(
          JSON.stringify(payload[i].employee_onboarding_offboarding_steps),
        );
        delete payload[i].employee_onboarding_offboarding_steps;

        if (payload[i].id) {
          upsertArray.push(payload[i]);
          delete employeeOnboardingSteps.employee_onboarding_offboarding_step_components;
          stepUpsertArray.push(employeeOnboardingSteps);
        } else {
          var queryObject = {
            table: "employee_onboarding_offboarding_steps",
            payload: {
              id: id,
              name: steps.name,
              email: steps.email,
            },
          };
          var id = await this.$store.dispatch(
            "createSupabaseEntity",
            queryObject,
          );
          payload[i].step_id = id;
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      var queryObject = {
        table: "employee_onboarding_offboarding_steps",
        payload: stepUpsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      this.loading = false;
    },
    async addComponent(content) {
      content.onboarding_step_id = this.chosenStep.id;
      content.order =
        this.chosenStep.employee_onboarding_offboarding_step_components.length +
        1;
      const addElement = JSON.parse(JSON.stringify(content));
      this.chosenStep.employee_onboarding_offboarding_step_components.push(
        addElement,
      );
    },
    async saveComponents() {
      var table = "employee_onboarding_offboarding_step_components";
      var payload =
        this.chosenStep.employee_onboarding_offboarding_step_components;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        delete payload[i].cols;
        delete payload[i].icon;
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      await this.getOnboardingProcesses();
      this.loading = false;
    },
    async deleteComponent(element, index) {
      var queryObject = {
        id: element.id,
        table: "employee_onboarding_offboarding_step_components",
      };
      if (element.id) {
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      }

      this.chosenStep.employee_onboarding_offboarding_step_components.splice(
        index,
        1,
      );
      this.updateOrder("components");
    },
    sortByKey(array, key) {
      return array.sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
    },
  },
  async mounted() {
    await this.getKeys();
    await this.getOnboardingProcesses();
  },
};
</script>

<style>
.separate-scroll-onboarding {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
