<template>
  <v-tabs v-model="currentTab" color="deep-purple-accent-4" align-tabs="center">
    <v-tab variant="outlined" value="employee" prepend-icon="mdi-account-cog">Supervisor Stock</v-tab>
    <v-tab variant="outlined" value="office" prepend-icon="mdi-office-building">Büro Stock</v-tab>
  </v-tabs>
  <v-window v-model="currentTab">
    <v-window-item value="employee">
      <v-card class="mt-4">
        <v-card-title>
    <h1 class="ma-2" align="center">Supervisor Stock</h1>
        </v-card-title>
      <v-card-text class="mt-1">
        <v-data-table-virtual
    :headers="headers"
    :items="employeesWithStock"
    height="400"
    item-value="name"
    fixed-header
  >
    <template v-slot:item="{ item }"> 
      <tr @click="openStockDialog(item)">
        <td>{{ item.name }}</td>
        <td v-for="accessory in accessoryItems" :key="accessory.id">
          {{ item.stock[accessory.id] || 0 }}
        </td>
      </tr>
    </template>
  </v-data-table-virtual>
  <v-row align="center">
    <v-col cols="3" offset="4"> 
      <v-autocomplete
        class="mt-8"
        variant="outlined"
        label="Mitarbeiter auswählen"
        :items="employees"
        item-title="name"
        item-value="id"
        v-model="selectedEmployeeId"
        clearable
      ></v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-btn 
        class="mt-1" 
        color="primary" 
        @click="openStockDialogForEmployee"
        :disabled="!selectedEmployeeId" 
      >
        Stock hinzufügen
      </v-btn>
    </v-col>
  </v-row>
        </v-card-text>
</v-card>
    </v-window-item>

    <v-window-item value="office">
    <v-card class="mt-4">
      <v-card-title>
      <h1 class="ma-2" align="center">Büro Stock</h1>
    </v-card-title>
      <v-card-text class="mt-3">
          <v-dialog v-model="protocolDialog">
            <v-card>
                <v-card-text>
                    <AccessoryProtocol :close-dialog="() => protocolDialog = false"></AccessoryProtocol>
                </v-card-text>
            </v-card>
          </v-dialog>
        <v-row v-for="(accessory, index) in accessoryItems" class="my-n6">
          
            <v-col cols="6">
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="accessory.name"
              label="Accessoire"
            ></v-text-field>
            
          </v-col>
          <v-col cols="3">
            <v-text-field
              variant="outlined"
              density="compact"
              type="number"
              @click:append="deleteAccessory(accessory, index)"
              v-model="accessory.stock_count"
              label="Anzahl"
            ></v-text-field>
          </v-col>
          <v-col cold="3">
            <v-text-field
              variant="outlined"
              density="compact"
              type="number"
              v-model="accessory.stock_alert_count"
              label="Kapazität"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      
      <v-col align="center">
        <v-btn @click="accessoryItems.push({ name: null })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn @click="saveAccessorys()" class="ml-2" :loading="loading">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" class="mb-5">
            <v-btn @click="protocolDialog = true" color="success" append-icon="mdi-plus">Protokoll erstellen</v-btn>
          </v-col>      
      </v-card>   
    </v-window-item>
  </v-window>   
  <v-dialog v-model="stockDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Mitarbeiter Stock bearbeiten: {{ editedEmployee.name }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-for="(accessory, index) in editedEmployeeStock" :key="index">
            <v-col cols="8">
              <v-text-field variant="outlined" density="compact" :value="accessory.name" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field variant="outlined" density="compact" type="number" v-model="accessory.stock_count"
                label="Anzahl"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="stockDialog = false">Schließen</v-btn>
        <v-btn color="blue darken-1" text @click="saveEmployeeStock">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </template>
  
  <script setup>
  import draggable from "vuedraggable";
  import AccessoryProtocol from "./MaterialManagement/AccessoryProtocol.vue";
  </script>
  <script>
  export default {
    
    data() {
      return {
        loading: false,
        accessoryItems: [],
        protocolDialog: false,
        employees: [],
        selectedEmployeeId: null,
        employeesWithStock: [],
        stockDialog: false,
        editedEmployee: null,
        editedEmployeeStock: [],
        currentTab: 'employee',
      };
    },

    computed: {
    headers() {
      return [
        { title: 'Mitarbeiter', key: 'name' },
        ...this.accessoryItems.map(accessory => ({
          title: accessory.name,
          key: accessory.id 
        }))
      ];
    }
  },
    
    methods: {
      async getAccessoryItems() {
        var queryObject = {
          table: "accessory_items",
          select: "*",
          order: {
            key: "name",
            ascending: true,
          },
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.accessoryItems = response.data.map(item => ({
          ...item,
          stock_alert_count: item.stock_alert_count
        }));
      },

      async getEmployees() {
      var queryObject = {
        table: "employees",
        select: "id, name",
        order: {
              key: 'name',
              ascending: true
            }
      };
      var response = await this.$store.dispatch("getSupabaseEntity", queryObject);
      this.employees = response.data;
    },

    openStockDialogForEmployee(){
      const selectedEmployee = this.employees.find(employee => employee.id === this.selectedEmployeeId);

      if(selectedEmployee){
        this.openStockDialog(selectedEmployee);
      }else{
        console.error("Selected employee not found!");
      }
    },

    async loadEmployeeStock() {
  try {
    const queryObject = {
      table: "accessory_employee_stock",
      select: "employee_id, accessory_item_id, stock_count",
    };

    const response = await this.$store.dispatch("getSupabaseEntity", queryObject);
    const employeeStockData = response.data;

    const employeeIdsWithStock = [...new Set(employeeStockData.map(item => item.employee_id))];

    const employeesWithStock = this.employees.filter(employee => employeeIdsWithStock.includes(employee.id));

    const employeeStockMap = {};

    this.employeesWithStock = employeesWithStock.map(employee => ({
      id: employee.id,
      name: employee.name,
      stock: {} 
    }));

    employeeStockData.forEach(item => {
      if (!employeeStockMap[item.employee_id]) {
        employeeStockMap[item.employee_id] = {};
      }
      employeeStockMap[item.employee_id][item.accessory_item_id] = item.stock_count;
    });

    this.employeesWithStock.forEach(employee => {
      employee.stock = employeeStockMap[employee.id] || {}; 
    });

  } catch (error) {
    console.error("Error loading employee stock:", error);
  }
},

    openStockDialog(employee) {
  this.editedEmployee = employee;

  this.editedEmployeeStock = this.accessoryItems.map(accessory => ({
    accessory_item_id: accessory.id,
    name: accessory.name,
    stock_count: employee.stock && employee.stock[accessory.id]? employee.stock[accessory.id]: 0
  }));

  this.stockDialog = true;
},

async saveEmployeeStock() {
  this.stockDialog = false;

  try {
    const queryObject = {
      table: "accessory_employee_stock",
      select: "employee_id, accessory_item_id, stock_count",
    };
    const response = await this.$store.dispatch("getSupabaseEntity", queryObject);
    const employeeStockData = response.data;

    const upsertData = this.editedEmployeeStock.filter(item => item.stock_count !== 0);

    const deleteData = employeeStockData
      .filter(stockItem => {
        const editedItem = this.editedEmployeeStock.find(edited => edited.accessory_item_id === stockItem.accessory_item_id);
        return editedItem && editedItem.stock_count === 0; 
      })
      .map(item => ({
        employee_id: item.employee_id, 
        accessory_item_id: item.accessory_item_id
      }));

    if (deleteData.length > 0) {
      await Promise.all(deleteData.map(async item => {
        const deleteQuery = {
          table: "accessory_employee_stock",
          filter: {
            key: "employee_id",
            value: item.employee_id,
            key2: "accessory_item_id",
            value2: item.accessory_item_id,
          },
        };
        await this.$store.dispatch("deleteSupabaseEntity", deleteQuery);
      }));
    }

    if (upsertData.length > 0) {
      const upsertQuery = {
        table: "accessory_employee_stock",
        payload: upsertData.map(item => ({
          employee_id: this.editedEmployee.id,
          accessory_item_id: item.accessory_item_id,
          stock_count: item.stock_count,
        })),
      };
      await this.$store.dispatch("upsertSupabaseEntity", upsertQuery);
    }

    await this.loadEmployeeStock();

  } catch (error) {
    console.error("Error saving employee stock:", error);
  }
},
      
      async deleteAccessory(accessory, index) {
        if (accessory.id) {
          await this.$store.dispatch("deleteSupabaseEntity", {
            id: accessory.id,
            table: "accessory_items",
          });
        }
        this.accessoryItems.splice(index, 1);
      },
      async saveAccessorys() {
        var table = "accessory_items";
        var payload = this.accessoryItems;
        this.loading = true;
        var payload = JSON.parse(JSON.stringify(payload));
        var upsertArray = [];
        for (var i = 0; i < payload.length; i++) {
          if (payload[i].id) {
            upsertArray.push(payload[i]);
          } else {
            var queryObject = {
              table: table,
              payload: payload[i],
            };
            await this.$store.dispatch("createSupabaseEntity", queryObject);
          }
        }
        var queryObject = {
          table: table,
          payload: upsertArray,
        };
        await this.$store.dispatch("upsertSupabaseEntity", queryObject);
        await this.getAccessoryItems();
        this.loading = false;
      },
    },
    async mounted() {
      await this.getAccessoryItems();
      await this.getEmployees();
      this.loadEmployeeStock();
    },
    
  };
  </script>