<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="d-flex align-center">
            Deadlines für Freiwünsche
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-col cols="12" class="d-flex align-center">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        icon="mdi-information-outline"
                        class="mr-2 mb-2"
                      ></v-icon>
                    </template>
                    <span>
                      Die Deadline, welche in der Infobox im Portal steht.
                      Mitarbeiter sollten die Wünsche vor dieser Deadline
                      eintragen, können es aber technisch etwas später
                      einreichen.
                    </span>
                  </v-tooltip>
                  <v-select
                    v-model="softDeadlineDay"
                    :items="weekDays"
                    label="Softe Deadline - Tag"
                  ></v-select>
                </v-col>
                <v-text-field
                  v-model="softDeadlineTime"
                  type="time"
                  label="Softe Deadline - Uhrzeit"
                ></v-text-field>
                <div class="text-caption text-grey">
                  {{ formatDeadline(softDeadlineDay, softDeadlineTime) }}
                </div>
              </v-col>
              <v-divider thickness="4" vertical class="mx-4"></v-divider>
              <v-col cols="12" md="5">
                <v-col cols="12" class="d-flex align-center">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        icon="mdi-information-outline"
                        class="mr-2 mb-2"
                      ></v-icon>
                    </template>
                    <span>
                      Die endgültige Deadline, nach der es auch technisch nicht
                      mehr möglich ist, einen Freiwunsch für die Folgewoche
                      einzureichen.
                    </span>
                  </v-tooltip>
                  <v-select
                    v-model="technicalDeadlineDay"
                    :items="weekDays"
                    label="Technische Deadline - Tag"
                  ></v-select>
                </v-col>
                <v-text-field
                  v-model="technicalDeadlineTime"
                  type="time"
                  label="Technische Deadline - Uhrzeit"
                ></v-text-field>
                <div class="text-caption text-grey">
                  {{
                    formatDeadline(technicalDeadlineDay, technicalDeadlineTime)
                  }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loading" @click="saveDeadlines">
              Speichern
            </v-btn>
          </v-card-actions>
          <!-- Updated Snackbar -->
          <v-snackbar
            v-model="snackbar.visible"
            timeout="3000"
            :color="snackbar.color"
          >
            {{ snackbar.message }}
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { supabase } from "@/supabase";

const weekDays = [
  { title: "Montag", value: 1 },
  { title: "Dienstag", value: 2 },
  { title: "Mittwoch", value: 3 },
  { title: "Donnerstag", value: 4 },
  { title: "Freitag", value: 5 },
  { title: "Samstag", value: 6 },
  { title: "Sonntag", value: 0 },
];

const loading = ref(false);
const softDeadlineDay = ref(null);
const softDeadlineTime = ref("");
const technicalDeadlineDay = ref(null);
const technicalDeadlineTime = ref("");

const snackbar = reactive({
  visible: false,
  message: "",
  color: "",
});

const formatDeadline = (day, time) => {
  if (!day || !time) return "";

  // Create a date object for the next occurrence of the selected weekday
  const today = new Date();
  const targetDay = day;
  const daysUntilTarget = (targetDay + 7 - today.getDay()) % 7;
  const nextDate = new Date(today);
  nextDate.setDate(today.getDate() + daysUntilTarget);

  // Set the time
  const [hours, minutes] = time.split(":");
  nextDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);

  return format(nextDate, "EEEE, HH:mm 'Uhr'", { locale: de });
};

const loadDeadlines = async () => {
  loading.value = true;
  try {
    const { data, error } = await supabase
      .from("deadlines")
      .select("*")
      .in("type", ["freeWishSoft", "freeWishTechnical"]);
    if (error) throw error;
    if (data && data.length) {
      const softDeadline = data.find((item) => item.type === "freeWishSoft");
      const technicalDeadline = data.find(
        (item) => item.type === "freeWishTechnical",
      );
      if (softDeadline) {
        softDeadlineDay.value = softDeadline.weekDay;
        softDeadlineTime.value = softDeadline.time;
      }
      if (technicalDeadline) {
        technicalDeadlineDay.value = technicalDeadline.weekDay;
        technicalDeadlineTime.value = technicalDeadline.time;
      }
    }
  } catch (error) {
    console.error(error);
    snackbar.message =
      "Fehler beim Laden der Deadlines. Bitte Seite erneut laden.";
    snackbar.color = "error";
    snackbar.visible = true;
  } finally {
    loading.value = false;
  }
};

const saveDeadlines = async () => {
  loading.value = true;
  try {
    const { error } = await supabase
      .from("deadlines")
      .update({
        weekDay: softDeadlineDay.value,
        time: softDeadlineTime.value,
      })
      .eq("type", "freeWishSoft");
    if (error) throw error;
    const { error: technicalError } = await supabase
      .from("deadlines")
      .update({
        weekDay: technicalDeadlineDay.value,
        time: technicalDeadlineTime.value,
      })
      .eq("type", "freeWishTechnical");
    if (technicalError) throw technicalError;

    // On success, update snackbar properties
    snackbar.message = "Deadlines erfolgreich gespeichert.";
    snackbar.color = "success";
    snackbar.visible = true;
  } catch (error) {
    console.error("Error saving deadlines:", error);
    snackbar.message =
      "Fehler beim Speichern des Deadlines. Bitte nochmal versuchen.";
    snackbar.color = "error";
    snackbar.visible = true;
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  loadDeadlines();
});
</script>
