<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab value="disposition" v-if="locationFilter">Hoteleinteilung</v-tab>
      <v-tab value="employees" v-if="locationFilter">Mitarbeiter</v-tab>
      <v-tab value="hotels" v-if="locationFilter">Hotels</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="disposition" v-if="locationFilter">
          <HotelEntries :locationFilter="locationFilter"></HotelEntries>
        </v-window-item>
        <v-window-item value="employees" v-if="locationFilter">
          <EmployeeHotelEntries
            :locationFilter="locationFilter"
            type="employees"
          ></EmployeeHotelEntries>
        </v-window-item>
        <v-window-item value="hotels" v-if="locationFilter">
          <EmployeeHotelEntries
            :locationFilter="locationFilter"
            type="hotels"
          ></EmployeeHotelEntries>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import HotelEntries from "@/components/Shifts/HotelEntries.vue";
import EmployeeHotelEntries from "@/components/Shifts/EmployeeHotelEntries.vue";
</script>
<script>
export default {
  data() {
    return {
      tab: "disposition",
      locationFilter: null,
    };
  },
  methods: {},
  async mounted() {
    let role = await this.$store.dispatch("checkRole");
    if (role.locationFilter.length > 0) {
      this.locationFilter = role.locationFilter;
    } else {
      this.locationFilter = [];
    }
  },
};
</script>
