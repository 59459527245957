<template>
  <div>
    <v-card rounded elevation="2" class="mb-3 levels-card">
      <v-card-title class="d-flex align-center py-2">
        <v-icon color="blue-grey-darken-1" class="mr-2">mdi-trophy</v-icon>
        Bonusstufen
      </v-card-title>

      <!-- Tabs for hamburg vs Berlin bonus levels -->
      <div class="px-4 pt-2">
        <v-tabs v-model="selectedCityTab" background-color="transparent">
          <v-tab value="hamburg">Hamburg</v-tab>
          <v-tab value="berlin">Berlin</v-tab>
        </v-tabs>
      </div>

      <v-card-text class="pt-2 pb-0">
        <!-- List of existing bonus levels in cards -->
        <v-row dense>
          <v-col cols="12" v-for="level in bonusLevels" :key="level.id">
            <v-card
              outlined
              :elevation="editingLevel === level.id ? 3 : 1"
              class="mb-2 pa-1"
            >
              <v-card-text class="py-1">
                <v-row align="center" dense>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="level.level_name"
                      label="Stufenname"
                      dense
                      outlined
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="1">
                    <v-text-field
                      v-model="level.min_points"
                      label="Mindestpunkte"
                      type="number"
                      dense
                      outlined
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- Hourly wage fields -->
                  <v-col cols="6" md="1">
                    <v-text-field
                      v-model="level.hourly_wage_bronze"
                      label="Bronze €/h"
                      type="number"
                      dense
                      outlined
                      suffix="€"
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="1">
                    <v-text-field
                      v-model="level.hourly_wage_silver"
                      label="Silber €/h"
                      type="number"
                      dense
                      outlined
                      suffix="€"
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="1">
                    <v-text-field
                      v-model="level.hourly_wage_gold"
                      label="Gold €/h"
                      type="number"
                      dense
                      outlined
                      suffix="€"
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      v-model="level.hourly_wage_shiftleader"
                      label="Schichtl. €/h"
                      type="number"
                      dense
                      outlined
                      suffix="€"
                      :readonly="editingLevel !== level.id"
                      @input="levelChanged = true"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    md="4"
                    class="d-flex justify-end align-center"
                  >
                    <div class="d-flex align-center">
                      <template v-if="editingLevel === level.id">
                        <v-btn
                          icon
                          color="success"
                          @click="updateBonusLevel(level)"
                          :elevation="1"
                          size="x-small"
                          class="mr-1"
                        >
                          <v-icon small>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="error"
                          @click="confirmDelete(level.id)"
                          :elevation="1"
                          size="x-small"
                        >
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <v-btn
                        v-else
                        icon
                        @click="editingLevel = level.id"
                        :elevation="1"
                        size="x-small"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- Add effective dates fields -->
                <v-row
                  v-if="editingLevel === level.id"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="6">
                    <v-text-field
                      v-model="level.effective_from"
                      label="Gültig ab"
                      type="date"
                      dense
                      outlined
                      hide-details
                      @input="levelChanged = true"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Historical values expansion -->
                <v-row
                  v-if="editingLevel === level.id && hasHistoricalValues(level)"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="12">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-title>
                          Verlauf anzeigen ({{
                            getHistoricalValuesCount(level)
                          }}
                          Einträge)
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-list dense>
                            <v-list-item
                              v-for="(histEntry, hIndex) in getHistoricalValues(
                                level,
                              )"
                              :key="hIndex"
                            >
                              <v-list-item-title>
                                {{ formatDate(histEntry.effective_from) }} -
                                {{
                                  histEntry.effective_to
                                    ? formatDate(histEntry.effective_to)
                                    : "jetzt"
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Mindestpunkte: {{ histEntry.min_points }} |
                                Bronze: {{ histEntry.hourly_wage_bronze }}€ |
                                Silber: {{ histEntry.hourly_wage_silver }}€ |
                                Gold: {{ histEntry.hourly_wage_gold }}€ |
                                Schichtleiter:
                                {{ histEntry.hourly_wage_shiftleader }}€
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

                <!-- Add new version button -->
                <v-row
                  v-if="editingLevel === level.id"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="12" class="text-center">
                    <v-btn
                      small
                      color="primary"
                      outlined
                      @click="showNewVersionDialog(level)"
                    >
                      <v-icon left small>mdi-clock-outline</v-icon>
                      Neue Version erstellen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Add button for showing the bonus level form -->
        <div class="text-center my-2">
          <v-btn
            color="blue-grey-darken-2"
            small
            outlined
            @click="showLevelForm = !showLevelForm"
          >
            <v-icon left small>{{
              showLevelForm ? "mdi-minus" : "mdi-plus"
            }}</v-icon>
            {{ showLevelForm ? "Abbrechen" : "" }}
          </v-btn>
        </div>

        <!-- Form to add new bonus level -->
        <v-expand-transition>
          <div v-if="showLevelForm" class="py-3">
            <v-divider class="mb-3"></v-divider>
            <div class="subtitle-2 mb-2">Neue Bonusstufe hinzufügen</div>
            <v-row align="center" dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="newLevel.level_name"
                  label="Stufenname"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="1">
                <v-text-field
                  v-model="newLevel.min_points"
                  label="Mindestpunkte"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- Hourly wage fields for new level -->
              <v-col cols="6" md="1">
                <v-text-field
                  v-model="newLevel.hourly_wage_bronze"
                  label="Bronze €/h"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="1">
                <v-text-field
                  v-model="newLevel.hourly_wage_silver"
                  label="Silber €/h"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="1">
                <v-text-field
                  v-model="newLevel.hourly_wage_gold"
                  label="Gold €/h"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="2">
                <v-text-field
                  v-model="newLevel.hourly_wage_shiftleader"
                  label="Schichtl. €/h"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="d-flex justify-end align-center">
                <v-btn
                  color="blue-grey-darken-2"
                  @click="addBonusLevel"
                  :disabled="!canAddLevel"
                  icon
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Bestätigung</v-card-title>
        <v-card-text>
          Sind Sie sicher, dass Sie diese Bonusstufe löschen möchten?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="confirmDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="error" text @click="executeDelete"> Löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Version Dialog -->
    <v-dialog v-model="newVersionDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Neue Version erstellen</v-card-title>
        <v-card-text>
          <p>Aktuelle Version abschließen und neue Version starten:</p>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="oldVersionEndDate"
                label="Alte Version gültig bis"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newVersionEffectiveDate"
                label="Neue Version gültig ab"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="newVersionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="primary" text @click="createNewVersion">
            Neue Version erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { supabase } from "@/supabase";
import useSnackbar from "@/utils/useSnackbar";
import {
  format,
  parseISO,
  lastDayOfQuarter,
  addDays,
  compareAsc,
} from "date-fns";

// Get snackbar functionality
const { showSuccess, showError } = useSnackbar();

// Data for existing records
const bonusLevels = ref([]);

// City tab selection
const selectedCityTab = ref("hamburg");

// Form visibility controls
const showLevelForm = ref(false);

// Data for new records
const newLevel = ref({
  level_name: "",
  min_points: 0,
  hourly_wage_bronze: null,
  hourly_wage_silver: null,
  hourly_wage_gold: null,
  hourly_wage_shiftleader: null,
});

// Edit tracking
const editingLevel = ref(null);

// Track if changes were made
const levelChanged = ref(false);

// Confirmation dialog
const confirmDialog = ref(false);
const deleteId = ref(null);

// New Version Dialog
const newVersionDialog = ref(false);
const newVersionItem = ref(null);
const newVersionEffectiveDate = ref("");
const oldVersionEndDate = ref("");

const finishEditing = () => {
  // Only clear editing state if changes were applied
  editingLevel.value = null;
  levelChanged.value = false;
};

// Confirmation handlers
const confirmDelete = (id) => {
  deleteId.value = id;
  confirmDialog.value = true;
};

const executeDelete = async () => {
  await deleteBonusLevel(deleteId.value);
  confirmDialog.value = false;
};

// Validation for new entries
const canAddLevel = computed(
  () => newLevel.value.level_name && newLevel.value.min_points !== "",
);

// Computed city id based on selected tab. Needs to be extended and improved when new cities are added.
const currentCityId = computed(() => {
  return selectedCityTab.value === "berlin" ? 2 : 1;
});

// Add helper functions for historical values
const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return format(parseISO(dateString), "dd.MM.yyyy");
  } catch (e) {
    console.error("Date formatting error:", e);
    return dateString;
  }
};

// Sort historical values chronologically by effective_from date
const sortHistoricalValues = (values) => {
  if (!Array.isArray(values)) return [];

  return [...values].sort((a, b) => {
    try {
      // Use compareAsc from date-fns for proper date comparison
      return compareAsc(parseISO(a.effective_from), parseISO(b.effective_from));
    } catch (e) {
      console.error("Error sorting historical values:", e);
      return 0;
    }
  });
};

const hasHistoricalValues = (item) => {
  return item.historical_values && item.historical_values.length > 0;
};

const getHistoricalValues = (item) => {
  if (!item.historical_values) return [];
  const values = Array.isArray(item.historical_values)
    ? item.historical_values
    : [];
  return sortHistoricalValues(values);
};

const getHistoricalValuesCount = (item) => {
  return getHistoricalValues(item).length;
};

// Fetch data methods
const fetchBonusLevels = async () => {
  const { data, error } = await supabase
    .from("bonus_levels")
    .select("*")
    .is("deleted_at", null)
    .eq("city_id", currentCityId.value)
    .order("min_points", { ascending: true });

  if (error) {
    console.error(error);
    showError("Fehler beim Laden der Bonusstufen");
  } else {
    // Process the data to ensure historical_values is properly formatted
    data.forEach((level) => {
      // Ensure historical_values is an array
      if (!level.historical_values) {
        level.historical_values = [];
      } else if (typeof level.historical_values === "string") {
        try {
          level.historical_values = JSON.parse(level.historical_values);
          level.historical_values = sortHistoricalValues(
            level.historical_values,
          );
        } catch (error) {
          console.error(
            "Fehler beim Parsen der historischen Werte:",
            error,
            level.historical_values,
          );
          level.historical_values = [];
        }
      } else {
        level.historical_values = sortHistoricalValues(level.historical_values);
      }

      if (level.effective_from) {
        level.effective_from = level.effective_from.substring(0, 10);
      } else {
        level.effective_from = format(new Date(), "yyyy-MM-dd");
      }
    });

    bonusLevels.value = data.sort((a, b) => b.min_points - a.min_points);
  }
};

// CRUD operations for Bonus Levels
const addBonusLevel = async () => {
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const levelToAdd = {
    ...newLevel.value,
    created_at: new Date(),
    city_id: currentCityId.value,
    effective_from: currentDate,
    historical_values: [],
    min_points: Number(newLevel.value.min_points) || 0,
    hourly_wage_bronze: Number(newLevel.value.hourly_wage_bronze) || null,
    hourly_wage_silver: Number(newLevel.value.hourly_wage_silver) || null,
    hourly_wage_gold: Number(newLevel.value.hourly_wage_gold) || null,
    hourly_wage_shiftleader:
      Number(newLevel.value.hourly_wage_shiftleader) || null,
  };

  const { error } = await supabase.from("bonus_levels").insert([levelToAdd]);

  if (error) {
    console.error(error);
    showError("Fehler beim Hinzufügen der Bonusstufe");
  } else {
    newLevel.value = {
      level_name: "",
      min_points: 0,
      hourly_wage_bronze: null,
      hourly_wage_silver: null,
      hourly_wage_gold: null,
      hourly_wage_shiftleader: null,
    };
    showLevelForm.value = false;
    fetchBonusLevels();
    showSuccess("Bonusstufe erfolgreich hinzugefügt");
  }
};

const updateBonusLevel = async (level) => {
  if (!levelChanged.value) {
    editingLevel.value = null;
    return;
  }

  // Format effective_from using date-fns if it exists
  let formattedEffectiveFrom = level.effective_from;
  if (formattedEffectiveFrom) {
    try {
      // Parse the date and format it properly
      const parsedDate = parseISO(formattedEffectiveFrom);
      formattedEffectiveFrom = format(parsedDate, "yyyy-MM-dd");
    } catch (e) {
      console.error("Error formatting effective_from date:", e);
    }
  }

  // Ensure historical values are properly processed
  const historicalValues = Array.isArray(level.historical_values)
    ? level.historical_values.map((entry) => ({
        ...entry,
        min_points: Number(entry.min_points) || 0,
        hourly_wage_bronze: Number(entry.hourly_wage_bronze) || null,
        hourly_wage_silver: Number(entry.hourly_wage_silver) || null,
        hourly_wage_gold: Number(entry.hourly_wage_gold) || null,
        hourly_wage_shiftleader: Number(entry.hourly_wage_shiftleader) || null,
      }))
    : [];

  const levelToUpdate = {
    ...level,
    modified_at: new Date(),
    city_id: currentCityId.value,
    effective_from: formattedEffectiveFrom,
    historical_values: sortHistoricalValues(historicalValues),
    min_points: Number(level.min_points) || 0,
    hourly_wage_bronze: Number(level.hourly_wage_bronze) || null,
    hourly_wage_silver: Number(level.hourly_wage_silver) || null,
    hourly_wage_gold: Number(level.hourly_wage_gold) || null,
    hourly_wage_shiftleader: Number(level.hourly_wage_shiftleader) || null,
  };

  const { error } = await supabase
    .from("bonus_levels")
    .update(levelToUpdate)
    .eq("id", level.id);

  if (error) {
    console.error(error);
    showError("Fehler beim Aktualisieren der Bonusstufe");
  } else {
    fetchBonusLevels();
    showSuccess("Bonusstufe aktualisiert");
    editingLevel.value = null;
    levelChanged.value = false;
  }
};

const deleteBonusLevel = async (id) => {
  const { error } = await supabase
    .from("bonus_levels")
    .update({ deleted_at: new Date().toISOString() })
    .eq("id", id);

  if (error) {
    console.error(error);
    showError("Fehler beim Löschen der Bonusstufe");
  } else {
    fetchBonusLevels();
    showSuccess("Bonusstufe gelöscht");
  }
};

// New version dialog functions
const showNewVersionDialog = (level) => {
  newVersionItem.value = level;
  const today = new Date();

  // Get the end of the current quarter
  const quarterEnd = lastDayOfQuarter(today);
  oldVersionEndDate.value =
    level.effective_to ?? format(quarterEnd, "yyyy-MM-dd");

  // Set new version date to day after quarter end
  const newVersionDate = addDays(quarterEnd, 1);
  newVersionEffectiveDate.value = format(newVersionDate, "yyyy-MM-dd");

  newVersionDialog.value = true;
};

// Create new version function
const createNewVersion = async () => {
  if (!newVersionItem.value) return;

  const level = newVersionItem.value;

  // Create historical entry with current values
  const historicalEntry = {
    effective_from: level.effective_from || format(new Date(), "yyyy-MM-dd"),
    effective_to: oldVersionEndDate.value,
    min_points: Number(level.min_points) || 0,
    hourly_wage_bronze: Number(level.hourly_wage_bronze) || null,
    hourly_wage_silver: Number(level.hourly_wage_silver) || null,
    hourly_wage_gold: Number(level.hourly_wage_gold) || null,
    hourly_wage_shiftleader: Number(level.hourly_wage_shiftleader) || null,
  };

  // Ensure historical_values is an array
  if (!level.historical_values) {
    level.historical_values = [];
  } else if (typeof level.historical_values === "string") {
    try {
      level.historical_values = JSON.parse(level.historical_values);
    } catch (e) {
      console.error("Error parsing historical values:", e);
      level.historical_values = [];
    }
  }

  // Add to history
  level.historical_values.push(historicalEntry);

  // Sort historical values chronologically
  level.historical_values = sortHistoricalValues(level.historical_values);

  // Set new effective date for current item
  level.effective_from = newVersionEffectiveDate.value;

  // Update the item with ONLY the versioning info, not the current values
  const updateData = {
    historical_values: level.historical_values,
    effective_from: newVersionEffectiveDate.value,
    modified_at: new Date(),
  };

  const { error } = await supabase
    .from("bonus_levels")
    .update(updateData)
    .eq("id", level.id);

  if (error) {
    console.error(error);
    showError(`Fehler beim Erstellen der neuen Version: ${error.message}`);
  } else {
    showSuccess("Neue Version erstellt");
    fetchBonusLevels();
    newVersionDialog.value = false;
  }
};

watch(selectedCityTab, () => {
  fetchBonusLevels();
});

onMounted(() => {
  fetchBonusLevels();
});
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
