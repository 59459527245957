<template>
  <v-card class="ma-2">
    <v-tabs v-model="tab">
      <v-tab value="fields">Felder</v-tab>
      <v-tab value="documents">Dokumente</v-tab>
      <v-tab value="templates">Vorlagen</v-tab>
      <v-tab value="onboarding">Onboarding</v-tab>
      <v-tab value="offboarding">Offboarding</v-tab>
      <v-tab value="fileTemplates">Dateivorlagen</v-tab>
      <v-tab value="employeeCheck">Mitarbeiter Check</v-tab>
      <v-tab value="companyDates">Jahresplan</v-tab>
      <v-tab value="materialManagement">Material Management</v-tab>
      <v-tab value="freeWishes">Freiwunsch Deadlines</v-tab>
      <v-tab value="bonus">Bonus</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="fields">
          <Fields></Fields>
        </v-window-item>
        <v-window-item value="documents">
          <Documents></Documents>
        </v-window-item>
        <v-window-item value="templates">
          <Templates></Templates>
        </v-window-item>
        <v-window-item value="onboarding">
          <OnOffboarding type="onboarding"></OnOffboarding>
        </v-window-item>
        <v-window-item value="offboarding">
          <OnOffboarding type="offboarding"></OnOffboarding>
        </v-window-item>
        <v-window-item value="fileTemplates">
          <FileTemplates></FileTemplates>
        </v-window-item>
        <v-window-item value="employeeCheck">
          <EmployeeCheck></EmployeeCheck>
        </v-window-item>
        <v-window-item value="companyDates">
          <CompanyDates></CompanyDates>
        </v-window-item>
        <v-window-item value="materialManagement">
          <MaterialManagement></MaterialManagement>
        </v-window-item>
        <v-window-item value="freeWishes">
          <FreeWishDeadlines />
        </v-window-item>
        <v-window-item value="bonus">
          <BonusConfig />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Fields from "@/components/Settings/Employees/Fields.vue";
import Documents from "@/components/Settings/Employees/Documents.vue";
import OnOffboarding from "@/components/Settings/Employees/OnOffboarding.vue";
import FileTemplates from "@/components/Settings/Employees/FileTemplates.vue";
import EmployeeCheck from "@/components/Settings/Employees/EmployeeCheck.vue";
import CompanyDates from "@/components/Settings/Employees/CompanyDates.vue";
import Templates from "@/components/Settings/Employees/Templates.vue";
import MaterialManagement from "@/components/Settings/Employees/MaterialManagement.vue";
import FreeWishDeadlines from "@/components/Settings/Employees/FreeWishDeadlines.vue";
import BonusConfig from "@/components/Settings/Employees/BonusConfig.vue";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "contractEndInfo",
    };
  },
  methods: {},
  async mounted() {},
};
</script>
