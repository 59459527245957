<template>
  <!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
  <!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
  <v-row>
    <v-col cols="12" class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-expansion-panels>
            <v-expansion-panel title="Dateien">
              <v-expansion-panel-text>
                <FileComponent
                  v-if="candidateInfo.id"
                  bucket="candidates"
                  :id="candidateInfo.id"
                ></FileComponent>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="6">
          <v-pagination
            :length="files.length"
            v-model="page"
            @click="getChosenFile(page)"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-progress-linear
        v-if="loading"
        height="10"
        indeterminate
      ></v-progress-linear>
      <v-col align="right" class="mb-n3 mt-n10">
        <v-btn @click="openFileInNewTab" color="blue-grey" v-if="this.file">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-col>
      <vue-pdf-app
        v-if="fileUrl"
        style="height: 100vh"
        :pdf="fileUrl"
      ></vue-pdf-app>
    </v-col>
  </v-row>
</template>

<script setup>
import { mapState } from "vuex";
import VuePdfApp from "vue3-pdf-app";
import FileComponent from "@/components/CommonComponents/Files.vue";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";
const props = defineProps({
  candidateInfo: "Object",
});
</script>
<script>
export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      fileUrl: null,
      loading: false,
      index: 0,
      files: [],
      page: 1,
      file: null,
    };
  },
  computed: {
    ...mapState({
      cvDataObject: (state) => state.cvDataObject,
    }),
  },
  watch: {
    async cvDataObject(newValue, oldValue) {
      if (newValue) {
        this.getPreviewFile();
      }
    },
  },
  methods: {
    async getChosenFile(page) {
      this.index = page - 1;
      this.getPreviewFile();
    },
    async openFileInNewTab() {
      if (!this.file) return;

      const queryObject = {
        bucket: "candidates",
        file: this.file,
      };

      try {
        const fileUrl = await this.$store.dispatch(
          "getStorageFileLink",
          queryObject,
        );
        window.open(fileUrl, "_blank");
      } catch (error) {
        console.error("Error opening file:", error);
      }
    },
    async getPreviewFile() {
      this.loading = true;
      var bucket = "candidates";
      var folder = this.candidateInfo.id;
      var files = await this.$store.dispatch("getStorageFolderFiles", {
        bucket: "candidates",
        folder: folder,
      });
      this.files = files.data;
      if (files.data.length > 0) {
        this.file = folder + "/" + files.data[this.index].name;
        var queryObject = {
          bucket: bucket,
          file: this.file,
        };
        var fileUrl = await this.$store.dispatch(
          "downloadStorageFile",
          queryObject,
        );
        this.fileUrl = fileUrl;
      }
      this.loading = false;
    },
  },
  async created() {
    this.getPreviewFile();
  },
};
</script>
