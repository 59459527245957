<template>
  <div class="bonus-level-progress">
    <v-card variant="outlined" class="mb-4">
      <v-card-title class="text-h6 d-flex justify-space-between align-center">
        <span>Bonuslevel-Fortschritt</span>
        <v-chip
          v-if="currentPosition && !isPenalty"
          :color="getPositionColor(currentPosition)"
          class="ml-2"
        >
          {{ currentPosition }}
        </v-chip>
        <v-chip v-if="isPenalty" color="error" class="ml-2"> Gesperrt </v-chip>
      </v-card-title>

      <v-card-text>
        <div v-if="loading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else-if="error" class="text-center error--text">
          {{ error }}
        </div>
        <div v-else>
          <!-- Show penalty overlay when penalty is active -->
          <div v-if="isPenalty" class="penalty-message mb-4">
            <v-icon color="error" size="large" class="mr-2">mdi-lock</v-icon>
            <span class="text-body-1"
              >Bonus gesperrt aufgrund von Malus-Ereignis</span
            >
          </div>

          <!-- Current wage info -->
          <div class="d-flex align-center mb-3">
            <span class="text-subtitle-2">Stundenlohn:</span>
            <span class="font-weight-bold mx-2">{{ currentHourlyWage }}€</span>
            <span
              v-if="showPotentialWage && !isPenalty"
              class="text-caption potential-wage"
            >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">
                    (Potenziell: {{ potentialHourlyWage }}€
                    <v-icon size="small" color="amber-darken-2" class="mb-1"
                      >mdi-star</v-icon
                    >
                    )</span
                  >
                </template>
                <span>Mit anstehenden Monatsboni</span>
              </v-tooltip>
            </span>
          </div>

          <!-- Bonus levels progress visualization with optional penalty overlay -->
          <div class="level-progress-container position-relative mb-4">
            <div class="level-track">
              <div
                v-for="(level, index) in sortedLevels"
                :key="index"
                class="level-segment"
                :class="{
                  'level-reached': totalPoints >= level.min_points,
                }"
                :style="{
                  width: `${getLevelSegmentWidth(level)}%`,
                  backgroundColor: isPenalty ? '#ccc' : getLevelColor(index),
                }"
              ></div>
            </div>

            <!-- Penalty overlay on progress bar -->
            <div v-if="isPenalty" class="progress-penalty-overlay">
              <v-icon color="error" size="x-large" class="lock-icon"
                >mdi-lock</v-icon
              >
            </div>

            <!-- Current points indicator -->
            <div
              v-if="!isPenalty"
              class="points-indicator"
              :style="{
                left: `${getPointsIndicatorPosition()}%`,
              }"
            >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <div class="indicator-dot" v-bind="props"></div>
                </template>
                <span>{{ totalPoints }} Punkte</span>
              </v-tooltip>
            </div>

            <!-- Potential points indicator (if applicable) -->
            <div
              v-if="showPotentialPoints && !isPenalty"
              class="points-indicator potential-indicator"
              :style="{
                left: `${getPotentialPointsIndicatorPosition()}%`,
              }"
            >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <div class="potential-indicator-dot" v-bind="props">
                    <span class="potential-star">⭐</span>
                  </div>
                </template>
                <span
                  >{{ potentialPoints }} Punkte (inkl.
                  {{ potentialPoints - totalPoints }} anstehend)</span
                >
              </v-tooltip>

              <!-- Dotted line connecting current and potential points -->
              <div
                class="connector-line"
                :style="{
                  width: `${Math.abs(getPointsIndicatorPosition() - getPotentialPointsIndicatorPosition())}%`,
                }"
              ></div>
            </div>
          </div>

          <!-- Level markers -->
          <div class="d-flex justify-space-between">
            <div
              v-for="(level, index) in sortedLevels"
              :key="index"
              class="text-caption text-center"
              :style="{
                width: `${getLevelSegmentWidth(level)}%`,
                fontWeight:
                  totalPoints >= level.min_points && !isPenalty
                    ? 'bold'
                    : 'normal',
                opacity: isPenalty ? '0.7' : '1',
              }"
              :class="{
                'potential-level':
                  potentialPoints >= level.min_points &&
                  totalPoints < level.min_points &&
                  !isPenalty,
              }"
            >
              {{ level.level_name }}
              <div>{{ level.min_points }} Punkte</div>
              <v-icon
                v-if="
                  potentialPoints >= level.min_points &&
                  totalPoints < level.min_points &&
                  !isPenalty
                "
                size="small"
                color="amber-darken-2"
                class="potential-icon"
              >
                mdi-star
              </v-icon>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { format, parseISO, isAfter, isBefore, isEqual } from "date-fns";

export default {
  name: "BonusLevelProgress",
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    totalPoints: {
      type: Number,
      required: true,
    },
    potentialPoints: {
      type: Number,
      default: null,
    },
    isPenalty: {
      type: Boolean,
      default: false,
    },
    employeeCityId: {
      type: Number,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      currentPosition: null, // Bronze, Silber, Gold, etc.
      bonusLevels: [],
      localSelectedDate: this.selectedDate,

      // Constants
      EMPLOYEE_DATA_MATCHING_POSITION_ID: 7, // ID for employee position
      BERLIN_CITY_ID: 2, // ID for Berlin city

      // Position mapping
      positionMap: {
        Bronze: "bronze",
        Silber: "silver",
        Gold: "gold",
        Shiftleader: "shiftleader",
      },
    };
  },
  computed: {
    // Filter levels based on the selected date
    applicableLevels() {
      if (!this.bonusLevels.length) return [];
      const result = [];

      // Safely parse the selected date, defaulting to today if invalid
      const date = this.localSelectedDate;

      // Process each bonus level
      this.bonusLevels.forEach((level) => {
        // Check if current level is applicable
        const effectiveFrom = parseISO(level.effective_from);

        if (isEqual(date, effectiveFrom) || isAfter(date, effectiveFrom)) {
          result.push({ ...level });
        }

        // Check historical values if present
        if (level.historical_values && Array.isArray(level.historical_values)) {
          level.historical_values.forEach((history) => {
            const historyFrom = parseISO(history.effective_from);
            const historyTo = history.effective_to
              ? parseISO(history.effective_to)
              : null;

            if (
              (isEqual(date, historyFrom) || isAfter(date, historyFrom)) &&
              (!historyTo ||
                isEqual(date, historyTo) ||
                isBefore(date, historyTo))
            ) {
              // Create a merged level from history data
              const historicalLevel = {
                ...level,
                min_points: history.min_points,
                hourly_wage_bronze: history.hourly_wage_bronze,
                hourly_wage_silver: history.hourly_wage_silver,
                hourly_wage_gold: history.hourly_wage_gold,
                hourly_wage_shiftleader: history.hourly_wage_shiftleader,
                level_name: level.level_name, // Keep original name
                id: level.id, // Keep original ID
                effective_from: history.effective_from,
                effective_to: history.effective_to,
              };
              result.push(historicalLevel);
            }
          });
        }
      });

      return result;
    },

    sortedLevels() {
      return [...this.applicableLevels].sort(
        (a, b) => a.min_points - b.min_points,
      );
    },

    // Rest of computed properties remain the same but work with sortedLevels
    maxPoints() {
      if (!this.sortedLevels.length) return 0;
      // Get the highest min_points + 50 as a maximum value for visualization
      return (
        Math.max(...this.sortedLevels.map((level) => level.min_points)) + 50
      );
    },
    currentLevel() {
      if (!this.sortedLevels.length) return null;

      // Find the highest level that's been reached
      for (let i = this.sortedLevels.length - 1; i >= 0; i--) {
        if (this.totalPoints >= this.sortedLevels[i].min_points) {
          return this.sortedLevels[i];
        }
      }
      return null;
    },
    currentHourlyWage() {
      if (!this.sortedLevels.length) return "-";

      // During penalty, show first level's wage regardless of points
      if (this.isPenalty && this.sortedLevels.length > 0) {
        const firstLevel = this.sortedLevels[0];
        const positionKey = `hourly_wage_${this.getPositionKey()}`;
        const wage = firstLevel[positionKey];
        return wage ? wage.toFixed(2) : "-";
      }

      if (!this.currentLevel || !this.currentPosition) return "-";

      const positionKey = `hourly_wage_${this.getPositionKey()}`;
      const wage = this.currentLevel[positionKey];

      return wage ? wage.toFixed(2) : "-";
    },
    showPotentialPoints() {
      return (
        !this.isPenalty &&
        this.potentialPoints !== null &&
        this.potentialPoints > this.totalPoints
      );
    },

    potentialLevel() {
      if (!this.showPotentialPoints || !this.sortedLevels.length) return null;

      // Find the highest level that could be reached with potential points
      for (let i = this.sortedLevels.length - 1; i >= 0; i--) {
        if (this.potentialPoints >= this.sortedLevels[i].min_points) {
          return this.sortedLevels[i];
        }
      }
      return null;
    },

    showPotentialWage() {
      return (
        this.showPotentialPoints &&
        this.potentialLevel &&
        this.potentialLevel !== this.currentLevel
      );
    },

    potentialHourlyWage() {
      if (!this.potentialLevel || !this.currentPosition) return "-";

      const positionKey = `hourly_wage_${this.getPositionKey()}`;
      const wage = this.potentialLevel[positionKey];

      return wage ? wage.toFixed(2) : "-";
    },
  },
  methods: {
    async fetchEmployeePosition() {
      try {
        const response = await this.$store.dispatch("getSupabaseEntity", {
          table: "employee_data_matching",
          select: "value_text",
          where: [
            { type: "eq", key: "employee_id", value: this.employeeId },
            {
              type: "eq",
              key: "key_id",
              value: this.EMPLOYEE_DATA_MATCHING_POSITION_ID,
            },
          ],
        });

        if (response.data && response.data.length > 0) {
          this.currentPosition = response.data[0].value_text;
        }
      } catch (error) {
        console.error("Error fetching employee position:", error);
        this.error = "Fehler beim Laden der Mitarbeiterposition";
      }
    },

    // Currenty only Hamburg and Berlin are supported
    async fetchBonusLevels() {
      try {
        const response = await this.$store.dispatch("getSupabaseEntity", {
          table: "bonus_levels",
          where: [
            { type: "eq", key: "city_id", value: this.employeeCityId },
            { type: "is", key: "deleted_at", value: null },
          ],
          order: { key: "min_points", ascending: true },
        });

        // Process the data to ensure historical_values is properly formatted
        if (response.data && response.data.length > 0) {
          this.bonusLevels = response.data.map((level) => {
            // Parse historical_values if it's a string
            if (
              level.historical_values &&
              typeof level.historical_values === "string"
            ) {
              try {
                level.historical_values = JSON.parse(level.historical_values);
              } catch (e) {
                console.error("Error parsing historical values:", e);
                level.historical_values = [];
              }
            } else if (!level.historical_values) {
              level.historical_values = [];
            }

            return level;
          });
        } else {
          this.bonusLevels = [];
        }
      } catch (error) {
        console.error("Error fetching bonus levels:", error);
        this.error = "Fehler beim Laden der Bonuslevel";
      }
    },

    getLevelSegmentWidth(level) {
      if (!this.sortedLevels.length) return 0;

      const index = this.sortedLevels.indexOf(level);
      const nextIndex = index + 1;

      if (nextIndex >= this.sortedLevels.length) {
        const lastLevelPoints = level.min_points;
        const totalRange = this.maxPoints - this.sortedLevels[0].min_points;
        const lastLevelRange = this.maxPoints - lastLevelPoints;

        return (lastLevelRange / totalRange) * 100;
      }

      const thisPoints = level.min_points;
      const nextPoints = this.sortedLevels[nextIndex].min_points;
      const range = nextPoints - thisPoints;
      const totalRange = this.maxPoints - this.sortedLevels[0].min_points;

      return (range / totalRange) * 100;
    },

    getPointsIndicatorPosition() {
      if (!this.maxPoints || !this.sortedLevels.length) return 0;

      const minPoints = this.sortedLevels[0].min_points;
      const fullRange = this.maxPoints - minPoints;
      const pointsInRange = Math.max(0, this.totalPoints - minPoints);

      // Ensure percentage is between 0-100
      return Math.max(0, Math.min((pointsInRange / fullRange) * 100, 100));
    },

    getPotentialPointsIndicatorPosition() {
      if (
        !this.showPotentialPoints ||
        !this.maxPoints ||
        !this.sortedLevels.length
      )
        return 0;

      const minPoints = this.sortedLevels[0].min_points;
      const fullRange = this.maxPoints - minPoints;
      const pointsInRange = Math.max(0, this.potentialPoints - minPoints);

      return Math.max(0, Math.min((pointsInRange / fullRange) * 100, 100));
    },

    getLevelColor(index) {
      // Simple color scheme for different levels
      const colors = [
        "#4CAF50", // Green
        "#8BC34A", // Light Green
        "#CDDC39", // Lime
        "#FFEB3B", // Yellow
        "#FFC107", // Amber
        "#FF9800", // Orange
        "#FF5722", // Deep Orange
      ];

      return colors[index % colors.length];
    },

    getPositionColor(position) {
      const positionColors = {
        Bronze: "amber-darken-1",
        Silber: "grey-lighten-1",
        Gold: "amber-accent-3",
        Shiftleader: "purple-lighten-1",
      };

      return positionColors[position] || "primary";
    },

    async fetchAllData() {
      this.loading = true;
      this.error = null;

      try {
        await this.fetchEmployeePosition();
        await this.fetchBonusLevels();
      } catch (error) {
        console.error("Error initializing bonus level data:", error);
        this.error = "Fehler beim Laden der Bonusdaten";
      } finally {
        this.loading = false;
      }
    },

    getPositionKey() {
      if (!this.currentPosition) return "bronze";
      return (
        this.positionMap[this.currentPosition] ||
        this.currentPosition.toLowerCase()
      );
    },
  },
  mounted() {
    this.fetchAllData();
  },
  watch: {
    employeeId() {
      // Reload data if employee ID changes
      this.fetchAllData();
    },
    selectedDate(newDate) {
      this.localSelectedDate = newDate;
      this.fetchAllData();
    },
  },
};
</script>

<style scoped>
.bonus-level-progress {
  margin-bottom: 24px;
}

.level-progress-container {
  height: 24px;
  margin: 30px 0;
}

.level-track {
  display: flex;
  width: 100%;
  height: 12px;
  background-color: #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
}

.level-segment {
  height: 100%;
  transition: background-color 0.3s ease;
}

.level-reached {
  filter: brightness(100%);
}

.level-not-reached {
  filter: brightness(70%);
  opacity: 0.6;
}

.points-indicator {
  position: absolute;
  top: -5px;
  transform: translateX(-50%);
}

.indicator-dot {
  width: 20px;
  height: 20px;
  background-color: #1867c0;
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}

/* Styling for potential indicator */
.potential-indicator {
  top: -8px;
}

.potential-indicator-dot {
  width: 22px;
  height: 22px;
  margin-top: 3px;
  background-color: #ffc107;
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  animation: glow 2s infinite alternate;
}

.potential-star {
  margin-left: 1px;
  font-size: 12px;
}

.connector-line {
  position: absolute;
  height: 2px;
  background: repeating-linear-gradient(
    to right,
    #ffc107 0px,
    #ffc107 5px,
    transparent 5px,
    transparent 8px
  );
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
  z-index: 1;
}

.potential-level {
  color: #ff9800;
  position: relative;
  animation: pulse 2s infinite alternate;
}

.potential-icon {
  position: absolute;
  top: -12px;
  right: 50%;
  transform: translateX(50%);
  animation: bounce 2s infinite;
}

.potential-wage {
  color: #ff9800;
  font-weight: bold;
  animation: pulse 2s infinite alternate;
}

/* Animations */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ffc107;
  }
  100% {
    box-shadow:
      0 0 15px #ffc107,
      0 0 20px #ff9800;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateX(50%) translateY(0);
  }
  50% {
    transform: translateX(50%) translateY(-5px);
  }
}

/* Add new penalty styles */
.penalty-track {
  filter: grayscale(100%);
  opacity: 0.4;
}

.level-penalty {
  background-color: #ccc !important;
}

.penalty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(244, 67, 54, 0.1);
  border-radius: 4px;
  border-left: 4px solid #f44336;
}

.progress-penalty-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: -12px;
  left: 0;
}

.lock-icon {
  margin-left: -10px;
}

/* Add styles for date selector */
.date-selector {
  max-width: 180px;
}
</style>
