<template>
  <v-card variant="tonal" color="light-blue-darken-4" rounded="lg">
    <v-card-title class="d-flex align-center">
      <v-icon class="mr-2">mdi-currency-eur</v-icon>
      Bonus
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        density="default"
        variant="tonal"
        prepend-icon="mdi-close-circle"
        class="mr-2"
        size="small"
        @click="toggleBonus"
      >
        Bonus deaktivieren
      </v-btn>
      <ManualEventsDialog
        :preselected-employee-id="employeeId"
        :preselected-date="new Date().toISOString().substr(0, 10)"
        :refresh-data="fetchBonusData"
      />
    </v-card-title>
    <v-card-text>
      <v-row v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="blue-grey-darken-2"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-else-if="!hasBonusSystem">
        <v-col cols="12">
          <v-alert
            type="info"
            icon="mdi-information"
            variant="tonal"
            border="start"
            prominent
          >
            <div class="d-flex align-center">
              <div>
                <div class="text-h6">Kein Bonussystem verfügbar</div>
                <div>
                  Für {{ cityName }} ist derzeit kein Bonussystem implementiert.
                </div>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-row>

      <template v-else>
        <v-row class="d-flex align-center">
          <v-col cols="8" md="6">
            <v-select
              v-model="chosenDate"
              :items="monthFilter"
              label="Datum"
              @update:model-value="onMonthChange"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="4" md="6" class="d-flex justify-end">
            <v-checkbox
              v-model="usePublicShifts"
              label="Nur veröffentlichte Schichten"
              hide-details
              class="mt-0"
              @change="fetchBonusData"
            />
          </v-col>
        </v-row>

        <!-- Penalty Banner - show when penalty is active -->
        <v-row v-if="hasPenalty" class="mt-2">
          <v-col cols="12">
            <v-alert
              type="error"
              icon="mdi-block-helper"
              variant="tonal"
              border="start"
              prominent
            >
              <div class="d-flex align-center">
                <div>
                  <div class="text-h6">Bonussperre aktiv</div>
                  <div>
                    Bonus gesperrt bis {{ formatDate(penaltyEndDate) }}. Grund:
                    {{ penaltyBonusName }}
                  </div>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="blue-grey-darken-2"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-else-if="calculationDone">
          <v-col cols="12">
            <v-card class="mb-4" variant="outlined">
              <v-card-title class="text-h6">
                <div class="d-flex align-center mb-4">
                  <div class="text-h6 font-weight-bold">
                    Gesamtbonus:
                    {{ isCurrentMonth ? confirmedTotalPoints : totalPoints }}
                    Punkte
                  </div>
                  <v-chip
                    v-if="isCurrentMonth && pendingTotalPoints > 0"
                    size="small"
                    color="amber-darken-1"
                    class="ml-2"
                  >
                    + {{ pendingTotalPoints }} Punkte anstehend
                  </v-chip>
                </div>
              </v-card-title>
              <v-card-text>
                <v-row class="mb-2">
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <div class="text-subtitle-1 font-weight-bold">
                      Bonus:
                      {{
                        isCurrentMonth
                          ? confirmedPositivePoints
                          : positivePoints
                      }}
                      Punkte
                    </div>
                    <v-chip
                      v-if="isCurrentMonth && pendingPositivePoints > 0"
                      size="small"
                      color="amber-darken-1"
                      class="ml-2"
                    >
                      + {{ pendingPositivePoints }} Punkte anstehend
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="text-subtitle-1 font-weight-bold">
                      Malus:
                      {{
                        isCurrentMonth
                          ? confirmedNegativePoints
                          : negativePoints
                      }}
                      Punkte
                    </div>
                    <v-chip
                      v-if="isCurrentMonth && pendingNegativePoints > 0"
                      size="small"
                      color="amber-darken-1"
                      class="ml-2"
                    >
                      + {{ pendingNegativePoints }} Punkte anstehend
                    </v-chip>
                  </v-col>
                </v-row>

                <BonusLevelProgress
                  v-if="calculationDone && employeeCityId"
                  :employee-id="employeeId"
                  :total-points="
                    isCurrentMonth ? confirmedTotalPoints : totalPoints
                  "
                  :potential-points="isCurrentMonth ? totalPoints : null"
                  :is-penalty="hasPenalty"
                  :employee-city-id="employeeCityId"
                  :selected-date="progressLevelDate"
                />
              </v-card-text>
            </v-card>

            <!-- Shift Bonus Details -->
            <v-expansion-panels v-model="expandedPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Schichtboni ({{
                    isCurrentMonth ? confirmedShiftBonusTotal : shiftBonusTotal
                  }}
                  Punkte)
                  <v-chip
                    v-if="isCurrentMonth && pendingShiftBonusTotal > 0"
                    size="small"
                    color="amber-darken-1"
                    class="ml-2"
                  >
                    + {{ pendingShiftBonusTotal }} Punkte anstehend
                  </v-chip>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <!-- Shift summary statistics -->
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="3">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold">
                              {{ shiftsWithBonus.length }}
                            </div>
                            <div class="text-caption">Schichten gesamt</div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold text-success">
                              {{ weekendShiftsCount }}
                            </div>
                            <div class="text-caption">Wochenendschichten</div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold">
                              {{ seniorShiftsCount }}
                            </div>
                            <div class="text-caption">Senior-Schichten</div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold">
                              {{ averagePointsPerShift.toFixed(1) }}
                            </div>
                            <div class="text-caption">Ø Punkte pro Schicht</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- Points Summary Table -->
                  <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1">
                      <v-icon class="mr-2">mdi-chart-bar</v-icon>
                      Punkteübersicht nach Kategorien
                    </v-card-title>
                    <v-card-text>
                      <v-table density="compact">
                        <thead>
                          <tr>
                            <th class="text-left">Punkte</th>
                            <th
                              v-for="(category, index) in pointCategories"
                              :key="index"
                              class="text-right"
                            >
                              {{ category.label }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="grey lighten-4 font-weight-bold">
                            <td>Summe</td>
                            <td
                              v-for="(category, index) in pointCategories"
                              :key="`sum-${index}`"
                              class="text-right"
                            >
                              {{ category.totalPoints }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="primary lighten-5">
                            <td class="font-weight-bold">Gesamtsumme</td>
                            <td
                              class="text-right font-weight-bold"
                              :colspan="pointCategories.length"
                            >
                              {{ totalPointsSum }}
                            </td>
                          </tr>
                        </tfoot>
                      </v-table>
                    </v-card-text>
                  </v-card>

                  <!-- Original list of shifts -->
                  <v-list>
                    <v-list-item
                      v-for="(shift, index) in shiftsWithBonus"
                      :key="index"
                    >
                      <v-list-item-title>
                        {{ formatDate(shift.date) }} - {{ shift.hotel_name }}
                        <span
                          v-if="shift.isTraining"
                          class="text-caption text-info ml-2"
                        >
                          (Einarbeitung)
                        </span>
                        <v-chip
                          v-if="isCurrentMonth && isFutureDate(shift.date)"
                          size="small"
                          color="amber-darken-1"
                          class="ml-2"
                        >
                          Anstehend
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ shift.is_senior ? "Senior" : "Clerk" }} +
                        {{ shift.points }} Punkte
                        <v-chip
                          v-if="shift.usedSeniorBonus && !shift.is_senior"
                          size="small"
                          color="purple"
                          class="ml-2"
                        >
                          Doppelbesetzungsbonus
                        </v-chip>
                        <v-chip
                          v-if="shift.isWeekend"
                          size="small"
                          color="success"
                          class="ml-2"
                        >
                          Wochenende +{{ shift.weekendBonus }} Punkte
                        </v-chip>
                        <v-chip
                          v-if="shift.isExternalCity"
                          size="small"
                          color="info"
                          class="ml-2"
                        >
                          Außenstandort +{{ shift.externalCityBonus }} Punkte
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <!-- Manual Events -->
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Manuelle Bonusereignisse ({{
                    isCurrentMonth
                      ? confirmedManualEventsTotal
                      : manualEventsTotal
                  }}
                  Punkte)
                  <v-chip
                    v-if="isCurrentMonth && pendingManualEventsTotal !== 0"
                    size="small"
                    color="amber-darken-1"
                    class="ml-2"
                  >
                    + {{ pendingManualEventsTotal }} Punkte anstehend
                  </v-chip>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list>
                    <v-list-item v-for="event in manualEvents" :key="event.id">
                      <v-list-item-title>
                        {{ event.bonus_name }}
                        <v-tooltip v-if="event.comment" location="top">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              size="x-small"
                              color="info"
                              class="ml-1"
                              v-bind="props"
                            >
                              mdi-comment-text-outline
                            </v-icon>
                          </template>
                          {{ event.comment }}
                        </v-tooltip>
                        <v-chip
                          v-if="
                            isCurrentMonth && isFutureDate(event.event_date)
                          "
                          size="small"
                          color="amber-darken-1"
                          class="ml-2"
                        >
                          Anstehend
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatDate(event.event_date) }}
                        <span
                          :class="
                            event.is_positive ? 'text-success' : 'text-error'
                          "
                        >
                          {{ event.is_positive ? "+" : "-"
                          }}{{
                            event.points + (Number(event.extra_points) || 0)
                          }}
                          Punkte
                        </span>
                        <v-icon
                          size="x-small"
                          color="grey"
                          class="ml-2"
                          @click.stop="confirmDeleteEvent(event)"
                          title="Ereignis löschen"
                        >
                          mdi-delete
                        </v-icon>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <!-- Automatic Shift and Absence Events -->
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Schicht- und Anwesenheitsereignisse ({{
                    isCurrentMonth ? confirmedAutoEventsTotal : autoEventsTotal
                  }}
                  Punkte)
                  <v-chip
                    v-if="isCurrentMonth && pendingAutoEventsTotal !== 0"
                    size="small"
                    color="amber-darken-1"
                    class="ml-2"
                  >
                    + {{ pendingAutoEventsTotal }} Punkte anstehend
                  </v-chip>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <!-- Add event summary statistics -->
                  <v-card
                    variant="flat"
                    class="mb-4 pa-2"
                    color="grey-lighten-5"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold">
                              {{ totalAutomaticEventCount }}
                            </div>
                            <div class="text-caption">Ereignisse gesamt</div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold text-success">
                              {{ positiveAutomaticEventCount }}
                            </div>
                            <div class="text-caption">Positive Ereignisse</div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <div class="text-center">
                            <div class="text-h5 font-weight-bold text-error">
                              {{ negativeAutomaticEventCount }}
                            </div>
                            <div class="text-caption">Negative Ereignisse</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- Group events by type with expanded details -->
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(eventGroup, index) in automaticEvents"
                      :key="index"
                      :value="index"
                    >
                      <v-expansion-panel-title>
                        <div
                          class="d-flex align-center justify-space-between w-100"
                        >
                          <div>
                            <strong>{{ eventGroup.name }}</strong>
                            <v-chip
                              size="x-small"
                              :color="
                                eventGroup.is_positive ? 'success' : 'error'
                              "
                              class="ml-2"
                            >
                              {{ eventGroup.is_positive ? "+" : "-"
                              }}{{ eventGroup.points }} pro Ereignis
                            </v-chip>
                          </div>
                          <div>
                            <v-chip
                              :color="
                                eventGroup.is_positive ? 'success' : 'error'
                              "
                              variant="outlined"
                            >
                              {{ eventGroup.is_positive ? "+" : "-"
                              }}{{
                                eventGroup.points * eventGroup.dates.length
                              }}
                              Punkte gesamt
                            </v-chip>
                            <v-chip
                              size="small"
                              class="ml-2"
                              color="grey-lighten-1"
                            >
                              {{ eventGroup.dates.length }}× vorgekommen
                            </v-chip>
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-list density="compact">
                          <v-list-item
                            v-for="(date, dateIndex) in eventGroup.dates"
                            :key="dateIndex"
                          >
                            <v-list-item-title
                              class="d-flex justify-space-between"
                            >
                              <span>
                                <v-icon
                                  :color="
                                    eventGroup.is_positive ? 'success' : 'error'
                                  "
                                  size="small"
                                  class="mr-2"
                                >
                                  {{
                                    eventGroup.is_positive
                                      ? "mdi-check-circle"
                                      : "mdi-alert-circle"
                                  }}
                                </v-icon>
                                {{ formatDate(date) }}
                                <v-chip
                                  v-if="isCurrentMonth && isFutureDate(date)"
                                  size="x-small"
                                  color="amber-darken-1"
                                  class="ml-2"
                                >
                                  Anstehend
                                </v-chip>
                              </span>
                              <span
                                :class="
                                  eventGroup.is_positive
                                    ? 'text-success'
                                    : 'text-error'
                                "
                              >
                                {{ eventGroup.is_positive ? "+" : "-"
                                }}{{ eventGroup.points }} Punkte
                              </span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <!-- Monthly Bonuses -->
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Monatliche Boni ({{ monthlyBonusTotal }} Punkte)
                  <v-chip
                    v-if="isCurrentMonth && pendingMonthlyBonusPoints > 0"
                    size="small"
                    color="amber-darken-1"
                    class="ml-2"
                  >
                    Anstehend
                  </v-chip>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list>
                    <v-list-item
                      v-for="(bonus, index) in monthlyBonuses"
                      :key="index"
                    >
                      <v-list-item-title>
                        {{ bonus.name }}
                        <v-chip
                          v-if="isCurrentMonth"
                          size="x-small"
                          color="amber-darken-1"
                          class="ml-2"
                        >
                          Anstehend
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span
                          :class="
                            bonus.is_positive ? 'text-success' : 'text-error'
                          "
                        >
                          {{ bonus.is_positive ? "+" : "-"
                          }}{{ bonus.points }} Punkte
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <!-- Employee Checks -->
              <v-expansion-panel v-if="failedChecks.length > 0">
                <v-expansion-panel-title>
                  Mitarbeiterchecks ({{ checkBonusTotal }} Punkte)
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list>
                    <v-list-item v-for="check in failedChecks" :key="check.id">
                      <v-list-item-title>
                        Nichtbestehen des Mitarbeiterchecks
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatDate(check.date) }} - {{ check.hotel_name }}
                        <span class="text-error">
                          -{{ check.points }} Punkte
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="text-center">
            <p>
              Wähle einen Monat aus und klicke auf "Berechnen" um den Bonus zu
              sehen.
            </p>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
  <v-dialog v-model="deleteDialog" max-width="400">
    <v-card>
      <v-card-title>Bonus-Ereignis löschen</v-card-title>
      <v-card-text>
        Möchtest du das Ereignis "{{ eventToDelete?.bonus_name }}" vom
        {{ eventToDelete ? formatDate(eventToDelete.event_date) : "" }} wirklich
        löschen?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="deleteDialog = false"
          >Abbrechen</v-btn
        >
        <v-btn color="red-darken-1" variant="text" @click="deleteEvent"
          >Löschen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  format,
  parse,
  startOfMonth,
  endOfMonth,
  isFriday,
  isSaturday,
  isSunday,
  addMonths,
  startOfDay,
} from "date-fns";
import { de } from "date-fns/locale";
import useSnackbar from "@/utils/useSnackbar";
import BonusLevelProgress from "./BonusLevelProgress.vue";
import ManualEventsDialog from "@/components/Settings/Employees/Bonus/ManualEventsDialog.vue";
import BonusCalculatorService from "@/services/BonusCalculatorService";

export default {
  name: "BonusCalculator",
  components: {
    BonusLevelProgress,
    ManualEventsDialog,
  },
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggleBonus"],
  setup() {
    // Use the global snackbar
    const { showError, showSuccess } = useSnackbar();
    return { showError, showSuccess };
  },
  data() {
    return {
      chosenDate: new Date().toISOString().substr(0, 7),
      menu: false,
      loading: true,
      calculationDone: false,
      expandedPanel: [],
      employeeCityId: null,
      hasBonusSystem: false,
      cityName: "",
      usePublicShifts: false,

      // Bonus data
      shifts: [],
      manualEvents: [],
      dateEvents: [],
      employeeChecks: [],
      bonuses: [],
      employeeHotels: [],

      // Results
      positivePoints: 0,
      negativePoints: 0,
      totalPoints: 0,
      shiftsWithBonus: [],
      automaticEvents: [],
      monthlyBonuses: [],
      failedChecks: [],

      // New data properties for pending calculations
      confirmedPositivePoints: 0,
      confirmedNegativePoints: 0,
      confirmedTotalPoints: 0,
      pendingPositivePoints: 0,
      pendingNegativePoints: 0,
      pendingTotalPoints: 0,

      today: new Date(), // Current date for pending calculations

      // Penalty related data
      hasPenalty: false,
      penaltyBonuses: [],
      penaltyEvents: [],
      penaltyStartDate: null,
      penaltyEndDate: null,
      penaltyMonths: 0,
      penaltyBonusName: "",

      deleteDialog: false,
      eventToDelete: null,
    };
  },
  computed: {
    progressLevelDate() {
      // Get the end of month but set the time to 00:00:00
      const date = parse(this.chosenDate, "yyyy-MM", new Date());
      const endDate = endOfMonth(date);
      return startOfDay(endDate);
    },
    dateFormatted() {
      if (!this.chosenDate) return "";
      const dateObj = parse(this.chosenDate, "yyyy-MM", new Date());
      return format(dateObj, "MMMM yyyy", { locale: de });
    },
    monthFilter() {
      // Start from August 2023
      const startMonth = new Date(2023, 7);
      const currentMonth = new Date();
      const months = [];

      // Add months from August 2023 to current date
      let monthDate = new Date(startMonth);
      while (monthDate <= currentMonth) {
        const value = format(monthDate, "yyyy-MM");
        const text = format(monthDate, "MMMM yyyy", { locale: de });

        months.push({
          value: value,
          title: text,
        });

        monthDate = addMonths(monthDate, 1);
      }

      return months;
    },
    isCurrentMonth() {
      const currentMonth = new Date().toISOString().substr(0, 7);
      return this.chosenDate === currentMonth;
    },
    // Use BonusCalculatorService for all the calculations
    shiftBonusTotal() {
      return BonusCalculatorService.calculateShiftBonusTotal(
        this.shiftsWithBonus,
      );
    },
    manualEventsTotal() {
      return BonusCalculatorService.calculateManualEventsTotal(
        this.manualEvents,
      );
    },
    autoEventsTotal() {
      return BonusCalculatorService.calculateAutomaticEventsTotal(
        this.automaticEvents,
      );
    },
    monthlyBonusTotal() {
      return BonusCalculatorService.calculateMonthlyBonusTotal(
        this.monthlyBonuses,
      );
    },
    checkBonusTotal() {
      return BonusCalculatorService.calculateCheckBonusTotal(this.failedChecks);
    },
    weekendShiftsCount() {
      return BonusCalculatorService.getWeekendShiftsCount(this.shiftsWithBonus);
    },
    seniorShiftsCount() {
      return BonusCalculatorService.getSeniorShiftsCount(this.shiftsWithBonus);
    },
    averagePointsPerShift() {
      return BonusCalculatorService.getAveragePointsPerShift(
        this.shiftsWithBonus,
      );
    },
    pendingMonthlyBonusPoints() {
      return BonusCalculatorService.getPendingMonthlyBonusPoints(
        this.monthlyBonuses,
        this.isCurrentMonth,
      );
    },
    totalAutomaticEventCount() {
      return BonusCalculatorService.getTotalAutomaticEventCount(
        this.automaticEvents,
      );
    },
    positiveAutomaticEventCount() {
      return BonusCalculatorService.getPositiveAutomaticEventCount(
        this.automaticEvents,
      );
    },
    negativeAutomaticEventCount() {
      return BonusCalculatorService.getNegativeAutomaticEventCount(
        this.automaticEvents,
      );
    },
    pendingShiftBonusTotal() {
      return BonusCalculatorService.getPendingShiftBonusTotal(
        this.shiftsWithBonus,
        this.isCurrentMonth,
      );
    },
    confirmedShiftBonusTotal() {
      return BonusCalculatorService.getConfirmedShiftBonusTotal(
        this.shiftsWithBonus,
        this.isCurrentMonth,
      );
    },
    pendingManualEventsTotal() {
      return BonusCalculatorService.getPendingManualEventsTotal(
        this.manualEvents,
        this.isCurrentMonth,
      );
    },
    confirmedManualEventsTotal() {
      return BonusCalculatorService.getConfirmedManualEventsTotal(
        this.manualEvents,
        this.isCurrentMonth,
      );
    },
    pendingAutoEventsTotal() {
      return BonusCalculatorService.getPendingAutoEventsTotal(
        this.automaticEvents,
        this.isCurrentMonth,
      );
    },
    confirmedAutoEventsTotal() {
      return BonusCalculatorService.getConfirmedAutoEventsTotal(
        this.automaticEvents,
        this.isCurrentMonth,
      );
    },
    pointCategories() {
      if (!this.shiftsWithBonus || this.shiftsWithBonus.length === 0) {
        return [];
      }

      // Group shifts by their unique point values
      const pointGroups = {};

      // Process each shift to extract unique point categories
      this.shiftsWithBonus.forEach((shift) => {
        console.timeLog();
        // Get base point value (without weekend/external bonuses)
        const basePoints = Math.abs(shift.points);

        // Add to base points category
        const baseKey = "Base_" + basePoints;
        if (!pointGroups[baseKey]) {
          pointGroups[baseKey] = {
            label: basePoints + " Punkte",
            pointsPerUnit: basePoints,
            totalPoints: 0,
          };
        }
        pointGroups[baseKey].totalPoints += basePoints;

        // Add weekend bonus if applicable
        if (shift.isWeekend && shift.weekendBonus) {
          const weekendKey = "Weekend";
          if (!pointGroups[weekendKey]) {
            pointGroups[weekendKey] = {
              label: "Wochenende",
              pointsPerUnit: shift.weekendBonus,
              totalPoints: 0,
            };
          }
          pointGroups[weekendKey].totalPoints += shift.weekendBonus;
        }

        // Add external city bonus if applicable
        if (shift.isExternalCity && shift.externalCityBonus) {
          const externalKey = "External";
          if (!pointGroups[externalKey]) {
            pointGroups[externalKey] = {
              label: "Außenstandort",
              pointsPerUnit: shift.externalCityBonus,
              totalPoints: 0,
            };
          }
          pointGroups[externalKey].totalPoints += shift.externalCityBonus;
        }
      });

      // Convert to array and sort by point value
      return Object.values(pointGroups).sort(
        (a, b) => a.pointsPerUnit - b.pointsPerUnit,
      );
    },
    totalPointsSum() {
      return this.pointCategories.reduce(
        (sum, category) => sum + category.totalPoints,
        0,
      );
    },
  },
  methods: {
    toggleBonus() {
      this.$emit("toggleBonusEligibility");
    },
    onMonthChange() {
      this.fetchBonusData();
    },
    formatDate(dateString) {
      return BonusCalculatorService.formatDate(dateString);
    },
    async fetchBonusData() {
      if (!this.hasBonusSystem) return;

      this.loading = true;
      this.calculationDone = false;
      this.hasPenalty = false;

      const startDate = startOfMonth(
        parse(this.chosenDate, "yyyy-MM", new Date()),
      );
      const endDate = endOfMonth(startDate);

      try {
        // Use the service to calculate all bonus data
        const bonusData =
          await BonusCalculatorService.calculateBonusForEmployee(
            this.employeeId,
            startDate,
            endDate,
            this.usePublicShifts,
          );

        if (bonusData.error) {
          this.showError(bonusData.error);
          return;
        }

        if (!bonusData.hasBonusSystem) {
          this.hasBonusSystem = false;
          this.cityName = bonusData.cityName;
          this.employeeCityId = bonusData.employeeCityId;
          return;
        }

        // Process the results
        this.hasBonusSystem = bonusData.hasBonusSystem;
        this.cityName = bonusData.cityName;
        this.employeeCityId = bonusData.employeeCityId;
        this.shiftsWithBonus = bonusData.shiftsWithBonus || [];
        this.manualEvents = bonusData.manualEvents || [];
        this.automaticEvents = bonusData.automaticEvents || [];
        this.monthlyBonuses = bonusData.monthlyBonuses || [];
        this.failedChecks = bonusData.failedChecks || [];

        // Set penalty status if applicable
        this.hasPenalty = bonusData.hasPenalty || false;
        if (this.hasPenalty) {
          this.penaltyStartDate = bonusData.penaltyStartDate;
          this.penaltyEndDate = bonusData.penaltyEndDate;
          this.penaltyMonths = bonusData.penaltyMonths;
          this.penaltyBonusName = bonusData.penaltyBonusName;
        }

        // Set point totals
        this.positivePoints = bonusData.positivePoints;
        this.negativePoints = bonusData.negativePoints;
        this.totalPoints = bonusData.totalPoints;
        this.confirmedPositivePoints = bonusData.confirmedPositivePoints;
        this.confirmedNegativePoints = bonusData.confirmedNegativePoints;
        this.confirmedTotalPoints = bonusData.confirmedTotalPoints;
        this.pendingPositivePoints = bonusData.pendingPositivePoints;
        this.pendingNegativePoints = bonusData.pendingNegativePoints;
        this.pendingTotalPoints = bonusData.pendingTotalPoints;

        this.calculationDone = true;
      } catch (error) {
        console.error("Error fetching bonus data:", error);
        this.showError("Fehler beim Laden der Bonusdaten");
      } finally {
        this.loading = false;
      }
    },

    isFutureDate(dateString) {
      return BonusCalculatorService.isFutureDate(dateString);
    },

    confirmDeleteEvent(event) {
      this.eventToDelete = event;
      this.deleteDialog = true;
    },

    async deleteEvent() {
      try {
        const currentUserId = await this.$store.dispatch("getUserEmployeeId");

        const response = await this.$store.dispatch("updateSupabaseEntity", {
          table: "bonus_employee_events",
          id: this.eventToDelete.id,
          payload: {
            deleted_at: new Date().toISOString(),
            deleted_by: currentUserId,
          },
        });

        if (response.error) {
          throw new Error(response.error.message);
        }

        this.showSuccess("Bonusereignis erfolgreich gelöscht", "success");
        this.deleteDialog = false;
        this.eventToDelete = null;
        this.fetchBonusData();
      } catch (error) {
        console.error("Error deleting bonus event:", error);
        this.showError("Fehler beim Löschen des Bonusereignisses");
      }
    },
  },
  async mounted() {
    try {
      // Get employee city ID
      this.employeeCityId = await BonusCalculatorService.fetchEmployeeCityId(
        this.employeeId,
      );

      // Check if bonus system exists for the city
      if (this.employeeCityId) {
        const { hasBonusSystem, cityName } =
          await BonusCalculatorService.checkBonusLevelsForCity(
            this.employeeCityId,
          );
        this.hasBonusSystem = hasBonusSystem;
        this.cityName = cityName;
      }

      // Only continue with bonus data if we have a bonus system
      if (this.hasBonusSystem) {
        await this.fetchBonusData();
      } else {
        this.loading = false;
      }
    } catch (error) {
      console.error("Error in component initialization:", error);
      this.showError("Fehler beim Initialisieren der Komponente");
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.text-success {
  color: green !important;
}

.text-error {
  color: red !important;
}

.v-list-item-title .v-chip {
  vertical-align: middle;
}

/* Animation for pending status */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.v-chip.amber-lighten-3 {
  animation: pulse 2s infinite;
}

/* Add styles for the penalty overlay and dialog */
.penalty-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(244, 67, 54, 0.1);
  backdrop-filter: blur(2px);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}

.penalty-icon {
  font-size: 64px;
  color: #f44336;
  margin-bottom: 16px;
}

@keyframes pulse-error {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

.v-alert[type="error"] {
  animation: pulse-error 2s infinite;
}

/* Additional styles for the automatic events panel */
.v-expansion-panel-title .v-chip {
  font-weight: normal;
}

.auto-event-count {
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
}

.v-table th,
.v-table td {
  padding: 8px 12px !important;
}
</style>
