<template>
  <v-row>
    <v-col cols="3" align="left">
      <v-btn class="ma-2" @click="createNewTemplate()"
        >Neue E-Mail Vorlage erstellen</v-btn
      >
      <v-list height="1000px">
        <v-list-item
          v-for="template in templates"
          key="id"
          @click="openTemplate(template)"
          :class="template.activated ? 'bg-blue-grey-lighten-2' : null"
          >{{ template.name }}</v-list-item
        >
      </v-list>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="9">
      <v-row>
        <v-col cols="9" class="mb-n4">
          <v-text-field
            label="Name"
            v-model="chosenTemplate.name"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="mb-n4">
          <v-select
            :items="statusList"
            label="Status"
            item-title="name"
            item-value="id"
            v-model="chosenTemplate.status_id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-n4">
          <v-text-field
            label="Betreff"
            v-model="chosenTemplate.subject"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            size="x-small"
            class="mr-1"
            v-for="placeholder in $store.state.templatePlaceholders"
            @click="addPlaceholder(placeholder)"
            >{{ placeholder.label }}</v-btn
          >
        </v-col>
        <v-col cols="10" align="center">
          <FileComponent
            v-if="chosenTemplate.id"
            bucket="attachments"
            :id="chosenTemplate.id"
          ></FileComponent>
        </v-col>
        <v-col cols="12">
          <v-chip v-for="logo in logos" @click="addLogoLink(logo)">{{
            logo.name
          }}</v-chip>
        </v-col>

        <v-col cols="12">
          <QuillEditor
            toolbar="essential"
            v-model:content="chosenTemplate.body"
            contentType="html"
            placeholder="Body"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-bottom-navigation>
    <v-btn
      class="bg-red"
      v-if="chosenTemplate.id > 0"
      @click="deleteTemplate()"
      :loading="loading"
      >Löschen</v-btn
    >
    <v-spacer></v-spacer>
    <v-btn
      class="bg-green"
      v-if="chosenTemplate.id > 0"
      @click="updateTemplate()"
      :loading="loading"
      >Aktualisiseren</v-btn
    >
    <v-btn
      class="bg-green"
      v-else-if="chosenTemplate.name"
      @click="saveNewTemplate()"
      :loading="loading"
      >Erstellen</v-btn
    >
  </v-bottom-navigation>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import FileComponent from "@/components/CommonComponents/Files.vue";
</script>
<script>
export default {
  data() {
    return {
      statusList: [],
      loading: false,
      chosenTemplate: {},
      logos: [],
      templates: [],
    };
  },
  methods: {
    async getStatusList() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_status",
      });
      this.statusList = response.data;
    },
    async getTemplates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_templates",
        where: [
          {
            type: "eq",
            key: "mandator_id",
            value: this.$store.state.userInfo.mandator_id,
          },
        ],
      });
      response.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );
      this.templates = response.data;
    },
    async getLogos() {
      var response = await this.$store.dispatch("getStorageFolderFiles", {
        bucket: "logos",
        folder: "SignatureLogos",
      });
      response.data.shift();
      this.logos = response.data;
    },
    async addLogoLink(logo) {
      var publicLink = await this.$store.dispatch("getPublicStorageFileLink", {
        bucket: "logos",
        file: "SignatureLogos/" + logo.name,
      });
      var logoLink = `<img height="60px" src="${publicLink}"></img>`;
      this.chosenTemplate.body = this.chosenTemplate.body + logoLink;
    },
    async addPlaceholder(placeholder) {
      if (!this.chosenTemplate.body) {
        this.chosenTemplate.body = "";
      }
      this.chosenTemplate.body =
        this.chosenTemplate.body + placeholder.placeholder;
    },
    async updateTemplate() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.chosenTemplate));
      delete payload.activated;
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_templates",
        payload: payload,
      });
      this.loading = false;
    },
    async createNewTemplate() {
      this.templates.forEach(function (item) {
        item.activated = false;
      });
      this.chosenTemplate = {
        name: null,
        subject: null,
        body: "<p></p>",
        mandator_id: this.$store.state.userInfo.mandator_id,
      };
    },
    async openTemplate(template) {
      this.templates.forEach(function (item) {
        if (item.id == template.id) {
          item.activated = true;
        } else {
          item.activated = false;
        }
      });
      this.chosenTemplate = {};
      await this.$store.dispatch("sleep");
      this.chosenTemplate = template;
    },
    async saveNewTemplate() {
      this.loading = true;
      var id = await this.$store.dispatch("createSupabaseEntity", {
        table: "recruiting_templates",
        payload: this.chosenTemplate,
      });
      await this.getTemplates();
      var template = this.templates.find((item) => item.id == id);
      this.chosenTemplate = template;
      this.loading = false;
      return id;
    },
    async deleteTemplate() {
      this.loading = true;
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "recruiting_templates",
        id: this.chosenTemplate.id,
      });
      this.createNewTemplate();
      await this.getTemplates();
      this.loading = false;
    },
  },
  async created() {
    await this.$store.dispatch("getUserInfo");
    this.getTemplates();
    this.getStatusList();
    this.getLogos();
  },
};
</script>
