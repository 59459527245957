<template>
  <div>
    <v-row class="px-2">
      <v-col cols="6">
        <v-card class="ma-2 pa-2" elevation="10">
          <v-card-title>Schichten der letzten 6 Monate</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-center mb-3">
                  <strong>Durchschnittliche Kunden Bewertung:</strong>
                  <div class="d-flex align-center justify-center">
                    <v-rating
                      v-model="clientAverageRating"
                      color="amber"
                      active-color="amber-darken-3"
                      size="30"
                      half-increments
                      readonly
                    ></v-rating>
                  </div>
                  <div>{{ clientAverageRating }} (n={{ clientRatingCount }})</div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center mb-3">
                  <strong>Durchschnittliche Mitarbeiter Bewertung:</strong>
                  <div class="d-flex align-center justify-center">
                    <v-rating
                      v-model="employeeAverageRating"
                      color="green"
                      active-color="green-darken-3"
                      size="30"
                      half-increments
                      readonly
                    ></v-rating>
                  </div>
                  <div>
                    {{ employeeAverageRating }} (n={{ employeeRatingCount }})
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-col cols="12" v-for="emp in employeeRecord" class="my-n2">
              <div>
                <router-link :to="'/Mitarbeiter/' + emp.employee_id"
                  >{{ emp.employee_name }} ({{ emp.status }})
                </router-link>
              </div>
              <v-progress-linear
                :model-value="emp.count"
                :max="employeeRecord[0].count"
                color="blue-darken-4"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.count) + " (Gesamt)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.senior_count > 0"
                :model-value="emp.senior_count"
                :max="employeeRecord[0].count"
                color="blue-darken-2"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.senior_count) + " (Senior)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.clerk_count > 0"
                :model-value="emp.clerk_count"
                :max="employeeRecord[0].count"
                color="blue-darken-1"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.clerk_count) + " (Clerk)"
                  }}</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                v-if="emp.training_count > 0"
                :model-value="emp.training_count"
                :max="employeeRecord[0].count"
                color="blue-darken-1"
                height="15"
              >
                <template v-slot:default="{ value }">
                  <strong :class="value > 50 ? 'text-white' : null">{{
                    Math.ceil(emp.training_count) + " (Einarbeitung)"
                  }}</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="ma-2 pa-2" elevation="10">
          <v-card-title>Mögliche Mitarbeiter</v-card-title>
          <v-card-text>
            <v-list>
              <template
                v-for="emp in hotelObject.employees_hotels"
                :key="emp.employee_id"
              >
                <v-list-item v-if="emp.employees.status == 'Aktiv'">
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link
                        class="mr-2"
                        :to="'/Mitarbeiter/' + emp.employee_id"
                        >{{ emp.employees.name }}
                      </router-link>
                      <v-chip size="small" v-if="emp.is_senior">Senior</v-chip>
                      <v-chip size="small" v-if="emp.is_clerk">Clerk</v-chip>
                      <v-chip
                        variant="elevated"
                        color="red"
                        v-if="emp.is_blocked"
                        >Gesperrt!</v-chip
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-8 mx-2" elevation="10">
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeMonth('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  Schichtplan für {{ getMonthName(shiftPlan.month) }}
                  {{ shiftPlan.year }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeMonth('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="d-flex flex-column align-center text-center"
              >
                <strong>Durchschnittliche Kunden Bewertung:</strong>
                <div class="d-flex align-center">
                  <v-rating
                    v-model="monthlyClientRating"
                    color="amber"
                    active-color="amber-darken-3"
                    size="40"
                    half-increments
                    readonly
                  ></v-rating>
                </div>
                <div>{{ monthlyClientRating }} (n={{ monthlyClientCount }})</div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex flex-column align-center text-center"
              >
                <strong>Durchschnittliche Mitarbeiter Bewertung:</strong>
                <div class="d-flex align-center">
                  <v-rating
                    v-model="monthlyEmployeeRating"
                    color="green"
                    active-color="green-darken-3"
                    size="40"
                    half-increments
                    readonly
                  ></v-rating>
                </div>
                <div>
                  {{ monthlyEmployeeRating }} (n={{ monthlyEmployeeCount }})
                </div>
              </v-col>
            </v-row>

            <v-data-table-virtual
              density="compact"
              :headers="shiftPlan.headers"
              :items="shiftPlan.shifts"
            >
              <template #item.employee="{ item }">
                <div v-if="item.hotel_booking">
                  <!-- Render shifts sorted: Senior, Clerk, Einarbeitung Senior, Einarbeitung Clerk -->
                  <div
                    v-for="shift in sortShifts(item.employee)"
                    :key="shift.id"
                    class="d-flex align-center mb-2"
                  >
                    {{ shift.employees?.name ?? "nicht erkannt" }}
                    <em>{{
                      shift.is_training
                        ? "(Einarbeitung " +
                          (shift.is_senior ? "Senior" : "Clerk") +
                          ")"
                        : "(" + (shift.is_senior ? "Senior" : "Clerk") + ")"
                    }}</em>
                    <v-tooltip
                      :top="true"
                      :open-on-hover="true"
                      :close-delay="100"
                      v-if="
                        shift.hotel_performance_records?.[0]?.client_feedback
                      "
                    >
                      <template #activator="{ props }">
                        <v-icon v-bind="props" class="ml-2" color="info">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <div style="max-width: 500px">
                        <strong>Feedback:</strong>
                        {{
                          shift.hotel_performance_records[0]?.client_feedback
                        }}
                      </div>
                    </v-tooltip>
                    <v-rating
                      v-if="
                        !shift.is_training &&
                        shift.hotel_performance_records?.[0]?.client_rating
                      "
                      v-model="shift.hotel_performance_records[0].client_rating"
                      color="amber"
                      active-color="amber-darken-3"
                      size="15"
                      class="pb-2 ml-1"
                      readonly
                    >
                    </v-rating>
                  </div>
                </div>
              </template>
            </v-data-table-virtual>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Your template code goes here -->
  </div>
</template>

<script setup>
const props = defineProps({ hotelObject: { type: Object, required: true } });
</script>
<script>
import { format } from "date-fns";
import { de } from "date-fns/locale";

export default {
  name: "Shifts",
  data() {
    return {
      shifts: [],
      employeeRecord: [],
      ratings: {
        // 6-month ratings
        clientAverage: 0,
        employeeAverage: 0,
        clientCount: 0,
        employeeCount: 0,
        // Monthly ratings
        monthlyClientAverage: 0,
        monthlyEmployeeAverage: 0,
        monthlyClientCount: 0,
        monthlyEmployeeCount: 0,
      },
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      shiftPlan: {
        month: null,
        year: null,
        days: null,
        headers: [
          {
            title: "Datum",
            align: "start",
            sortable: false,
            value: "date",
          },
          {
            title: "Mitarbeiter + Bewertung",
            align: "start",
            sortable: false,
            value: "employee",
          },
        ],
        shifts: [],
      },
    };
  },
  // Computed properties to maintain backward compatibility
  computed: {
    clientAverageRating() {
      return this.ratings.clientAverage;
    },
    employeeAverageRating() {
      return this.ratings.employeeAverage;
    },
    clientRatingCount() {
      return this.ratings.clientCount;
    },
    employeeRatingCount() {
      return this.ratings.employeeCount;
    },
    monthlyClientRating() {
      return this.ratings.monthlyClientAverage;
    },
    monthlyEmployeeRating() {
      return this.ratings.monthlyEmployeeAverage;
    },
    monthlyClientCount() {
      return this.ratings.monthlyClientCount;
    },
    monthlyEmployeeCount() {
      return this.ratings.monthlyEmployeeCount;
    },
  },
  methods: {
    async changeMonth(type) {
      if (type === "plus") {
        this.shiftPlan.month++;
        if (this.shiftPlan.month > 12) {
          this.shiftPlan.month = 1;
          this.shiftPlan.year++;
        }
      } else {
        this.shiftPlan.month--;
        if (this.shiftPlan.month < 1) {
          this.shiftPlan.month = 12;
          this.shiftPlan.year--;
        }
      }
      this.shiftPlan.shifts = [];
      this.createShiftPlan();
    },
    async createShiftPlan() {
      if (!this.shiftPlan.month) {
        var date = new Date();
      } else {
        var date = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, 1);
      }
      this.shiftPlan.month = date.getMonth() + 1;
      this.shiftPlan.year = date.getFullYear();
      this.shiftPlan.days = new Date(
        this.shiftPlan.year,
        this.shiftPlan.month,
        0,
      ).getDate();
      var start = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, 2);
      var end = new Date(this.shiftPlan.year, this.shiftPlan.month, 0);
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "shifts",
        select:
          "*,employees(name),hotel_performance_records(client_rating, client_feedback, employee_rating)",
        where: [
          { type: "gte", key: "date", value: start.toISOString() },
          { type: "lte", key: "date", value: end.toISOString() },
          { type: "eq", key: "hotel_id", value: this.hotelObject.id },
        ],
      });
      var shifts = response.data;
      let sumClientRating = 0;
      let countClientRating = 0;
      let sumEmployeeRating = 0;
      let countEmployeeRating = 0;

      var shiftDateObject = {};
      shifts.forEach((shift) => {
        var shiftDate = new Date(shift.date);
        shiftDate.setDate(shiftDate.getDate() - 1);
        var key = shiftDate.toISOString().slice(0, 10);
        if (!shiftDateObject[key]) {
          shiftDateObject[key] = [];
        }
        shiftDateObject[key].push(shift);
        if (shift.hotel_performance_records?.[0]) {
          if (
            shift.hotel_performance_records[0].client_rating !== null &&
            !isNaN(shift.hotel_performance_records[0].client_rating)
          ) {
            sumClientRating += Number(
              shift.hotel_performance_records[0].client_rating,
            );
            countClientRating++;
          }

          const empRating = shift.hotel_performance_records[0].employee_rating;
          if (
            empRating !== null &&
            empRating !== undefined &&
            !isNaN(Number(empRating))
          ) {
            sumEmployeeRating += Number(empRating);
            countEmployeeRating++;
          }
        }
      });

      this.ratings.monthlyClientAverage =
        countClientRating > 0
          ? Math.round((sumClientRating / countClientRating) * 10) / 10
          : 0;
      this.ratings.monthlyEmployeeAverage =
        countEmployeeRating > 0
          ? Math.round((sumEmployeeRating / countEmployeeRating) * 10) / 10
          : 0;
      this.ratings.monthlyClientCount = countClientRating;
      this.ratings.monthlyEmployeeCount = countEmployeeRating;

      this.shiftPlan.shifts = [];
      for (let i = 1; i <= this.shiftPlan.days; i++) {
        var date = new Date(this.shiftPlan.year, this.shiftPlan.month - 1, i);
        var dateISO = date.toISOString().slice(0, 10);
        var weekday = this.weekdays[date.getDay()];
        var hotelBooking = this.hotelObject.shift_days?.find(
          (day) => day === date.getDay(),
        );
        this.shiftPlan.shifts.push({
          date: format(date, "dd.MM.yyyy, EEE", { locale: de }),
          hotel_booking: hotelBooking >= 0 ? true : false,
          employee: shiftDateObject[dateISO] ? shiftDateObject[dateISO] : [],
        });
      }
    },
    createEmployeeShifts() {
      var employeeCheck = {};
      let sumClientRating = 0;
      let countClientRating = 0;
      let sumEmployeeRating = 0;
      let countEmployeeRating = 0;

      this.shifts.forEach((shift) => {
        if (!employeeCheck[shift.employee_id]) {
          employeeCheck[shift.employee_id] = {
            employee_id: shift.employee_id,
            status: shift.employees?.status,
            employee_name: shift.employees
              ? shift.employees.name
              : "Nicht gematched",
            count: 1,
          };
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count = 1;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 1;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 1;
          }
        } else {
          employeeCheck[shift.employee_id].count++;
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count++;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].clerk_count++;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].training_count++;
          }
        }

        if (
          shift.hotel_performance_records &&
          shift.hotel_performance_records.length > 0
        ) {
          shift.hotel_performance_records.forEach((record) => {
            // Client rating check
            if (
              record.client_rating !== null &&
              !isNaN(Number(record.client_rating))
            ) {
              sumClientRating += Number(record.client_rating);
              countClientRating++;
            }

            // Employee rating check - ensure it's a valid number
            const empRating = record.employee_rating;
            if (
              empRating !== null &&
              empRating !== undefined &&
              !isNaN(Number(empRating))
            ) {
              sumEmployeeRating += Number(empRating);
              countEmployeeRating++;
            }
          });
        }
      });

      // Calculate and set the average ratings
      this.ratings.clientAverage =
        countClientRating > 0
          ? Math.round((sumClientRating / countClientRating) * 10) / 10
          : 0;
      this.ratings.employeeAverage =
        countEmployeeRating > 0
          ? Math.round((sumEmployeeRating / countEmployeeRating) * 10) / 10
          : 0;
      this.ratings.clientCount = countClientRating;
      this.ratings.employeeCount = countEmployeeRating;

      this.employeeRecord = Object.values(employeeCheck);
      this.employeeRecord.sort((a, b) => b.count - a.count);
    },
    // New helper method: sorts shifts by type
    sortShifts(shifts) {
      return shifts.slice().sort((a, b) => {
        const getRank = (shift) => {
          if (!shift.is_training) {
            return shift.is_senior ? 1 : 2;
          } else {
            return shift.is_senior ? 3 : 4;
          }
        };
        return getRank(a) - getRank(b);
      });
    },
    getMonthName(month) {
      const date = new Date(2000, month - 1, 1);
      return format(date, "MMMM", { locale: de });
    },
  },
  async mounted() {
    this.shifts = this.hotelObject.shifts;
    this.createEmployeeShifts();
    await this.createShiftPlan();
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
