<template>
    <!-- Loading indicator -->
    <v-overlay v-model="loading" class="align-center justify-center">
        <v-row>
            <v-col cols="12" align="center">
                <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
            </v-col>
            <v-col cols="12" align="center">
                <div class="text-h6 mt-4">Lade Dienstanweisung</div>
            </v-col>
        </v-row>
        
    </v-overlay>
    <v-row v-if="hotelInformation.hotel_key_facts.length === 0 && !loading">
        <v-col cols="12" class="text-center py-8">
            <v-icon size="64" color="grey lighten-1">mdi-file-search-outline</v-icon>
            <h3 class="mt-4 text-grey">Keine Informationen vorhanden</h3>
        </v-col>
    </v-row>
    <v-container fluid v-if="hotelInformation.hotel_key_facts.length > 0">
        <!-- Error message -->
        <v-alert v-if="error" type="error" class="mb-4">
            {{ error }}
        </v-alert>

        <!-- Search and navigation section -->
        <v-row>
            <v-col cols="12" md="12" class="mb-4">
                <v-text-field
                    v-model="searchQuery"
                    prepend-inner-icon="mdi-magnify"
                    label="Suche"
                    outlined
                    dense
                    clearable
                    :loading="isSearching"
                    class="search-field"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" class="category-navigation-container">
                <div class="d-flex align-center category-navigation">
                    <v-btn 
                        text 
                        :class="{'category-active': selectedCategory === null}"
                        @click="selectedCategory = null"
                        rounded="pill"
                    >
                        All
                    </v-btn>
                    <v-divider vertical class="mx-2"></v-divider>
                    <div class="d-flex flex-wrap">
                        <v-chip
                            v-for="category in categoryList"
                            :key="category"
                            :color="selectedCategory === category ? 'primary' : ''"
                            :class="{'category-active': selectedCategory === category}"
                            class="mx-1 my-1 category-chip"
                            @click="selectedCategory = category"
                            variant="elevated"
                            size="large"
                            :text-color="selectedCategory === category ? 'white' : undefined"
                        >
                            {{ category || 'General' }}
                        </v-chip>
                    </div>
                </div>
            </v-col>
        </v-row>

        <!-- No results message -->
         
        <v-row v-if="Object.keys(displayedInstructions).length === 0 && !loading">
            <v-col cols="12" class="text-center py-8">
                <v-icon size="64" color="grey lighten-1">mdi-file-search-outline</v-icon>
                <h3 class="mt-4 text-grey">Keine Ergebnisse zu Ihrer Suche</h3>
            </v-col>
        </v-row>
        
        <!-- Instructions content -->
        <template v-for="(categoryGroup, category) in displayedInstructions" :key="category">
            <v-row v-if="shouldShowCategory(category)">
                <v-col cols="12">
                    <div class="category-header">
                        <h2 class="text-h5 font-weight-bold primary--text">{{ category || 'General Information' }}</h2>
                        <v-divider class="mt-2 mb-4"></v-divider>
                    </div>
                    
                    <!-- Special case for credentials -->
                    <template v-if="category === 'Zugangsdaten'">
                        <v-card variant="outlined" class="mb-6 data-table-card" elevation="2">
                            <v-card-title class="text-subtitle-1 font-weight-bold pb-0">Account Information</v-card-title>
                            <v-card-text>
                                <v-table class="rounded styled-table">
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            <th>Username</th>
                                            <th>Passwort</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(cred, index) in sortedCredentials" :key="index">
                                            <td class="font-weight-medium">{{ cred.account }}</td>
                                            <td>{{ cred.account_name }}</td>
                                            <td>
                                                <div class="d-flex align-center">
                                                    <span class="password-dots me-2">{{cred.password}}</span>
                                                    <v-btn
                                                        v-if="false"
                                                        icon="mdi-content-copy"
                                                        size="small"
                                                        @click="copyToClipboard(cred.password)"
                                                        variant="text"
                                                    ></v-btn>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-card-text>
                        </v-card>
                    </template>
                    
                    <!-- Special case for Notfallrufnummern -->
                    <template v-else-if="category === 'Notfallrufnummern'">
                        <v-card variant="outlined" class="mb-6 data-table-card" elevation="2">
                            <v-card-title class="text-subtitle-1 font-weight-bold pb-0">Emergency Contact Information</v-card-title>
                            <v-card-text>
                                <v-table class="rounded styled-table">
                                    <thead>
                                        <tr>
                                            <th>Was</th>
                                            <th>Wer</th>
                                            <th>Nummer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, index) in sortedEmergencyContacts" :key="index">
                                            <td class="font-weight-medium">{{ contact.what }}</td>
                                            <td>{{ contact.who }}</td>
                                            <td>
                                                <div class="d-flex align-center">
                                                    <span class="me-2">{{ contact.number }}</span>
                                                    <v-btn
                                                        v-if="false"
                                                        icon="mdi-content-copy"
                                                        size="small"
                                                        @click="copyToClipboard(contact.number)"
                                                        variant="text"
                                                    ></v-btn>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-card-text>
                        </v-card>
                    </template>
                    
                    <!-- Regular case for instructions -->
                    <template v-else v-for="(subCategoryGroup, subCategory) in categoryGroup" :key="subCategory">
                        <div class="mb-8">
                            <h3 v-if="subCategory" class="text-h6 font-weight-medium secondary--text mb-4 ps-2 subcategory-title">
                                {{ subCategory }}
                            </h3>
                            
                            <v-row>
                                <template v-for="instruction in subCategoryGroup" :key="instruction.id">
                                    <v-col cols="12" md="4" v-if="instruction.type == 'text-field' && shouldShowInstruction(instruction)">
                                        <v-card variant="elevated" class="instruction-card h-100" elevation="2" hover>
                                            <v-card-title class="text-subtitle-1 font-weight-bold pb-1">{{ instruction.name }}</v-card-title>
                                            <v-card-text v-html="highlightSearchTerm(instruction.value[0]?.value)"></v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" v-else-if="instruction.type == 'html' && shouldShowInstruction(instruction)" class="lazy-load-container">
                                        <v-card class="instruction-card-html" variant="elevated" elevation="2" hover>
                                            <v-card-title class="text-subtitle-1 font-weight-bold">{{ instruction.name }}</v-card-title>
                                            <v-divider class="mx-4"></v-divider>
                                            <v-card-text class="rich-text-content" v-if="!isSearchActive" v-html="instruction.value[0]?.value"></v-card-text>
                                            <v-card-text class="rich-text-content" v-else v-html="highlightSearchTerm(instruction.value[0]?.value)"></v-card-text>
                                        </v-card>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                    </template>
                </v-col>
            </v-row>
            <v-divider v-if="shouldShowCategory(category) && Object.keys(displayedInstructions).length > 1" class="my-6"></v-divider>
        </template>
    </v-container>
</template>

<script setup>
import { ref, computed, watchEffect, nextTick, onMounted } from 'vue'
import { useThrottleFn, useDebounce } from '@vueuse/core'
import { supabase } from '@/supabase';

const props = defineProps({
    hotelId: {
        type: Number,
        required: true
    }
})

const hotelInformation = ref({hotel_key_facts: []})
const searchQuery = ref('')
const selectedCategory = ref("Factsheet")
const loading = ref(true)
const error = ref(null)
const isSearching = ref(false)
const isSearchActive = ref(false)

// Group instructions by category and sub-category
const groupedInstructions = computed(() => {
    if (!hotelInformation.value?.hotel_key_facts) return {}
    
    const grouped = {}
    
    hotelInformation.value.hotel_key_facts.forEach(fact => {
        const category = fact.category || ''
        const subCategory = fact.sub_category || ''
        
        // Initialize category if it doesn't exist
        if (!grouped[category]) {
            grouped[category] = {}
        }
        
        // Initialize sub_category if it doesn't exist
        if (!grouped[category][subCategory]) {
            grouped[category][subCategory] = []
        }
        
        // Add the fact to its category and sub_category
        grouped[category][subCategory].push(fact)
    })
    
    return grouped
})

// List of all available categories
const categoryList = computed(() => {
    const regularCategories = Object.keys(groupedInstructions.value)
    const specialCategories = []
    
    if (hotelInformation.value?.hotel_account_credentials?.length) {
        specialCategories.push('Zugangsdaten')
    }
    
    if (hotelInformation.value?.hotel_emergency_contacts?.length) {
        specialCategories.push('Notfallrufnummern')
    }
    
    return [...regularCategories, ...specialCategories]
})

// Cache for search results to improve performance
const searchCache = ref({})

// Filter instructions based on search query with optimized caching
const filteredInstructions = computed(() => {
    if (!searchQuery.value) {
        isSearchActive.value = false
        return groupedInstructions.value
    }
    
    isSearchActive.value = true
    
    // Check if result is already in cache
    const cacheKey = searchQuery.value.toLowerCase()
    if (searchCache.value[cacheKey]) {
        return searchCache.value[cacheKey]
    }
    
    const searchTermLower = cacheKey
    const filtered = {}
    
    // Search in all categories and subcategories
    Object.entries(groupedInstructions.value).forEach(([category, subCategories]) => {
        const filteredSubCategories = {}
        
        Object.entries(subCategories).forEach(([subCategory, instructions]) => {
            const matchingInstructions = instructions.filter(instruction => {
                const nameMatch = instruction.name?.toLowerCase().includes(searchTermLower)
                
                // For performance reasons, limit search in HTML content to title and shorter text values
                const valueText = instruction.value[0]?.value || ''
                let valueMatch = false
                
                if (instruction.type === 'text-field' || valueText.length < 1000) {
                    valueMatch = valueText.toLowerCase().includes(searchTermLower)
                } else {
                    // For HTML content, perform simpler check on smaller data
                    const textContent = valueText.replace(/<[^>]*>/g, ' ').slice(0, 1000) // Get plain text 
                    valueMatch = textContent.toLowerCase().includes(searchTermLower)
                }
                
                return nameMatch || valueMatch
            })
            
            if (matchingInstructions.length > 0) {
                filteredSubCategories[subCategory] = matchingInstructions
            }
        })
        
        if (Object.keys(filteredSubCategories).length > 0) {
            filtered[category] = filteredSubCategories
        }
    })
    
    // Cache the results
    searchCache.value[cacheKey] = filtered
    return filtered
})

// Display instructions based on selected category or search results
const displayedInstructions = computed(() => {
    let result = {}
    
    if (searchQuery.value) {
        result = { ...filteredInstructions.value }
        
        // Include credentials if they match search
        if (hotelInformation.value?.hotel_account_credentials?.length) {
            const matchingCredentials = sortedCredentials.value.filter(cred => 
                Object.values(cred).some(value => 
                    String(value).toLowerCase().includes(searchQuery.value.toLowerCase())
                )
            )
            
            if (matchingCredentials.length > 0) {
                result['Zugangsdaten'] = {}
            }
        }
        
        // Include contacts if they match search
        if (hotelInformation.value?.hotel_emergency_contacts?.length) {
            const matchingContacts = sortedEmergencyContacts.value.filter(contact => 
                Object.values(contact).some(value => 
                    String(value).toLowerCase().includes(searchQuery.value.toLowerCase())
                )
            )
            
            if (matchingContacts.length > 0) {
                result['Notfallrufnummern'] = {}
            }
        }
        
        return result
    }
    
    if (selectedCategory.value === null) {
        result = { ...groupedInstructions.value }
        
        if (hotelInformation.value?.hotel_account_credentials?.length) {
            result['Zugangsdaten'] = {}
        }
        
        if (hotelInformation.value?.hotel_emergency_contacts?.length) {
            result['Notfallrufnummern'] = {}
        }
        
        return result
    }
    
    // Handle special categories selection
    if (selectedCategory.value === 'Zugangsdaten') {
        result['Zugangsdaten'] = {}
        return result
    }
    
    if (selectedCategory.value === 'Notfallrufnummern') {
        result['Notfallrufnummern'] = {}
        return result
    }
    
    // Regular category selection
    result[selectedCategory.value] = groupedInstructions.value[selectedCategory.value] || {}
    return result
})

// Method to determine if a category should be shown
const shouldShowCategory = (category) => {
    if (searchQuery.value) {
        // For special categories during search, check if they're in the filtered results
        if (category === 'Zugangsdaten' || category === 'Notfallrufnummern') {
            return category in displayedInstructions.value
        }
        return true // Regular categories handling
    }
    return selectedCategory.value === null || selectedCategory.value === category
}

// Method to determine if an instruction should be shown
const shouldShowInstruction = (instruction) => {
    if (!searchQuery.value) {
        return true
    }
    
    // Use cached search results for performance
    const categoryKey = instruction.category || ''
    const subCategoryKey = instruction.sub_category || ''
    
    if (filteredInstructions.value[categoryKey] && 
        filteredInstructions.value[categoryKey][subCategoryKey]) {
        return filteredInstructions.value[categoryKey][subCategoryKey].some(i => i.id === instruction.id)
    }
    
    return false
}

// Highlight search term in text with performance optimization
const highlightSearchTerm = (text) => {
    if (!text || !searchQuery.value || text.length > 5000) {
        // Skip highlighting for very long texts to avoid performance issues
        return text
    }
    
    try {
        const regex = new RegExp(`(${escapeRegExp(searchQuery.value)})`, 'gi')
        return text.replace(regex, '<mark class="search-highlight">$1</mark>')
    } catch (e) {
        // Fallback if regex fails
        return text
    }
}

// Helper function to escape special characters in regex
const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

// Debounced search function for performance
watchEffect(searchQuery, useDebounce(async (value) => {
    if (value) {
        isSearching.value = true
        selectedCategory.value = null

        // Use nextTick to avoid UI freezing during search
        await nextTick()
        // The filteredInstructions computed property will update
        
        isSearching.value = false
    } else {
        // Clear the search cache when search is cleared
        searchCache.value = {}
    }
}, 300))


onMounted(async () => {
    console.log(props.hotelId)
    loading.value = true
    error.value = null
    
    try {
        const response = await supabase
            .from('hotels')
            .select('*,hotel_key_facts(*,value:hotel_key_facts_matching(*)),hotel_account_credentials(account,account_name,password,order_number),hotel_emergency_contacts(what,who,number,order_number)')
            .eq('id', props.hotelId)
            .eq('hotel_key_facts.hotel_key_facts_matching.hotel_id', props.hotelId)
            .order('order_number', { referencedTable: 'hotel_key_facts', ascending: true })
        
        if (!response.data || response.data.length === 0) {
            throw new Error('No hoteool information found')
        }
        // Process the data to remove empty hotel_key_facts_matching entries
        const processedData = {...response.data[0]};

        // Filter out empty values in hotel_key_facts_matching
        if (processedData.hotel_key_facts && processedData.hotel_key_facts.length) {
            processedData.hotel_key_facts = processedData.hotel_key_facts.map(fact => {
                // If fact has value array and it's not empty
                console.log(fact)
                if (fact.value && Array.isArray(fact.value)) {
                    // Filter out values that are null, undefined, or have empty value property)
                    fact.value = fact.value.filter(val => 
                        val && val.value !== null && val.value !== undefined && val.value !== '' && val.value !== '<p>-</p>' && val.value !== '-' && val.value !== '<p></p>'
                    );
                }
                return fact;
            });

            // Optionally, filter out facts that now have empty value arrays
            processedData.hotel_key_facts = processedData.hotel_key_facts.filter(fact => 
                !fact.value || fact.value.length > 0
            );
        }

        hotelInformation.value = processedData;
    } catch (err) {
        console.error('Error loading hotel information:', err)
        error.value = 'Failed to load hotel information. Please try again later.'
    } finally {
        loading.value = false
    }
})

// Data for credentials and Notfallrufnummern
const sortedCredentials = computed(() => {
    if (!hotelInformation.value?.hotel_account_credentials) return []
    return [...hotelInformation.value.hotel_account_credentials]
        .sort((a, b) => (a.order_number || 0) - (b.order_number || 0))
})

const sortedEmergencyContacts = computed(() => {
    if (!hotelInformation.value?.hotel_emergency_contacts) return []
    return [...hotelInformation.value.hotel_emergency_contacts]
        .sort((a, b) => (a.order_number || 0) - (b.order_number || 0))
})

// Copy to clipboard utility
const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .catch(err => console.error('Failed to copy text: ', err))
}
</script>

<style scoped>
.v-card {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 8px !important;
}

.v-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

.instruction-card {
    background-color: #fff;
    border-left: 4px solid var(--v-primary-base, #1976d2);
}

.instruction-card-html {
    background-color: #fff;
    border-left: 4px solid var(--v-secondary-base, #26a69a);
}

.category-navigation-container {
    margin-top: -24px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    margin-bottom: 24px;
}

.category-navigation {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    padding-bottom: 4px;
}

.category-navigation::-webkit-scrollbar {
    height: 4px;
}

.category-navigation::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
}

.category-chip {
    transition: all 0.2s ease;
    cursor: pointer;
}

.category-active {
    font-weight: bold;
}

:deep(.search-highlight) {
    background-color: rgba(255, 213, 79, 0.4);
    border-radius: 2px;
    padding: 2px 1px;
    font-weight: bold;
}

.category-header {
    position: relative;
    margin-bottom: 20px;
}

.subcategory-title {
    position: relative;
    padding-left: 12px;
    border-left: 3px solid var(--v-secondary-base, #26a69a);
}

/* Optimize for large content */
.lazy-load-container {
    contain: content;
}

/* Enhance progress indicator visibility */
.v-overlay {
    background-color: rgba(255, 255, 255, 0.9);
}

/* Password display styling */
.password-dots {
    letter-spacing: 1px;
    font-weight: bold;
}

/* Table styling */
.styled-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.styled-table th {
    background-color: rgba(25, 118, 210, 0.05);
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.styled-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.search-field {
    border-radius: 24px;
}

.data-table-card {
    overflow: hidden;
}

.rich-text-content :deep(img) {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 8px 0;
}

.rich-text-content :deep(h1),
.rich-text-content :deep(h2),
.rich-text-content :deep(h3) {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
}

.rich-text-content :deep(p) {
    margin-bottom: 12px;
    line-height: 1.6;
}

.rich-text-content :deep(ul),
.rich-text-content :deep(ol) {
    padding-left: 20px;
    margin-bottom: 16px;
}

.rich-text-content :deep(a) {
    color: var(--v-primary-base, #1976d2);
    text-decoration: none;
}

.rich-text-content :deep(a:hover) {
    text-decoration: underline;
}

.rich-text-content :deep(blockquote) {
    border-left: 3px solid var(--v-primary-base, #1976d2);
    padding-left: 16px;
    margin-left: 0;
    color: rgba(0,0,0,0.7);
    font-style: italic;
}

.rich-text-content :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
}

.rich-text-content :deep(th), 
.rich-text-content :deep(td) {
    border: 1px solid rgba(0,0,0,0.12);
    padding: 8px 12px;
}

.rich-text-content :deep(th) {
    background-color: rgba(0,0,0,0.03);
}
</style>