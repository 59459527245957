<template>
  <div>
    <!-- Last edited information display -->
    <div v-if="latestHistoryEntry" class="text-body-2 font-italic text-grey">
      <em>Zuletzt bearbeitet am {{ formattedLastEditDate }}</em>
    </div>

    <!-- History Dialog -->
    <v-dialog v-model="showDialog" max-width="900">
      <v-card>
        <v-card-title class="text-h5">Änderungsverlauf</v-card-title>
        <v-card-text class="pt-4">
          <v-data-table
            :headers="historyHeaders"
            :items="formattedHistoryEntries"
            :items-per-page="10"
            class="elevation-1"
            :loading="loadingHistory"
          >
            <template v-slot:item.operation="{ item }">
              <span
                :class="
                  item.operation === 'Hinzugefügt'
                    ? 'green--text'
                    : 'amber--text'
                "
              >
                {{ item.operation }}
              </span>
            </template>
            <template v-slot:item.changes="{ item }">
              <div v-html="item.changes"></div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey-darken-1" text @click="closeDialog">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { supabase } from "@/supabase";
import { format, parseISO } from "date-fns";

// Props
const props = defineProps({
  showHistoryButton: {
    type: Boolean,
    default: true,
  },
});

// Emits
const emit = defineEmits(["update:showDialog"]);

// History tracking refs
const latestHistoryEntry = ref(null);
const historyEntries = ref([]);
const showDialog = ref(false);
const loadingHistory = ref(false);

// Format the last edit date
const formattedLastEditDate = computed(() => {
  if (!latestHistoryEntry.value) return "";

  const date = new Date(latestHistoryEntry.value.changed_at);
  return `${format(date, "dd.MM.yyyy")} um ${format(date, "HH:mm")} Uhr`;
});

// History table headers
const historyHeaders = [
  { title: "Datum", key: "changed_at", width: "150px", sortable: false },
  { title: "Bearbeiter", key: "changed_by", width: "150px" },
  { title: "Tabelle", key: "table_name", width: "120px" },
  { title: "Vorgang", key: "operation", width: "100px" },
  { title: "Änderungen", key: "changes" },
];

// Format the history entries for the data table
const formattedHistoryEntries = computed(() => {
  return historyEntries.value.map((entry) => {
    // Format date
    const date = new Date(entry.changed_at);
    const formattedDate = format(date, "dd.MM.yyyy HH:mm");

    // Format operation
    const operation =
      entry.operation === "INSERT" ? "Hinzugefügt" : "Bearbeitet";

    // Format table name
    let tableName;
    switch (entry.table_name) {
      case "bonuses":
        tableName = "Bonus/Malus";
        break;
      case "bonus_levels":
        tableName = "Bonusstufe";
        break;
      case "hotels":
        tableName = "Hotels";
        break;
      case "bonus_hotel_points":
        tableName = "Hotel Bonus Punkte";
        break;
      default:
        tableName = entry.table_name;
    }

    // Format changes
    let changesHtml = "";
    if (entry.operation === "INSERT") {
      const newValues = entry.new_values || {};

      // Get hotel name for bonus_hotel_points
      let hotelInfo = "";
      if (entry.table_name === "bonus_hotel_points" && newValues.hotel_id) {
        hotelInfo = `Hotel ID: ${newValues.hotel_id}`;
      }

      changesHtml = `
        <div>${newValues.name || newValues.level_name || hotelInfo || "-"} ${
          newValues.city_id === 2
            ? "(Berlin)"
            : newValues.city_id !== undefined
              ? "(Standard)"
              : ""
        }</div>
        ${
          newValues.points !== undefined
            ? `<div><strong>Punkte:</strong> ${newValues.points}</div>`
            : ""
        }
        ${
          newValues.min_points !== undefined
            ? `<div><strong>Mindestpunkte:</strong> ${newValues.min_points}</div>`
            : ""
        }
        ${
          newValues.requires_manual_input !== undefined
            ? `<div><strong>Händischer Eintrag:</strong> ${newValues.requires_manual_input ? "Ja" : "Nein"}</div>`
            : ""
        }
        ${
          newValues.clerk_points !== undefined
            ? `<div><strong>Clerk Punkte:</strong> ${newValues.clerk_points}</div>`
            : ""
        }
        ${
          newValues.senior_points !== undefined
            ? `<div><strong>Senior Punkte:</strong> ${newValues.senior_points}</div>`
            : ""
        }
        ${
          newValues.effective_from !== undefined
            ? `<div><strong>Gültig ab:</strong> ${formatDateString(newValues.effective_from)}</div>`
            : ""
        }
      `;
    } else if (entry.operation === "UPDATE") {
      const oldValues = entry.old_values || {};
      const newValues = entry.new_values || {};

      const changes = [];

      // Always include the name first for better identification
      let itemName =
        newValues.name ||
        oldValues.name ||
        newValues.level_name ||
        oldValues.level_name ||
        "-";

      // For bonus_hotel_points, use hotel_id instead
      if (entry.table_name === "bonus_hotel_points") {
        itemName = `Hotel ID: ${newValues.hotel_id || oldValues.hotel_id || "-"}`;
      }

      const cityInfo =
        newValues.city_id === 2
          ? "Berlin"
          : newValues.city_id !== undefined
            ? "Standard"
            : "";

      changes.push(`<div>${itemName} ${cityInfo ? `(${cityInfo})` : ""}</div>`);

      // Check name changes
      if (
        oldValues.name !== newValues.name &&
        oldValues.name &&
        newValues.name
      ) {
        changes.push(
          `<div><strong>Name geändert:</strong> ${oldValues.name} → ${newValues.name}</div>`,
        );
      } else if (
        oldValues.level_name !== newValues.level_name &&
        oldValues.level_name &&
        newValues.level_name
      ) {
        changes.push(
          `<div><strong>Name geändert:</strong> ${oldValues.level_name} → ${newValues.level_name}</div>`,
        );
      }

      // Check points changes
      if (
        oldValues.points !== newValues.points &&
        oldValues.points !== undefined &&
        newValues.points !== undefined
      ) {
        changes.push(
          `<div><strong>Punkte:</strong> ${oldValues.points} → ${newValues.points}</div>`,
        );
      }

      // Check min_points changes
      if (
        oldValues.min_points !== newValues.min_points &&
        oldValues.min_points !== undefined &&
        newValues.min_points !== undefined
      ) {
        changes.push(
          `<div><strong>Mindestpunkte:</strong> ${oldValues.min_points} → ${newValues.min_points}</div>`,
        );
      }

      // Check requires_manual_input changes
      if (
        oldValues.requires_manual_input !== newValues.requires_manual_input &&
        oldValues.requires_manual_input !== undefined &&
        newValues.requires_manual_input !== undefined
      ) {
        const oldValue = oldValues.requires_manual_input ? "Ja" : "Nein";
        const newValue = newValues.requires_manual_input ? "Ja" : "Nein";
        changes.push(
          `<div><strong>Händischer Eintrag:</strong> ${oldValue} → ${newValue}</div>`,
        );
      }

      // Check effective_from changes
      if (
        oldValues.effective_from !== newValues.effective_from &&
        oldValues.effective_from !== undefined &&
        newValues.effective_from !== undefined
      ) {
        const oldDate = formatDateString(oldValues.effective_from);
        const newDate = formatDateString(newValues.effective_from);
        changes.push(
          `<div><strong>Gültig ab:</strong> ${oldDate} → ${newDate}</div>`,
        );
      }

      // Check historical_values changes
      if (
        hasHistoricalValuesChanged(
          oldValues.historical_values,
          newValues.historical_values,
        )
      ) {
        const changeDetails = getHistoricalValuesChangeDescription(
          oldValues.historical_values,
          newValues.historical_values,
        );
        changes.push(
          `<div><strong>Versionsverlauf:</strong> ${changeDetails}</div>`,
        );
      }

      // Check bonus_penalty_months changes
      if (oldValues.bonus_penalty_months !== newValues.bonus_penalty_months) {
        const oldMonths =
          oldValues.bonus_penalty_months !== null
            ? oldValues.bonus_penalty_months
            : "keine";
        const newMonths =
          newValues.bonus_penalty_months !== null
            ? newValues.bonus_penalty_months
            : "keine";
        changes.push(
          `<div><strong>Bonussperre:</strong> ${oldMonths} → ${newMonths} Monate</div>`,
        );
      }

      // Check hourly wage changes
      const wageTypes = [
        { key: "hourly_wage_bronze", label: "Bronze" },
        { key: "hourly_wage_silver", label: "Silber" },
        { key: "hourly_wage_gold", label: "Gold" },
        { key: "hourly_wage_shiftleader", label: "Schichtleiter" },
      ];

      wageTypes.forEach((type) => {
        if (
          oldValues[type.key] !== newValues[type.key] &&
          oldValues[type.key] !== undefined &&
          newValues[type.key] !== undefined
        ) {
          changes.push(
            `<div><strong>Stundenlohn ${type.label}:</strong> ${
              oldValues[type.key]
            }€ → ${newValues[type.key]}€</div>`,
          );
        }
      });

      // Check bonus_hotel_points changes
      if (
        oldValues.clerk_points !== newValues.clerk_points &&
        newValues.clerk_points !== undefined
      ) {
        const oldPoints =
          oldValues.clerk_points !== null ? oldValues.clerk_points : "0";
        const newPoints = newValues.clerk_points;
        changes.push(
          `<div><strong>Clerk Punkte:</strong> ${oldPoints} → ${newPoints}</div>`,
        );
      }

      if (
        oldValues.senior_points !== newValues.senior_points &&
        newValues.senior_points !== undefined
      ) {
        const oldPoints =
          oldValues.senior_points !== null ? oldValues.senior_points : "0";
        const newPoints = newValues.senior_points;
        changes.push(
          `<div><strong>Senior Punkte:</strong> ${oldPoints} → ${newPoints}</div>`,
        );
      }

      // Check hotel changes for both tables
      if (oldValues.bonus_points_clerk !== newValues.bonus_points_clerk) {
        changes.push(
          `<div><strong>Clerk Punkte:</strong> ${oldValues.bonus_points_clerk} → ${newValues.bonus_points_clerk}</div>`,
        );
      }
      if (oldValues.bonus_points_senior !== newValues.bonus_points_senior) {
        changes.push(
          `<div><strong>Senior Punkte:</strong> ${oldValues.bonus_points_senior} → ${newValues.bonus_points_senior}</div>`,
        );
      }

      // Check for hotel_id changes in bonus_hotel_points
      if (
        entry.table_name === "bonus_hotel_points" &&
        oldValues.hotel_id !== newValues.hotel_id &&
        oldValues.hotel_id !== undefined &&
        newValues.hotel_id !== undefined
      ) {
        changes.push(
          `<div><strong>Hotel ID geändert:</strong> ${oldValues.hotel_id} → ${newValues.hotel_id}</div>`,
        );
      }

      // Check for soft deletion (when deleted_at is set from null to a date)
      if (
        (oldValues.deleted_at === null || oldValues.deleted_at === undefined) &&
        newValues.deleted_at !== null &&
        newValues.deleted_at !== undefined
      ) {
        changes.push(`<div><strong>Gelöscht</strong></div>`);
      }

      // Check for restoration (when deleted_at is set from a date to null)
      if (
        oldValues.deleted_at !== null &&
        oldValues.deleted_at !== undefined &&
        (newValues.deleted_at === null || newValues.deleted_at === undefined)
      ) {
        changes.push(`<div><strong>Wiederhergestellt</strong></div>`);
      }

      changesHtml = changes.join("");
    }

    return {
      changed_at: formattedDate,
      changed_by: entry.employee_name || "Direkt in Datenbank",
      table_name: tableName,
      operation: operation,
      changes: changesHtml,
    };
  });
});

// Format date string with error handling
const formatDateString = (dateString) => {
  if (!dateString) return "-";
  try {
    return format(parseISO(dateString), "dd.MM.yyyy");
  } catch (e) {
    console.error("Date formatting error:", e, dateString);
    return dateString;
  }
};

// Check if historical values have changed
const hasHistoricalValuesChanged = (oldValues, newValues) => {
  if (!oldValues && !newValues) return false;
  if (!oldValues && newValues) return true;
  if (oldValues && !newValues) return true;

  // Convert strings to arrays if needed
  const oldArray =
    typeof oldValues === "string" ? JSON.parse(oldValues) : oldValues;
  const newArray =
    typeof newValues === "string" ? JSON.parse(newValues) : newValues;

  // Check if arrays are different by length first
  if (!Array.isArray(oldArray) || !Array.isArray(newArray)) return true;
  if (oldArray.length !== newArray.length) return true;

  // For simplicity, we'll consider any change to the array a change
  // A more complex implementation would compare each element
  return JSON.stringify(oldArray) !== JSON.stringify(newArray);
};

// Get a description of what changed in historical values
const getHistoricalValuesChangeDescription = (oldValues, newValues) => {
  try {
    // Convert strings to arrays if needed
    const oldArray =
      typeof oldValues === "string" ? JSON.parse(oldValues) : oldValues || [];
    const newArray =
      typeof newValues === "string" ? JSON.parse(newValues) : newValues || [];

    if (!Array.isArray(oldArray) || !Array.isArray(newArray)) {
      return "Format geändert";
    }

    if (oldArray.length < newArray.length) {
      const diff = newArray.length - oldArray.length;
      return `${diff} neue Version${diff > 1 ? "en" : ""} hinzugefügt`;
    } else if (oldArray.length > newArray.length) {
      const diff = oldArray.length - newArray.length;
      return `${diff} Version${diff > 1 ? "en" : ""} entfernt`;
    } else {
      return "Versionen aktualisiert";
    }
  } catch (e) {
    console.error("Error parsing historical values:", e);
    return "Änderung im Versionsverlauf";
  }
};

// Fetch the latest history entry
const fetchLatestHistoryEntry = async () => {
  const { data, error } = await supabase
    .from("bonus_config_history")
    .select("*")
    .order("changed_at", { ascending: false })
    .limit(1);

  if (error) {
    console.error("Error fetching history:", error);
  } else if (data && data.length > 0) {
    latestHistoryEntry.value = data[0];
  }
};

// Fetch all history entries for the dialog
const fetchHistoryEntries = async () => {
  loadingHistory.value = true;
  const { data, error } = await supabase
    .from("bonus_config_history")
    .select(
      `
      *,
      employees (
        first_name,
        last_name
      )
    `,
    )
    .order("changed_at", { ascending: false })
    .limit(100);

  if (error) {
    console.error("Error fetching history:", error);
  } else {
    historyEntries.value = data.map((entry) => ({
      ...entry,
      employee_name: entry.employees
        ? `${entry.employees.first_name} ${entry.employees.last_name}`
        : "Unbekannt",
    }));
  }
  loadingHistory.value = false;
};

// Open history dialog and load data
const openDialog = async () => {
  showDialog.value = true;
  await fetchHistoryEntries();
};

// Close dialog
const closeDialog = () => {
  showDialog.value = false;
  emit("update:showDialog", false);
};

// Expose methods to parent component
defineExpose({
  openDialog,
  fetchLatestHistoryEntry,
});

onMounted(() => {
  fetchLatestHistoryEntry();
});
</script>
