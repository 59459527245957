import { ref } from "vue";

const snackbar = ref({ isOpen: false, text: "", color: "" });

export default function useSnackbar() {
  const showError = (message) => {
    snackbar.value = {
      isOpen: true,
      text: message,
      color: "error",
    };
  };

  const showSuccess = (message) => {
    snackbar.value = {
      isOpen: true,
      text: message,
      color: "success",
    };
  };

  const showInfo = (message) => {
    snackbar.value = {
      isOpen: true,
      text: message,
      color: "info",
    };
  };

  return { snackbar, showError, showSuccess, showInfo };
}
