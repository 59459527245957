<template>
  <v-row>
    <v-col cols="3" align="center">
      <v-list height="1000px">
        <v-list-item
          @click="openRole(role, index)"
          v-for="(role, index) in roles"
          :class="role.activated ? 'bg-blue-grey-lighten-2' : null"
          >{{ role.name }}</v-list-item
        >
        <v-list-item v-if="addRole.show">
          <v-card class="py-4" variant="outlined">
            <v-card-text>
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="addRole.name"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="saveNewRole(addRole)" :loading="loading"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-list-item>
        <v-btn class="mt-2" color="success" @click="addRole.show = true"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-list>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="9">
      <v-row class="mt-4">
        <v-col cols="6">
          <h3>
            <v-text-field
              v-model="chosenRole.name"
              append-icon="mdi-content-save"
              @click:append="updateRole(chosenRole)"
            ></v-text-field>
          </h3>
        </v-col>
        <v-col cols="6" align="right">
          <v-btn
            :loading="loading"
            color="error"
            @click="deleteRole(chosenRole)"
            >Löschen</v-btn
          >
        </v-col>
        <v-tabs v-model="tab">
          <v-tab value="members">Mitglieder</v-tab>
          <v-tab value="rights">Zugriffsrechte</v-tab>
          <v-tab value="reminder">Erinnerungen</v-tab>
        </v-tabs>
      </v-row>
      <v-row>
        <v-col>
          <v-window v-model="tab">
            <v-window-item value="members">
              <Users :chosenRole="chosenRole" :index="index"></Users>
            </v-window-item>
            <v-window-item value="rights">
              <v-expansion-panels color="blue-grey-darken-1">
                <v-expansion-panel title="Daten">
                  <v-expansion-panel-text>
                    <Data :chosenRole="chosenRole" :index="index"></Data>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Dokumente">
                  <v-expansion-panel-text>
                    <Documents
                      :chosenRole="chosenRole"
                      :index="index"
                    ></Documents>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Segmente">
                  <v-expansion-panel-text>
                    <Segments
                      :chosenRole="chosenRole"
                      :index="index"
                    ></Segments>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Emails">
                  <v-expansion-panel-text>
                    <Emails :chosenRole="chosenRole" :index="index"></Emails>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Sonderrechte">
                  <v-expansion-panel-text>
                    <SpecialRights
                      :chosenRole="chosenRole"
                      :index="index"
                    ></SpecialRights>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Dashboard">
                  <v-expansion-panel-text>
                    <Dashboard
                      :chosenRoleId="chosenRole.id"
                      :index="index"
                    ></Dashboard>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel title="Standorte">
                  <v-expansion-panel-text>
                    <Locations
                      :chosenRole="chosenRole"
                      :index="index"
                    ></Locations>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-window-item>
            <v-window-item value="reminder">
              <Reminder
                v-if="chosenRole.id"
                :chosenRole="chosenRole"
                :index="index"
              ></Reminder>
            </v-window-item>
            <v-window-item value="dashboard">
              <Dashboard
                v-if="chosenRole.id"
                :chosenRole="chosenRole"
                :index="index"
              ></Dashboard>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import Users from "@/components/Settings/Admin/RoleManagement/Users.vue";
import Reminder from "@/components/Settings/Admin/RoleManagement/Reminder.vue";
import Data from "@/components/Settings/Admin/RoleManagement/Data.vue";
import Documents from "@/components/Settings/Admin/RoleManagement/Documents.vue";
import Segments from "@/components/Settings/Admin/RoleManagement/Segments.vue";
import Emails from "@/components/Settings/Admin/RoleManagement/Emails.vue";
import SpecialRights from "@/components/Settings/Admin/RoleManagement/SpecialRights.vue";
import Dashboard from "@/components/Settings/Admin/RoleManagement/Dashboard.vue";
import Locations from "@/components/Settings/Admin/RoleManagement/Locations.vue";
</script>
<script>
export default {
  data() {
    return {
      addRole: {
        show: false,
        name: null,
      },
      loading: false,
      roles: [],
      chosenRole: {},
      index: 0,
      users: [],
      tab: null,
    };
  },
  methods: {
    async getRoles() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        select:
          "*,public_user_role_section_matching(*),public_user_role_segment_matching(*),public_user_role_document_matching(*),public_user_role_matching(*,public_users(full_name,id,occupation))",
        table: "public_user_roles",
      });
      this.roles = response.data;
      for (var i = 0; i < this.roles.length; i++) {
        this.roles[i].sections = await this.getSectionData(
          this.roles[i].id,
          this.roles[i].public_user_role_section_matching,
        );
        this.roles[i].documents = await this.getDocumentData(
          this.roles[i].id,
          this.roles[i].public_user_role_document_matching,
        );
        this.roles[i].segments = await this.getSegmentData(
          this.roles[i].id,
          this.roles[i].public_user_role_segment_matching,
        );
        this.roles[i].users = this.getUsers(this.roles[i]);
        this.roles[i].emailGroups = await this.getEmailGroups(this.roles[i]);
      }
      this.chosenRole = this.roles[this.index];
      this.tab = "members";
    },
    getUsers(role) {
      var users = [];
      for (var i = 0; i < this.users.length; i++) {
        var user = this.users[i];
        var pushToArray = true;
        for (var j = 0; j < role.public_user_role_matching.length; j++) {
          var checkUser = role.public_user_role_matching[j];
          if (user.id == checkUser.user_id) {
            var pushToArray = false;
          }
        }
        if (pushToArray) {
          users.push(user);
        }
      }
      return users;
    },
    async getEmailGroups(role) {
      var queryObject = {
        table: "email_groups",
        select:
          "*,public_user_role_email_group_matching(*,public_user_roles(*))",
        where: [
          {
            type: "eq",
            key: "public_user_role_email_group_matching.public_user_role_id",
            value: role.id,
          },
          { type: "is", key: "use_frontend", value: true },
        ],
      };
      let response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var emailGroups = response.data;
      emailGroups.forEach((group) => {
        if (
          group.public_user_role_email_group_matching.length > 0 &&
          group.public_user_role_email_group_matching[0].is_checked
        ) {
          group.is_checked = true;
        } else {
          group.is_checked = false;
        }
      });
      return emailGroups;
    },
    async getSectionData(roleId, matching) {
      var queryObject = {
        select: "*,public_user_role_section_matching(*)",
        table: "employee_data_sections",
        where: [
          {
            type: "eq",
            key: "public_user_role_section_matching.role_id",
            value: roleId,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        var matching = item.public_user_role_section_matching[0];
        item.rights = {
          show: [
            {
              key: "show_own_data",
              value: matching ? matching.show_own_data : false,
              label: "Eigene",
            },
            {
              key: "show_nightshift_data",
              value: matching ? matching.show_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "show_all_data",
              value: matching ? matching.show_all_data : false,
              label: "Alle",
            },
          ],
          suggest: [
            {
              key: "suggest_own_data",
              value: matching ? matching.suggest_own_data : false,
              label: "Eigene",
            },
            {
              key: "suggest_nightshift_data",
              value: matching ? matching.suggest_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "suggest_all_data",
              value: matching ? matching.suggest_all_data : false,
              label: "Alle",
            },
          ],
          edit: [
            {
              key: "edit_own_data",
              value: matching ? matching.edit_own_data : false,
              label: "Eigene",
            },
            {
              key: "edit_nightshift_data",
              value: matching ? matching.edit_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "edit_all_data",
              value: matching ? matching.edit_all_data : false,
              label: "Alle",
            },
          ],
        };
      });
      return response.data;
    },
    async getDocumentData(roleId, matching) {
      var queryObject = {
        select: "*,public_user_role_document_matching(*)",
        table: "employee_document_categories",
        where: [
          {
            type: "eq",
            key: "public_user_role_document_matching.role_id",
            value: roleId,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        var matching = item.public_user_role_document_matching[0];
        item.rights = {
          show: [
            {
              key: "show_own_data",
              value: matching ? matching.show_own_data : false,
              label: "Eigene",
            },
            {
              key: "show_nightshift_data",
              value: matching ? matching.show_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "show_all_data",
              value: matching ? matching.show_all_data : false,
              label: "Alle",
            },
          ],
          suggest: [
            {
              key: "suggest_own_data",
              value: matching ? matching.suggest_own_data : false,
              label: "Eigene",
            },
            {
              key: "suggest_nightshift_data",
              value: matching ? matching.suggest_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "suggest_all_data",
              value: matching ? matching.suggest_all_data : false,
              label: "Alle",
            },
          ],
          edit: [
            {
              key: "edit_own_data",
              value: matching ? matching.edit_own_data : false,
              label: "Eigene",
            },
            {
              key: "edit_nightshift_data",
              value: matching ? matching.edit_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "edit_all_data",
              value: matching ? matching.edit_all_data : false,
              label: "Alle",
            },
          ],
        };
      });
      response.data.sort((a, b) => a.name.localeCompare(b.name));
      return response.data;
    },
    async getSegmentData(roleId, matching) {
      var queryObject = {
        select: "*,public_user_role_segment_matching(*)",
        table: "employee_segments",
        where: [
          {
            type: "eq",
            key: "public_user_role_segment_matching.role_id",
            value: roleId,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        var matching = item.public_user_role_segment_matching[0];
        item.rights = {
          show: [
            {
              key: "show_own_data",
              value: matching ? matching.show_own_data : false,
              label: "Eigene",
            },
            {
              key: "show_nightshift_data",
              value: matching ? matching.show_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "show_all_data",
              value: matching ? matching.show_all_data : false,
              label: "Alle",
            },
          ],
          suggest: [
            {
              key: "suggest_own_data",
              value: matching ? matching.suggest_own_data : false,
              label: "Eigene",
            },
            {
              key: "suggest_nightshift_data",
              value: matching ? matching.suggest_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "suggest_all_data",
              value: matching ? matching.suggest_all_data : false,
              label: "Alle",
            },
          ],
          edit: [
            {
              key: "edit_own_data",
              value: matching ? matching.edit_own_data : false,
              label: "Eigene",
            },
            {
              key: "edit_nightshift_data",
              value: matching ? matching.edit_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "edit_all_data",
              value: matching ? matching.edit_all_data : false,
              label: "Alle",
            },
          ],
        };
      });
      response.data.sort((a, b) => a.name.localeCompare(b.name));
      return response.data;
    },
    async addEmployee(item) {
      this.loading = true;
      var queryObject = {
        table: "public_user_role_matching",
        payload: {
          user_id: item,
          role_id: this.chosenRole.id,
        },
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      await this.getRoles();
      item = null;
      this.loading = false;
    },
    async removeEmployee(user) {
      this.loading = true;
      var queryObject = {
        table: "public_user_role_matching",
        id: user.id,
      };
      this.$store.dispatch("deleteSupabaseEntity", queryObject);
      await this.getRoles();
      this.loading = false;
    },
    async saveNewRole(role) {
      this.loading = true;
      await this.$store.dispatch("createSupabaseEntity", {
        table: "public_user_roles",
        payload: { name: role.name },
      });
      await this.getRoles();
      this.addRole = {
        show: false,
        name: null,
      };
      this.loading = false;
    },
    async openRole(role, index) {
      this.chosenRole = {};
      this.index = index;
      this.roles.forEach(function (item) {
        if (item.id == role.id) {
          item.activated = true;
        } else {
          item.activated = false;
        }
      });
      this.chosenTemplate = {};
      await this.$store.dispatch("sleep");
      this.chosenRole = role;
    },
    async updateRole(chosenRole) {
      var object = {
        table: "public_user_roles",
        payload: {
          id: chosenRole.id,
          name: chosenRole.name,
        },
      };
      await this.$store.dispatch("upsertSupabaseEntity", object);
    },
    async deleteRole(chosenRole) {
      this.loading = true;
      var object = {
        table: "public_user_roles",
        id: chosenRole.id,
      };
      await this.$store.dispatch("deleteSupabaseEntity", object);
      this.loading = false;
      await this.getRoles();
    },
    async updateRights(section, check) {
      var payload = {
        role_id: this.chosenRole.id,
        section_id: section.id,
      };
      payload[check.key] = check.value;
      this.$store.dispatch("upsertSupabaseEntity", {
        table: "public_user_role_section_matching",
        payload,
      });
    },
  },
  async created() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
      where: [{ type: "is", key: "active", value: true }],
    });
    this.users = response.data;
    this.getRoles();
  },
};
</script>
