<template>
  <v-row>
    <v-col>
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    </v-col>
    <v-col cols="12" align="center">
      <v-btn color="green" @click="reminders.push({ role_id: chosenRole.id })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <v-expansion-panels color="blue-grey-darken-1" class="ma-2">
      <v-expansion-panel v-for="(reminder, index) in reminders">
        <v-expansion-panel-title v-if="reminder.id">
          <span class="mr-1">Erinnerung für</span>
          <span class="mx-1 font-weight-bold">{{
            roles.find((item) => reminder.to_remind_role_id == item.id).name
          }}</span>
          <span class="mx-1">an</span>
          <span class="mx-1 font-weight-bold">{{
            dates.find((item) => reminder.employee_data_key_id == item.id)
              .key_name_ger
          }}</span>
          <span class="mx-1 font-weight-bold">{{
            reminder.time_unit_count +
            " " +
            timeUnits.find((item) => reminder.time_unit == item.value).label
          }}</span>
          <span class="mx-1 font-weight-bold">{{
            reminder.reminder_after ? "nachher" : "vorher"
          }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-title v-else
          >Neue Erinnerung</v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <v-card variant="outlined">
            <v-card-text>
              <v-row class="mt-3">
                <v-col cols="6">
                  <v-select
                    variant="outlined"
                    label="Erinnere an"
                    :items="dates"
                    item-title="key_name_ger"
                    item-value="id"
                    v-model="reminder.employee_data_key_id"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    variant="outlined"
                    label="Personengruppe"
                    :items="roles"
                    item-title="name"
                    item-value="id"
                    v-model="reminder.to_remind_role_id"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-card>
                    <v-card-title>Aufgabe hinzufügen</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            clearable
                            variant="outlined"
                            density="compact"
                            label="Aufgabentitel"
                            v-model="reminder.task_title"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-textarea
                            clearable
                            variant="outlined"
                            density="compact"
                            label="Aufgabentext"
                            v-model="reminder.task_note"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    variant="outlined"
                    type="number"
                    label="Zeit"
                    v-model="reminder.time_unit_count"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    variant="outlined"
                    label="Zeiteinheit"
                    :items="timeUnits"
                    item-title="label"
                    item-value="value"
                    v-model="reminder.time_unit"
                  ></v-select>
                </v-col>
                <v-col cols="6" align="center">
                  <v-btn-toggle v-model="reminder.reminder_after">
                    <v-btn :value="false">Vorher</v-btn>
                    <v-btn :value="true">Nachher</v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    :loading="loading"
                    class="mr-2"
                    icon
                    color="green-darken-2"
                    @click="updateReminder(reminder)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    icon
                    color="red-darken-2"
                    @click="deleteReminder(reminder, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      reminders: [],
      dates: [],
      roles: [],
      timeUnits: [
        { label: "Wochen", value: "week" },
        { label: "Tage", value: "day" },
      ],
    };
  },
  methods: {
    async getRoles() {
      var queryObject = {
        table: "public_user_roles",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.roles = response.data;
    },
    async getDates() {
      var queryObject = {
        table: "employee_data_keys",
        where: [{ type: "or", value: "type.eq.date,id.eq.29" }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        if (item.id == 29) {
          item.key_name_ger = "Probezeitende";
        }
      });
      this.dates = response.data;
    },
    async getReminders() {
      var queryObject = {
        select: "*",
        table: "employee_reminder_rules",
        where: [{ type: "eq", key: "role_id", value: this.chosenRole.id }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.reminders = response.data;
    },
    async updateReminder(reminder) {
      this.loading = true;
      if (reminder.id) {
        var queryObject = {
          table: "employee_reminder_rules",
          id: reminder.id,
          payload: reminder,
        };
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "employee_reminder_rules",
          payload: reminder,
        };
        await this.$store.dispatch("createSupabaseEntity", queryObject);
      }
      this.getReminders();
      this.loading = false;
    },
    async deleteReminder(reminder, index) {
      this.loading = true;
      if (reminder.id) {
        var queryObject = {
          table: "employee_reminder_rules",
          id: reminder.id,
        };
        await this.$store.dispatch("deleteSupabaseEntity", queryObject);
        this.getReminders();
      } else {
        this.reminders.splice(index, 1);
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getDates();
    await this.getRoles();
    await this.getReminders();
    this.loading = false;
  },
};
</script>
