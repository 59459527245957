<template>
  <v-card rounded elevation="2" class="mb-3 hotels-card">
    <v-card-title class="d-flex align-center py-2">
      <v-icon color="indigo" class="mr-2">mdi-domain</v-icon>
      Bonus Punkte pro Hotel
    </v-card-title>

    <v-card-text class="pt-2 pb-0">
      <!-- Filters row -->
      <v-row dense>
        <v-col cols="12" md="6">
          <!-- City filter -->
          <v-select
            v-model="selectedCity"
            :items="cities"
            item-title="city"
            item-value="id"
            label="Nach Stadt filtern"
            clearable
            dense
            outlined
            hide-details
            class="mb-3"
            @update:model-value="fetchHotels"
          >
            <template v-slot:item="{ item, props }">
              <v-list-item v-bind="props" :title="item.raw.city"></v-list-item>
            </template>
            <template v-slot:prepend-item>
              <v-list-item
                @click="
                  selectedCity = null;
                  fetchHotels();
                "
              >
                <v-list-item-title>Alle Städte mit Bonus</v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Search field -->
          <v-text-field
            v-model="searchQuery"
            label="Hotels suchen"
            prepend-inner-icon="mdi-magnify"
            clearable
            dense
            outlined
            hide-details
            class="mb-3"
            @input="debouncedSearch"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Scrollable content for hotels list -->
      <div class="scrollable-content">
        <!-- List of hotels with bonus points -->
        <v-row dense>
          <v-col cols="12" v-for="hotel in filteredHotels" :key="hotel.id">
            <v-card
              outlined
              :elevation="editingHotel === hotel.id ? 3 : 1"
              class="mb-2 pa-1"
            >
              <v-card-text class="py-1">
                <v-row dense class="d-flex align-center">
                  <v-col cols="12" md="5">
                    <div>
                      <strong>{{ hotel.name }}</strong>
                      <div class="text-caption text-grey">
                        {{ hotel.city_name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      v-model="hotel.clerk_points"
                      label="Clerk Punkte"
                      type="number"
                      dense
                      outlined
                      @input="hotelChanged = true"
                      :readonly="editingHotel !== hotel.id"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      v-model="hotel.senior_points"
                      label="Senior Punkte"
                      type="number"
                      dense
                      outlined
                      @input="hotelChanged = true"
                      :readonly="editingHotel !== hotel.id"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    class="d-flex justify-end align-center"
                  >
                    <div class="d-flex align-center">
                      <template v-if="editingHotel === hotel.id">
                        <v-btn
                          icon
                          color="success"
                          @click="updateHotelBonusPoints(hotel)"
                          :elevation="1"
                          size="x-small"
                          class="mr-1"
                        >
                          <v-icon small>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <v-btn
                        v-else
                        icon
                        @click="editingHotel = hotel.id"
                        :elevation="1"
                        size="x-small"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- Add effective dates fields when editing -->
                <v-row
                  v-if="editingHotel === hotel.id"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="6">
                    <v-text-field
                      v-model="hotel.effective_from"
                      label="Gültig ab"
                      type="date"
                      dense
                      outlined
                      hide-details
                      @input="hotelChanged = true"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Historical values expansion -->
                <v-row
                  v-if="editingHotel === hotel.id && hasHistoricalValues(hotel)"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="12">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-title>
                          Verlauf anzeigen ({{
                            getHistoricalValuesCount(hotel)
                          }}
                          Einträge)
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-list dense>
                            <v-list-item
                              v-for="(histEntry, hIndex) in getHistoricalValues(
                                hotel,
                              )"
                              :key="hIndex"
                            >
                              <v-list-item-title>
                                {{ formatDate(histEntry.effective_from) }} -
                                {{
                                  histEntry.effective_to
                                    ? formatDate(histEntry.effective_to)
                                    : "jetzt"
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Clerk Punkte: {{ histEntry.clerk_points }} |
                                Senior Punkte: {{ histEntry.senior_points }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

                <!-- Add new version button -->
                <v-row
                  v-if="editingHotel === hotel.id"
                  align="center"
                  dense
                  class="mt-2"
                >
                  <v-col cols="12" class="text-center">
                    <v-btn
                      small
                      color="primary"
                      outlined
                      @click="showNewVersionDialog(hotel)"
                    >
                      <v-icon left small>mdi-clock-outline</v-icon>
                      Neue Version erstellen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- Add button for showing the new hotel bonus points form -->
      <div class="text-center my-4">
        <v-btn
          color="blue-grey-darken-1"
          small
          outlined
          @click="showAddForm = !showAddForm"
        >
          <v-icon left small>{{
            showAddForm ? "mdi-minus" : "mdi-plus"
          }}</v-icon>
          {{ showAddForm ? "Abbrechen" : "" }}
        </v-btn>
      </div>

      <!-- Form to add new hotel bonus points -->
      <v-expand-transition>
        <div v-if="showAddForm" class="py-3">
          <v-divider class="mb-3"></v-divider>
          <div class="subtitle-2 mb-2">Neuen Hotel Bonus hinzufügen</div>
          <v-row align="center" dense>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="newBonusPoints.hotel_id"
                :items="availableHotels"
                item-title="name"
                item-value="id"
                label="Hotel auswählen"
                outlined
                dense
                hide-details
                :filter="customHotelFilter"
                :no-data-text="'Kein neues Hotel gefunden'"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props">
                    <v-list-item-subtitle>{{
                      item.raw.city_name
                    }}</v-list-item-subtitle>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                v-model="newBonusPoints.clerk_points"
                label="Clerk Punkte"
                type="number"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                v-model="newBonusPoints.senior_points"
                label="Senior Punkte"
                type="number"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" class="d-flex justify-end align-center">
              <v-btn
                color="blue-grey-darken-1"
                @click="addHotelBonusPoints"
                :disabled="!canAddBonus"
                icon
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>

  <!-- New Version Dialog -->
  <v-dialog v-model="newVersionDialog" max-width="500">
    <v-card>
      <v-card-title class="text-h5">Neue Version erstellen</v-card-title>
      <v-card-text>
        <p>Aktuelle Version abschließen und neue Version starten:</p>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="oldVersionEndDate"
              label="Alte Version gültig bis"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="newVersionEffectiveDate"
              label="Neue Version gültig ab"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="newVersionDialog = false">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="createNewVersion">
          Neue Version erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { supabase } from "@/supabase";
import {
  format,
  parseISO,
  lastDayOfQuarter,
  addDays,
  compareAsc,
} from "date-fns";
import debounce from "lodash/debounce";
import useSnackbar from "@/utils/useSnackbar";

// Initialize snackbar
const { showSuccess, showError } = useSnackbar();

// Data for hotels
const hotels = ref([]);
const availableHotels = ref([]);
const cities = ref([]);
const selectedCity = ref(null);
const editingHotel = ref(null);
const hotelChanged = ref(false);

// Search functionality
const searchQuery = ref("");

// Add form
const showAddForm = ref(false);
const newBonusPoints = ref({
  hotel_id: null,
  clerk_points: null,
  senior_points: null,
  effective_from: format(new Date(), "yyyy-MM-dd"),
});

// New version dialog
const newVersionDialog = ref(false);
const newVersionItem = ref(null);
const newVersionEffectiveDate = ref("");
const oldVersionEndDate = ref("");

// Computed property to check if a new bonus entry can be added
const canAddBonus = computed(() => {
  return (
    newBonusPoints.value.hotel_id &&
    (newBonusPoints.value.clerk_points !== null ||
      newBonusPoints.value.senior_points !== null)
  );
});

// Filtered hotels based on search query
const filteredHotels = computed(() => {
  if (!searchQuery.value) return hotels.value;

  const query = searchQuery.value.toLowerCase();
  return hotels.value.filter(
    (hotel) =>
      hotel.name?.toLowerCase().includes(query) ||
      hotel.city_name?.toLowerCase().includes(query),
  );
});

// Date formatting helper
const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return format(parseISO(dateString), "dd.MM.yyyy");
  } catch (e) {
    console.error("Date formatting error:", e);
    return dateString;
  }
};

// Create debounced search
const debouncedSearch = debounce(() => {
  // The filtering happens via the computed property
}, 300);

// Custom filter for hotel autocomplete
const customHotelFilter = (item, queryText) => {
  if (!queryText) return true;

  const query = queryText.toLowerCase();
  return (
    item.raw.name.toLowerCase().includes(query) ||
    item.raw.city_name?.toLowerCase().includes(query)
  );
};

// Sort historical values by effective_from date
const sortHistoricalValues = (values) => {
  if (!Array.isArray(values)) return [];

  return [...values].sort((a, b) => {
    try {
      return compareAsc(parseISO(a.effective_from), parseISO(b.effective_from));
    } catch (e) {
      console.error("Error sorting historical values:", e);
      return 0;
    }
  });
};

const hasHistoricalValues = (item) => {
  return item.historical_values && item.historical_values.length > 0;
};

const getHistoricalValues = (item) => {
  if (!item.historical_values) return [];
  const values = Array.isArray(item.historical_values)
    ? item.historical_values
    : [];
  return sortHistoricalValues(values);
};

const getHistoricalValuesCount = (item) => {
  return getHistoricalValues(item).length;
};

// Fetch cities that have bonus levels
const fetchCities = async () => {
  // First get the city IDs that have bonus levels
  const { data: bonusCities, error: bonusError } = await supabase
    .from("bonus_levels")
    .select("city_id")
    .not("city_id", "is", null);

  if (bonusError) {
    console.error("Error fetching cities with bonus levels:", bonusError);
    return;
  }

  // Extract unique city IDs
  const cityIds = [...new Set(bonusCities.map((item) => item.city_id))];

  if (cityIds.length === 0) {
    console.log("No cities found with bonus levels");
    return;
  }

  // Then fetch the city details for those IDs
  const { data, error } = await supabase
    .from("cities")
    .select("id, city")
    .eq("hotel_city", true)
    .in("id", cityIds)
    .order("city");

  if (error) {
    console.error("Error fetching cities:", error);
  } else {
    cities.value = data;
  }
};

// Fetch all available hotels for the dropdown
const fetchAvailableHotels = async () => {
  // Query for all hotels
  const query = supabase
    .from("hotels")
    .select(
      `
      id, 
      name,
      city_id,
      cities:city_id(city)
    `,
    )
    .eq("email", true)
    .order("created_at", { ascending: false });

  // Filter by city if selected
  if (selectedCity.value) {
    query.eq("city_id", selectedCity.value);
  } else if (cities.value.length > 0) {
    query.in(
      "city_id",
      cities.value.map((city) => city.id),
    );
  }

  // Execute the query
  const { data, error } = await query;

  if (error) {
    console.error("Error fetching available hotels:", error);
    showError("Fehler beim Laden der verfügbaren Hotels");
    return;
  }

  // Get current list of hotel IDs that already have bonus points
  const hotelsWithBonusIds = hotels.value.map((h) => h.id);

  // Transform data to include city names and filter out hotels with existing bonus points
  availableHotels.value = data
    .filter((hotel) => !hotelsWithBonusIds.includes(hotel.id))
    .map((hotel) => ({
      id: hotel.id,
      name: hotel.name,
      city_id: hotel.city_id,
      city_name: hotel.cities?.city || "Keine Stadt",
    }));
};

// Fetch hotels with bonus points
const fetchHotels = async () => {
  // First, get all city IDs that have bonus levels
  const { data: bonusCities, error: bonusError } = await supabase
    .from("bonus_levels")
    .select("city_id")
    .not("city_id", "is", null);

  if (bonusError) {
    console.error("Error fetching cities with bonus levels:", bonusError);
    showError("Fehler beim Laden der Städte mit Bonuslevels");
    return;
  }

  // Extract unique city IDs
  const cityIds = [...new Set(bonusCities.map((item) => item.city_id))];

  if (cityIds.length === 0) {
    console.log("No cities found with bonus levels");
    hotels.value = [];
    return;
  }

  // First get all hotels
  let hotelQuery = supabase
    .from("hotels")
    .select(
      `
      id, 
      name,
      city_id,
      cities:city_id (city),
      created_at
    `,
    )
    .eq("email", true)
    .in("city_id", cityIds);

  // Add city filter if selected
  if (selectedCity.value) {
    hotelQuery = hotelQuery.eq("city_id", selectedCity.value);
  }

  const { data: hotelsData, error: hotelsError } = await hotelQuery.order(
    "name",
    { ascending: true },
  );

  if (hotelsError) {
    console.error("Error fetching hotels:", hotelsError);
    showError("Fehler beim Laden der Hoteldaten");
    return;
  }

  // Now fetch bonus points for these hotels
  const hotelIds = hotelsData.map((hotel) => hotel.id);
  const { data: bonusData, error: bonusPointsError } = await supabase
    .from("bonus_hotel_points")
    .select("*")
    .in("hotel_id", hotelIds);

  if (bonusPointsError) {
    console.error("Error fetching bonus points:", bonusPointsError);
    showError("Fehler beim Laden der Bonuspunkte");
    return;
  }

  // Map bonus data to hotels
  const hotelMap = hotelsData.map((hotel) => {
    // Find bonus data for this hotel (most recent if multiple)
    const hotelBonus =
      bonusData
        .filter((b) => b.hotel_id === hotel.id)
        .sort(
          (a, b) => new Date(b.effective_from) - new Date(a.effective_from),
        )[0] || {};

    // Process historical values
    let historicalValues = [];
    if (hotelBonus.historical_values) {
      if (typeof hotelBonus.historical_values === "string") {
        try {
          historicalValues = JSON.parse(hotelBonus.historical_values);
        } catch (e) {
          console.error("Error parsing historical values:", e);
          historicalValues = [];
        }
      } else {
        historicalValues = hotelBonus.historical_values;
      }
    }

    return {
      id: hotel.id,
      name: hotel.name,
      city_id: hotel.city_id,
      city_name: hotel.cities?.city || "Keine Stadt",
      clerk_points: hotelBonus.clerk_points || null,
      senior_points: hotelBonus.senior_points || null,
      bonus_id: hotelBonus.id || null,
      effective_from: hotelBonus.effective_from
        ? hotelBonus.effective_from.substring(0, 10)
        : format(new Date(), "yyyy-MM-dd"),
      historical_values: historicalValues,
    };
  });

  hotels.value = hotelMap;
};

// Add new hotel bonus points
const addHotelBonusPoints = async () => {
  // Get selected hotel info
  const selectedHotel = availableHotels.value.find(
    (h) => h.id === newBonusPoints.value.hotel_id,
  );

  if (!selectedHotel) {
    showError("Bitte wählen Sie ein Hotel aus");
    return;
  }

  // Check if hotel already has bonus points
  const existingHotel = hotels.value.find((h) => h.id === selectedHotel.id);
  if (existingHotel) {
    showError("Dieses Hotel hat bereits Bonuspunkte");
    return;
  }

  const bonusData = {
    hotel_id: selectedHotel.id,
    clerk_points: Number(newBonusPoints.value.clerk_points) || null,
    senior_points: Number(newBonusPoints.value.senior_points) || null,
    effective_from: newBonusPoints.value.effective_from,
    historical_values: [],
  };

  const { error } = await supabase.from("bonus_hotel_points").insert(bonusData);

  if (error) {
    console.error("Error adding hotel bonus points:", error);
    showError("Fehler beim Hinzufügen der Hotel Bonuspunkte");
  } else {
    showSuccess("Hotel Bonuspunkte hinzugefügt");
    // Reset form
    newBonusPoints.value = {
      hotel_id: null,
      clerk_points: null,
      senior_points: null,
      effective_from: format(new Date(), "yyyy-MM-dd"),
    };
    showAddForm.value = false;
    fetchHotels();
  }
};

// New function to update bonus points in hotels table
const updateHotelBonusInHotelsTable = async (
  hotelId,
  clerkPoints,
  seniorPoints,
) => {
  const { error } = await supabase
    .from("hotels")
    .update({
      bonus_points_clerk: clerkPoints,
      bonus_points_senior: seniorPoints,
    })
    .eq("id", hotelId);

  if (error) {
    console.error("Error updating bonus points in hotels table:", error);
    // Not showing error to user as this is a secondary operation
  } else {
    console.log("Hotel bonus points updated in hotels table");
  }
};

// Update hotel bonus points
const updateHotelBonusPoints = async (hotel) => {
  if (!hotelChanged.value) {
    editingHotel.value = null;
    return;
  }

  // Format effective_from using date-fns if it exists
  let formattedEffectiveFrom = hotel.effective_from;
  if (formattedEffectiveFrom) {
    try {
      const parsedDate = parseISO(formattedEffectiveFrom);
      formattedEffectiveFrom = format(parsedDate, "yyyy-MM-dd");
    } catch (e) {
      console.error("Error formatting effective_from date:", e);
    }
  } else {
    formattedEffectiveFrom = format(new Date(), "yyyy-MM-dd");
  }

  const bonusData = {
    hotel_id: hotel.id,
    clerk_points: Number(hotel.clerk_points) || null,
    senior_points: Number(hotel.senior_points) || null,
    effective_from: formattedEffectiveFrom,
    historical_values: hotel.historical_values || [],
  };

  let result;
  if (hotel.bonus_id) {
    // Update existing record
    result = await supabase
      .from("bonus_hotel_points")
      .update(bonusData)
      .eq("id", hotel.bonus_id);
  } else {
    // Insert new record
    result = await supabase
      .from("bonus_hotel_points")
      .insert(bonusData)
      .select();
  }

  const { error } = result;

  if (error) {
    console.error("Error updating hotel bonus points:", error);
    showError("Fehler beim Aktualisieren der Hotel Bonuspunkte");
  } else {
    // Also update points in hotels table (remove in the future)
    await updateHotelBonusInHotelsTable(
      hotel.id,
      Number(hotel.clerk_points) || null,
      Number(hotel.senior_points) || null,
    );

    showSuccess("Hotel Bonuspunkte aktualisiert");
    fetchHotels();
  }

  editingHotel.value = null;
  hotelChanged.value = false;
};

const finishEditing = () => {
  editingHotel.value = null;
  hotelChanged.value = false;
};

// New version dialog functions
const showNewVersionDialog = (hotel) => {
  newVersionItem.value = hotel;
  const today = new Date();

  // Get the end of the current quarter
  const quarterEnd = lastDayOfQuarter(today);
  oldVersionEndDate.value = format(quarterEnd, "yyyy-MM-dd");

  // Set new version date to day after quarter end
  const newVersionDate = addDays(quarterEnd, 1);
  newVersionEffectiveDate.value = format(newVersionDate, "yyyy-MM-dd");

  newVersionDialog.value = true;
};

// Create new version function
const createNewVersion = async () => {
  if (!newVersionItem.value) return;

  const hotel = newVersionItem.value;

  // Create historical entry with current values
  const historicalEntry = {
    effective_from: hotel.effective_from || format(new Date(), "yyyy-MM-dd"),
    effective_to: oldVersionEndDate.value,
    clerk_points: Number(hotel.clerk_points) || null,
    senior_points: Number(hotel.senior_points) || null,
  };

  // Ensure historical_values is an array
  if (!hotel.historical_values) {
    hotel.historical_values = [];
  } else if (typeof hotel.historical_values === "string") {
    try {
      hotel.historical_values = JSON.parse(hotel.historical_values);
    } catch (e) {
      console.error("Error parsing historical values:", e);
      hotel.historical_values = [];
    }
  }

  // Add to history
  hotel.historical_values.push(historicalEntry);

  // Sort historical values chronologically
  hotel.historical_values = sortHistoricalValues(hotel.historical_values);

  // Set new effective date for current item
  hotel.effective_from = newVersionEffectiveDate.value;

  // Update only versioning info, not the current values
  const updateData = {
    historical_values: hotel.historical_values,
    effective_from: newVersionEffectiveDate.value,
  };

  // Update existing record
  const { error, data } = await supabase
    .from("bonus_hotel_points")
    .update(updateData)
    .eq("id", hotel.bonus_id);

  if (error) {
    console.error("Error updating hotel bonus points with new version:", error);
    showError("Fehler beim Erstellen der neuen Version");
  } else {
    showSuccess("Neue Version erstellt");
    fetchHotels();
    newVersionDialog.value = false;
  }
};

// Watch for city changes
watch(selectedCity, async () => {
  await fetchHotels();
  fetchAvailableHotels();
});

watch(cities, (newCities) => {
  if (newCities.length > 0) {
    fetchAvailableHotels();
  }
});

// Fetch data on component mount
onMounted(() => {
  fetchCities();
  fetchHotels().then(() => {
    fetchAvailableHotels();
  });
});
</script>

<style scoped>
.hotels-card {
  background-color: #f8f9fa;
}

.scrollable-content {
  max-height: 800px;
  overflow-y: auto;
}
</style>
