<template>
  <router-view />
</template>

<script>
export default {
  async mounted() {
    await this.$store.commit("initializeStore");
  },
};
</script>

<style>
html {
  overscroll-behavior: none;
}
</style>
