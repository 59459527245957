<template>
  <div>
    <v-tooltip text="Einarbeitungsstatus ändern" location="bottom">
      <template v-slot:activator="{ props }">
        <v-icon
          v-bind="props"
          @click="openStatusDialog"
          class="cursor-pointer"
          :color="iconColor"
        >
          mdi-chart-gantt
        </v-icon>
      </template>
    </v-tooltip>

    <v-dialog v-model="dialog.isOpen" max-width="500">
      <v-card>
        <v-card-title class="headline bg-blue-grey-darken-2 text-white">
          Einarbeitungsstatus ändern
        </v-card-title>
        <v-card-text class="pt-4">
          <div class="mb-4">
            Aktueller Status:
            <v-chip
              :color="currentStatus?.color"
              class="ml-2"
              v-if="currentStatus"
            >
              {{ currentStatus?.label }}
            </v-chip>
            <span v-else class="ml-2 text-grey">Nicht gesetzt</span>
          </div>

          <v-select
            v-model="dialog.selectedStatusId"
            :items="trainingStatuses"
            item-title="label"
            item-value="id"
            label="Neuer Status"
            variant="outlined"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" text @click="dialog.isOpen = false">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey-darken-2"
            variant="text"
            @click="changeTrainingStatus"
            :loading="dialog.isSubmitting"
            :disabled="!dialog.selectedStatusId"
          >
            Status ändern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, reactive } from "vue";
import { supabase } from "@/supabase";
import useSnackbar from "@/utils/useSnackbar";

const { showError, showSuccess } = useSnackbar();

const props = defineProps({
  trainingHotelResultsId: {
    type: Number,
    required: true,
  },
  currentStatusId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["status-changed"]);

const trainingStatuses = ref([]);
const currentStatus = ref(null);

const dialog = reactive({
  isOpen: false,
  selectedStatusId: null,
  comment: "",
  isSubmitting: false,
});

async function fetchTrainingStatuses() {
  try {
    const { data, error } = await supabase
      .from("training_statuses")
      .select("*")
      .order("id");

    if (error) throw error;

    trainingStatuses.value = data || [];

    // Update current status
    if (props.currentStatusId) {
      const foundStatus = trainingStatuses.value.find(
        (status) => status.id === props.currentStatusId,
      );
      if (foundStatus) {
        currentStatus.value = foundStatus;
      }
    }
  } catch (error) {
    console.error("Fehler beim Laden der Trainings-Status:", error);
    showError("Fehler beim Laden der Trainings-Status");
  }
}

const iconColor = computed(() => {
  return currentStatus.value?.color || "grey";
});

const openStatusDialog = () => {
  dialog.isOpen = true;
};

async function changeTrainingStatus() {
  try {
    dialog.isSubmitting = true;

    if (!dialog.selectedStatusId) {
      showError("Bitte wählen Sie einen Status aus");
      return;
    }

    const selectedStatus = trainingStatuses.value.find(
      (status) => status.id === dialog.selectedStatusId,
    );

    // Update training_hotel_results with new status
    const { error } = await supabase
      .from("training_hotel_results")
      .update({
        status_id: dialog.selectedStatusId,
        completed_training_at: selectedStatus.is_completed
          ? new Date().toISOString()
          : null,
      })
      .eq("id", props.trainingHotelResultsId);

    if (error) throw error;

    // Update the current status
    currentStatus.value = selectedStatus;

    showSuccess(`Status erfolgreich auf "${selectedStatus?.label}" geändert`);
    emit("status-changed", dialog.selectedStatusId);
    dialog.isOpen = false;
  } catch (error) {
    console.error("Fehler beim Ändern des Status:", error);
    showError("Fehler beim Ändern des Status");
  } finally {
    dialog.isSubmitting = false;
  }
}

onMounted(() => {
  fetchTrainingStatuses();
});
</script>
