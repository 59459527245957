<template>
  <v-col v-if="$route.params.id > 0" cols="12" sm="12">
    <!--<Process></Process>-->
  </v-col>
  <v-row class="ma-2">
    <v-col cols="12" sm="12">
      <JobInfo v-if="jobInfo" :jobInfo="jobInfo"></JobInfo>
      <FileComponent v-if="!newJob && jobInfo.id" bucket="jobs"></FileComponent>
    </v-col>

    <v-col cols="12"> </v-col>
  </v-row>
</template>

<script setup>
import JobInfo from "@/components/Jobs/Info.vue";
import Process from "@/components/Jobs/Process.vue";
import FileComponent from "@/components/CommonComponents/Files.vue";
</script>

<script>
export default {
  data() {
    return {
      jobInfo: {},
      newJob: null,
      tab: "notes",
    };
  },
  watch: {
    $route(to, from) {
      // Check if the route parameter has changed
      if (from.name === to.name && to.params.id !== from.params.id) {
        // Fetch data when the parameter changes
        this.getJob();
      }
    },
  },
  methods: {
    async getJob() {
      if (this.$route.params.id > 0) {
        var queryObject = {
          select: "*",
          table: "recruiting_jobs",
          where: [
            {
              type: "eq",
              key: "id",
              value: this.$route.params.id,
            },
          ],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.jobInfo = response.data[0];
      }
    },
  },
  async created() {
    await this.getJob();
  },
};
</script>
