<template>
    <v-container>
      <v-card>
          <v-card-title class="mb-1">
            <v-row justify="center" align="center" class="fill-height">
              <v-col cols="12" class="text-center">
                <h3>Übergabeprotokoll Bellcaptain Accessoires</h3>
              </v-col>
            </v-row>
            <v-btn variant="outlined" icon @click="closeDialog" class="position-absolute top-0 right-0 mt-0 mr-0">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-divider :thickness="4"></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
                <v-col cols="6">
            <v-autocomplete
              class="mt-10"
              variant="outlined"
              v-model="selectedEmployee"
              :items="employees"
              item-title="name"
              item-value="id"
              label="Wählen Sie einen Mitarbeiter"
              clearable
            ></v-autocomplete>
                </v-col>
                <v-col cols="6">
            <v-autocomplete
            class="mt-10"
            variant="outlined"
            v-model="selectedCandidate"
            :items="candidates"
            item-title="full_name"
            item-value="id"
            label="Wählen Sie einen Kandidaten"
            clearable 
            ></v-autocomplete>
        </v-col>
        </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
              <v-text-field variant="outlined" type="date" v-model="date" class="date-center" hide-details :style="{maxWidth: '200px'}" density="compact"></v-text-field>
              <v-card-text
                align="center"
                class="font-weight-black mt-10"
                style="font-size: 18px"
                >Accessoires:</v-card-text
              >
              <v-expansion-panels variant="popout" v-model="activePanel">
                <v-expansion-panel
                  v-for="accessory in accessoryButtons"
                  :key="accessory.id"
                >
                  <v-expansion-panel-title class="bg-grey-lighten-3">
                    {{ accessory.name }}
                    <v-row>
                      <v-col cols="12" align="right">
                        <v-chip size="small" color="green" variant="elevated">
                          {{ selectedNumbers[accessory.id] || inventoryCounts[accessory.id] || "0" }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-title>
  
                  <v-expansion-panel-text>
                    <v-col
                      v-if="selectedChips[accessory.id] != 'Inventar'"
                      cols="12"
                      align="center"
                      class="font-italic font-weight-bold"
                      >Anzahl:</v-col
                    >
                    <v-col v-if="selectedChips[accessory.id] != 'Inventar'" cols="12" align="center" class="pb-0">
                      <v-btn
                        v-for="number in 4"
                        class="ml-2"
                        :key="`${accessory.id}-${number}`"
                        :variant="selectedNumbers[accessory.id] === number ? 'flat' : 'outlined'"
                        small
                        size="small"
                        :color="
                          selectedNumbers[accessory.id] === number
                            ? 'success'
                            : 'default'
                        "                      
                        @click="selectNumber(accessory.id, number)"
                      >
                        {{ number }}
                      </v-btn>
                    </v-col>
                  </v-expansion-panel-text>
  
                  <v-expansion-panel-text>
                    <v-col
                      cols="12"
                      align="center"
                      class="font-italic font-weight-bold"
                      >Grund:</v-col
                    >
                    <v-col cols="12" align="center" class="mt-n4">
                      <v-chip                     
                        style="width: 200px;"
                        class="justify-center my-1 mx-1"
                        :variant="
                          selectedChips[accessory.id] === 'Erstausstattung'
                            ? 'elevated'
                            : 'outlined'
                        "
                        :color="
                          selectedChips[accessory.id] === 'Erstausstattung'
                            ? 'success'
                            : 'default'
                        "
                        @click="selectChip(accessory.id, 'Erstausstattung')"
                        >
                        <v-icon :color="selectedChips[accessory.id] === 'Erstausstattung' ? '#FFFFFF' : 'info'" 
                        style="position:absolute; left: 8px">mdi-numeric-1-box</v-icon>
                        Erstausstattung
                        </v-chip
                      >
                      <v-chip
                        style="width: 200px"
                        class="justify-center my-1 mx-1"
                        :variant="
                          selectedChips[accessory.id] === 'Beschädigung/Verlust'
                            ? 'elevated'
                            : 'outlined'
                        "
                        :color="
                          selectedChips[accessory.id] === 'Beschädigung/Verlust'
                            ? 'success'
                            : 'default'
                        "
                        @click="selectChip(accessory.id, 'Beschädigung/Verlust')"
                        >
                        <v-icon :color="selectedChips[accessory.id] === 'Beschädigung/Verlust' ? '#FFFFFF' : 'info'" 
                        style="position:absolute; left: 8px">mdi-package-variant-remove</v-icon>
                        Beschädigung/Verlust
                        </v-chip
                      >
                      <v-chip
                        style="width: 200px"
                        class="justify-center my-1 mx-1"
                        :variant="
                          selectedChips[accessory.id] === 'Austausch'
                            ? 'elevated'
                            : 'outlined'
                        "
                        :color="
                          selectedChips[accessory.id] === 'Austausch'
                            ? 'success'
                            : 'default'
                        "
                        @click="selectChip(accessory.id, 'Austausch')"
                        >
                        <v-icon :color="selectedChips[accessory.id] === 'Austausch' ? '#FFFFFF' : 'info'" 
                        style="position:absolute; left: 8px">mdi-swap-horizontal</v-icon>
                        Austausch
                        </v-chip
                      >
                      <v-chip
                        style="width: 200px"
                        class="justify-center my-1 mx-1"
                        :variant="
                          selectedChips[accessory.id] === 'Rückgabe'
                            ? 'elevated'
                            : 'outlined'
                        "
                        :color="
                          selectedChips[accessory.id] === 'Rückgabe'
                            ? 'success'
                            : 'default'
                        "
                        @click="selectChip(accessory.id, 'Rückgabe')"
                        >
                        <v-icon :color="selectedChips[accessory.id] === 'Rückgabe' ? '#FFFFFF' : 'info'" 
                        style="position:absolute; left: 8px">mdi-keyboard-return</v-icon>
                        Rückgabe
                        </v-chip
                      >
                    </v-col>
                    <v-col cols="12" align="center">
                      <v-chip
                        style="width: 170px"
                        class="justify-center my-1 mx-1"
                        :variant="
                          selectedChips[accessory.id] === 'Inventar'
                            ? 'elevated'
                            : 'outlined'
                        "
                        :color="
                          selectedChips[accessory.id] === 'Inventar'
                            ? 'success'
                            : 'default'
                        "
                        @click="selectChip(accessory.id, 'Inventar')"
                        label
                        >
                        <v-icon :color="selectedChips[accessory.id] === 'Inventar' ? '#FFFFFF' : 'info'" 
                        style="position:absolute; left: 8px">mdi-clipboard-list</v-icon>
                        Inventar
                        </v-chip
                      >
                    </v-col>
                                       
                    <v-col v-if="selectedChips[accessory.id] === 'Inventar'" cols="12" align="center"> 
                      
                      <v-row justify="center" align="center" no-gutters>
    <v-col cols="auto" class="pr-4">
      <span :class="{ 'red--text' : !isReturnSwitch[accessory.id], 'font-weight-bold': !isReturnSwitch[accessory.id] }" :style="{ color: !isReturnSwitch[accessory.id] ? 'blue' : '' }">Ausgabe </span>
    </v-col>
    <v-col cols="auto">
      <v-switch v-model="isReturnSwitch[accessory.id]" hide-details :color="isReturnSwitch[accessory.id] ? 'blue' : 'red'" :true-value="true" :false-value="false"></v-switch>
    </v-col>
    <v-col cols="auto" class="pl-4">
      <span :class="{ 'blue--text': isReturnSwitch[accessory.id], 'font-weight-bold': isReturnSwitch[accessory.id] }" :style="{ color: isReturnSwitch[accessory.id] ? 'blue' : '' }">Rückgabe</span>
    </v-col>
</v-row>
<p class="font-italic font-weight-bold pb-3 pt-2">Anzahl:</p>
<v-text-field v-model="inventoryCounts[accessory.id]" type="number" :label="isReturnSwitch[accessory.id] ? 'Rückgabe' : 'Ausgabe'" variant="outlined" max-width="105px">                      
</v-text-field> 
                    </v-col>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider :thickness="3"></v-divider>
        <v-card-text class="text-center" style="font-size: 10px">
          Hiermit bestätige ich, dass ein Pfand in Höhe von 5,00 EUR pro
          ausgehändigtem Pin der Erstausstattung von meinem nächsten Gehalt
          abgezogen werden darf. Dies wird bei Rückgabe wieder ausgezahlt. Die
          Accessoires bleiben Eigentum der Bellcaptain GmbH und sind bei
          Ausscheiden dem Arbeitgeber auszuhändigen. Im Falle von Verlust oder
          Beschädigung, die zur Unnutzbarkeit führt, darf eine erneute Gebühr in
          Höhe von 10,00 EUR pro Namensschild, Krawatte/Halstuch bzw. 5,00 EUR pro
          Pin von meinem Gehalt abgezogen werden.
          <br /><br />Außerdem verpflichte ich mich, mit meiner Unterschrift bei
          jedem Arbeitseinsatz diese Accessoires sichtbar zu tragen.
        </v-card-text>
        <v-divider :thickness="3"></v-divider>
        <v-col cols="12">
          <v-card
            class="px-0"
            elevation="3"
            rounded="lg"
            @click="
              !signaturesBoth.final_signature_receiver
                ? (receiverSignatureDialog.isOpen = true)
                : null
            "
            :ripple="false"
          >
            <v-card-title style="font-size: 13px">
              <v-row>
                <v-col cols="10">
                  <span v-if="(!selectedEmployee && !selectedCandidate)||(selectedEmployee && selectedCandidate)">
                    Unterschrift MitarbeiterIn/ KandidatIn, {{ signatureDate }}
                  </span>
                  <span v-else-if="selectedEmployee">
                    Unterschrift {{ selectedEmployeeDetails().name }}, {{ signatureDate }}
                  </span>
                  <span v-else-if="selectedCandidate">
                    Unterschrift {{ selectedCandidateDetails().full_name }}, {{ signatureDate }}
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-img
                v-if="signaturesBoth.final_signature_receiver"
                :src="signaturesBoth.final_signature_receiver"
                height="100px"
              />
              <v-sheet
                v-else
                height="100px"
                class="d-flex align-center justify-center font-italic font-weight-light"
                style="
                  border: 1px dashed grey;
                  background-color: #f5f5f5;
                  font-size: 11px;
                "
                >Hier klicken, um zu unterschreiben</v-sheet
              >
            </v-card-text>
          </v-card>
          <v-dialog v-model="receiverSignatureDialog.isOpen" persistent>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span style="font-size: 13px;" v-if="(!selectedEmployee && !selectedCandidate)||(selectedEmployee && selectedCandidate)">
                    Unterschrift MitarbeiterIn/ KandidatIn, {{ signatureDate }}
                  </span>
                  <span style="font-size: 13px;" v-else-if="selectedEmployee">
                    Unterschrift {{ selectedEmployeeDetails().name }}, {{ signatureDate }}
                  </span>
                  <span style="font-size: 13px;" v-else-if="selectedCandidate">
                    Unterschrift {{ selectedCandidateDetails().full_name }}, {{ signatureDate }}
                  </span>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon
                      @click="
                        receiverSignatureDialog.isOpen = false;
                        receiverSignaturePad.clear();
                      "
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="px-2 py-0">
                <ClientOnly>
                  <Vue3Signature
                    ref="receiverSignaturePad"
                    :sigOption="signatureOptions"
                    :w="'100%'"
                    :h="'213px'"
                    style="border: 2px solid black"
                  >
                  </Vue3Signature>
                </ClientOnly>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="saveSignature('receiver')"> Speichern </v-btn>
                <v-btn
                  @click="
                    receiverSignatureDialog.isOpen = false;
                    receiverSignaturePad.clear();
                  "
                  color="blue-grey-darken-3"
                  variant="tonal"
                >
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12">
          <v-card
            class="px-0"
            elevation="3"
            rounded="lg"
            @click="
              !signaturesBoth.final_signature_transferor
                ? (transferorSignatureDialog.isOpen = true)
                : null
            "
            :ripple="false"
          >
            <v-card-title style="font-size: 13px">
              <v-row>
                <v-col cols="10">
                  <span v-if="loggedInEmployee">
                    Unterschrift Bellcaptain GmbH <br />
                    ({{
                      loggedInEmployee.name
                    }}),<!--Bellcaptain GmbH,-->
                    {{ signatureDate }}
                  </span>
                  <span v-else> Unterschrift Bellcaptain GmbH, {{ signatureDate }} </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-img
                v-if="signaturesBoth.final_signature_transferor"
                :src="signaturesBoth.final_signature_transferor"
                height="100px"
              />
              <v-sheet
                v-else
                height="100px"
                class="d-flex align-center justify-center font-italic font-weight-light"
                style="
                  border: 1px dashed grey;
                  background-color: #f5f5f5;
                  font-size: 11px;
                "
                >Hier klicken, um zu unterschreiben</v-sheet
              >
            </v-card-text>
          </v-card>
          <v-dialog v-model="transferorSignatureDialog.isOpen" persistent>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span v-if="loggedInEmployee" style="font-size: 13px">
                      Unterschrift Bellcaptain GmbH <br />
                      ({{ loggedInEmployee.name }}),
                      {{ signatureDate }}
                    </span>
                    <span v-else> Unterschrift Bellcaptain GmbH, {{ signatureDate }} </span>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon
                      @click="
                        transferorSignatureDialog.isOpen = false;
                        transferorSignaturePad.clear();
                      "
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="px-2 py-0">
                <ClientOnly>
                  <Vue3Signature
                    ref="transferorSignaturePad"
                    :sigOption="signatureOptions"
                    :w="'100%'"
                    :h="'213px'"
                    style="border: 2px solid black"
                  >
                  </Vue3Signature>
                </ClientOnly>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="saveSignature('transferor')"> Speichern </v-btn>
                <v-btn
                  @click="
                    transferorSignatureDialog.isOpen = false;
                    transferorSignaturePad.clear();
                  "
                  color="blue-grey-darken-3"
                  variant="tonal"
                >
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <p v-if="!checkValidity()" class="text-red mt-5" align="center">
          {{ validationMessage() }}
        </p>
        <v-card-actions class="justify-center">
          <v-btn
            variant="outlined"
            position="relative"
            color="blue-grey-darken-3"
            @click="submitForm"
            :disabled="!checkValidity()"
          >
            Absenden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
      {{ snackbar.text }}
    </v-snackbar>
  </template>

  <script setup lang="ts">
  
  import { ref, reactive, onMounted } from "vue";
  import { supabase } from '@/supabase';
  import Vue3Signature from 'vue3-signature';
  import { useStore } from 'vuex'; 
import { reportingObserverIntegration } from "@sentry/vue";

  const props = defineProps<{
    closeDialog: () => void
  }>()

  let store = useStore()

  let employees = ref([]);
  let candidates = ref([]);
  let userEmployeeId = ref([]);
  let selectedEmployee = ref(null);
  let loggedInEmployee = ref(null);
  let selectedCandidate = ref(null);
  const loading = ref(false);

  const activePanel = ref(null);
  
  let accessoryButtons = ref([]);
  const selectedNumbers = reactive({});
  const selectedChips = reactive({});
  const inventoryCounts = reactive({});

  const isReturnSwitch = reactive({});
  const selectedReturn = reactive({});
  
  let date = ref(new Date().toISOString().split("T")[0]);
  
  const receiverSignaturePad = ref(null);
  const transferorSignaturePad = ref(null);
  const receiverSignatureDialog = ref({ isOpen: false });
  const transferorSignatureDialog = ref({ isOpen: false });
  const signatureDate = ref(new Date().toLocaleDateString("de"));
  const signaturesBoth = ref({});

  const selectedEmployeeDetails = () => {
    return employees.value.find(
      (employee) => employee.id === selectedEmployee.value
    ) || null;
  };

  const selectedCandidateDetails = () => {
    return candidates.value.find(
      (candidate) => candidate.id === selectedCandidate.value
    ) || null;
  };
  
  function selectChip(panelId, chip) {
    if(selectedChips[panelId] === chip){
    selectedChips[panelId] = null;
    inventoryCounts[panelId] = null;
    selectedNumbers[panelId] = null;
  }else{
    selectedChips[panelId] = chip;
    if(chip === "Inventar"){
      inventoryCounts[panelId] = null;
      selectedNumbers[panelId] = null;
      
    }else{
      inventoryCounts[panelId] = null;
    }
  }
  }
  
  function selectNumber(panelId, number) {
    selectedNumbers[panelId] = selectedNumbers[panelId] == number ? null : number;
  }

  const getCandidates = async () => {
    const response = await supabase
      .from("recruiting_candidates")
      .select("id,first_name,last_name,recruiting_job_candidates!inner(*)")
      .eq("recruiting_job_candidates.status_id",12)
    if(response.data){
        candidates.value = response.data.map(candidate => ({
            ...candidate,
            full_name: `${candidate.first_name} ${candidate.last_name}`,
        }));
    }
  };

  const getEmployeesData = async () => {
    const response = await supabase.from("employees").select("*").order("name", {ascending: true});
    let employeeInfo = response.data.find(employee => {
      return employee.email == store.state.userInfo.email
    });
    userEmployeeId.value = employeeInfo.id;
    employees.value = response.data.filter(employee => employee.id !== userEmployeeId.value);

    loggedInEmployee.value = employeeInfo;
  };

  const getAccessories = async () => {
    const response = await supabase
      .from("accessory_items")
      .select("*")
      .order("name", { ascending: true });
    accessoryButtons.value = response.data;

    accessoryButtons.value.forEach(accessory => {
      isReturnSwitch[accessory.id] = false
    })
  };
  
  const saveSignature = (author: string) => {
    if (author === "receiver") {
      if (receiverSignaturePad.value.isEmpty()) {
        snackbar.text = "Es fehlt die Unterschrift des Mitarbeiters";
        snackbar.color = "error";
        snackbar.isOpen = true;
        return;
      }
      signaturesBoth.value.final_signature_receiver =
        receiverSignaturePad.value.save("image/jpeg");
      receiverSignatureDialog.value.isOpen = false;
    } else {
      if (transferorSignaturePad.value.isEmpty()) {
        snackbar.text = "Es fehlt die Unterschrift der Bellcaptain GmbH";
        snackbar.color = "error";
        snackbar.isOpen = true;
        return;
      }
      signaturesBoth.value.final_signature_transferor =
        transferorSignaturePad.value.save("image/jpeg");
      transferorSignatureDialog.value.isOpen = false;
    }
  };
  
  const signatureOptions = {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(255,255,255)",
  };
  
  const snackbar = reactive({
    isOpen: false,
    text: "",
    color: "",
  });
  
  function resetProtocol() {
    selectedEmployee.value = null;
    selectedCandidate.value = null;
    Object.keys(selectedNumbers).forEach((key) => (selectedNumbers[key] = null));
    Object.keys(selectedChips).forEach((key) => (selectedChips[key] = null));
    Object.keys(inventoryCounts).forEach((key) => (inventoryCounts[key] = null));
  
    signaturesBoth.value = {
      final_signature_receiver: null,
      final_signature_transferor: null,
    };
  
    if (receiverSignaturePad.value) receiverSignaturePad.value.clear();
    if (transferorSignaturePad.value) transferorSignaturePad.value.clear();
  
    activePanel.value = null;
  }

  const submitForm = async () => {
  const inventoryEntries = [];
  const accessoryProtData = {
    date: date.value,
    transferor_id: userEmployeeId.value,
    signature_transferor: signaturesBoth.value.final_signature_transferor,
    signature_receiver: signaturesBoth.value.final_signature_receiver,
  };

  if(selectedEmployee.value){
    accessoryProtData.receiver_id = selectedEmployee.value;
    accessoryProtData.receiver_candidate_id = null;
  }else if(selectedCandidate.value){
    accessoryProtData.receiver_id = null;
    accessoryProtData.receiver_candidate_id = selectedCandidate.value;
  };

  const { data: protocolData, error: protocolError } = await supabase
    .from("accessory_protocol")
    .insert(accessoryProtData)
    .select();

    const accessoryItemsData = accessoryButtons.value
  .filter(
    (accessory) =>
      selectedNumbers[accessory.id] || selectedChips[accessory.id]
  )
  .map((accessory) => {
    let count = 0;
    let change_cause = "";

    if (selectedChips[accessory.id] === "Inventar") {
      count = inventoryCounts[accessory.id] || 0;
      change_cause = isReturnSwitch[accessory.id] ? "Inventar-Rückgabe" : "Inventar-Ausgabe";
      } else {
      count = selectedNumbers[accessory.id];
      change_cause = selectedChips[accessory.id];
    }

    return {
      accessory_id: accessory.id,
      count: count,
      change_cause: change_cause,
      protocol_id: protocolData[0].id,
    };
  });

  const { data: accessoryItemData, error: accessoryItemError } = await supabase
    .from("accessory_protocol_items")
    .insert(accessoryItemsData);


  if (accessoryItemError) {
    console.error("Fehler beim Eintragen in accessory_protocol_items");
    snackbar.text = "Fehler beim Absenden der Daten!";
    snackbar.color = "error";
    snackbar.isOpen = true;
  } 

  let stockObject = {};
  if (accessoryItemsData.some(item => item.change_cause === "Inventar")) {
    const { data: existingEmployeeStock, error: existingEmployeeStockError } = await supabase
      .from("accessory_employee_stock")
      .select("accessory_item_id, stock_count")
      .eq("employee_id", selectedEmployee.value);

    if (existingEmployeeStockError) {
      console.error("Fehler beim Abrufen der Bestände:", existingEmployeeStockError);
    } else {
      accessoryButtons.value.forEach((accessory) => {
        const existingStock = existingEmployeeStock?.find(stock => stock.accessory_item_id === accessory.id);
        stockObject[accessory.id] = existingStock ? existingStock.stock_count : 0;
      });
    }
  }

  try {
    for (const accessoryCount of accessoryItemsData) {
      accessoryButtons.value.forEach((accessory) => {
        if(accessoryCount.accessory_id == accessory.id){
          const countToChange = Number(inventoryCounts[accessory.id]) || 0;
          if(accessoryCount.change_cause === "Inventar-Rückgabe"){
            if(stockObject[accessory.id] !== undefined){
              stockObject[accessory.id] -= countToChange;
            }
            accessory.stock_count += countToChange;
          }
          
          else if(accessoryCount.change_cause === "Inventar-Ausgabe"){
            
            accessory.stock_count -= countToChange;
            stockObject[accessory.id] = (stockObject[accessory.id] || 0) + countToChange;
            
           
          } else if(accessoryCount.change_cause === "Rückgabe") {
            accessory.stock_count += accessoryCount.count;
          }else {
            accessory.stock_count -= accessoryCount.count;
          }
        }
      });
    }
    
    const { error: stockUpdateError } = await supabase
      .from("accessory_items")
      .upsert(accessoryButtons.value);
      

    if (stockUpdateError) {
      console.error("Fehler beim Aktualisieren des stock_count:", stockUpdateError);
    }

    for (const accessory of accessoryButtons.value) {
      if (selectedChips[accessory.id] === "Inventar" && inventoryCounts[accessory.id]) {
        const currentEmployeeStock = stockObject[accessory.id] || 0;

        inventoryEntries.push({
          accessory_item_id: accessory.id,
          employee_id: selectedEmployee.value,
          stock_count: currentEmployeeStock,
        });
      }
    }

    if(Object.keys(stockObject).length > 0){
      const stockUpdates = Object.entries(stockObject).map(([accessory_id, stock_count]) => ({
        accessory_item_id: accessory_id,
        employee_id: selectedEmployee.value,
        stock_count,
      }));
      const {error: employeeStockError} = await supabase.from("accessory_employee_stock").upsert(stockUpdates);
      if(employeeStockError){
        console.error("Fehler beim Aktualisieren des Mitarbeiter-Bestands:", employeeStockError);
      }
    }

    if (inventoryEntries.length > 0) {
      const { error: inventoryError } = await supabase
        .from("accessory_employee_stock")
        .upsert(inventoryEntries, { onConflict: ["accessory_item_id", "employee_id"] });

      if (inventoryError) {
        console.error("Fehler beim Aktualisieren von accessory_employee_stock:", inventoryError);
      }
    }
  snackbar.text = "Daten erfolgreich abgesendet!";
  snackbar.color = "success";
  snackbar.isOpen = true;
  resetProtocol();
  } catch (error) {
    console.error("Fehler beim Aktualisieren des Lagerbestands:", error);
  }
  
};
  
const checkValidity = () => {
  const hasReceiverSignature = !!signaturesBoth.value.final_signature_receiver;
  const hasTransferorSignature = !!signaturesBoth.value.final_signature_transferor;

  const hasAtLeastOneCompleteAccessory = accessoryButtons.value.some((accessory) => {
    const isInventory = selectedChips[accessory.id] === "Inventar";
    const numberSelected = !!selectedNumbers[accessory.id];  
    const reasonSelected = !!selectedChips[accessory.id];

    const inventoryCountEntered =
      isInventory &&
      inventoryCounts[accessory.id] !== undefined &&
      inventoryCounts[accessory.id] !== "" &&
      Number(inventoryCounts[accessory.id]) > 0;

    const otherReasonComplete = !isInventory && numberSelected && reasonSelected;

    return inventoryCountEntered || otherReasonComplete;
  });

  const oneDropdownSelected = !!selectedEmployee.value !== !!selectedCandidate.value;
  
  return (
    oneDropdownSelected &&
    hasAtLeastOneCompleteAccessory &&
    accessoryButtons.value.every((accessory) => {
      const isInventory = selectedChips[accessory.id] === "Inventar";
      const numberSelected = !!selectedNumbers[accessory.id];
      const reasonSelected = !!selectedChips[accessory.id];

      if (reasonSelected) {
        if (isInventory) {
          return inventoryCounts[accessory.id] !== undefined &&
                 inventoryCounts[accessory.id] !== "" &&
                 Number(inventoryCounts[accessory.id]) > 0;
        }
        return numberSelected; 
      }

      return !numberSelected; 
    }) &&
    hasReceiverSignature &&
    hasTransferorSignature
  );
};

const validationMessage = () => {
  if (!selectedEmployee.value && !selectedCandidate.value) {
    return "Bitte wählen Sie einen Mitarbeiter oder einen Kandidaten aus.";
  }

  if (selectedEmployee.value && selectedCandidate.value) {
    return "Bitte wählen Sie nur einen Mitarbeiter oder einen Kandidaten.";
  }

  const hasAtLeastOneCompleteAccessory = accessoryButtons.value.some((accessory) => {
    const isInventory = selectedChips[accessory.id] === "Inventar";
    const numberSelected = !!selectedNumbers[accessory.id];  
    const reasonSelected = !!selectedChips[accessory.id];

    const inventoryCountEntered =
      isInventory &&
      inventoryCounts[accessory.id] !== undefined &&
      inventoryCounts[accessory.id] !== "" &&
      Number(inventoryCounts[accessory.id]) > 0;

    const otherReasonComplete = !isInventory && numberSelected && reasonSelected;

    return inventoryCountEntered || otherReasonComplete;
  });

  if (!hasAtLeastOneCompleteAccessory) {
    return "Bitte wählen Sie mindestens ein Accessoire aus und geben Sie Anzahl und Grund an.";
  }

  const missingAccessories = accessoryButtons.value.filter((accessory) => {
    const isInventory = selectedChips[accessory.id] === "Inventar";
    const numberSelected = !!selectedNumbers[accessory.id];
    const reasonSelected = !!selectedChips[accessory.id];

    if (reasonSelected) {
      if (isInventory) {
        return !(inventoryCounts[accessory.id] !== undefined &&
                 inventoryCounts[accessory.id] !== "" &&
                 Number(inventoryCounts[accessory.id]) > 0);
      } else {
        return !numberSelected;
      }
    }

    return numberSelected && !reasonSelected;
  });

  if (missingAccessories.length > 0) {
    return "Bitte wählen Sie Grund und Anzahl für alle gewählten Accessoires aus.";
  }

  if (!signaturesBoth.value.final_signature_receiver) {
    return "Die Unterschrift des Mitarbeiters fehlt.";
  }

  if (!signaturesBoth.value.final_signature_transferor) {
    return "Die Unterschrift der Bellcaptain GmbH fehlt.";
  }

  return "";
};
  
  onMounted(async () => {
    getAccessories();
    getCandidates();
    getEmployeesData();
    
  });
  </script>
<style scoped>
.date-center {
  margin: 0 auto;
}
.date-center :deep(input) {
  text-align: center;
}
.date-center :deep(.v-field__append-inner) {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.date-center :deep(.v-field__field) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>