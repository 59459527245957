<template>
  <v-col cols="12" class="mb-n3">
    <v-alert
      v-if="!bonusEligible"
      type="warning"
      icon="mdi-currency-off"
      border="start"
      variant="tonal"
    >
      <div class="d-flex justify-space-between align-center">
        <div>
          <div class="text-h6">Bonus deaktiviert</div>
          <div>Für diesen Mitarbeiter ist der Bonus deaktiviert.</div>
        </div>
        <v-btn
          prepend-icon="mdi-check"
          @click="updateBonusEligibility(true)"
          size="small"
        >
          Bonus aktivieren
        </v-btn>
      </div>
    </v-alert>
    <BonusCalculator
      v-if="bonusEligible"
      :employee-id="employeeInfo.id"
      @toggle-bonus-eligibility="toggleBonusFromCalculator"
    />
  </v-col>
  <v-col cols="12">
    <v-card
      variant="tonal"
      color="blue-darken-4"
      rounded="lg"
      v-if="performanceSelectedYear || performanceArray.length > 0"
    >
      <v-card-title class="d-flex align-center">
        <v-icon class="mr-2">mdi-speedometer</v-icon>
        Performance {{ performanceSelectedYear }}
        <v-menu
          v-model="performanceMenuOpen"
          offset-y
          v-if="performanceYears.length > 1"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon
              size="x-small"
              class="ml-1"
              width="16"
              height="16"
            >
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="year in performanceYears"
              :key="year"
              @click="selectYear(year)"
            >
              {{ year }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <template v-for="item in performanceArray">
              <v-chip
                variant="elevated"
                :color="item.count > 0 && item.is_positive ? 'green' : 'red'"
                class="ma-1"
              >
                {{ item.title }}: {{ item.count }}
              </v-chip>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <v-card elevation="8" class="mx-3" rounded="lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-domain</v-icon>
      Hotels
    </v-card-title>
    <v-card-text>
      <v-row>
        <!--
        <v-col cols="4" >
          <v-autocomplete
            v-model="chosenHotel.hotel_id"
            label="Hotel hinzufügen"
            :items="hotels"
            item-title="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-rating label="Level" v-model="chosenHotel.level"></v-rating>
        </v-col>
        <v-col cols="4" align="center">
          <v-btn
            :loading="loading"
            prepend-icon="mdi-plus-circle"
            color="success"
            @click="addHotel()"
          >
            Hinzufügen
          </v-btn>
        </v-col>
        -->
        <v-col cols="6">
          <v-card class="ma-2 pa-2" elevation="10" rounded="lg">
            <v-card-title>Schichten der letzten 6 Monate</v-card-title>
            <v-card-text>
              <v-col cols="12" v-for="hotel in hotelRecord" class="my-n2">
                <div>
                  <router-link :to="'/Hotels/' + hotel.hotel_id"
                    >{{ hotel.hotel_name }}
                  </router-link>
                  <div
                    class="d-flex align-center mb-1 mt-n1"
                    v-if="hotel.ratings"
                  >
                    <div
                      class="d-flex align-center mr-3"
                      v-if="hotel.ratings.clientAverage > 0"
                    >
                      <v-tooltip
                        :text="`Kundenbewertung: ${hotel.ratings.clientAverage}`"
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center" v-bind="props">
                            <v-rating
                              v-model="hotel.ratings.clientAverage"
                              color="amber"
                              active-color="amber-darken-3"
                              size="16"
                              half-increments
                              readonly
                            ></v-rating>
                            <span class="text-caption ml-1 mt-2"
                              >(n = {{ hotel.ratings.clientCount }})</span
                            >
                          </div>
                        </template>
                      </v-tooltip>
                    </div>
                    <div
                      class="d-flex align-center mr-3"
                      v-if="hotel.ratings.employeeAverage > 0"
                    >
                      <v-tooltip
                        :text="`Eigene Bewertung: ${hotel.ratings.employeeAverage}`"
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center" v-bind="props">
                            <v-rating
                              v-model="hotel.ratings.employeeAverage"
                              color="green"
                              active-color="green-darken-3"
                              size="16"
                              half-increments
                              readonly
                            ></v-rating>
                            <span class="text-caption ml-1 mt-2"
                              >(n = {{ hotel.ratings.employeeCount }})</span
                            >
                          </div>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <v-progress-linear
                  :model-value="hotel.count"
                  :max="hotelRecord[0].count"
                  color="primary"
                  height="15"
                >
                  <template v-slot:default="{ value }">
                    <strong :class="value > 50 ? 'text-white' : null">{{
                      Math.ceil(hotel.count) + " (Gesamt)"
                    }}</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear
                  v-if="hotel.senior_count > 0"
                  :model-value="hotel.senior_count"
                  :max="hotelRecord[0].count"
                  color="blue-grey-darken-2"
                  height="15"
                >
                  <template v-slot:default="{ value }">
                    <strong :class="value > 50 ? 'text-white' : null">{{
                      Math.ceil(hotel.senior_count) + " (Senior)"
                    }}</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear
                  v-if="hotel.clerk_count > 0"
                  :model-value="hotel.clerk_count"
                  :max="hotelRecord[0].count"
                  color="blue-grey-darken-4"
                  height="15"
                >
                  <template v-slot:default="{ value }">
                    <strong :class="value > 50 ? 'text-white' : null">{{
                      Math.ceil(hotel.clerk_count) + " (Clerk)"
                    }}</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear
                  v-if="hotel.training_count > 0"
                  :model-value="hotel.training_count"
                  :max="hotelRecord[0].count"
                  color="blue-grey-darken-4"
                  height="15"
                >
                  <template v-slot:default="{ value }">
                    <strong :class="value > 50 ? 'text-white' : null">{{
                      Math.ceil(hotel.training_count) + " (Einarbeitung)"
                    }}</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            v-for="hotel in employeeInfo.employees_hotels"
            elevation="5"
            class="my-2"
            rounded="lg"
          >
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <div>
                    {{ hotel.hotels.name }}
                  </div>
                </v-col>
                <v-col cols="5" align="rights">
                  <v-btn
                    size="x-small"
                    rounded="xl"
                    :color="hotel.is_clerk ? 'green' : null"
                    @click="
                      ((hotel.is_clerk = !hotel.is_clerk), updateRating(hotel))
                    "
                    >Clerk</v-btn
                  >
                  <v-btn
                    size="x-small"
                    rounded="xl"
                    :color="hotel.is_senior ? 'green' : null"
                    @click="
                      ((hotel.is_senior = !hotel.is_senior),
                      updateRating(hotel))
                    "
                    >Senior</v-btn
                  >
                  <v-btn
                    size="x-small"
                    rounded="xl"
                    :color="hotel.is_blocked ? 'red' : null"
                    @click="
                      ((hotel.is_blocked = !hotel.is_blocked),
                      updateRating(hotel))
                    "
                    >GESPERRT</v-btn
                  >
                </v-col>
                <v-col cols="2" align="right">
                  <v-btn color="error" icon @click="removeHotel(hotel)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card class="ma-3" elevation="8" rounded="lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-account-check</v-icon>
      Mitarbeiter-Checks
    </v-card-title>
    <v-card-text>
      <v-row>
        <template v-for="check in employeeChecks">
          <v-col cols="6">
            <v-card
              elevation="3"
              rounded="lg"
              :color="checkColor(check)"
              :variant="check.is_signed ? 'elevated' : 'outlined'"
              @click="
                ((employeeCheck.dialog = !employeeCheck.dialog),
                (employeeCheck.employeeCheck = check))
              "
            >
              <v-card-text>
                <v-row>
                  <v-col cols="10">
                    <div>{{ check.employee }} - {{ check.hotel }}</div>
                    <div class="text-caption">
                      {{ new Date(check.date).toLocaleDateString() }} von
                      {{ check.inspector }}
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      v-if="check.is_signed && check.is_passed"
                      color="green"
                      size="x-large"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon
                      v-else-if="check.is_signed && !check.is_passed"
                      color="red"
                      size="x-large"
                      >mdi-close-circle</v-icon
                    >
                    <v-icon v-else color="grey" size="x-large"
                      >mdi-circle-outline</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card
    class="ma-3"
    elevation="8"
    rounded="lg"
    v-if="employeeTrainings.length > 0"
  >
    <v-card-title>
      <v-icon class="mr-2">mdi-account-school</v-icon>
      Einarbeitungsdokumente
    </v-card-title>
    <v-card-text>
      <v-row>
        <template v-for="training in employeeTrainings">
          <v-col cols="12" xs="12" sm="12" md="4">
            <v-card elevation="5" rounded="lg">
              <v-card-title>
                <span style="font-size: 14px">{{ training?.hotel?.name }}</span>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="2" align="center">
                    <v-icon>mdi-map-marker</v-icon>
                  </v-col>
                  <v-col cols="10">
                    {{ training?.hotel?.city?.city }}
                  </v-col>
                </v-row>

                <!-- Clerk Documents Section -->
                <template v-if="training?.trainingDateRangeClerk">
                  <v-row no-gutters>
                    <v-col cols="2" align="center">
                      <v-icon>mdi-calendar</v-icon>
                    </v-col>
                    <v-col cols="10">
                      {{ training?.trainingDateRangeClerk }} (Clerk)
                    </v-col>
                  </v-row>

                  <!-- Status row for Clerk -->
                  <v-row
                    no-gutters
                    v-if="training?.trainingDocs.some((doc) => !doc.is_senior)"
                  >
                    <v-col cols="2" align="center">
                      <TrainingStatusChanger
                        v-if="
                          training?.trainingDocs.find((doc) => !doc.is_senior)
                        "
                        :training-hotel-results-id="
                          training?.trainingDocs.find((doc) => !doc.is_senior)
                            ?.id
                        "
                        :current-status-id="
                          training?.trainingDocs.find((doc) => !doc.is_senior)
                            ?.status_id
                        "
                        @status-changed="getTrainingDocuments"
                      />
                    </v-col>
                    <v-col cols="10" class="d-flex align-center">
                      <v-chip
                        size="small"
                        :color="
                          training?.trainingDocs.find((doc) => !doc.is_senior)
                            ?.status?.color || 'grey'
                        "
                      >
                        {{
                          training?.trainingDocs.find((doc) => !doc.is_senior)
                            ?.status?.label || "Nicht gesetzt"
                        }}
                      </v-chip>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      v-for="document in training?.trainingDocs.filter(
                        (doc) => !doc.is_senior,
                      )"
                    >
                      <div v-if="document?.showTrainingChip">
                        <v-chip
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Einarbeitungsliste',
                            })
                          "
                        >
                          Einarbeitungsliste
                        </v-chip>
                      </div>
                      <div v-if="document?.showIntermediaryChip">
                        <v-chip
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Zwischenstand',
                            })
                          "
                        >
                          Zwischenstand
                        </v-chip>
                      </div>
                      <div v-if="document?.showFinalChip">
                        <v-chip
                          :color="
                            getDocumentChipColor(
                              document.training_result_grading_id,
                            )
                          "
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Finale Abnahme',
                            })
                          "
                        >
                          Finale Abnahme
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </template>

                <!-- Divider if both clerk and senior sections exist -->
                <v-divider
                  v-if="
                    training?.trainingDateRangeClerk &&
                    training?.trainingDateRangeSenior
                  "
                  class="my-2"
                ></v-divider>

                <!-- Senior Documents Section -->
                <template v-if="training?.trainingDateRangeSenior">
                  <v-row no-gutters>
                    <v-col cols="2" align="center">
                      <v-icon>mdi-calendar</v-icon>
                    </v-col>
                    <v-col cols="10">
                      {{ training?.trainingDateRangeSenior }} (Senior)
                    </v-col>
                  </v-row>

                  <!-- Status row for Senior -->
                  <v-row
                    no-gutters
                    v-if="training?.trainingDocs.some((doc) => doc.is_senior)"
                  >
                    <v-col cols="2" align="center">
                      <TrainingStatusChanger
                        v-if="
                          training?.trainingDocs.find((doc) => doc.is_senior)
                        "
                        :training-hotel-results-id="
                          training?.trainingDocs.find((doc) => doc.is_senior)
                            ?.id
                        "
                        :current-status-id="
                          training?.trainingDocs.find((doc) => doc.is_senior)
                            ?.status_id
                        "
                        @status-changed="getTrainingDocuments"
                      />
                    </v-col>
                    <v-col cols="10" class="d-flex align-center">
                      <v-chip
                        size="small"
                        :color="
                          training?.trainingDocs.find((doc) => doc.is_senior)
                            ?.status?.color || 'grey'
                        "
                      >
                        {{
                          training?.trainingDocs.find((doc) => doc.is_senior)
                            ?.status?.label || "Nicht gesetzt"
                        }}
                      </v-chip>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      v-for="document in training?.trainingDocs.filter(
                        (doc) => doc.is_senior,
                      )"
                    >
                      <div v-if="document?.showTrainingChip">
                        <v-chip
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Einarbeitungsliste',
                            })
                          "
                        >
                          Einarbeitungsliste (Senior)
                        </v-chip>
                      </div>
                      <div v-if="document?.showIntermediaryChip">
                        <v-chip
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Zwischenstand',
                            })
                          "
                        >
                          Zwischenstand (Senior)
                        </v-chip>
                      </div>
                      <div v-if="document?.showFinalChip">
                        <v-chip
                          :color="
                            getDocumentChipColor(
                              document.training_result_grading_id,
                            )
                          "
                          rounded="lg"
                          label
                          class="my-1"
                          @click="
                            openTrainingForm({
                              trainingDocument: document,
                              type: 'Finale Abnahme',
                            })
                          "
                        >
                          Finale Abnahme (Senior)
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog v-model="employeeCheck.dialog">
    <v-card>
      <EmployeeCheck
        v-if="employeeCheck.dialog"
        :employeeCheckProp="employeeCheck.employeeCheck"
        @closeDialog="closeEmployeeCheckDialog"
      ></EmployeeCheck>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="trainingFormsDialog.Einarbeitungsliste.isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <ProgressForm
      :trainee="employeeInfo"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :is-senior="trainingFormsDialog.trainingResult.is_senior"
      :is-dialog="true"
      :close-dialog="
        () => {
          trainingFormsDialog.Einarbeitungsliste.isOpen = false;
          getTrainingDocuments();
        }
      "
    ></ProgressForm>
  </v-dialog>
  <v-dialog
    v-model="trainingFormsDialog.Zwischenstand.isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <IntermediaryTestForm
      :trainee="employeeInfo"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :training-result="trainingFormsDialog.trainingResult"
      :close-dialog="
        () => {
          trainingFormsDialog.Zwischenstand.isOpen = false;
          getTrainingDocuments();
        }
      "
    ></IntermediaryTestForm>
  </v-dialog>
  <v-dialog
    v-model="trainingFormsDialog['Finale Abnahme'].isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <FinalTestForm
      :trainee="employeeInfo"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :training-result="trainingFormsDialog.trainingResult"
      :close-dialog="
        () => {
          trainingFormsDialog['Finale Abnahme'].isOpen = false;
          getTrainingDocuments();
        }
      "
    ></FinalTestForm>
  </v-dialog>
</template>

<script setup>
import { subDays } from "date-fns";
import EmployeeCheck from "@/components/Employees/ProfileComponents/Performance/EmployeeCheck.vue";
import ProgressForm from "@/components/Trainings/ProgressForm.vue";
import IntermediaryTestForm from "@/components/Trainings/IntermediaryTestForm.vue";
import FinalTestForm from "@/components/Trainings/FinalTestForm.vue";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";
import BonusCalculator from "@/components/Employees/ProfileComponents/Performance/BonusCalculator.vue";
import TrainingStatusChanger from "@/components/Trainings/TrainingStatusChanger.vue";
import useSnackbar from "@/utils/useSnackbar";

const props = defineProps({
  employeeInfo: Object,
  role: Object,
});
</script>

<script>
export default {
  data() {
    return {
      hotels: [],
      chosenHotels: {},
      chosenHotel: {},
      loading: false,
      shifts: [],
      hotelRecord: [],
      employeeChecks: [],
      employeeCheck: {
        dialog: false,
        employeeCheck: {},
      },
      employeeTrainings: [],
      trainingFormsDialog: {
        hotel: null,
        city: null,
        Einarbeitungsliste: {
          isOpen: false,
        },
        Zwischenstand: {
          isOpen: false,
        },
        ["Finale Abnahme"]: {
          isOpen: false,
        },
      },
      performanceRecords: [],
      performanceArray: [],
      performance: {
        has_changed_shift: {
          title: "Schicht gewechselt",
          count: 0,
          is_positive: true,
        },
        is_additional_shift: {
          title: "Zusatzschicht",
          count: 0,
          is_positive: true,
        },
        is_late: {
          title: "Zu spät",
          count: 0,
          is_positive: false,
        },
        is_sick: {
          title: "Krank",
          count: 0,
          is_positive: false,
        },
      },
      performanceSelectedYear: new Date().getFullYear(),
      performanceYears: [],
      performanceMenuOpen: false,
      bonusEligible: false,
      showBonusCalculator: false,
    };
  },
  methods: {
    async getHotels() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        order: {
          key: "name",
          ascending: true,
        },
      });
      this.hotels = [];
      response.data.forEach((item) => {
        if (!this.chosenHotels[item.id]) {
          this.hotels.push(item);
        }
      });
    },
    closeEmployeeCheckDialog() {
      this.employeeCheck.dialog = false;
    },
    async getInitialYear() {
      const queryObject = {
        table: "employee_date_event_matching",
        select: "date",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
          { type: "is", key: "is_activated", value: true },
        ],
        order: {
          key: "date",
          ascending: true,
        },
        limit: 1,
      };
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const currentYear = new Date().getFullYear();
      if (!response.data.length) {
        this.performanceYears = [currentYear];
        return;
      }
      const firstYear = new Date(response.data[0].date).getFullYear();
      for (let year = firstYear; year <= currentYear; year++) {
        this.performanceYears.push(year);
      }
      this.performanceYears.reverse();
    },
    async getPerformance() {
      this.performanceArray = [];
      const queryObject = {
        select: "*,key:employee_date_events_keys(*)",
        table: "employee_date_event_matching",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
          {
            type: "gte",
            key: "date",
            value: this.performanceSelectedYear + "-01-01",
          },
          {
            type: "lte",
            key: "date",
            value: this.performanceSelectedYear + "-12-31",
          },
          { type: "is", key: "is_activated", value: true },
        ],
      };
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const performance = {
        has_changed_shift: {
          title: "Schicht gewechselt",
          count: 0,
          is_positive: true,
        },
        is_additional_shift: {
          title: "Zusatzschicht",
          count: 0,
          is_positive: true,
        },
        is_late: {
          title: "Zu spät",
          count: 0,
          is_positive: false,
        },
        is_absent: {
          title: "Ausfall",
          count: 0,
          is_positive: false,
        },
        is_sick: {
          title: "Krank",
          count: 0,
          is_positive: false,
        },
      };
      response.data.forEach((item) => {
        if (item.key.value === "is_late") {
          performance.is_late.count++;
        }
        if (
          item.key.value === "is_sick" ||
          item.key.value == "is_absent_to_late"
        ) {
          performance.is_sick.count++;
        }
        if (item.key.value === "is_absent") {
          performance.is_absent.count++;
        }
        if (item.key.value === "is_additional_shift") {
          performance.is_additional_shift.count++;
        }
        if (item.key.value === "has_changed_shift") {
          performance.has_changed_shift.count++;
        }
      });
      Object.keys(performance).forEach((key) => {
        this.performanceArray.push(performance[key]);
      });
    },
    selectYear(year) {
      this.performanceSelectedYear = year;
      this.performanceMenuOpen = false;
      this.getPerformance();
    },
    async addHotel(hotel) {
      this.loading = true;
      var queryObject = {
        table: "employees_hotels",
        payload: this.chosenHotel,
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.chosenHotel = {
        employee_id: this.employeeInfo.id,
        hotel_id: null,
        level: null,
      };
      await this.getEmployeeHotels();
      await this.getHotels();
      this.loading = false;
    },
    checkColor(check) {
      if (check.is_passed) {
        return "green-lighten-3";
      } else if (check.is_passed === false) {
        return "red-lighten-3";
      } else {
        return "black";
      }
    },
    async getShifts() {
      var queryObject = {
        select:
          "*,hotels(name),hotel_performance_records(client_rating,employee_rating)",
        table: "shifts",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
          {
            type: "gte",
            key: "date",
            value: subDays(new Date(), 180).toISOString(),
          },
          {
            type: "lte",
            key: "date",
            value: subDays(new Date(), 1).toISOString(),
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.shifts = response.data;
      var hotelCheck = {};
      this.shifts.forEach((shift) => {
        if (!hotelCheck[shift.hotel_id]) {
          hotelCheck[shift.hotel_id] = {
            hotel_id: shift.hotel_id,
            hotel_name: shift.hotels ? shift.hotels.name : "Nicht gematched",
            count: 1,
            ratings: {
              sumClientRating: 0,
              countClientRating: 0,
              sumEmployeeRating: 0,
              countEmployeeRating: 0,
              clientAverage: 0,
              employeeAverage: 0,
              clientCount: 0,
              employeeCount: 0,
            },
          };
          // Initialize counts for shift types
          if (shift.is_training) {
            hotelCheck[shift.hotel_id].training_count = 1;
            hotelCheck[shift.hotel_id].senior_count = 0;
            hotelCheck[shift.hotel_id].clerk_count = 0;
          } else if (shift.is_senior) {
            hotelCheck[shift.hotel_id].senior_count = 1;
            hotelCheck[shift.hotel_id].clerk_count = 0;
            hotelCheck[shift.hotel_id].training_count = 0;
          } else {
            hotelCheck[shift.hotel_id].senior_count = 0;
            hotelCheck[shift.hotel_id].clerk_count = 1;
            hotelCheck[shift.hotel_id].training_count = 0;
          }
        } else {
          hotelCheck[shift.hotel_id].count++;
          if (shift.is_training) {
            hotelCheck[shift.hotel_id].training_count++;
          } else if (shift.is_senior) {
            hotelCheck[shift.hotel_id].senior_count++;
          } else if (shift.is_senior === false) {
            hotelCheck[shift.hotel_id].clerk_count++;
          }
        }

        // Process ratings for each shift
        if (
          shift.hotel_performance_records &&
          shift.hotel_performance_records.length > 0
        ) {
          shift.hotel_performance_records.forEach((record) => {
            // Client rating check
            if (
              record.client_rating !== null &&
              !isNaN(Number(record.client_rating))
            ) {
              hotelCheck[shift.hotel_id].ratings.sumClientRating += Number(
                record.client_rating,
              );
              hotelCheck[shift.hotel_id].ratings.countClientRating++;
            }

            // Employee rating check
            const empRating = record.employee_rating;
            if (
              empRating !== null &&
              empRating !== undefined &&
              !isNaN(Number(empRating))
            ) {
              hotelCheck[shift.hotel_id].ratings.sumEmployeeRating +=
                Number(empRating);
              hotelCheck[shift.hotel_id].ratings.countEmployeeRating++;
            }
          });
        }
      });

      // Calculate averages for ratings
      Object.values(hotelCheck).forEach((hotel) => {
        if (hotel.ratings.countClientRating > 0) {
          hotel.ratings.clientAverage =
            Math.round(
              (hotel.ratings.sumClientRating /
                hotel.ratings.countClientRating) *
                10,
            ) / 10;
          hotel.ratings.clientCount = hotel.ratings.countClientRating;
        }
        if (hotel.ratings.countEmployeeRating > 0) {
          hotel.ratings.employeeAverage =
            Math.round(
              (hotel.ratings.sumEmployeeRating /
                hotel.ratings.countEmployeeRating) *
                10,
            ) / 10;
          hotel.ratings.employeeCount = hotel.ratings.countEmployeeRating;
        }
      });

      this.hotelRecord = Object.values(hotelCheck);
      this.hotelRecord.sort((a, b) => b.count - a.count);
      this.hotelRecord = this.hotelRecord.filter((item) => item.hotel_id);
    },
    async getEmployeeChecks() {
      var queryObject = {
        select:
          "*,hotels(id,name),employee:employees!employee_check_form_employee_id_fkey(name),inspector:employees!employee_check_form_inspector_id_fkey(name)",
        table: "employee_check_form",
        where: [
          {
            type: "or",
            value: `inspector_id.eq.${this.employeeInfo.id},employee_id.eq.${this.employeeInfo.id}`,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.employeeChecks = response.data;
      response.data.forEach((item) => {
        item.inspector = item.inspector.name;
        item.employee = item.employee.name;
        item.hotel = item.hotels?.name;
      });
    },
    async updateRating(hotel) {
      var queryObject = {
        table: "employees_hotels",
        id: hotel.id,
        payload: {
          level: hotel.level,
          is_clerk: hotel.is_clerk,
          is_senior: hotel.is_senior,
          is_blocked: hotel.is_blocked,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
    },
    async removeHotel(hotel) {
      var queryObject = {
        table: "employees_hotels",
        id: hotel.id,
      };
      await this.$store.dispatch("deleteSupabaseEntity", queryObject);
      await this.getEmployeeHotels();
      await this.getHotels();
    },
    async getEmployeeHotels() {
      var queryObject = {
        select: "*,hotels(*)",
        table: "employees_hotels",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        this.chosenHotels[item.hotel_id] = true;
      });
      this.employeeInfo.employees_hotels = response.data;
    },
    async getTrainingDocuments() {
      var queryObject = {
        select:
          "*, employee:employees!training_hotel_result_trainee_id_fkey(id, last_name, first_name, name), hotel:hotels!inner(id, name, short_name, city:cities!inner(city)), status:training_statuses(*)",
        table: "training_hotel_results",
        where: [{ type: "eq", key: "trainee_id", value: this.employeeInfo.id }],
        order: {
          key: "created_at",
          ascending: true,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      const trainingsInHotels = {};
      response.data.forEach((training) => {
        if (!trainingsInHotels[training.hotel_id]) {
          trainingsInHotels[training.hotel_id] = {
            hotel: training.hotel,
            trainings: [],
          };
        }
        trainingsInHotels[training.hotel_id].trainings.push(training);
      });

      const employeeTrainigs = [];

      Object.keys(trainingsInHotels).forEach((key) => {
        const trainingDocs = [];
        let startDateClerk = null;
        let endDateClerk = null;
        let startDateSenior = null;
        let endDateSenior = null;
        trainingsInHotels[key].trainings.forEach((training) => {
          const showTrainingChip = training.started_training_at !== null;
          const showIntermediaryChip =
            training.reviewed_intermediary_at !== null;
          const showFinalChip = training.reviewed_final_at !== null;
          if (showTrainingChip || showFinalChip || showIntermediaryChip) {
            const trainingStartDate = new Date(training.created_at);
            const trainingEndDate = training.completed_final_at
              ? new Date(training.completed_final_at)
              : training.completed_training_at
                ? new Date(training.completed_training_at)
                : new Date();
            if (training.is_senior) {
              if (
                startDateSenior === null ||
                trainingStartDate < new Date(startDateSenior)
              ) {
                startDateSenior = trainingStartDate;
              }
              if (
                endDateSenior === null ||
                trainingEndDate > new Date(endDateSenior)
              ) {
                endDateSenior = trainingEndDate;
              }
            } else {
              if (
                startDateClerk === null ||
                trainingStartDate < new Date(startDateClerk)
              ) {
                startDateClerk = trainingStartDate;
              }
              if (
                endDateClerk === null ||
                trainingEndDate > new Date(endDateClerk)
              ) {
                endDateClerk = trainingEndDate;
              }
            }
            trainingDocs.push({
              ...training,
              showTrainingChip,
              showIntermediaryChip,
              showFinalChip,
            });
          }
        });
        if (trainingDocs.length > 0) {
          employeeTrainigs.push({
            hotel: trainingsInHotels[key].hotel,
            trainingDocs: trainingDocs,
            trainingDateRangeSenior: startDateSenior
              ? `${getFormattedShiftDate(
                  startDateSenior,
                )} - ${getFormattedShiftDate(endDateSenior)}`
              : null,
            trainingDateRangeClerk: startDateClerk
              ? `${getFormattedShiftDate(
                  startDateClerk,
                )} - ${getFormattedShiftDate(endDateClerk)}`
              : null,
          });
        }
      });

      this.employeeTrainings = employeeTrainigs;
    },
    openTrainingForm({ trainingDocument, type }) {
      this.trainingFormsDialog.hotel = trainingDocument.hotel;
      this.trainingFormsDialog.city = trainingDocument.hotel.city.city;
      this.trainingFormsDialog.trainingResult = trainingDocument;
      this.trainingFormsDialog[type].isOpen = true;
    },
    getDocumentChipColor(resultGradingId) {
      switch (resultGradingId) {
        case 1:
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
        case 5:
          return "error";
        default:
          return "grey";
      }
    },
    toggleBonusFromCalculator(isEnabled) {
      this.updateBonusEligibility(!this.bonusEligible);
    },

    async updateBonusEligibility(newState = null) {
      // If newState is provided, use it, otherwise toggle current state
      const newEligibility = newState !== null ? newState : !this.bonusEligible;
      const { showError, showSuccess } = useSnackbar();

      try {
        await this.$store.dispatch("updateSupabaseEntity", {
          table: "employees",
          id: this.employeeInfo.id,
          payload: {
            bonus_eligible: newEligibility,
          },
        });

        // Update local state after successful update
        this.bonusEligible = newEligibility;

        const message = newEligibility
          ? "Bonus für diesen Mitarbeiter aktiviert"
          : "Bonus für diesen Mitarbeiter deaktiviert";

        showSuccess(message);
      } catch (error) {
        console.error("Error updating bonus eligibility:", error);
        showError("Fehler beim Aktualisieren der Bonus-Berechtigung");
      }
    },
  },
  async created() {
    this.chosenHotel = {
      employee_id: this.employeeInfo.id,
      hotel_id: null,
      level: null,
    };

    // Initialize bonus eligibility status
    this.bonusEligible = this.employeeInfo.bonus_eligible !== false;
    this.showBonusCalculator = this.bonusEligible;

    await this.getEmployeeHotels();
    this.getHotels();
    this.getShifts();
    await this.getInitialYear();
    this.getPerformance();
    this.getEmployeeChecks();
    this.getTrainingDocuments();
  },
};
</script>
