<template>
  <div>
    <v-card flat class="mb-4">
      <v-card-text>
        <v-row dense>
          <!-- City filter -->
          <v-col cols="12" md="4">
            <v-select
              v-model="selectedCity"
              :items="cities"
              item-title="city"
              item-value="id"
              label="Stadt filtern"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
              @update:model-value="filterHotelsByCity"
            ></v-select>
          </v-col>
          <!-- Search filter -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="searchQuery"
              label="Hotels suchen"
              prepend-inner-icon="mdi-magnify"
              clearable
              outlined
              dense
              hide-details
              class="mb-4"
              @input="filterHotels"
            ></v-text-field>
          </v-col>
          <!-- Reset button -->
          <v-col cols="12" md="2" class="d-flex align-center">
            <v-btn
              color="blue-grey-darken-1"
              @click="resetAllChanges"
              :disabled="!hasChanges"
            >
              <v-icon start>mdi-refresh</v-icon>
              Zurücksetzen
            </v-btn>
          </v-col>
        </v-row>

        <!-- Scrollable content for hotels list -->
        <div class="scrollable-content">
          <!-- List of hotels with bonus points -->
          <v-row dense>
            <v-col cols="12" v-for="hotel in filteredHotels" :key="hotel.id">
              <v-card outlined class="mb-2 pa-1">
                <v-card-text class="py-1">
                  <v-row dense class="d-flex align-center">
                    <v-col cols="12" md="4">
                      <div>
                        <strong>{{ hotel.name }}</strong>
                        <div class="text-caption text-grey">
                          {{ hotel.city_name }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="original-points">
                        <div class="text-caption">Clerk Original</div>
                        <div class="text-body-2 font-weight-medium">
                          {{ hotel.original_clerk_points }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="original-points">
                        <div class="text-caption">Senior Original</div>
                        <div class="text-body-2 font-weight-medium">
                          {{ hotel.original_senior_points }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model="hotel.clerk_points"
                        label="Clerk Simulation"
                        type="number"
                        dense
                        outlined
                        hide-details
                        class="simulation-input"
                        :class="{
                          'changed-value': hasValueChanged(
                            hotel.original_clerk_points,
                            hotel.clerk_points,
                          ),
                        }"
                        @blur="applySimulation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model="hotel.senior_points"
                        label="Senior Simulation"
                        type="number"
                        dense
                        outlined
                        hide-details
                        class="simulation-input"
                        :class="{
                          'changed-value': hasValueChanged(
                            hotel.original_senior_points,
                            hotel.senior_points,
                          ),
                        }"
                        @blur="applySimulation"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Impact information -->
                  <v-row v-if="hotel.pointsChange !== 0" dense class="mt-2">
                    <v-col cols="12">
                      <div class="d-flex align-center impact-row">
                        <v-chip
                          :color="getImpactColor(hotel.pointsChange)"
                          text-color="white"
                          size="small"
                          class="mr-2"
                        >
                          {{ formatPointChange(hotel.pointsChange) }}
                        </v-chip>
                        <div class="text-body-2">
                          {{ hotel.shiftsCount }} Schichten
                          <span v-if="hotel.employeeCount" class="ml-1">
                            ({{ hotel.employeeCount }} Mitarbeiter)
                          </span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          x-small
                          icon
                          @click="resetHotel(hotel)"
                          v-if="
                            hasValueChanged(
                              hotel.original_clerk_points,
                              hotel.clerk_points,
                            ) ||
                            hasValueChanged(
                              hotel.original_senior_points,
                              hotel.senior_points,
                            )
                          "
                        >
                          <v-icon x-small>mdi-refresh</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useDebounce } from "@vueuse/core";

const props = defineProps({
  hotelBonusData: {
    type: Array,
    required: true,
  },
  cities: {
    type: Array,
    default: () => [],
  },
  employeeData: {
    type: Array,
    required: true,
  },
  bonusLevels: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  selectedCity: {
    type: [String, Number],
    default: null,
  },
});

const emit = defineEmits(["update:simulation"]);

// Local state
const hotels = ref([]);
const filteredHotels = ref([]);
const searchQuery = ref("");
const debouncedSearchQuery = useDebounce(searchQuery, 300);
const selectedCity = ref(props.selectedCity);

// Computed properties
const hasChanges = computed(() => {
  return hotels.value.some(
    (hotel) =>
      hasValueChanged(hotel.original_clerk_points, hotel.clerk_points) ||
      hasValueChanged(hotel.original_senior_points, hotel.senior_points),
  );
});

// Methods
const initializeData = () => {
  if (!props.hotelBonusData || props.hotelBonusData.length === 0) return;

  hotels.value = props.hotelBonusData.map((hotel) => {
    // Deep clone to avoid modifying original data
    const clonedHotel = JSON.parse(JSON.stringify(hotel));

    // Add simulation-specific fields
    return {
      ...clonedHotel,
      original_clerk_points: clonedHotel.clerk_points,
      original_senior_points: clonedHotel.senior_points,
      originalPoints: 0,
      newPoints: 0,
      pointsChange: 0,
      shiftsCount: 0,
      employeeCount: 0,
    };
  });

  calculateImpact();
  filterHotels();
};

const filterHotels = () => {
  if (!hotels.value) return;

  let filtered = [...hotels.value];

  // Filter by city if selected
  if (selectedCity.value) {
    filtered = filtered.filter((hotel) => hotel.city_id === selectedCity.value);
  }

  // Filter by search query
  if (debouncedSearchQuery.value) {
    const query = debouncedSearchQuery.value.toLowerCase();
    filtered = filtered.filter(
      (hotel) =>
        hotel.name?.toLowerCase().includes(query) ||
        hotel.city_name?.toLowerCase().includes(query),
    );
  }

  filteredHotels.value = filtered;
};

const filterHotelsByCity = () => {
  filterHotels();
  calculateImpact();
};

// Check if value has changed
const hasValueChanged = (original, simulated) => {
  return Number(original) !== Number(simulated);
};

const calculateImpact = () => {
  if (!props.employeeData || !hotels.value) return;

  // Reset impact data for all hotels
  hotels.value.forEach((hotel) => {
    hotel.originalPoints = 0;
    hotel.newPoints = 0;
    hotel.pointsChange = 0;
    hotel.shiftsCount = 0;
    hotel.employeeCount = new Set();
  });

  // Process each employee's shifts
  props.employeeData.forEach((employee) => {
    if (!employee.shiftsWithBonus) return;

    // Skip if we're filtering by city and employee isn't in that city
    if (selectedCity.value && employee.city_id !== selectedCity.value) return;

    employee.shiftsWithBonus.forEach((shift) => {
      const hotel = hotels.value.find((h) => h.id === shift.hotels?.id);
      if (!hotel) return;

      // Count this shift
      hotel.shiftsCount++;
      hotel.employeeCount.add(employee.id);

      // Calculate original points from this shift
      let originalPoints = 0;
      if (shift.is_senior) {
        originalPoints = hotel.original_senior_points || 0;
      } else if (shift.usedSeniorBonus) {
        originalPoints = hotel.original_senior_points || 0;
      } else {
        originalPoints = hotel.original_clerk_points || 0;
      }

      // Calculate new points based on modified values
      let newPoints = 0;
      if (shift.is_senior) {
        newPoints = hotel.senior_points || 0;
      } else if (shift.usedSeniorBonus) {
        newPoints = hotel.senior_points || 0;
      } else {
        newPoints = hotel.clerk_points || 0;
      }

      // Add to hotel totals
      hotel.originalPoints += originalPoints;
      hotel.newPoints += newPoints;
    });
  });

  // Calculate changes for each hotel
  hotels.value.forEach((hotel) => {
    hotel.pointsChange = hotel.newPoints - hotel.originalPoints;
    hotel.employeeCount = hotel.employeeCount.size;
  });
};

const applySimulation = () => {
  calculateImpact();
  emitSimulationResults();
};

// Reset a single hotel to original values
const resetHotel = (hotel) => {
  hotel.clerk_points = hotel.original_clerk_points;
  hotel.senior_points = hotel.original_senior_points;
  calculateImpact();
};

const resetAllChanges = () => {
  hotels.value.forEach((hotel) => {
    hotel.clerk_points = hotel.original_clerk_points;
    hotel.senior_points = hotel.original_senior_points;
  });

  calculateImpact();
};

const emitSimulationResults = () => {
  const hotelSimulationData = {
    hotels: hotels.value
      .map((hotel) => ({
        id: hotel.id,
        hotel_id: hotel.hotel_id || hotel.id,
        name: hotel.name,
        clerk_points: Number(hotel.clerk_points),
        senior_points: Number(hotel.senior_points),
        original_clerk_points: hotel.original_clerk_points,
        original_senior_points: hotel.original_senior_points,
        pointsChange: hotel.pointsChange,
      }))
      .filter(
        (hotel) =>
          hotel.pointsChange !== 0 ||
          hasValueChanged(hotel.original_clerk_points, hotel.clerk_points) ||
          hasValueChanged(hotel.original_senior_points, hotel.senior_points),
      ),
  };

  emit("update:simulation", {
    type: "hotel-points",
    data: hotelSimulationData,
  });
};

// Formatting helpers
const formatPointChange = (points) => {
  if (points === 0) return "0";
  return points > 0 ? `+${points}` : `${points}`;
};

const getImpactColor = (change) => {
  if (change === 0) return "blue-grey";
  return change > 0 ? "success" : "error";
};

// Watch for changes in props or search query
watch(() => props.hotelBonusData, initializeData, {
  immediate: true,
  deep: true,
});

watch(
  () => props.selectedCity,
  (newValue) => {
    selectedCity.value = newValue;
    filterHotels();
  },
  {
    immediate: true,
  },
);

watch(debouncedSearchQuery, filterHotels);
watch(selectedCity, filterHotelsByCity);

// Initialize on component mount
onMounted(() => {
  initializeData();
});
</script>

<style scoped>
.scrollable-content {
  max-height: 800px;
  overflow-y: auto;
}

.simulation-input {
  width: 100%;
  min-width: 110px;
}

.original-points {
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.changed-value {
  background-color: rgba(128, 203, 196, 0.2);
}

.impact-row {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.v-text-field.v-input--dense :deep(.v-field__field) {
  min-height: 56px !important;
}
</style>
