<template>
  <v-btn
    v-if="false"
    class="ma-2"
    @click="inviteUser()"
    :loading="inviteUserLoading"
    >Zum System einladen</v-btn
  >
  <v-data-table-virtual :items="taskHistory" :headers="headers" class="mb-8">
    <template v-slot:item.old_value="{ item }">
      <v-chip
        v-if="item.old_value"
        size="small"
        variant="elevated"
        color="blue-grey"
        >{{
          item.old_value_name ? item.old_value_name : item.old_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.new_value="{ item }">
      <v-chip
        v-if="item.new_value"
        size="small"
        variant="elevated"
        color="green"
        >{{
          item.new_value_name ? item.new_value_name : item.new_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.actions="{ item }">
      <template v-if="item.is_request_accepted == null && item.requested_by_id">
        <div>
          <router-link v-if="item.key_id == 17" to="/Settings/Admin"
            >Kann nur in der Adminkonsole geändert werden</router-link
          >
        </div>
        <v-btn
          :loading="loading"
          size="x-small"
          color="success"
          @click="updateValue(item, 'positive')"
          icon
          :disabled="!item.editable"
          v-if="!item.employee_data_keys.is_blocked"
          ><v-icon>mdi-thumb-up</v-icon></v-btn
        >
        <v-btn
          :loading="loading"
          size="x-small"
          color="error"
          @click="updateValue(item, 'negative')"
          icon
          :disabled="
            !item.editable ||
            (item.employee_data_keys.is_blocked &&
              !$store.state.userInfo.userRights.is_admin)
          "
          ><v-icon>mdi-thumb-down</v-icon></v-btn
        >
      </template>
      <template v-else>
        <v-icon v-if="item.is_request_accepted == false && item.requested_by_id"
          >mdi-thumb-down</v-icon
        >
        <v-icon v-else-if="item.is_request_accepted && item.requested_by_id"
          >mdi-thumb-up</v-icon
        >
      </template>
    </template>
  </v-data-table-virtual>
  <v-data-table :items="history" :headers="headers">
    <template v-slot:item.old_value="{ item }">
      <v-chip
        v-if="item.old_value"
        size="small"
        variant="elevated"
        color="blue-grey"
        >{{
          item.old_value_name ? item.old_value_name : item.old_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.new_value="{ item }">
      <v-chip
        v-if="item.new_value"
        size="small"
        variant="elevated"
        color="green"
        >{{
          item.new_value_name ? item.new_value_name : item.new_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.actions="{ item }">
      <template v-if="item.is_request_accepted == null && item.requested_by_id">
        <v-btn
          :loading="loading"
          size="x-small"
          color="success"
          @click="updateValue(item, 'positive')"
          icon
          ><v-icon>mdi-thumb-up</v-icon></v-btn
        >
        <v-btn
          :loading="loading"
          size="x-small"
          color="error"
          @click="updateValue(item, 'negative')"
          icon
          ><v-icon>mdi-thumb-down</v-icon></v-btn
        >
      </template>
      <template v-else>
        <v-icon v-if="item.is_request_accepted == false && item.requested_by_id"
          >mdi-thumb-down</v-icon
        >
        <v-icon v-else-if="item.is_request_accepted && item.requested_by_id"
          >mdi-thumb-up</v-icon
        >
      </template>
    </template>
  </v-data-table>
</template>

<script setup>
import axios from "axios";
const props = defineProps({
  employeeInfo: Object,
  role: Object,
});
</script>

<script>
export default {
  data() {
    return {
      loading: false,
      inviteUserLoading: false,
      history: [],
      taskHistory: [],
      headers: [
        { title: "Key", value: "keyName" },
        { title: "Geändert durch", value: "changedByName" },
        { title: "Vorgeschlagen von", value: "requestedByName" },
        { title: "Alter Wert", value: "old_value" },
        { title: "Neuer Wert", value: "new_value" },
        { title: "Erstellt am", value: "showDate" },
        { title: "Geplant für", value: "plannedShowDate" },
        { title: "Übernehmen", value: "actions" },
      ],
    };
  },
  methods: {
    async getHistory() {
      this.history = [];
      this.taskHistory = [];
      var queryObject = {
        select:
          "*,employee_data_keys!inner(selection_table,key_name_ger,section_id,is_blocked),changedByUser:public_employee_data_history_changed_from_id_fkey(id,full_name),requestedByUser:public_employee_data_history_requested_by_id_fkey(id,full_name)",
        table: "employee_data_history",
        where: [
          { type: "eq", key: "employee_id", value: this.employeeInfo.id },
          {
            type: "in",
            key: "employee_data_keys.section_id",
            value: this.role.sections,
          },
        ],
        order: {
          key: "created_at",
          ascending: false,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      for (var i = 0; i < response.data.length; i++) {
        var item = response.data[i];
        if (item.employee_data_keys.selection_table) {
          var table = item.employee_data_keys.selection_table;
          var queryObject = {
            select: "*",
            table: table,
            where: [
              {
                type: "in",
                key: "id",
                value: [item.old_value, item.new_value],
              },
            ],
          };
          var ids = await this.$store.dispatch(
            "getSupabaseEntity",
            queryObject,
          );
          ids.data.forEach((id) => {
            if (id.id == item.old_value) {
              item.old_value_name = id.name;
            } else {
              item.new_value_name = id.name;
            }
          });
        }
        item.keyName = item.employee_data_keys.key_name_ger;
        item.editable =
          this.role.sectionRights[item.employee_data_keys.section_id].editable;
        item.showDate = await this.$store.dispatch(
          "formatDate",
          new Date(item.created_at),
        );

        if (item.to_save_date) {
          item.plannedShowDate = (
            await this.$store.dispatch(
              "formatDate",
              new Date(item.to_save_date),
            )
          ).split(" ")[0];
        }

        if (item.changed_by_id) {
          item.changedByName = item.changedByUser.full_name;
        }
        if (item.requested_by_id) {
          item.requestedByName = item.requestedByUser.full_name;
        }
        if (item.is_request_accepted == null && item.requested_by_id) {
          this.taskHistory.push(item);
        } else {
          this.history.push(item);
        }
      }
    },
    async updateValue(item, type) {
      this.loading = true;
      var queryObject = {
        table: "employee_data_matching",
        payload: {
          employee_id: item.employee_id,
          key_id: item.key_id,
        },
      };
      queryObject.payload[item.key_type + "_suggestion"] = null;
      if (type == "positive") {
        queryObject.payload[item.key_type] = item.new_value;
        var isRequestAccepted = true;
      } else {
        var isRequestAccepted = false;
      }

      await this.$store.dispatch("upsertSupabaseEntity", queryObject);

      var queryObject = {
        table: "employee_data_history",
        id: item.id,
        payload: {
          is_request_accepted: isRequestAccepted,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.getHistory();
      this.loading = false;
    },
    async inviteUser() {
      this.inviteUserLoading = true;
      var googleCredentials = await this.$store.dispatch("getSupabaseEntity", {
        table: "google_credentials",
      });
      var appsScriptKey = googleCredentials.data[0].apps_script_key;
      var email = this.employeeInfo.email;
      var task = "inviteUser";
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec";
      var fetchUrl = `${url}?secret_key=${appsScriptKey}&task=${task}&email=${email}`;
      var response = await axios.get(fetchUrl);
      var userObject = {
        table: "public_users",
        payload: {
          first_name: this.employeeInfo.first_name,
          last_name: this.employeeInfo.last_name,
          email: this.employeeInfo.email,
          full_name: this.employeeInfo.name,
          active: true,
          occupation: this.employeeInfo.position,
          is_admin: false,
          is_recruiter: false,
          is_office: false,
        },
      };
      await this.$store.dispatch("createSupabaseEntity", userObject);
      this.inviteUserLoading = false;
    },
  },
  created() {
    this.getHistory();
  },
};
</script>
