<template>
    <v-container>
      <v-alert
      v-if="lowStockItems.length > 0 && !employeeInfo"
      type="warning"
      prominent
      max-width="500"
    >
      <strong style="font-size: large;">Niedriger Lagerbestand für:</strong>
      <ul class="mt-1">
        <li v-for="item in lowStockItems" :key="item.id"
        >
         <strong> {{ item.name }} </strong> (Aktueller Bestand: {{ item.stock_count }}, Schwellenwert: {{ item.stock_alert_count }})
        </li>
      </ul>
    </v-alert>
      <v-data-table
        :headers="headers"
        :items="protocols"
        :loading="loading"
        class="elevation-1"
      >     
        <template v-slot:item.items="{ item }">
          <v-chip
            v-for="accessoryItem in item.items"
            :key="accessoryItem.id"
            size="small"
            class="ma-1"
            variant="elevated"
            color="blue-grey-lighten-1"           
          >
            {{ accessoryItem.accessory.name }} <v-chip size="x-small" class="ml-1" variant="elevated" color="blue-grey-lighten-3"> {{ accessoryItem.count }} </v-chip>
          </v-chip>
        </template>
        <template v-slot:item.reviewed="{ item }">
          <div class="d-flex algin-center">
          <v-checkbox
            v-if="false"
            v-model="item.reviewed"
            @change="updateReviewStatus(item)"
            :disabled="!item.infoClicked"
          ></v-checkbox>
          <v-icon icon="mdi-eye" @click="openProtocolDialog(item)"></v-icon>
        </div>
        </template>
      </v-data-table>
        <v-dialog v-model="dialogVisible" max-width="800px">
          <v-card v-if="selectedProtocol">
            <v-card-title>
              <v-row align="center" class="w-100 justify-space-between">
                <v-col cols="3">
              <v-checkbox
                    v-if="!employeeInfo"
                    label="Geprüft?"
                    v-model="selectedProtocol.reviewed"
                    @change="updateReviewStatus(selectedProtocol)"
                    class="mr-2"
                   ></v-checkbox>
                   
                  </v-col>                            
                    
                    <v-col cols="6" class="d-flex justify-center">
                      <h2>Übergabeprotokoll</h2>
                    </v-col>
                    
                <v-col cols="3" class="d-flex justify-end">
              <v-btn variant="outlined" icon @click="dialogVisible = false" class="position-absolute top-0 right-0 mt-2 mr-2">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            </v-row>
            </v-card-title>
          
        <v-card-text class="pt-0">
          <v-divider :thickness="4" class="my-3"></v-divider>
          <v-row>
            <v-col cols="6">
              <strong>Datum:</strong> {{ selectedProtocol.created_at }}
            </v-col>
            <v-col cols="6">
              <strong>Übergabe durch:</strong> {{ selectedProtocol.transferor.name }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
                <strong v-if="!selectedProtocol.receiver_candidate_id">Mitarbeiter:</strong>
                <strong v-else>Kandidat:</strong>
                
                {{ selectedProtocol.receiver.name }}
            </v-col>
          </v-row>
          <v-divider :thickness="4" class="my-3"></v-divider>
          <h3 align="center">Accessoires:</h3>
          
          <v-container>
  <v-row class="justify-center">
    <v-col
      v-for="item in selectedProtocol.items"
      :key="item.id"
      cols="auto"
      class="d-flex justify-center align-center"
    >
      <v-card color="info" variant="outlined" class="text-center" width="200" height="95">
        <v-card-title class="justify-center" style="font-size: 18px;">
          <strong>{{ item.accessory.name }}</strong>: {{ item.count }}
        </v-card-title>
        <v-card-subtitle class="justify-center text-wrap" style="font-weight: bold;">
          Grund: {{ item.change_cause || 'N/A' }}
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</v-container>

<v-divider :thickness="4" class="my-3"></v-divider>
          
          <v-row>
            <v-col cols="6">
              <strong>Unterschrift {{ selectedProtocol.transferor.name }}:</strong>
              <img :src="selectedProtocol.signature_transferor" alt="Transferor Signature" class="signature-image">
            </v-col>
            <v-col cols="6">
              <strong>Unterschrift {{ selectedProtocol.receiver.name }}:</strong>
              <img :src="selectedProtocol.signature_receiver" alt="Receiver Signature" class="signature-image">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-container>
  </template>
  
  <script setup>
    const props = defineProps({
        employeeInfo: Object,
    });
  </script>
  <script>
  export default {
    data() {
      return {
        loading: true,
        protocols: [],
        headers: [
          { title: 'Datum', key: 'created_at' },
          { title: 'Übergabe durch', key: 'transferor.name' },
          { title: 'Empfänger/ Rückgeber', key: 'receiver.name' },
          { title: 'Accessoires', key: 'items' },
          { title: 'Protokoll', key: 'reviewed' },
        ],
        dialogVisible: false,
        selectedProtocol: null,
        accessories: [],
      };
    },
    computed: {
    
    lowStockItems() {
    return this.accessories.filter(accessory => accessory.stock_count < accessory.stock_alert_count);
  },
  },
    methods: {
      async fetchProtocols() {
        try {

          const queryObject = {
            table: 'accessory_protocol',
            select: `
              *,
              transferor:transferor_id(name),
              receiver:receiver_id(name),
              receiver_candidate:receiver_candidate_id(first_name,last_name),
              items:accessory_protocol_items(
                id,
                count,
                change_cause,
                accessory:accessory_id(id, name, stock_count, stock_alert_count)
              )
            `,
            order: {
              key: 'created_at',
              ascending: false
            },
            where:[]
          };
          if (this.employeeInfo) {
            queryObject.where.push(
              {type:"or",value:[
      `receiver_id.eq.${this.employeeInfo.id}`,
      this.employeeInfo.candidate_id ? `receiver_candidate_id.eq.${this.employeeInfo.candidate_id}` : null
    ].filter(Boolean).join(',')}
            )
          }
          else {
            queryObject.where.push(
              {type:"is",key:"is_checked",value:null}
            )
          }
          const response = await this.$store.dispatch('getSupabaseEntity', queryObject);
  
          if (response.error) throw response.error;
  
          this.protocols = response.data.map(protocol => ({
            ...protocol,
            created_at: new Date(protocol.created_at).toLocaleDateString("De-de"),
            reviewed: false, 
            infoClicked:false
          }));
          this.protocols.forEach(protocol => {
            protocol.receiver = protocol.receiver ? protocol.receiver : { name: protocol.receiver_candidate.first_name + " " + protocol.receiver_candidate.last_name}
          });
        } catch (error) {
          console.error('Error fetching protocols:', error);
        } finally {
          this.loading = false;
        }
      },

      async fetchAccessoryItems() {
      const queryObject = {
        table: "accessory_items",
        select: "id, name, stock_count, stock_alert_count"
      };
      const response = await this.$store.dispatch("getSupabaseEntity", queryObject);
      this.accessories = response.data;
    },  

      async updateReviewStatus(protocolItem) {
  try {
    if (!protocolItem.id) {
      console.error('Item ID is undefined. Aborting update.');
      return;
    }

    const updatedStatus = protocolItem.reviewed; 

    const { error } = await this.$store.dispatch('updateSupabaseEntity', {
      table: 'accessory_protocol',
      id: protocolItem.id, 
      payload: { is_checked: updatedStatus } 
    });

    if (error) throw error;

    if (updatedStatus) {
      this.protocols = this.protocols.filter(protocol => protocol.id !== protocolItem.id);
      this.dialogVisible = false;
    }
  } catch (error) {
    console.error('Error updating review status:', error);
    protocolItem.reviewed = !protocolItem.reviewed; 
  }
},

      openProtocolDialog(protocol) {
        protocol.infoClicked = true;
        this.selectedProtocol = protocol;
        this.dialogVisible = true;
    },
    },

    mounted() {
      this.fetchAccessoryItems();
      this.fetchProtocols();
    }
  };
  </script>  

<style scoped>
.signature-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  margin-top: 8px;
}
</style>