<template>
  <v-checkbox
    v-model="location.is_activated"
    v-for="location in locations"
    :label="location.city"
  ></v-checkbox>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {
      start: true,
      locations: [],
      roleLocationArray: [],
    };
  },
  methods: {
    async getLocations() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
      });
      this.locations = response.data;

      this.locations.forEach((location) => {
        if (
          this.chosenRole.locations.find(
            (locationSearch) => locationSearch === location.id,
          )
        ) {
          location.is_activated = true;
        } else {
          location.is_activated = false;
        }
      });
    },
    async updateLocationArray() {
      let idArray = [];
      this.roleLocationArray = this.locations.filter(
        (location) => location.is_activated,
      );
      this.roleLocationArray.forEach((location) => {
        idArray.push(location.id);
      });
      await this.$store.dispatch("updateSupabaseEntity", {
        table: "public_user_roles",
        id: this.chosenRole.id,
        payload: {
          locations: idArray,
        },
      });
    },
  },
  watch: {
    "chosenRole.id": {
      handler: function () {
        this.getLocations();
      },
      deep: true,
    },
    locations: {
      handler: function () {
        if (!this.start) {
          this.updateLocationArray();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getLocations();
    this.start = false;
  },
};
</script>

<style scoped></style>
