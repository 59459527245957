<template>
  <div class="tables-container">
    <v-card>
      <v-card-text v-if="tableLoading">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
      <v-card-text
        v-else
        style="overflow: auto; width: 100%"
        class="tableFixHead"
      >
        <v-row>
          <v-col cols="4">
            <v-switch
              class="mb-n7"
              v-model="preciseTable"
              label="Ausführlicher Plan"
            ></v-switch>
          </v-col>
          <v-col cols="8">
            <v-expansion-panels
              class="mt-2"
              v-model="showFilter"
              v-if="type == 'employees'"
            >
              <v-expansion-panel title="Eventfilter">
                <v-expansion-panel-text>
                  <v-chip
                    v-if="eventFilter"
                    @click="toggleEventFilter(null)"
                    class="ma-1"
                    size="small"
                    color="primary"
                    variant="elevated"
                    prepend-icon="mdi-filter-remove"
                  >
                    Filter löschen
                  </v-chip>
                  <template v-for="event in allEventTypes" :key="event.value">
                    <v-chip
                      @click="toggleEventFilter(event.value)"
                      class="ma-1"
                      size="small"
                      :variant="
                        eventFilter == event.value ? 'elevated' : 'outlined'
                      "
                      :color="eventFilter == event.value ? 'primary' : ''"
                      :prepend-icon="event.icon"
                    >
                      {{ event.name }}
                    </v-chip>
                  </template>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <div class="table-scroll-container">
          <table class="ma-2" v-if="publicCompareArray.length > 0">
            <tr>
              <template v-for="(header, index) in headers">
                <th v-if="index > 0">{{ header.title }}</th>
              </template>
            </tr>
            <tr>
              <td
                style="border: 1px solid black"
                v-for="compare in publicCompareArray"
              >
                <v-progress-circular
                  size="x-small"
                  v-if="compareLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <template v-else>
                  <v-icon
                    v-if="compare.areEqual"
                    color="success"
                    icon="mdi-earth"
                  ></v-icon>
                  <v-icon
                    @click="updatePublicShifts(compare)"
                    v-else
                    icon="mdi-earth-off"
                  ></v-icon>
                </template>
              </td>
            </tr>
          </table>
          <table v-if="preciseTable" style="table-layout: fixed; width: 100%">
            <thead>
              <tr>
                <th
                  v-for="(header, n) in headers"
                  style="font-size: 6pt"
                  :class="n == 0 ? 'sticky-col' : ''"
                  :style="{
                    border: '4px solid black',
                    borderRight: n == 10 || n == 17 ? '8px black solid' : null,
                    borderLeft:
                      new Date(header.date).getDay() == 1
                        ? '8px solid black'
                        : null,
                  }"
                >
                  {{ header.title }}
                </th>
              </tr>
            </thead>
            <colgroup>
              <col style="width: 150px" />
              <col
                span="1"
                v-for="header in headers"
                style="width: 55px"
                :style="
                  new Date(header.date).getDay() == 6 ||
                  new Date(header.date).getDay() == 5
                    ? 'background-color: #D6EEEE'
                    : null
                "
              />
            </colgroup>
            <tr v-for="item in displayedItems" :key="item.tableId">
              <td
                style="border: 4px solid black; text-align: left"
                class="sticky-col"
              >
                <router-link
                  target="_blank"
                  :to="{ path: item.link }"
                  class="d-inline-block text-truncate"
                  style="max-width: 150px"
                  >{{ item.show_name }}</router-link
                >
              </td>
              <td
                @click="editEvent(item, item[n], n)"
                v-for="n in dayRange"
                :style="{
                  cursor: 'pointer',
                  backgroundColor:
                    item[n].addedEvents && item[n].addedEvents[0]
                      ? item[n].addedEvents[0].color
                      : item[n].color,
                  borderBottom: '2px black solid',
                  borderRight: n == 10 || n == 17 ? '8px black solid' : null,
                  borderLeft:
                    n == 4 || n == 11 || n == 18 || n == 25 || n == 32
                      ? '6px solid black'
                      : null,
                }"
              >
                <div
                  v-if="
                    (item[n].is_shift || item[n].is_not_filled) && item[n].event
                  "
                  style="font-size: 6pt"
                  size="x-small"
                >
                  {{ item[n].event }}
                  <v-icon
                    class="ml-1"
                    size="x-small"
                    v-for="icon in item[n].addedEvents"
                    :icon="icon.icon"
                  ></v-icon>
                </div>
                <v-icon
                  v-else-if="item[n]"
                  size="x-small"
                  :icon="item[n].icon"
                ></v-icon>
                <v-tooltip
                  v-if="item[n].toolTip"
                  activator="parent"
                  location="bottom"
                  open-delay="300"
                >
                  <div v-html="item[n].toolTip"></div>
                </v-tooltip>
              </td>
            </tr>
          </table>
          <v-data-table
            v-else
            items-per-page="-1"
            fixed-header
            class="full-height-card"
            :loading="tableLoading"
            :search="search"
            :headers="headers"
            :items="displayedItems"
            hide-default-footer
            style="border: 2px solid black; width: 100%"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.tableId">
                <td style="text-align: left">
                  <router-link
                    target="_blank"
                    :to="{ path: item.link }"
                    class="d-inline-block text-truncate"
                    style="max-width: 150px"
                    >{{ item.show_name }}</router-link
                  >
                </td>
                <template v-for="n in dayRange">
                  <v-menu
                    open-delay="80"
                    close-delay="50"
                    v-model="item[n].menu"
                    open-on-hover
                    @mouseenter="getPossibleItems(item, n)"
                  >
                    <template v-slot:activator="{ props }">
                      <td
                        @click="editEvent(item, item[n], n)"
                        v-bind="props"
                        :style="{
                          cursor: 'pointer',
                          borderRight:
                            n == 3 || n == 10 ? '6px black solid' : null,
                          backgroundColor:
                            item[n].addedEvents && item[n].addedEvents[0]
                              ? item[n].addedEvents[0].color
                              : item[n].color,
                        }"
                      >
                        <v-tooltip
                          :text="item[n].note"
                          v-if="
                            (item[n].is_shift || item[n].is_not_filled) &&
                            item[n].event
                          "
                        >
                          <template v-slot:activator="{ props }">
                            <v-chip
                              v-bind="props"
                              :append-icon="item[n].note ? 'mdi-note' : null"
                              :variant="
                                item[n].addedEvents && item[n].addedEvents[0]
                                  ? 'elevated'
                                  : 'outlined'
                              "
                              :color="
                                item[n].addedEvents && item[n].addedEvents[0]
                                  ? 'white'
                                  : null
                              "
                              size="small"
                            >
                              {{ item[n].event }}
                              <v-icon
                                class="ml-1"
                                size="small"
                                :color="item[n].color"
                                v-for="icon in item[n].addedEvents"
                                :icon="icon.icon"
                                >{{ item[n] }} {{ icon.icon }}</v-icon
                              >
                            </v-chip>
                          </template>
                        </v-tooltip>
                        <div v-else>
                          <v-icon :icon="item[n].icon"></v-icon>
                          <v-icon
                            class="ml-1"
                            size="small"
                            :color="item[n].color"
                            v-for="icon in item[n].addedEvents"
                            :icon="icon.icon"
                            >{{ item[n] }} {{ icon.icon }}</v-icon
                          >
                        </div>
                        <v-tooltip
                          v-if="item[n].toolTip"
                          activator="parent"
                          location="bottom"
                          open-delay="300"
                        >
                          <div v-html="item[n].toolTip"></div>
                        </v-tooltip>
                        <v-icon
                          size="x-small"
                          v-if="item[n].performanceRecordId"
                          @click.stop.prevent:="
                            performanceRecord.reportId =
                              item[n].performanceRecordId;
                            performanceRecord.shift = item[n].shift;
                            performanceRecord.dialog = true;
                          "
                          >mdi-file-document</v-icon
                        >
                      </td>
                    </template>
                    <v-list v-if="type == 'employees'">
                      <v-list-item link v-if="type == 'hotels'">
                        <v-list-item-title>Mitarbeiter</v-list-item-title>
                        <template v-slot:append>
                          <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                        </template>

                        <v-menu
                          :open-on-focus="false"
                          activator="parent"
                          open-on-hover
                          submenu
                        >
                          <v-list>
                            <v-list-item
                              v-for="combi in item.employees"
                              link
                              @click="saveShift(item[n], combi)"
                            >
                              <v-list-item-title>
                                <v-icon
                                  v-if="combi.employee.event.length > 0"
                                  >{{
                                    combi.employee.event[0].key.icon
                                  }}</v-icon
                                >
                                {{ combi.employee.show_name }}
                                <v-chip size="x-small">{{
                                  combi.is_senior ? "Senior" : "Clerk"
                                }}</v-chip>
                                <span
                                  v-if="
                                    combi.employee.shifts[0] &&
                                    combi.employee.shifts[0].employee_id > 0
                                  "
                                >
                                  <v-icon>mdi-swap-horizontal</v-icon>
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>

                      <v-list-item link v-if="type == 'employees'">
                        <v-list-item-title>Hotels</v-list-item-title>
                        <template v-slot:append>
                          <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                        </template>

                        <v-menu
                          :open-on-focus="false"
                          activator="parent"
                          open-on-hover
                          submenu
                        >
                          <v-list>
                            <v-list-item
                              v-for="combi in item.hotels"
                              link
                              @click="saveShift(item[n], combi)"
                            >
                              <v-list-item-title v-if="type == 'employees'">
                                {{ combi.hotel.name }}
                                <v-chip size="x-small">{{
                                  combi.is_senior ? "Senior" : "Clerk"
                                }}</v-chip>
                                <span
                                  v-if="
                                    combi.hotel.shifts[0] &&
                                    combi.hotel.shifts[0].employee_id > 0
                                  "
                                >
                                  <v-icon>mdi-swap-horizontal</v-icon
                                  >{{ combi.hotel.shifts[0].employees.name }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                      <v-list-item link v-if="type == 'employees'">
                        <v-list-item-title>Ereignisse</v-list-item-title>
                        <template v-slot:append>
                          <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                        </template>
                        <v-menu
                          :open-on-focus="false"
                          activator="parent"
                          open-on-hover
                          submenu
                        >
                          <v-list>
                            <v-list-item
                              v-for="event in splittedEvents.is_single"
                              link
                              @click="saveEvent(item[n], event)"
                            >
                              <v-list-item-title>
                                <v-icon>{{ event.icon }}</v-icon
                                >{{ event.name }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item
                              v-for="event in splittedEvents.is_addable"
                              link
                              @click="saveEvent(item[n], event)"
                            >
                              <v-list-item-title>
                                <v-icon>{{ event.icon }}</v-icon
                                >{{ event.name }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                      <v-list-item link>
                        <v-list-item-title @click="clearShift(item[n])"
                          >Schicht leeren</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
  <v-dialog v-model="performanceRecord.dialog">
    <template #default="{ attrs }">
      <v-toolbar color="indigo">
        <v-toolbar-title>Leistungsnachweis</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="performanceRecord.dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="dialog-container" style="overflow-y: auto">
        <ShiftRecord
          :reportId="performanceRecord.reportId"
          :shift="performanceRecord.shift"
        ></ShiftRecord>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import EditCard from "./EditCard.vue";
import ShiftRecord from "@/components/Shifts/PerformanceRecord.vue";
import { supabase } from "@/supabase";
</script>

<script>
export default {
  components: {
    EditCard,
  },
  props: {
    allEventTypes: Array,
    headers: Array,
    items: Array,
    dayRange: Number,
    search: String,
    employees: Array,
    type: String,
    eventFilter: String,
    tableLoading: Boolean,
    publicCompareArray: Array,
    compareLoading: Boolean,
    snackbar: Object,
  },
  data() {
    return {
      splittedEvents: {
        is_addable: [],
        is_single: [],
        is_disabled: [],
      },
      showFilter: false,
      preciseTable: false,
      performanceRecord: {
        dialog: false,
        reportId: null,
        shift: null,
      },
    };
  },
  computed: {
    filteredItems() {
      if (!this.eventFilter) return this.items;

      return this.items.filter((item) => {
        // Check if any of the days has the filtered event type
        for (let i = 1; i <= this.dayRange; i++) {
          if (
            item[i] &&
            (item[i].event_type === this.eventFilter ||
              (item[i].addedEvents &&
                item[i].addedEvents.some(
                  (event) => event.event_type === this.eventFilter,
                )))
          ) {
            return true;
          }
        }
        return false;
      });
    },
    displayedItems() {
      return this.filteredItems;
    },
  },
  methods: {
    async synchronizeWithSheets(shiftObject) {
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=updateShiftPlan&secret_key=TEW567LPOVBN267";
      var options = {
        method: "POST",
        body: JSON.stringify(shiftObject),
      };
      try {
        var response = await fetch(url, options);
      } catch (err) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      }

      response = await response.json();
      var employee = this.employees?.find(
        (employee) => employee.id == response.payload.employee_id,
      );
      var date = new Date(response.payload.date).toLocaleDateString("de-DE");
      this.snackbar.data = employee
        ? employee.show_name
        : "Alter Mitarbeiter entfernt" + " am " + date;
      if (response.error) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      } else {
        this.snackbar.color = "success";
        this.snackbar.timeout = 1000;
        this.snackbar.show = true;
      }
    },
    getEventTypes() {
      this.splittedEvents = {
        is_addable: [],
        is_single: [],
        is_disabled: [],
      };
      this.allEventTypes.forEach((event) => {
        if (event.disabled) {
          this.splittedEvents.is_disabled.push(event);
        } else if (event.is_addable && !event.disabled) {
          this.splittedEvents.is_addable.push(event);
        } else if (!event.is_addable && !event.disabled) {
          this.splittedEvents.is_single.push(event);
        }
      });
      this.splittedEvents.is_addable.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      this.splittedEvents.is_single.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      this.splittedEvents.is_disabled.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    },
    editEvent(item, value, n) {
      this.$emit("edit-event", { item, value, n });
    },
    toggleEventFilter(eventType) {
      this.$emit("update:eventFilter", eventType);
    },
    async saveShift(item, combi) {
      let shifts = [];
      let deleteObject = false;
      let deleteHotel = null;
      let deleteEmployee = null;
      let date = item.real_date.toISOString().split("T")[0];
      if (this.type == "employees") {
        shifts = combi.hotel.shifts;
        deleteObject =
          shifts.length > 0 &&
          shifts[0].employee_id > 0 &&
          shifts[0].employee_id != item.employee_id;
        deleteEmployee = deleteObject ? shifts[0].employee_id : null;
        deleteHotel = combi.hotel_id;
        await supabase.from("shifts").upsert({
          date: item.real_date.toISOString(),
          employee_id: null,
          hotel_id: combi.hotel_id,
          is_senior: combi.is_senior,
          is_training: false,
          check_id: 1,
        });
        if (item.hotel_id > 0) {
          this.clearShift(item);
        }
      } else if (this.type == "hotels") {
        item.employee_id = combi.employee_id;
        shifts = combi.employee.shifts;
        deleteObject = shifts.length > 0;
        deleteEmployee = combi.employee_id;
        deleteHotel = deleteObject ? shifts[0].hotel_id : null;
        if (item.employee_id > 0) {
          this.clearShift(item);
        }
      }
      if (deleteObject) {
        const deleteSheetObject = {
          hotel_id: deleteHotel,
          date: date,
          is_training: false,
          is_senior: combi.is_senior,
          employee_id: null,
          oldEmpId: deleteEmployee,
        };
        this.synchronizeWithSheets(deleteSheetObject);
      }

      const response = await supabase.from("shifts").upsert({
        date: date,
        employee_id: item.employee_id,
        hotel_id: combi.hotel_id,
        is_senior: combi.is_senior,
        is_training: false,
        check_id: 1,
      });
      const activeSheetObject = {
        hotel_id: combi.hotel_id,
        date: date,
        is_training: false,
        is_senior: combi.is_senior,
        employee_id: item.employee_id,
      };
      this.synchronizeWithSheets(activeSheetObject);
      this.$emit("refresh-data", this.type);
    },
    async saveEvent(item, event) {
      let date = item.real_date.toISOString().split("T")[0];
      if (!event.is_addable) {
        const checkShift = await supabase
          .from("shifts")
          .select("*")
          .eq("employee_id", item.employee_id)
          .eq("date", item.real_date.toISOString())
          .eq("is_training", false);
        if (checkShift.data.length > 0) {
          const deleteShift = checkShift.data[0];
          const deleteObject = {
            hotel_id: deleteShift.hotel_id,
            date: date,
            is_training: false,
            is_senior: deleteShift.is_senior,
            employee_id: item.employee_id,
            real_date: item.real_date,
          };
          this.clearShift(deleteObject);
        }
      }
      const payload = {
        employee_id: item.employee_id,
        employee_date_event_id: event.id,
        date: date,
        hotel_id: item.hotel_id,
        is_activated: true,
      };
      const response = await supabase
        .from("employee_date_event_matching")
        .upsert(payload);

      const sheetPayloadObject = {
        employee_id: item.employee_id,
        date: date,
        is_employee_event: true,
      };
      sheetPayloadObject[event.value] = true;
      this.synchronizeWithSheets(sheetPayloadObject);
      this.$emit("refresh-data", this.type);
    },
    async clearShift(item) {
      let date = item.real_date.toISOString().split("T")[0];
      const response = await supabase.from("shifts").upsert({
        date: item.real_date.toISOString(),
        employee_id: null,
        hotel_id: item.hotel_id,
        is_senior: item.is_senior,
        is_training: false,
        check_id: 1,
      });
      const deleteSheetObject = {
        hotel_id: item.hotel_id,
        date: date,
        is_training: false,
        is_senior: item.is_senior,
        employee_id: null,
        oldEmpId: item.employee_id,
      };
      this.synchronizeWithSheets(deleteSheetObject);
      this.$emit("refresh-data", this.type);
    },
    async getPossibleItems(item, n) {
      this.getEventTypes();
      let date = item[n].date.split(".");
      date = new Date(date[2], date[1] - 1, date[0]);
      date.setHours(12);
      if (this.type == "employees") {
        const response = await supabase
          .from("employees_hotels")
          .select(
            `*,hotel:hotels!inner(name,short_name,shifts(employee_id,hotel_id,date,is_senior,is_training,employees(name)))`,
          )
          .eq("employee_id", item.employee_id)
          .eq("hotels.shifts.date", date.toISOString())
          .is("hotels.email", true)
          .order("name", { referencedTable: "hotels", ascending: false });

        item.hotels = [];
        response.data.forEach((hotel) => {
          let isSenior = hotel.is_senior;
          let isClerk = hotel.is_clerk;
          if (isSenior) {
            const hotelData = JSON.parse(JSON.stringify(hotel));
            hotelData.is_senior = true;
            hotelData.hotel.shifts = hotelData.hotel.shifts.filter(
              (shift) => shift.is_senior == hotelData.is_senior,
            );
            item.hotels.push(hotelData);
          }
          if (isClerk) {
            const hotelData = JSON.parse(JSON.stringify(hotel));
            hotelData.is_senior = false;
            hotelData.hotel.shifts = hotelData.hotel.shifts.filter(
              (shift) => shift.is_senior == hotelData.is_senior,
            );
            item.hotels.push(hotelData);
          }
        });
      } else if (this.type == "hotels") {
        const response = await supabase
          .from("employees_hotels")
          .select(
            `*,employee:employees!inner(name,last_name,first_name,event:employee_date_event_matching!employee_id(*,key:employee_date_events_keys(*)),shifts(employee_id,hotel_id,date,is_senior,is_training))`,
          )
          .eq("hotel_id", item.hotel_id)
          .eq("employees.shifts.date", date.toISOString())
          .eq("employees.employee_date_event_matching.date", date.toISOString())
          .eq("employees.status", "Aktiv")
          .is("employees.employee_date_event_matching.is_activated", true)
          .order("last_name", {
            referencedTable: "employees",
            ascending: false,
          });
        response.data.forEach((employee) => {
          employee.employee.show_name =
            employee.employee.last_name + ", " + employee.employee.first_name;
        });
        response.data.sort((a, b) => {
          return a.employee.show_name.localeCompare(b.employee.show_name);
        });
        console.log(response.data);
        item.employees = response.data;
      }
    },
    async updatePublicShifts(compareObject) {
      let date = compareObject.date.split(".");
      date = new Date(date[2], date[1] - 1, date[0]);
      date.setHours(12);

      await supabase
        .from("shifts_public")
        .delete()
        .eq("date", date.toISOString());

      await supabase.from("shifts_public").insert(compareObject.shiftsReal);

      await supabase
        .from("employee_date_event_matching_public")
        .delete()
        .eq("date", date.toISOString());

      await supabase
        .from("employee_date_event_matching_public")
        .insert(compareObject.eventsReal);
      this.$emit("refresh-data", this.type);
    },
  },
  emits: [
    "edit-event",
    "refresh-data",
    "update:eventFilter",
    "update:dayRange",
    "change-date-range",
  ],
  watch: {
    preciseTable(newPreciseTable) {
      if (newPreciseTable) {
        // Instead of modifying props directly, emit events
        this.$emit("change-date-range", {
          forwardDays: 27,
          backDays: 10,
          dayRange: 38,
        });
      } else {
        this.$emit("change-date-range", {
          forwardDays: 6,
          backDays: 3,
          dayRange: 10,
        });
      }
    },
    eventFilter(newVal) {
      this.$emit("update:eventFilter", newVal);
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 1px;
  text-align: center;
}

:deep(.v-data-table-virtual th),
:deep(.v-data-table-virtual td) {
  border: 1px solid black;
}

.tables-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table-scroll-container {
  overflow: auto;
  flex-grow: 1;
  max-height: calc(100vh - 5px);
}

.full-height-card {
  height: calc(
    100vh - 50px
  ) !important; /* Subtract some pixels for padding/margin */
  min-height: 500px; /* Minimum height for smaller screens */
}

.tableFixHead {
  overflow: auto;
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableFixHead thead th {
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: white;
}

.sticky-col {
  position: sticky;
  left: -20px;
  border: 4px solid black;
  z-index: 1001; /* Höher als der Header */
  background-color: white; /* Sonst wird es transparent */
  box-shadow: 4px 0 0 black;
  text-align: left;
}
/* Your component-specific styles go here */
</style>
