<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col>Einträge erstellen</v-col>
        <v-col cols="4" align="right" v-if="!drawer">
          <v-btn size="x-large" flat @click="eventEdit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="type == 'employees'" class="mt-8">
          <v-card variant="outlined" v-if="eventEdit.eventData.is_training">
            <v-card-text>
              <div class="text-h5">Mitarbeiter in Einarbeitung</div>
            </v-card-text>
          </v-card>
          <v-card variant="outlined" v-else>
            <v-card-title>Mögliche Hotels</v-card-title>
            <v-card-text>
              <v-chip
                :color="eventEdit.eventData.is_senior ? 'success' : 'grey'"
                :variant="
                  eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                "
                @click="
                  ((eventEdit.eventData.is_senior = true), checkHotelDropdown())
                "
                >Senior</v-chip
              >
              <v-chip
                :color="!eventEdit.eventData.is_senior ? 'success' : null"
                :variant="
                  !eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                "
                @click="
                  ((eventEdit.eventData.is_senior = false),
                  checkHotelDropdown())
                "
                >Clerk</v-chip
              >
              <v-divider class="my-2"></v-divider>
              <v-btn
                :color="
                  hotel.id == eventEdit.eventData.hotel_id ? 'success' : null
                "
                size="small"
                rounded="lg"
                class="ma-1"
                v-for="hotel in eventEdit.hotels"
                @click="changeHotel(hotel)"
                :value="hotel.id"
                >{{ hotel.short_name }}</v-btn
              >
              <v-col
                v-if="
                  eventEdit.shiftArray &&
                  eventEdit.shiftArray.length > 0 &&
                  eventEdit.firstHotelId != eventEdit.eventData.hotel_id
                "
              >
                <v-alert
                  type="warning"
                  title="Folgende Schichten werden nach dem Speichern
                        rausgenommen!"
                >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="3" v-for="shift in eventEdit.shiftArray">
                        <v-card
                          elevation="8"
                          :key="shift.id"
                          v-if="shift.employees"
                        >
                          <v-card-text>
                            <div>
                              {{
                                new Date(shift.date).toLocaleDateString("de-DE")
                              }}
                            </div>
                            <div>{{ shift.employees.name }}</div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-alert>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="switchShifts('clearShifts')"
                v-if="eventEdit.firstHotelId > 0"
                size="small"
                prepend-icon="mdi-close"
                rounded="xl"
                >Schichten leeren</v-btn
              >
              <v-btn
                :loading="eventEdit.switchLoading"
                v-if="
                  eventEdit.eventData.hotel_id &&
                  eventEdit.eventData.hotel_id != eventEdit.firstHotelId
                "
                color="success"
                @click="switchHotels()"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="type == 'hotels'">
          <v-card variant="outlined">
            <v-card-title>Mögliche Mitarbeiter</v-card-title>
            <v-card-text>
              <v-chip
                :color="eventEdit.eventData.is_senior ? 'success' : 'grey'"
                :variant="
                  eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                "
                v-if="eventEdit.eventData.is_senior"
                >Senior</v-chip
              >
              <v-chip
                :color="!eventEdit.eventData.is_senior ? 'success' : null"
                :variant="
                  !eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                "
                v-if="!eventEdit.eventData.is_senior"
                >Clerk</v-chip
              >
              <v-divider class="my-2"></v-divider>
              <v-tooltip
                v-for="employee in eventEdit.employees"
                :text="employee.toolTip"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    :color="
                      employee.id == eventEdit.eventData.employee_id
                        ? 'success'
                        : employee.color
                    "
                    size="small"
                    rounded="lg"
                    class="ma-1"
                    :append-icon="employee.icon"
                    @click="changeEmployee(employee)"
                    :value="employee.id"
                    >{{ employee.short_name }}
                    <span v-if="employee.hotel">{{
                      ` (${employee.hotel})`
                    }}</span></v-btn
                  >
                </template>
              </v-tooltip>
              <v-col
                v-if="
                  eventEdit.shiftArray &&
                  eventEdit.shiftArray.length > 0 &&
                  eventEdit.firstEmployeeId != eventEdit.eventData.employee_id
                "
              >
                <v-alert
                  type="warning"
                  title="Folgende Schichten werden nach dem Speichern
                        rausgenommen!"
                >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="3" v-for="shift in eventEdit.shiftArray">
                        <v-card
                          elevation="8"
                          :key="shift.id"
                          v-if="shift.hotels"
                        >
                          <v-card-text>
                            <div>
                              {{
                                new Date(shift.date).toLocaleDateString("de-DE")
                              }}
                            </div>
                            <div>{{ shift.hotels.name }}</div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-alert>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="switchShifts('clearShifts')"
                v-if="eventEdit.firstHotelId > 0"
                size="small"
                prepend-icon="mdi-close"
                rounded="xl"
                >Schichten leeren</v-btn
              >
              <v-btn
                :loading="eventEdit.switchLoading"
                v-if="
                  eventEdit.eventData.employee_id &&
                  eventEdit.eventData.employee_id != eventEdit.firstEmployeeId
                "
                color="success"
                @click="switchShifts()"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="d-flex flex-column justify-space-between">
          <v-date-picker
            v-model="eventEdit.eventData.dateRange"
            multiple
            @update:model-value="limitDateRange"
            show-adjacent-months
            first-day-of-week="1"
            hide-header
            :min="eventEdit.eventData.formattedDate"
          ></v-date-picker>
          <div>
            <manual-events-dialog
              :preselected-date="eventEdit.eventData.formattedDate"
              :preselected-employee-id="eventEdit.eventData.employee_id"
            />
          </div>
        </v-col>
        <template v-if="type == 'hotels'">
          <v-col cols="6" class="mb-n6">
            <v-autocomplete
              :items="cityHotels['Alle Hotels']"
              v-model="eventEdit.eventData.hotel_id"
              item-title="name"
              item-value="id"
              variant="solo"
              disabled
              label="Hotel"
            ></v-autocomplete>
            <v-alert
              :type="eventEdit.eventData.isDifferentTime ? 'warning' : null"
            >
              <v-row>
                <v-col cols="5" class="mb-n4">
                  <v-text-field
                    type="time"
                    variant="solo"
                    label="Startzeit"
                    v-model="eventEdit.eventData.special_start_time"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" class="mb-n4">
                  <v-text-field
                    type="time"
                    variant="solo"
                    label="Endzeit"
                    v-model="eventEdit.eventData.special_end_time"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="mb-n4">
                  <v-btn size="small" @click="saveTime(eventEdit.eventData)">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </template>
        <v-col cols="6" class="mb-n6" v-if="type == 'employees'">
          <v-autocomplete
            :items="employeeDropdown"
            v-model="eventEdit.eventData.employee_id"
            item-title="show_name"
            item-value="id"
            variant="solo"
            disabled
            label="Mitarbeiter"
          ></v-autocomplete>
          <div>Ereignis</div>
          <v-btn
            size="x-small"
            :color="eventEdit.eventData[event.value] ? 'success' : null"
            v-for="event in events"
            rounded="xl"
            class="ma-1"
            :disabled="event.disabled"
            :prepend-icon="event.icon"
            @click="chooseEvent(eventEdit.eventData, event, { added: false })"
            >{{ event.name }}</v-btn
          >
          <div class="mt-4">Zusätzliche Ereignisse</div>
          <v-btn
            size="x-small"
            rounded="xl"
            class="ma-1"
            :disabled="event.disabled"
            :color="eventEdit.eventData[event.value] ? 'success' : null"
            v-for="event in addedEventInfo"
            :prepend-icon="event.icon"
            @click="chooseEvent(eventEdit.eventData, event, { added: true })"
            >{{ event.name }}</v-btn
          >
        </v-col>
        <v-col cols="12" class="mb-n8 mt-2">
          <v-text-field
            variant="solo"
            append-inner-icon="mdi-content-save"
            @click:append-inner="saveNote(eventEdit.eventData)"
            v-model="eventEdit.eventData.note"
            label="Notiz"
          ></v-text-field>
        </v-col>
        <v-col cols="6"> </v-col>
        <v-col cols="2">
          <v-divider vertical></v-divider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ManualEventsDialog from "@/components/Settings/Employees/Bonus/ManualEventsDialog.vue";
export default {
  components: {
    ManualEventsDialog,
  },
  props: {
    eventEdit: {
      type: Object,
      required: true,
    },
    cityHotels: Object,
    type: String,
    employeeDropdown: Array,
    events: Array,
    addedEventInfo: Array,
    allEventTypes: Array,
    employees: Array,
    snackbar: Object,
    drawer: Boolean,
    isPublic: Boolean,
  },
  // Add emits option to declare the refresh-data event
  emits: ["refresh-data"],
  data() {
    return {
      preciseTable: false,
    };
  },
  computed: {},
  methods: {
    limitDateRange(newDates) {
      if (newDates.length > 2) {
        this.eventEdit.eventData.dateRange = [newDates[0], newDates[1]]; // Keep only the first two selected dates
      } else {
        this.eventEdit.eventData.dateRange = newDates; // Update as usual when selecting up to two dates
      }
    },
    async changeHotel(hotel) {
      let employeeCheck = await this.checkHotelData(hotel.id);
      this.eventEdit.eventData.hotel_id = hotel.id;
      if (!employeeCheck && !this.eventEdit.firstHotelId) {
        this.switchShifts();
      }
    },
    async changeEmployee(employee) {
      let hotelCheck = await this.checkEmployeeData(employee.id);
      this.eventEdit.eventData.employee_id = employee.id;
      if (!hotelCheck && !this.eventEdit.firstEmployeeId) {
        this.switchShifts();
      }
    },
    async switchHotels() {
      const oldHotelId = this.eventEdit.firstHotelId;
      const newHotelId = this.eventEdit.eventData.hotel_id;
      this.eventEdit.eventData.hotel_id = oldHotelId;
      if (oldHotelId) {
        await this.switchShifts("clearShifts");
      }
      this.eventEdit.eventData.hotel_id = newHotelId;
      await this.switchShifts();
    },
    async saveTime(eventData) {
      await this.$store.dispatch("updateSupabaseEntity", {
        table: this.isPublic ? "shifts_public" : "shifts",
        id: eventData.shift_id,
        payload: {
          special_start_time: eventData.special_start_time,
          special_end_time: eventData.special_end_time,
        },
      });
    },
    async switchEmployees() {
      let oldEmployeeId = null;
      if (this.eventEdit.shiftArray.length > 0) {
        oldEmployeeId = this.eventEdit.shiftArray[0].employee_id;
      }
      const newEmployeeId = this.eventEdit.eventData.employee_id;
      this.eventEdit.eventData.employee_id = oldEmployeeId;
      if (oldEmployeeId) {
        await this.switchShifts("clearShifts");
      }
      this.eventEdit.eventData.employee_id = newEmployeeId;
      await this.switchShifts();
    },
    async switchShifts(type) {
      this.eventEdit.switchLoading = true;
      let eventData = this.eventEdit.eventData;
      let shiftArray = this.eventEdit.shiftArray;
      let dayRange = 1;
      if (eventData.dateRange.length > 1) {
        let startDate = new Date(eventData.dateRange[0]);
        startDate.setHours(startDate.getHours() + 6);
        let endDate = new Date(eventData.dateRange[1]);
        endDate.setHours(endDate.getHours() + 6);
        dayRange = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1,
        );
      }
      let upsertArray = [];
      for (var i = 0; i < dayRange; i++) {
        let payloadDate = new Date(eventData.dateRange[0]);
        payloadDate.setDate(payloadDate.getDate() + i);
        payloadDate = payloadDate.toISOString().split("T")[0];
        const activeShiftObject = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          hotel_id: eventData.hotel_id,
          is_senior: eventData.is_senior,
          is_training: false,
          check_id: 1,
        };
        if (type === "clearShifts") {
          activeShiftObject.employee_id = null;
        }
        upsertArray.push(activeShiftObject);

        const activeSheetObject = {
          hotel_id: eventData.hotel_id,
          date: payloadDate,
          is_training: false,
          is_senior: eventData.is_senior,
          employee_id: activeShiftObject.employee_id,
        };
        if (type === "clearShifts") {
          activeSheetObject.oldEmpId = eventData.employee_id;
        }
        if (!this.isPublic) {
          this.synchronizeWithSheets(activeSheetObject);
        }

        if (shiftArray[i] && shiftArray[i].employee_id) {
          const deleteSheetObject = {
            hotel_id: shiftArray[i].hotel_id,
            date: payloadDate,
            is_training: false,
            is_senior: eventData.is_senior,
            employee_id: null,
            oldEmpId: shiftArray[i].employee_id,
          };
          const deleteShiftObject = {
            employee_id: null,
            date: payloadDate,
            hotel_id: shiftArray[i].hotel_id,
            is_senior: shiftArray[i].is_senior,
            is_training: false,
            check_id: 1,
          };
          if (deleteShiftObject.hotel_id !== activeShiftObject.hotel_id) {
            upsertArray.push(deleteShiftObject);
          }
          if (!this.isPublic) {
            this.synchronizeWithSheets(deleteSheetObject);
          }
        }
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: this.isPublic ? "shifts_public" : "shifts",
          payload: upsertArray,
        });
      }
      this.eventEdit.switchLoading = false;
      this.$emit("refresh-data", this.type);
    },
    async saveNote(event) {
      let payload = {
        table: "shifts",
        id: event.shift_id,
        payload: {
          note: event.note,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", payload);
    },
    async checkHotelDropdown() {
      const value = this.eventEdit.eventData;
      let queryObject = {
        select: "id,hotels!inner(id,name,short_name)",
        table: "employees_hotels",
        where: [
          { type: "eq", key: "employee_id", value: value.employee_id },
          { type: "is", key: "hotels.email", value: true },
          { type: "not", key: "is_blocked", value: true },
        ],
      };
      if (value.is_senior == false) {
        queryObject.where.push({ type: "eq", key: "is_clerk", value: true });
      } else {
        queryObject.where.push({ type: "eq", key: "is_senior", value: true });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.eventEdit.hotels = response.data.map((hotel) => {
        return {
          id: hotel.hotels.id,
          name: hotel.hotels.name,
          short_name: hotel.hotels.short_name,
        };
      });
      this.eventEdit.hotels = this.eventEdit.hotels.sort((a, b) => {
        return a.short_name.localeCompare(b.short_name);
      });
      let searchHotel = this.eventEdit.hotels.find((hotel) => {
        if (hotel.id === value.hotel_id) {
          return hotel;
        }
      });
      if (!searchHotel) {
        this.eventEdit.eventData.hotel_id = null;
      }
    },
    async checkEmployeeData(employeeId) {
      if (employeeId) {
        let queryObject = {
          select:
            "id,is_senior,hotel_id,employee_id,employees(name),hotels(name),date",
          table: this.isPublic ? "shifts_public" : "shifts",
          where: [
            { type: "eq", key: "employee_id", value: employeeId },
            { type: "gte", key: "hotel_id", value: 0 },
          ],
        };
        if (this.eventEdit.eventData.dateRange.length == 1) {
          let startDate = new Date(this.eventEdit.eventData.dateRange[0]);
          startDate.setHours(startDate.getHours() + 3);
          let endDate = new Date(this.eventEdit.eventData.dateRange[0]);
          endDate.setHours(endDate.getHours() + 6);
          queryObject.where.push({
            type: "gte",
            key: "date",
            value: startDate.toISOString().split("T")[0],
          });
          queryObject.where.push({
            type: "lte",
            key: "date",
            value: endDate.toISOString().split("T")[0],
          });
        }
        if (this.eventEdit.eventData.dateRange.length > 1) {
          let endDate = new Date(this.eventEdit.eventData.dateRange[1]);
          endDate.setHours(endDate.getHours() + 6);
          queryObject.where.push({
            type: "gte",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
          queryObject.where.push({
            type: "lte",
            key: "date",
            value: endDate.toISOString(),
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.eventEdit.shiftArray = response.data;
        return response.data.length > 0;
      }
    },
    async checkHotelData(hotelId) {
      let queryObject = {
        select:
          "id,is_senior,hotel_id,employee_id,employees(name),hotels(name),date",
        table: this.isPublic ? "shifts_public" : "shifts",
        where: [
          { type: "eq", key: "hotel_id", value: hotelId },
          { type: "gte", key: "employee_id", value: 0 },
          {
            type: "eq",
            key: "is_senior",
            value: this.eventEdit.eventData.is_senior,
          },
        ],
      };
      if (this.eventEdit.eventData.dateRange.length == 1) {
        queryObject.where.push({
          type: "eq",
          key: "date",
          value: this.eventEdit.eventData.dateRange[0].toISOString(),
        });
      }
      if (this.eventEdit.eventData.dateRange.length > 1) {
        let endDate = new Date(this.eventEdit.eventData.dateRange[1]);
        endDate.setHours(endDate.getHours() + 6);
        queryObject.where.push({
          type: "gte",
          key: "date",
          value: this.eventEdit.eventData.dateRange[0].toISOString(),
        });
        queryObject.where.push({
          type: "lte",
          key: "date",
          value: endDate.toISOString(),
        });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      return response.data.length > 0;
    },
    async synchronizeWithSheets(shiftObject) {
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=updateShiftPlan&secret_key=TEW567LPOVBN267";
      var options = {
        method: "POST",
        body: JSON.stringify(shiftObject),
      };
      try {
        var response = await fetch(url, options);
      } catch (err) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      }

      response = await response.json();
      var employee = this.employees.find(
        (employee) => employee.id == response.payload.employee_id,
      );
      var date = new Date(response.payload.date).toLocaleDateString("de-DE");
      this.snackbar.data = employee
        ? employee.show_name
        : "Alter Mitarbeiter entfernt" + " am " + date;
      if (response.error) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      } else {
        this.snackbar.color = "success";
        this.snackbar.timeout = 1000;
        this.snackbar.show = true;
      }
    },
    async chooseEvent(eventData, eventKey, added) {
      let searchArray = [];
      if (eventData.hotel_id > 0 && !added.added) {
        this.switchShifts("clearShifts");
      }
      eventData.event = eventKey.name;
      eventData.icon = eventKey.icon;
      if (added.added) {
        searchArray = this.addedEventInfo;
      } else {
        searchArray = this.events;
      }
      for (var i = 0; i < searchArray.length; i++) {
        let eventValue = searchArray[i].value;
        if (eventKey.name === searchArray[i].name) {
          eventData[eventValue] = !eventData[eventValue];
        }
      }
      var upsertArray = [];
      var newDatabaseArray = [];

      let dayRange = 1;
      if (eventData.dateRange.length > 1) {
        let startDate = new Date(eventData.dateRange[0]);
        startDate.setHours(startDate.getHours() + 6);
        let endDate = new Date(eventData.dateRange[1]);
        endDate.setHours(endDate.getHours() + 6);
        dayRange = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1,
        );
      }

      for (var i = 0; i < dayRange; i++) {
        let payloadDate = new Date(eventData.dateRange[0]);
        payloadDate.setDate(payloadDate.getDate() + i);
        payloadDate = payloadDate.toISOString().split("T")[0];
        let payload = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          hotel_id: eventData.hotel_id,
          is_senior: eventData.is_senior,
        };

        let sheetPayloadObject = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          is_employee_event: true,
        };

        this.allEventTypes.forEach((event) => {
          let eventValue = event.value;
          if (eventData[eventValue]) {
            payload[eventValue] = true;
            sheetPayloadObject[eventValue] = true;
            sheetPayloadObject.keyInfo = event;
          } else {
            payload[eventValue] = false;
            sheetPayloadObject[eventValue] = false;
            sheetPayloadObject.keyInfo = event;
          }
          if (event.id == eventKey.id) {
            let newPayload = {
              employee_id: eventData.employee_id,
              employee_date_event_id: event.id,
              date: payloadDate,
              modified_at: new Date().toISOString(),
              hotel_id: eventData.hotel_id,
              is_activated: payload[eventValue],
              is_senior: eventData.is_senior,
            };
            newDatabaseArray.push(newPayload);
          }
        });

        upsertArray.push(payload);
        if (!this.isPublic) {
          this.synchronizeWithSheets(sheetPayloadObject);
        }
      }

      /*
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "employee_date_events",
        id: eventData.id,
        payload: upsertArray,
      });
      */

      await this.$store.dispatch("upsertSupabaseEntity", {
        table: this.isPublic
          ? "employee_date_event_matching_public"
          : "employee_date_event_matching",
        payload: newDatabaseArray,
      });

      // Emit event to parent to refresh data based on type
      this.$emit("refresh-data", this.type);
    },
  },
  created() {
    if (this.type == "employees") {
      this.checkHotelDropdown();
    }
    
  },
  watch: {},
};
</script>
