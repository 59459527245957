<template>
  <div>
    <h1 class="text-h5 mb-4 text-center">Bonusverwaltung</h1>

    <!-- Last edited information -->
    <v-container fluid class="pa-0 pa-sm-2">
      <!-- Tabs for different bonus management sections -->
      <v-tabs
        v-model="activeTab"
        fixed-tabs
        color="blue-grey-darken-1"
        class="px-1 mb-4"
      >
        <v-tab value="bonus-config">Bonuskonfigurationen</v-tab>
        <v-tab value="eligibility">Bonusberechtigungen</v-tab>
        <v-tab value="simulation">Simulation</v-tab>
      </v-tabs>

      <v-window v-model="activeTab" disabled>
        <!-- Bonus/Malus Events Tab -->
        <v-window-item value="bonus-config">
          <v-row dense class="mb-2">
            <v-col
              cols="12"
              class="d-flex align-center justify-space-between pb-0"
            >
              <!-- Use the bonus history component for last edit display -->
              <BonusHistory ref="bonusHistoryComponent" />
              <v-btn
                color="blue-grey-darken-1"
                text
                size="small"
                @click="openHistoryDialog"
                class="px-2"
              >
                <v-icon size="small" class="mr-1">mdi-history</v-icon>
                Änderungsverlauf
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense class="equal-height-row">
            <v-col cols="12" md="6">
              <v-card
                rounded
                elevation="2"
                class="mb-3 bonus-card h-100 d-flex flex-column"
              >
                <v-card-title class="d-flex align-center py-2">
                  <v-icon color="success" class="mr-2">mdi-check</v-icon>
                  Bonus Ereignisse
                </v-card-title>

                <!-- List of existing bonuses -->
                <v-card-text class="pt-2 pb-0 flex-grow-1">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(bonus, index) in bonuses"
                      :key="bonus.id"
                      class="mb-2 pa-1"
                      outlined
                      :elevation="editingBonus === bonus.id ? 3 : 1"
                    >
                      <v-card-text class="py-1">
                        <v-row align="center" dense>
                          <v-col cols="12" lg="7">
                            <v-text-field
                              v-model="bonus.name"
                              label="Name"
                              dense
                              :readonly="editingBonus !== bonus.id"
                              @input="bonusChanged = true"
                              @change="bonusChanged = true"
                              @focus="editingBonus === bonus.id"
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" lg="2">
                            <v-text-field
                              v-model="bonus.points"
                              label="Punkte"
                              type="number"
                              min="0"
                              dense
                              :readonly="editingBonus !== bonus.id"
                              @input="bonusChanged = true"
                              @change="bonusChanged = true"
                              @focus="editingBonus === bonus.id"
                              outlined
                              class="points-field"
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="6"
                            lg="3"
                            class="d-flex justify-end align-center"
                          >
                            <v-checkbox
                              class="mr-3"
                              v-model="bonus.requires_manual_input"
                              hide-details
                              :disabled="editingBonus !== bonus.id"
                              @change="bonusChanged = true"
                            >
                              <v-tooltip
                                activator="parent"
                                location="bottom"
                                open-delay="300"
                              >
                                Händischer Eintrag notwendig
                              </v-tooltip>
                              <template v-slot:label>
                                <v-icon small>mdi-hand-back-right</v-icon>
                              </template>
                            </v-checkbox>
                            <div class="d-flex align-center">
                              <template v-if="editingBonus === bonus.id">
                                <v-btn
                                  icon
                                  color="success"
                                  @click="saveBonus(bonus)"
                                  :elevation="1"
                                  size="x-small"
                                  class="mr-1"
                                >
                                  <v-icon small>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  color="error"
                                  @click="confirmDelete('bonus', bonus.id)"
                                  :elevation="1"
                                  size="x-small"
                                >
                                  <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <v-btn
                                v-else
                                icon
                                @click="editingBonus = bonus.id"
                                :elevation="1"
                                size="x-small"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>

                        <!-- Add effective dates fields -->
                        <v-row
                          v-if="editingBonus === bonus.id"
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="6">
                            <v-text-field
                              v-model="bonus.effective_from"
                              label="Gültig ab"
                              type="date"
                              dense
                              outlined
                              hide-details
                              @input="bonusChanged = true"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- Historical values expansion -->
                        <v-row
                          v-if="
                            editingBonus === bonus.id &&
                            hasHistoricalValues(bonus)
                          "
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="12">
                            <v-expansion-panels flat>
                              <v-expansion-panel>
                                <v-expansion-panel-title>
                                  Verlauf anzeigen ({{
                                    getHistoricalValuesCount(bonus)
                                  }}
                                  Einträge)
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <v-list dense>
                                    <v-list-item
                                      v-for="(
                                        histEntry, hIndex
                                      ) in getHistoricalValues(bonus)"
                                      :key="hIndex"
                                    >
                                      <v-list-item-title>
                                        {{
                                          formatDate(histEntry.effective_from)
                                        }}
                                        -
                                        {{
                                          histEntry.effective_to
                                            ? formatDate(histEntry.effective_to)
                                            : "jetzt"
                                        }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Punkte: {{ histEntry.points }}
                                      </v-list-item-subtitle>
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>

                        <!-- Add new version button -->
                        <v-row
                          v-if="editingBonus === bonus.id"
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="12" class="text-center">
                            <v-btn
                              small
                              color="primary"
                              outlined
                              @click="showNewVersionDialog(bonus, 'bonus')"
                            >
                              <v-icon left small>mdi-clock-outline</v-icon>
                              Neue Version erstellen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-slide-y-transition>

                  <!-- Add button for showing the bonus form -->
                  <div class="text-center my-2">
                    <v-btn
                      color="blue-grey-darken-2"
                      small
                      outlined
                      @click="showBonusForm = !showBonusForm"
                    >
                      <v-icon left small>{{
                        showBonusForm ? "mdi-minus" : "mdi-plus"
                      }}</v-icon>
                      {{ showBonusForm ? "Abbrechen" : "" }}
                    </v-btn>
                  </div>

                  <!-- Form to add new bonus -->
                  <v-expand-transition>
                    <div v-if="showBonusForm" class="py-3">
                      <v-divider class="mb-3"></v-divider>
                      <div class="subtitle-2 mb-2">Neuen Bonus hinzufügen</div>
                      <v-row align="center" dense>
                        <v-col cols="12" lg="7">
                          <v-text-field
                            v-model="newBonus.name"
                            label="Name"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" lg="3">
                          <v-text-field
                            v-model="newBonus.points"
                            label="Punkte"
                            type="number"
                            min="0"
                            outlined
                            dense
                            class="points-field"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          lg="2"
                          class="d-flex justify-end align-center"
                        >
                          <v-checkbox
                            class="mr-6"
                            v-model="newBonus.requires_manual_input"
                            hide-details
                          >
                            <v-tooltip
                              activator="parent"
                              location="bottom"
                              open-delay="300"
                            >
                              Händischer Eintrag notwendig
                            </v-tooltip>
                            <template v-slot:label>
                              <v-icon small>mdi-hand-back-right</v-icon>
                            </template>
                          </v-checkbox>
                          <v-btn
                            color="blue-grey-darken-2"
                            @click="addBonus"
                            :disabled="!canAddBonus"
                            icon
                          >
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card
                rounded
                elevation="2"
                class="mb-3 malus-card h-100 d-flex flex-column"
              >
                <v-card-title class="d-flex align-center py-2">
                  <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                  Malus Ereignisse
                </v-card-title>

                <!-- List of existing maluses -->
                <v-card-text class="pt-2 pb-0 flex-grow-1">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(malus, index) in maluses"
                      :key="malus.id"
                      class="mb-2 pa-1"
                      outlined
                      :elevation="editingMalus === malus.id ? 3 : 1"
                    >
                      <v-card-text class="py-1">
                        <v-row align="center" dense>
                          <v-col cols="12" lg="6" class="d-flex align-center">
                            <v-text-field
                              v-model="malus.name"
                              label="Name"
                              dense
                              :readonly="editingMalus !== malus.id"
                              @input="malusChanged = true"
                              @change="malusChanged = true"
                              @focus="editingMalus === malus.id"
                              outlined
                              hide-details
                              class="mr-2"
                            ></v-text-field>
                            <v-text-field
                              v-model="malus.points"
                              label="Punkte"
                              type="number"
                              min="1"
                              dense
                              prefix="-"
                              :readonly="editingMalus !== malus.id"
                              @input="malusChanged = true"
                              @change="malusChanged = true"
                              @focus="editingMalus === malus.id"
                              outlined
                              color="error"
                              class="points-field"
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" lg="3" class="d-flex align-center">
                            <v-checkbox
                              v-model="malus.has_bonus_penalty"
                              :label="
                                malus.has_bonus_penalty ? '' : 'Bonussperre'
                              "
                              dense
                              :disabled="editingMalus !== malus.id"
                              @change="toggleBonusPenalty(malus)"
                              class="mt-0 mr-2"
                              hide-details
                            ></v-checkbox>
                            <v-text-field
                              v-if="malus.has_bonus_penalty"
                              v-model="malus.bonus_penalty_months"
                              label="Bonussperre Monate"
                              type="number"
                              min="1"
                              dense
                              :readonly="editingMalus !== malus.id"
                              @input="malusChanged = true"
                              @change="malusChanged = true"
                              @focus="editingMalus === malus.id"
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="6"
                            lg="3"
                            class="d-flex align-center justify-end"
                          >
                            <v-checkbox
                              class="mr-3"
                              v-model="malus.requires_manual_input"
                              hide-details
                              :disabled="editingMalus !== malus.id"
                              @change="malusChanged = true"
                            >
                              <v-tooltip
                                activator="parent"
                                location="bottom"
                                open-delay="300"
                              >
                                Händischer Eintrag notwendig
                              </v-tooltip>
                              <template v-slot:label>
                                <v-icon small>mdi-hand-back-right</v-icon>
                              </template>
                            </v-checkbox>
                            <div class="d-flex align-center">
                              <template v-if="editingMalus === malus.id">
                                <v-btn
                                  icon
                                  color="success"
                                  @click="saveMalus(malus)"
                                  :elevation="1"
                                  size="x-small"
                                  class="mr-1"
                                >
                                  <v-icon small>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  color="error"
                                  @click="confirmDelete('malus', malus.id)"
                                  :elevation="1"
                                  size="x-small"
                                >
                                  <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <v-btn
                                v-else
                                icon
                                @click="editingMalus = malus.id"
                                :elevation="1"
                                size="x-small"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>

                        <!-- Add effective dates fields -->
                        <v-row
                          v-if="editingMalus === malus.id"
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="6">
                            <v-text-field
                              v-model="malus.effective_from"
                              label="Gültig ab"
                              type="date"
                              dense
                              outlined
                              hide-details
                              @input="malusChanged = true"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- Historical values expansion -->
                        <v-row
                          v-if="
                            editingMalus === malus.id &&
                            hasHistoricalValues(malus)
                          "
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="12">
                            <v-expansion-panels flat>
                              <v-expansion-panel>
                                <v-expansion-panel-title>
                                  Verlauf anzeigen ({{
                                    getHistoricalValuesCount(malus)
                                  }}
                                  Einträge)
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <v-list dense>
                                    <v-list-item
                                      v-for="(
                                        histEntry, hIndex
                                      ) in getHistoricalValues(malus)"
                                      :key="hIndex"
                                    >
                                      <v-list-item-title>
                                        {{
                                          formatDate(histEntry.effective_from)
                                        }}
                                        -
                                        {{
                                          histEntry.effective_to
                                            ? formatDate(histEntry.effective_to)
                                            : "jetzt"
                                        }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Punkte: -{{ histEntry.points }}
                                        <template
                                          v-if="histEntry.bonus_penalty_months"
                                        >
                                          | Bonussperre:
                                          {{ histEntry.bonus_penalty_months }}
                                          Monate
                                        </template>
                                      </v-list-item-subtitle>
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>

                        <!-- Add new version button -->
                        <v-row
                          v-if="editingMalus === malus.id"
                          align="center"
                          dense
                          class="mt-2"
                        >
                          <v-col cols="12" class="text-center">
                            <v-btn
                              small
                              color="primary"
                              outlined
                              @click="showNewVersionDialog(malus, 'malus')"
                            >
                              <v-icon left small>mdi-clock-outline</v-icon>
                              Neue Version erstellen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-slide-y-transition>

                  <!-- Add button for showing the malus form -->
                  <div class="text-center my-2">
                    <v-btn
                      color="blue-grey-darken-2"
                      small
                      outlined
                      @click="showMalusForm = !showMalusForm"
                    >
                      <v-icon left small>{{
                        showMalusForm ? "mdi-minus" : "mdi-plus"
                      }}</v-icon>
                      {{ showMalusForm ? "Abbrechen" : "" }}
                    </v-btn>
                  </div>

                  <!-- Form to add new malus -->
                  <v-expand-transition>
                    <div v-if="showMalusForm" class="py-3">
                      <v-divider class="mb-3"></v-divider>
                      <div class="subtitle-2 mb-2">Neuen Malus hinzufügen</div>
                      <v-row dense class="d-flex align-center">
                        <v-col cols="12" lg="6" class="d-flex align-center">
                          <v-text-field
                            v-model="newMalus.name"
                            label="Name"
                            outlined
                            dense
                            hide-details
                            class="mr-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="newMalusPoints"
                            label="Punkte"
                            type="number"
                            min="1"
                            outlined
                            dense
                            prefix="-"
                            color="error"
                            class="points-field"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" lg="3" class="d-flex align-center">
                          <div class="d-flex align-center">
                            <v-checkbox
                              v-model="newMalus.has_bonus_penalty"
                              :label="
                                newMalus.has_bonus_penalty ? '' : 'Bonussperre'
                              "
                              dense
                              class="mt-0 mr-2"
                              hide-details
                            ></v-checkbox>
                            <v-text-field
                              v-if="newMalus.has_bonus_penalty"
                              v-model="newMalus.bonus_penalty_months"
                              label="Bonussperre Monate"
                              type="number"
                              min="1"
                              outlined
                              dense
                              class="penalty-field"
                              hide-details
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col
                          cols="6"
                          lg="2"
                          class="d-flex align-center justify-end"
                        >
                          <v-checkbox
                            class="mr-6"
                            v-model="newMalus.requires_manual_input"
                            hide-details
                          >
                            <v-tooltip
                              activator="parent"
                              location="bottom"
                              open-delay="300"
                            >
                              Händischer Eintrag notwendig
                            </v-tooltip>
                            <template v-slot:label>
                              <v-icon small>mdi-hand-back-right</v-icon>
                            </template>
                          </v-checkbox>
                          <v-btn
                            color="blue-grey-darken-2"
                            @click="addMalus"
                            :disabled="!canAddMalus"
                            icon
                          >
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="8" offset-md="2">
              <BonusLevelConfig />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="8" offset-md="2">
              <HotelBonusPoints @showSuccess="showSuccess" />
            </v-col>
          </v-row>
        </v-window-item>

        <!-- Employee Bonus Eligibility Tab -->
        <v-window-item value="eligibility">
          <v-row dense>
            <v-col cols="8" offset="2">
              <BonusBulkEligibility />
            </v-col>
          </v-row>
        </v-window-item>

        <!-- Simulation Tab -->
        <v-window-item value="simulation">
          <v-row dense>
            <v-col cols="12">
              <BonusSimulator />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-container>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Bestätigung</v-card-title>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="confirmDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="error" text @click="executeDelete"> Löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Version Dialog -->
    <v-dialog v-model="newVersionDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Neue Version erstellen</v-card-title>
        <v-card-text>
          <p>Aktuelle Version abschließen und neue Version starten:</p>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="oldVersionEndDate"
                label="Alte Version gültig bis"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newVersionEffectiveDate"
                label="Neue Version gültig ab"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="newVersionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="primary" text @click="createNewVersion">
            Neue Version erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { supabase } from "@/supabase";
import useSnackbar from "@/utils/useSnackbar";
import BonusHistory from "./Bonus/BonusHistory.vue";
import HotelBonusPoints from "./Bonus/HotelBonusPoints.vue";
import BonusLevelConfig from "./Bonus/BonusLevelConfig.vue";
import BonusBulkEligibility from "./Bonus/BonusBulkEligibility.vue";
import BonusSimulator from "./Bonus/BonusSimulator.vue";
import {
  format,
  parseISO,
  lastDayOfQuarter,
  addDays,
  compareAsc,
} from "date-fns";

// Get snackbar functionality
const { showSuccess, showError } = useSnackbar();

// Tab control
const activeTab = ref("bonus-events");

// Data for existing records
const bonuses = ref([]);
const maluses = ref([]);

// Form visibility controls
const showBonusForm = ref(false);
const showMalusForm = ref(false);

// Data for new records
const newBonus = ref({
  name: "",
  points: 0,
  is_positive: true,
  requires_manual_input: false,
  historical_values: [],
});
const newMalus = ref({
  name: "",
  bonus_penalty_months: null,
  is_positive: false,
  has_bonus_penalty: false,
  requires_manual_input: false,
  historical_values: [],
});
const newMalusPoints = ref(0);

// Edit tracking
const editingBonus = ref(null);
const editingMalus = ref(null);

// Track if changes were made
const bonusChanged = ref(false);
const malusChanged = ref(false);

const finishEditing = () => {
  // Only clear editing state if changes were applied
  editingBonus.value = null;
  editingMalus.value = null;
  bonusChanged.value = false;
  malusChanged.value = false;
};

// Confirmation dialog
const confirmDialog = ref(false);
const confirmMessage = ref("");
const deleteType = ref("");
const deleteId = ref(null);

// Validation for new entries
const canAddBonus = computed(
  () => newBonus.value.name && newBonus.value.points > 0,
);
const canAddMalus = computed(
  () =>
    newMalus.value.name &&
    newMalusPoints.value >= 0 &&
    (!newMalus.value.has_bonus_penalty ||
      newMalus.value.bonus_penalty_months > 0),
);

// Confirmation handlers
const confirmDelete = (type, id) => {
  deleteType.value = type;
  deleteId.value = id;

  if (type === "bonus") {
    confirmMessage.value =
      "Sind Sie sicher, dass Sie diesen Bonus löschen möchten?";
  } else if (type === "malus") {
    confirmMessage.value =
      "Sind Sie sicher, dass Sie diesen Malus löschen möchten?";
  }

  confirmDialog.value = true;
};

const executeDelete = async () => {
  if (deleteType.value === "bonus") {
    await deleteBonus(deleteId.value);
  } else if (deleteType.value === "malus") {
    await deleteMalus(deleteId.value);
  }

  confirmDialog.value = false;
};

// Reference to the BonusHistory component
const bonusHistoryComponent = ref(null);

// History dialog opener
const openHistoryDialog = () => {
  bonusHistoryComponent.value.openDialog();
};

// Add helper functions for historical values
const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return format(parseISO(dateString), "dd.MM.yyyy");
  } catch (e) {
    console.error("Date formatting error:", e);
    return dateString;
  }
};

// Sort historical values chronologically by effective_from date
const sortHistoricalValues = (values) => {
  if (!Array.isArray(values)) return [];

  return [...values].sort((a, b) => {
    try {
      // Use compareAsc from date-fns for proper date comparison
      return compareAsc(parseISO(a.effective_from), parseISO(b.effective_from));
    } catch (e) {
      console.error("Error sorting historical values:", e);
      return 0;
    }
  });
};

const hasHistoricalValues = (item) => {
  return item.historical_values && item.historical_values.length > 0;
};

const getHistoricalValues = (item) => {
  if (!item.historical_values) return [];
  const values = Array.isArray(item.historical_values)
    ? item.historical_values
    : [];
  return sortHistoricalValues(values);
};

const getHistoricalValuesCount = (item) => {
  return getHistoricalValues(item).length;
};

// Fetch data methods
const fetchBonuses = async () => {
  const { data, error } = await supabase
    .from("bonuses")
    .select("*")
    .is("deleted_at", null)
    .order("created_at", { ascending: true });

  if (error) {
    console.error(error);
    showError("Fehler beim Laden der Daten");
  } else {
    // Format dates and parse historical values
    data.forEach((item) => {
      // Ensure historical_values is an array
      if (!item.historical_values) {
        item.historical_values = [];
      } else if (typeof item.historical_values === "string") {
        try {
          item.historical_values = JSON.parse(item.historical_values);
          // Sort historical values chronologically
          item.historical_values = sortHistoricalValues(item.historical_values);
        } catch (error) {
          console.error(
            "Fehler beim Parsen der historischen Werte:",
            error,
            item.historical_values,
          );
          item.historical_values = [];
        }
      } else {
        // Sort historical values chronologically
        item.historical_values = sortHistoricalValues(item.historical_values);
      }

      if (item.effective_from) {
        item.effective_from = item.effective_from.substring(0, 10);
      } else {
        item.effective_from = format(new Date(), "yyyy-MM-dd");
      }
    });

    // Sort bonuses and maluses
    bonuses.value = data
      .filter((b) => b.is_positive === true)
      .sort((a, b) => b.points - a.points);

    maluses.value = data
      .filter((b) => b.is_positive === false)
      .sort((a, b) => {
        // First sort by bonus_penalty_months (null values last)
        if (a.bonus_penalty_months !== null && b.bonus_penalty_months === null)
          return -1;
        if (a.bonus_penalty_months === null && b.bonus_penalty_months !== null)
          return 1;
        if (
          a.bonus_penalty_months !== null &&
          b.bonus_penalty_months !== null
        ) {
          if (a.bonus_penalty_months !== b.bonus_penalty_months) {
            return b.bonus_penalty_months - a.bonus_penalty_months;
          }
        }
        // Then sort by points (highest absolute value first)
        return Math.abs(b.points) - Math.abs(a.points);
      });

    maluses.value.forEach((malus) => {
      malus.has_bonus_penalty = malus.bonus_penalty_months !== null;
    });
  }
};

// CRUD operations for Bonuses
const addBonus = async () => {
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const bonusToAdd = {
    ...newBonus.value,
    created_at: new Date(),
    effective_from: currentDate,
    historical_values: [],
    points: Number(newBonus.value.points) || 0,
  };

  const { error } = await supabase.from("bonuses").insert([bonusToAdd]);

  if (error) {
    console.error(error);
    showError("Fehler beim Hinzufügen des Bonus");
  } else {
    newBonus.value = {
      name: "",
      points: 0,
      is_positive: true,
      requires_manual_input: false,
    };
    showBonusForm.value = false;
    fetchBonuses();
    showSuccess("Bonus erfolgreich hinzugefügt");
  }
};

const deleteBonus = async (id) => {
  const { error } = await supabase
    .from("bonuses")
    .update({ deleted_at: new Date().toISOString() })
    .eq("id", id);

  if (error) {
    console.error(error);
    showError("Fehler beim Löschen des Bonus");
  } else {
    fetchBonuses();
    showSuccess("Bonus gelöscht");
  }
};

const toggleBonusPenalty = async (malus) => {
  malusChanged.value = true;

  if (malus.has_bonus_penalty && !malus.bonus_penalty_months) {
    malus.bonus_penalty_months = "";
  } else if (!malus.has_bonus_penalty) {
    malus.bonus_penalty_months = null;
  }
};

// CRUD operations for Maluses
const addMalus = async () => {
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const malusToAdd = {
    name: newMalus.value.name,
    points: -Math.abs(Number(newMalusPoints.value)) || 0,
    created_at: new Date(),
    is_positive: false,
    requires_manual_input: newMalus.value.requires_manual_input,
    effective_from: currentDate,
    // Only include bonus_penalty_months if the checkbox is checked
    bonus_penalty_months: newMalus.value.has_bonus_penalty
      ? Number(newMalus.value.bonus_penalty_months) || null
      : null,
  };

  const { error } = await supabase.from("bonuses").insert([malusToAdd]);

  if (error) {
    console.error(error);
    showError("Fehler beim Hinzufügen des Malus");
  } else {
    newMalus.value = {
      name: "",
      bonus_penalty_months: null,
      is_positive: false,
      has_bonus_penalty: false,
      requires_manual_input: false,
    };
    newMalusPoints.value = 0;
    showMalusForm.value = false;
    fetchBonuses();
    showSuccess("Malus erfolgreich hinzugefügt");
  }
};

const deleteMalus = async (id) => {
  const { error } = await supabase
    .from("bonuses")
    .update({ deleted_at: new Date().toISOString() })
    .eq("id", id);

  if (error) {
    console.error(error);
    showError("Fehler beim Löschen des Malus");
  } else {
    fetchBonuses();
    showSuccess("Malus gelöscht");
  }
};

/**
 *  new version dialog
 *
 */
const newVersionDialog = ref(false);
const newVersionItem = ref(null);
const newVersionType = ref("");
const newVersionEffectiveDate = ref("");
const oldVersionEndDate = ref("");

const showNewVersionDialog = (item, type) => {
  newVersionItem.value = item;
  newVersionType.value = type;
  const today = new Date();

  // Get the end of the current quarter
  const quarterEnd = lastDayOfQuarter(today);
  oldVersionEndDate.value =
    item.effective_to ?? format(quarterEnd, "yyyy-MM-dd");

  // Set new version date to day after quarter end
  const newVersionDate = addDays(oldVersionEndDate.value, 1);
  newVersionEffectiveDate.value = format(newVersionDate, "yyyy-MM-dd");

  newVersionDialog.value = true;
};

// Create new version function - dialog implementation
const createNewVersion = async () => {
  if (!newVersionItem.value) return;

  const item = newVersionItem.value;
  const type = newVersionType.value;

  // Create historical entry with current values using date-fns
  const historicalEntry = {
    effective_from: item.effective_from || format(new Date(), "yyyy-MM-dd"),
    effective_to: oldVersionEndDate.value,
    points: Number(item.points) || 0,
  };

  // Add bonus penalty months for malus
  if (type === "malus" && item.bonus_penalty_months !== null) {
    historicalEntry.bonus_penalty_months =
      Number(item.bonus_penalty_months) || null;
  }

  // Ensure historical_values is an array
  if (!item.historical_values) {
    item.historical_values = [];
  } else if (typeof item.historical_values === "string") {
    try {
      item.historical_values = JSON.parse(item.historical_values);
    } catch (e) {
      console.error("Error parsing historical values:", e);
      item.historical_values = [];
    }
  }

  // Add to history
  item.historical_values.push(historicalEntry);

  // Sort historical values chronologically
  item.historical_values = sortHistoricalValues(item.historical_values);

  // Set new effective date for current item
  item.effective_from = newVersionEffectiveDate.value;
  // Remove effective_to since it's only needed in history

  // Update the item with versioning info
  const updateData = {
    historical_values: item.historical_values,
    effective_from: newVersionEffectiveDate.value,
    modified_at: new Date(),
  };

  const { error } = await supabase
    .from("bonuses")
    .update(updateData)
    .eq("id", item.id);

  if (error) {
    console.error(error);
    showError(`Fehler beim Erstellen der neuen Version: ${error.message}`);
  } else {
    showSuccess("Neue Version erstellt");
    fetchBonuses();
    newVersionDialog.value = false;
  }
};

// Update existing save functions to ensure historical_values is handled properly
const saveBonus = async (bonus) => {
  if (!bonusChanged.value) {
    editingBonus.value = null;
    return;
  }

  // Format effective_from using date-fns if it exists
  let formattedEffectiveFrom = bonus.effective_from;
  if (formattedEffectiveFrom) {
    try {
      // Parse the date and format it properly
      const parsedDate = parseISO(formattedEffectiveFrom);
      formattedEffectiveFrom = format(parsedDate, "yyyy-MM-dd");
    } catch (e) {
      console.error("Error formatting effective_from date:", e);
    }
  }

  // Process historical values to ensure numeric data is properly formatted
  const historicalValues = Array.isArray(bonus.historical_values)
    ? bonus.historical_values.map((entry) => ({
        ...entry,
        points: Number(entry.points) || 0,
      }))
    : [];

  const { error } = await supabase
    .from("bonuses")
    .update({
      name: bonus.name,
      points: Number(bonus.points) || 0,
      is_positive: bonus.is_positive,
      requires_manual_input: bonus.requires_manual_input,
      effective_from: formattedEffectiveFrom,
      historical_values: sortHistoricalValues(historicalValues),
      modified_at: new Date(),
    })
    .eq("id", bonus.id);

  if (error) {
    console.error(error);
    showError("Fehler beim Aktualisieren des Bonus");
  } else {
    fetchBonuses();
    showSuccess("Bonus aktualisiert");
    editingBonus.value = null;
    bonusChanged.value = false;
  }
};

const saveMalus = async (malus) => {
  if (!malusChanged.value) {
    editingMalus.value = null;
    return;
  }

  // Format effective_from using date-fns if it exists
  let formattedEffectiveFrom = malus.effective_from;
  if (formattedEffectiveFrom) {
    try {
      // Parse the date and format it properly
      const parsedDate = parseISO(formattedEffectiveFrom);
      formattedEffectiveFrom = format(parsedDate, "yyyy-MM-dd");
    } catch (e) {
      console.error("Error formatting effective_from date:", e);
    }
  }

  // Process historical values to ensure numeric data is properly formatted
  const historicalValues = Array.isArray(malus.historical_values)
    ? malus.historical_values.map((entry) => ({
        ...entry,
        points: Number(entry.points) || 0,
        bonus_penalty_months:
          entry.bonus_penalty_months !== null
            ? Number(entry.bonus_penalty_months) || null
            : null,
      }))
    : [];

  const malusForDb = {
    name: malus.name,
    points: Number(malus.points) || 0,
    bonus_penalty_months: malus.has_bonus_penalty
      ? Number(malus.bonus_penalty_months) || null
      : null,
    is_positive: malus.is_positive,
    requires_manual_input: malus.requires_manual_input,
    historical_values: sortHistoricalValues(historicalValues),
    effective_from: formattedEffectiveFrom,
    modified_at: new Date(),
  };

  const { error } = await supabase
    .from("bonuses")
    .update(malusForDb)
    .eq("id", malus.id);

  if (error) {
    console.error(error);
    showError("Fehler beim Aktualisieren des Malus");
  } else {
    fetchBonuses();
    showSuccess("Malus aktualisiert");
    editingMalus.value = null;
    malusChanged.value = false;
  }
};

onMounted(() => {
  fetchBonuses();
});
</script>

<style scoped>
.equal-height-row {
  display: flex;
  flex-wrap: wrap;
}
.equal-height-row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.h-100 {
  height: 100%;
}
.points-field {
  max-width: 100px;
}
.penalty-field {
  max-width: 100px;
}
</style>
