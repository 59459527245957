<template>
  <v-row class="ma-4">
    <v-col cols="6">
      <v-text-field label="Suche" v-model="search" clearable></v-text-field>
    </v-col>
    <v-col cols="12">
      <div class="text-h5">{{ duplicates.length }} mögliche Dubletten</div>
    </v-col>
    <template v-for="(dup, index) in duplicates">
      <Duplicates :dup="dup" :search="search"></Duplicates>
    </template>
  </v-row>
</template>

<script setup>
import Duplicates from "@/components/Recruiting/Duplicates.vue";
</script>
<script>
export default {
  data() {
    return {
      search: null,
      duplicates: [],
    };
  },
  methods: {
    async getDuplicates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "check_duplicates",
      });
      response.data.forEach(function (item) {
        item.mergedCandidate = {};
      });
      this.duplicates = response.data;
    },
  },
  async created() {
    this.getDuplicates();
  },
};
</script>
