<template>
  <div>
    <div v-if="!originalLevels.length" class="text-center py-5">
      <v-icon x-large color="grey-lighten-1" class="mb-2">mdi-stairs</v-icon>
      <div class="text-body-1">
        Keine Bonusstufen für den ausgewählten Zeitraum gefunden
      </div>
    </div>

    <div v-else>
      <!-- Bonus levels table -->
      <v-card outlined class="mb-4">
        <v-card-title class="text-subtitle-1 py-2">
          <v-btn
            color="primary"
            small
            outlined
            @click="resetSimulation"
            :disabled="!hasChanges"
          >
            <v-icon small left>mdi-refresh</v-icon>
            Zurücksetzen
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="simulatedLevels"
            class="bonus-levels-table"
            density="compact"
            :loading="isLoading"
            :hide-default-footer="true"
          >
            <template v-slot:item.level_name="{ item }">
              <v-text-field
                v-model="item.level_name"
                dense
                hide-details
                outlined
                :class="{
                  'changed-value': hasValueChanged(item, 'level_name'),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>

            <template v-slot:item.min_points="{ item }">
              <v-text-field
                v-model="item.min_points"
                type="number"
                dense
                hide-details
                outlined
                :class="{
                  'changed-value': hasValueChanged(item, 'min_points'),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>

            <template v-slot:item.hourly_wage_bronze="{ item }">
              <v-text-field
                v-model="item.hourly_wage_bronze"
                type="number"
                step="0.5"
                dense
                hide-details
                outlined
                suffix="€"
                :class="{
                  'changed-value': hasValueChanged(item, 'hourly_wage_bronze'),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>

            <template v-slot:item.hourly_wage_silver="{ item }">
              <v-text-field
                v-model="item.hourly_wage_silver"
                type="number"
                step="0.5"
                dense
                hide-details
                outlined
                suffix="€"
                :class="{
                  'changed-value': hasValueChanged(item, 'hourly_wage_silver'),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>

            <template v-slot:item.hourly_wage_gold="{ item }">
              <v-text-field
                v-model="item.hourly_wage_gold"
                type="number"
                step="0.5"
                dense
                hide-details
                outlined
                suffix="€"
                :class="{
                  'changed-value': hasValueChanged(item, 'hourly_wage_gold'),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>

            <template v-slot:item.hourly_wage_shiftleader="{ item }">
              <v-text-field
                v-model="item.hourly_wage_shiftleader"
                type="number"
                step="0.5"
                dense
                hide-details
                outlined
                suffix="€"
                :class="{
                  'changed-value': hasValueChanged(
                    item,
                    'hourly_wage_shiftleader',
                  ),
                }"
                @blur="
                  updateSimulation();
                  hasChanges = true;
                "
              ></v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- Impact summary -->
      <v-card outlined class="mb-4" v-if="hasChanges">
        <v-card-title class="text-subtitle-1 py-2"> Auswirkungen </v-card-title>

        <v-card-text>
          <div class="text-subtitle-2 mb-2">Mitarbeiter Verteilung</div>
          <v-data-table
            :headers="distributionHeaders"
            :items="levelDistribution"
            density="compact"
            :hide-default-footer="true"
          >
            <template v-slot:item.diff="{ item }">
              <span :class="item.diffClass">{{ item.diff }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { supabase } from "@/supabase";

const props = defineProps({
  originalData: {
    type: Object,
    required: true,
  },
  bonusConfiguration: {
    type: Object,
    required: true,
  },
  selectedPeriod: {
    type: Object,
    required: true,
  },
  selectedCity: {
    type: [Number, null],
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:simulation"]);

// Component state
const originalLevels = ref([]);
const simulatedLevels = ref([]);
const selectedCityId = ref(null);
const hasChanges = ref(false);

// Table headers for bonus levels
const headers = [
  { title: "Stufenname", key: "level_name", sortable: true },
  { title: "Mindestpunkte", key: "min_points", sortable: true, width: "120px" },
  {
    title: "Bronze €/h",
    key: "hourly_wage_bronze",
    sortable: true,
    width: "120px",
  },
  {
    title: "Silber €/h",
    key: "hourly_wage_silver",
    sortable: true,
    width: "120px",
  },
  {
    title: "Gold €/h",
    key: "hourly_wage_gold",
    sortable: true,
    width: "120px",
  },
  {
    title: "Schichtl. €/h",
    key: "hourly_wage_shiftleader",
    sortable: true,
    width: "150px",
  },
];

// Table headers for distribution impact
const distributionHeaders = [
  { title: "Bonusstufe", key: "name", sortable: true },
  { title: "Original", key: "original", sortable: true, align: "center" },
  { title: "Simuliert", key: "simulated", sortable: true, align: "center" },
  { title: "Differenz", key: "diff", sortable: true, align: "center" },
];

// Computed properties
const availableCities = computed(() => {
  if (
    !props.bonusConfiguration.cities ||
    props.bonusConfiguration.cities.length === 0
  ) {
    return [];
  }

  return props.bonusConfiguration.cities.map((city) => ({
    id: city.id,
    city: city.city,
  }));
});

const levelDistribution = computed(() => {
  if (!simulatedLevels.value.length || !props.originalData.details) {
    return [];
  }

  // Filter employees by the selected city
  const cityEmployees = props.originalData.details.filter(
    (emp) => emp.city_id === selectedCityId.value,
  );

  // Create distribution map for both original and simulated levels
  const originalDistribution = {};
  const simulatedDistribution = {};

  // Initialize with all levels
  simulatedLevels.value.forEach((level) => {
    originalDistribution[level.id] = 0;
    simulatedDistribution[level.id] = 0;
  });

  // Add a "no level" category
  originalDistribution["none"] = 0;
  simulatedDistribution["none"] = 0;

  // Count employees in each level
  cityEmployees.forEach((employee) => {
    const originalLevel = findBonusLevel(
      employee.totalPoints,
      originalLevels.value,
    );
    const simulatedLevel = findBonusLevel(
      employee.totalPoints,
      simulatedLevels.value,
    );

    if (originalLevel) {
      originalDistribution[originalLevel.id] =
        (originalDistribution[originalLevel.id] || 0) + 1;
    } else {
      originalDistribution["none"] = (originalDistribution["none"] || 0) + 1;
    }

    if (simulatedLevel) {
      simulatedDistribution[simulatedLevel.id] =
        (simulatedDistribution[simulatedLevel.id] || 0) + 1;
    } else {
      simulatedDistribution["none"] = (simulatedDistribution["none"] || 0) + 1;
    }
  });

  // Create the result array
  const result = [];

  // Add entries for each bonus level
  simulatedLevels.value.forEach((level) => {
    const original = originalDistribution[level.id] || 0;
    const simulated = simulatedDistribution[level.id] || 0;
    const diff = simulated - original;

    result.push({
      id: level.id,
      name: level.level_name,
      original,
      simulated,
      diff: diff > 0 ? `+${diff}` : diff,
      diffClass: diff > 0 ? "green--text" : diff < 0 ? "red--text" : "",
    });
  });

  // Add entry for "no level" if needed
  if (originalDistribution["none"] > 0 || simulatedDistribution["none"] > 0) {
    const original = originalDistribution["none"];
    const simulated = simulatedDistribution["none"];
    const diff = simulated - original;

    result.push({
      id: "none",
      name: "Keine Stufe",
      original,
      simulated,
      diff: diff > 0 ? `+${diff}` : diff,
      diffClass: diff > 0 ? "green--text" : diff < 0 ? "red--text" : "",
    });
  }

  return result;
});

// Methods
function hasValueChanged(item, field) {
  if (!item || !originalLevels.value || !item.id) return false;

  // Find corresponding original level
  const originalLevel = originalLevels.value.find(
    (level) => level.id === item.id,
  );
  if (!originalLevel) return false;

  // Compare values based on field type
  if (field === "level_name") {
    return item.level_name !== originalLevel.level_name;
  } else {
    // For numeric fields, convert to number before comparison to avoid string/number mismatch
    const simValue = Number(item[field] || 0);
    const origValue = Number(originalLevel[field] || 0);

    return simValue !== origValue;
  }
}

function loadBonusLevels() {
  if (!props.bonusConfiguration.bonusLevels) {
    return;
  }

  // Reset in case we're reloading
  originalLevels.value = [];
  simulatedLevels.value = [];

  // Use the selected city from the parent component if available
  if (!selectedCityId.value) {
    if (props.selectedCity) {
      selectedCityId.value = props.selectedCity;
    } else if (props.bonusConfiguration.cities.length > 0) {
      selectedCityId.value = props.bonusConfiguration.cities[0].id;
    }
  }

  // Filter bonus levels for the selected city
  const cityLevels = props.bonusConfiguration.bonusLevels.filter(
    (level) => level.city_id === selectedCityId.value,
  );

  // Sort levels by min_points in descending order
  const sortedLevels = [...cityLevels].sort(
    (a, b) => b.min_points - a.min_points,
  );

  // Deep clone to ensure we don't modify the original data
  originalLevels.value = JSON.parse(JSON.stringify(sortedLevels));
  simulatedLevels.value = JSON.parse(JSON.stringify(sortedLevels));
  hasChanges.value = false;
}

function findBonusLevel(points, levels) {
  if (!levels || !points) return null;

  // Find the first level where employee points are greater than or equal to the minimum points
  // Since levels are sorted with highest min_points first, this will find the highest applicable level
  return levels.find((level) => points >= level.min_points) || null;
}

function calculateEmployeeHourlyWage(employee, bonusLevel) {
  if (!bonusLevel) return 0;

  const position = employee.position?.toLowerCase();

  if (position?.includes("bronze") && bonusLevel.hourly_wage_bronze) {
    return Number(bonusLevel.hourly_wage_bronze);
  } else if (position?.includes("silber") && bonusLevel.hourly_wage_silver) {
    return Number(bonusLevel.hourly_wage_silver);
  } else if (position?.includes("gold") && bonusLevel.hourly_wage_gold) {
    return Number(bonusLevel.hourly_wage_gold);
  } else if (
    position?.includes("shiftleader") &&
    bonusLevel.hourly_wage_shiftleader
  ) {
    return Number(bonusLevel.hourly_wage_shiftleader);
  }

  // Fallback to average if position doesn't match
  const wages = [
    bonusLevel.hourly_wage_bronze,
    bonusLevel.hourly_wage_silver,
    bonusLevel.hourly_wage_gold,
    bonusLevel.hourly_wage_shiftleader,
  ].filter((wage) => wage !== null && wage > 0);

  return wages.length > 0
    ? wages.reduce((sum, wage) => sum + Number(wage), 0) / wages.length
    : 0;
}

function updateSimulation() {
  // Ensure all numeric values are properly converted to numbers
  const processedLevels = simulatedLevels.value.map((level) => ({
    ...level,
    min_points: Number(level.min_points || 0),
    hourly_wage_bronze: Number(level.hourly_wage_bronze || 0),
    hourly_wage_silver: Number(level.hourly_wage_silver || 0),
    hourly_wage_gold: Number(level.hourly_wage_gold || 0),
    hourly_wage_shiftleader: Number(level.hourly_wage_shiftleader || 0),
    // Make sure city_id is set
    city_id: level.city_id || selectedCityId.value,
  }));

  // Calculate impact on employees
  const simulatedEmployeeData = [];

  if (props.originalData.details) {
    // Filter employees by the selected city
    const cityEmployees = props.originalData.details.filter(
      (emp) => emp.city_id === selectedCityId.value,
    );

    // Recalculate wages for each employee
    cityEmployees.forEach((employee) => {
      const simulatedLevel = findBonusLevel(
        employee.totalPoints,
        processedLevels,
      );
      const simulatedHourlyWage = calculateEmployeeHourlyWage(
        employee,
        simulatedLevel,
      );
      const simulatedMonthlyWage = simulatedHourlyWage * 160; // Assuming 160 hours per month

      simulatedEmployeeData.push({
        id: employee.id,
        name: employee.name,
        points: employee.totalPoints,
        bonusLevel: simulatedLevel ? simulatedLevel.level_name : "Keine Stufe",
        hourlyWage: simulatedHourlyWage,
        monthlyWage: simulatedMonthlyWage,
      });
    });
  }

  // Calculate total cost
  const totalCost = simulatedEmployeeData.reduce(
    (sum, emp) => sum + emp.monthlyWage,
    0,
  );

  // Emit updated simulation data
  emit("update:simulation", {
    type: "levels",
    data: {
      bonusLevels: processedLevels,
      employeeData: simulatedEmployeeData,
      totalCost: totalCost,
      // Add cityId to help the parent component
      cityId: selectedCityId.value,
    },
  });
}

function resetSimulation() {
  // Reset simulated levels to original levels
  simulatedLevels.value = JSON.parse(JSON.stringify(originalLevels.value));
  hasChanges.value = false;
  updateSimulation();
}

// Watch for changes in selected city from parent component
watch(
  () => props.selectedCity,
  (newCity) => {
    if (newCity && newCity !== selectedCityId.value) {
      selectedCityId.value = newCity;
      loadBonusLevels();
      updateSimulation();
    }
  },
);

// Watch for changes in selected city or bonus configuration
watch(
  [() => props.bonusConfiguration.bonusLevels, selectedCityId],
  () => {
    loadBonusLevels();
    updateSimulation();
  },
  { deep: true },
);

onMounted(() => {
  loadBonusLevels();
  updateSimulation();
});
</script>

<style scoped>
.bonus-levels-table :deep(th) {
  white-space: nowrap;
}

.changed-value {
  background-color: rgba(128, 203, 196, 0.2);
}
</style>
