<template>
  <v-tabs v-model="tab">
    <v-tab value="dates">Datumswerte</v-tab>
    <v-tab value="checks">Mitarbeiterchecks</v-tab>
    <v-tab value="training">Einarbeitung</v-tab>
    <v-tab value="god">GOD Einträge</v-tab>
    <v-tab value="bonus">Bonus Übersicht</v-tab>
    <v-tab value="timeTracking">Zeiterfassung</v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="dates">
      <EmployeeDates />
    </v-window-item>
    <v-window-item value="checks">
      <EmployeeChecks />
    </v-window-item>
    <v-window-item value="training">
      <EmployeeTraining />
    </v-window-item>
    <v-window-item value="god">
      <GodEntries />
    </v-window-item>
    <v-window-item value="bonus">
      <MonthlyBonusReport />
    </v-window-item>
    <v-window-item value="timeTracking">
      <TimeTracking />
    </v-window-item>
  </v-window>
</template>

<script setup>
import EmployeeDates from "@/components/Reports/EmployeeDates.vue";
import EmployeeChecks from "@/components/Reports/EmployeeChecks.vue";
import EmployeeTraining from "@/components/Reports/EmployeeTraining.vue";
import GodEntries from "@/components/Reports/GodEntries.vue";
import MonthlyBonusReport from "@/components/Reports/MonthlyBonusReport.vue";
import TimeTracking from "@/components/Reports/TimeTracking.vue";
import { ref } from "vue";
let tab = ref(null);
</script>
