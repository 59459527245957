<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-combobox
          v-model="employeeFilter"
          :items="employeeFilterOptions"
          label="Mitarbeiter"
          item-title="name"
          multiple
          chips
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-combobox
          v-model="hotelFilter"
          :items="hotelFilterOptions"
          label="Hotel"
          item-title="name"
          item-value="id"
          multiple
          chips
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="monthFilter"
          :items="monthFilterOptions"
          label="Monat"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-combobox
          v-model="statusFilter"
          :items="statusFilterOptions"
          label="Status"
          item-title="label"
          item-value="id"
          multiple
          chips
          clearable
          return-object
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="trainingDocs"
          :items-per-page="50"
          :headers="headers"
          hover
          @click:row="openTrainingForm"
        >
          <template v-slot:item.employee="{ item }">
            <router-link :to="'/Mitarbeiter/' + item.employee.id" @click.stop>
              {{
                `${item?.employee?.last_name}, ${item?.employee?.first_name}`
              }}</router-link
            >
          </template>
          <template v-slot:item.hotel="{ item }">
            <v-chip>
              {{ item.hotel.short_name }}
              <v-tooltip activator="parent" location="right" open-delay="300">
                {{ item.hotel.name }}
              </v-tooltip>
            </v-chip>
          </template>
          <template v-slot:item.started_training="{ item }">
            {{ getFormattedShiftDate(item.started_training_at) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.trainingResult?.status?.color ?? 'grey'">
              {{ item.trainingResult?.status?.label }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
    v-model="trainingFormsDialog.isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <ProgressForm
      :trainee="trainingFormsDialog.employee"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :is-senior="trainingFormsDialog.trainingResult.is_senior"
      :is-dialog="true"
      :close-dialog="
        () => {
          trainingFormsDialog.isOpen = false;
          fetchTrainingData();
        }
      "
    ></ProgressForm>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, watch, reactive } from "vue";
import { supabase } from "@/supabase";
import { parse, isSameMonth, isValid, compareAsc } from "date-fns";
import { de } from "date-fns/locale";
import ProgressForm from "../Trainings/ProgressForm.vue";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";

let trainingDocs = ref([]);
let headers = ref([
  {
    title: "Mitarbeiter",
    key: "employee",
    value: "employee",
    sort: (a, b) => a.last_name.localeCompare(b.last_name),
  },
  { title: "Stadt", key: "city", value: "city" },
  { title: "Hotel", key: "hotel", value: "hotel" },
  {
    title: "Begonnen am",
    key: "started_training_at",
    value: "started_training_at",
    sort: (a, b) => {
      if (!a && !b) return 0;
      if (!a) return 1;
      if (!b) return -1;

      const dateA = parse(a, "dd.MM.yyyy", new Date());
      const dateB = parse(b, "dd.MM.yyyy", new Date());

      return compareAsc(dateA, dateB);
    },
  },
  {
    title: "Status",
    key: "status",
    value: "status",
  },
]);

let trainingFormsDialog = reactive({
  employee: null,
  hotel: null,
  city: null,
  trainingResult: null,
  isOpen: false,
});

const employeeFilter = ref([]);
const hotelFilter = ref([]);
const monthFilter = ref(null);
const statusFilter = ref([]);

const monthFilterOptions = ref([]);
const hotelFilterOptions = ref([]);
const employeeFilterOptions = ref([]);
const statusFilterOptions = ref([]);

const prepareTableData = (dbTrainingResultData) => {
  const trainingData = [];

  dbTrainingResultData.forEach((trainingResult) => {
    trainingData.push({
      employee: trainingResult.employee,
      city: trainingResult.hotel.city.city,
      hotel: trainingResult.hotel,
      started_training_at: getFormattedShiftDate(
        trainingResult.started_training_at,
      ),
      trainingResult,
    });
  });

  trainingDocs.value = trainingData;
};

const isWithinMonth = (dateString, month) => {
  const parsedMonth = parse(month, "LLLL yyyy", new Date(), { locale: de });
  const date = new Date(dateString);
  return isSameMonth(date, parsedMonth);
};

const fetchTrainingData = async () => {
  const filters = {
    employee: employeeFilter.value.map((e) => e.id),
    hotel: hotelFilter.value.map((h) => h.id),
    month: monthFilter.value,
    status: statusFilter.value.map((s) => s.id),
  };

  let dataFetchQuery = supabase
    .from("training_hotel_results")
    .select(
      "*, employee:employees!training_hotel_result_trainee_id_fkey(id, last_name, first_name, name), hotel:hotels!inner(id, name, short_name, city:cities!inner(city)), status:training_statuses(*)",
    )
    .not("started_training_at", "is", null);

  if (filters.employee.length > 0) {
    dataFetchQuery = dataFetchQuery.in("trainee_id", filters.employee);
  }

  if (filters.hotel.length > 0) {
    dataFetchQuery = dataFetchQuery.in("hotel_id", filters.hotel);
  }

  if (filters.status.length > 0) {
    dataFetchQuery = dataFetchQuery.in("status_id", filters.status);
  }

  // Filter by time (Monthname yyyy)
  if (filters.month) {
    const parsedMonth = parse(filters.month, "LLLL yyyy", new Date(), {
      locale: de,
    });
    const monthStart = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth(),
      1,
    );
    const monthEnd = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth() + 1,
      1,
    );

    dataFetchQuery = dataFetchQuery.gte(
      "started_training_at",
      monthStart.toISOString(),
    );
    dataFetchQuery = dataFetchQuery.lte(
      "started_training_at",
      monthEnd.toISOString(),
    );
  }

  const { data, error } = await dataFetchQuery;

  if (error) {
    console.error(error);
    // TODO: handle error
    return;
  }

  prepareTableData(data);
};

const fetchFilterOptions = async () => {
  // Fetch Employees
  const { data: employees, error: empError } = await supabase
    .from("employees")
    .select("id, last_name, first_name, name")
    .order("last_name", { ascending: true });

  if (empError) {
    // TODO: handle error
    console.error(empError);
    return;
  }
  employeeFilterOptions.value = employees;

  // Fetch Hotels
  const { data: hotels, error: hotelError } = await supabase
    .from("hotels")
    .select("id, name, short_name, city:cities(city)")
    .order("name", { ascending: true });

  if (hotelError) {
    console.error(hotelError);
    return;
  }
  hotelFilterOptions.value = hotels;

  // Fetch Statuses
  const { data: statuses, error: statusError } = await supabase
    .from("training_statuses")
    .select("id, name, label")
    .order("id", { ascending: true });

  if (statusError) {
    console.error(statusError);
    return;
  }
  statusFilterOptions.value = statuses;

  // Directly add the first status to the statusFilter
  if (statuses && statuses.length > 0) {
    statusFilter.value = [statuses[0]]; // Add first status to filter
  }

  // Fetch Months
  const { data: trainingResults, error: trError } = await supabase
    .from("training_hotel_results")
    .select("started_training_at");

  if (trError) {
    console.error(trError);
    return;
  }

  const months = new Set();
  trainingResults.forEach((tr) => {
    if (tr.started_training_at) {
      months.add(
        new Date(tr.started_training_at).toLocaleString("de", {
          month: "long",
          year: "numeric",
        }),
      );
    }
  });
  monthFilterOptions.value = Array.from(months).sort(
    (a, b) => new Date(b) - new Date(a),
  );
};

function openTrainingForm(event, { item }) {
  // Skip if clicking on the employee name which has its own router-link
  if (event.target.closest("a")) return;

  trainingFormsDialog.employee = item.employee;
  trainingFormsDialog.hotel = item.hotel;
  trainingFormsDialog.city = item.city;
  trainingFormsDialog.trainingResult = item.trainingResult;
  trainingFormsDialog.isOpen = true;
}

onMounted(async () => {
  await fetchFilterOptions();
  await fetchTrainingData();
});

watch([employeeFilter, hotelFilter, monthFilter, statusFilter], () => {
  fetchTrainingData();
});
</script>

<style>
/* Remove the clickable-rows styles as they're no longer needed */
</style>
