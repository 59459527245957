<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h5 font-weight-bold">
        Mitarbeiter Zeiterfassung
      </v-card-title>
      
      <v-card-text>
        <v-row>
            <v-col cols="12" md="2">
                <v-btn 
                  variant="outlined"
                  height="56"
                  block
                  color="primary"
                  @click="resetEmployeeSelection"
                  prepend-icon="mdi-account-group"
                >
                  Alle Mitarbeiter
                </v-btn>
            </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="selectedEmployee"
              :items="employees"
              item-title="displayName"
              item-value="id"
              label="Mitarbeiter auswählen"
              density="comfortable"
              return-object
              @update:model-value="fetchTimeTrackingData"
            ></v-autocomplete>
          </v-col>
          
          <v-col cols="12" md="6">
            <div class="d-flex align-center">
              <v-btn
                icon="mdi-chevron-left"
                variant="text"
                @click="navigateMonth(-1)"
                :disabled="loading"
              ></v-btn>
              
              <div class="text-h6 mx-4 text-center flex-grow-1">
                {{ dateDisplay }}
              </div>
              
              <v-btn
                icon="mdi-chevron-right"
                variant="text"
                @click="navigateMonth(1)"
                :disabled="loading"
              ></v-btn>
            </div>
          </v-col>
        </v-row>
        
        <v-divider class="my-4"></v-divider>
        
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <div class="mt-2">Daten werden geladen...</div>
        </div>
        
        <div v-else-if="timeEntries.length === 0">
          <v-alert type="info" variant="tonal">
            Keine Zeiteinträge im {{ dateDisplay }} gefunden.
          </v-alert>
        </div>
        
        <div v-else-if="timeEntries.length > 0">
          <v-card variant="outlined" class="mb-4">
            <v-card-text>
              <div class="text-h6 mb-2">Zusammenfassung</div>
              <v-row>
                <v-col cols="4">
                  <div class="text-subtitle-2">Gesamtstunden</div>
                  <div class="text-h5">{{ totalHours.toFixed(2) }}</div>
                </v-col>
                <v-col cols="4">
                  <div class="text-subtitle-2">Anzahl Schichten</div>
                  <div class="text-h5">{{ timeEntries.length }}</div>
                </v-col>
                <v-col cols="4">
                  <div class="text-subtitle-2">Durchschnitt/Schicht</div>
                  <div class="text-h5">{{ (totalHours / timeEntries.length).toFixed(2) }}</div>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="fillAllTimes()">Alle tatsächliche Zeiten ausfüllen</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-data-table
            :headers="headers"
            :items="timeEntries"
            class="elevation-1"
            density="comfortable"
            :items-per-page="20"
          >
            <template v-slot:item.date="{ item }">
              {{ formatDate(item.date) }}
            </template>

            <template v-slot:item.hotel_name="{ item }">
              {{ item.hotel_name }} 
              <v-chip size="x-small" variant="outlined">{{item.is_senior ? 'Senior' : 'Clerk'}}</v-chip>
              <v-icon v-if="item.is_training">mdi-school</v-icon>
            </template>
            
            <template v-slot:item.workTime="{ item }">
              <span :class="{'text-red': item.overtime > 0}">
                {{ formatTimeRange(item.start_time, item.end_time) }}
                <v-tooltip activator="parent" location="top">
                  {{ item.hours.toFixed(2) }} Stunden
                  <div v-if="item.overtime > 0" class="red--text">
                    ({{ item.overtime.toFixed(2) }}h Überstunden)
                  </div>
                </v-tooltip>
                <v-icon @click="copyPlannedTime(item)">mdi-arrow-right</v-icon>
              </span>
            </template>
            
            <template v-slot:item.realTime="{ item }">
                <v-row>
                    <v-col cols="6">
                      <v-text-field 
                        variant="solo" 
                        density="compact" 
                        v-model="item.real_start_time" 
                        type="time" 
                        format="HH:mm" 
                        hide-details
                        @update:model-value="onRealTimeChange(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field 
                        variant="solo" 
                        density="compact" 
                        v-model="item.real_end_time" 
                        type="time" 
                        format="HH:mm" 
                        hide-details
                        @update:model-value="onRealTimeChange(item)"
                      ></v-text-field>
                    </v-col>
                </v-row>
            </template>
            
            <template v-slot:item.performance="{ item }">
                <v-icon @click="copyPerformanceTime(item)">mdi-arrow-left</v-icon>
              <span v-if="item.login_time && item.logout_time">
                {{ formatTimeRange(item.login_time, item.logout_time) }}
                <v-tooltip activator="parent" location="top">
                  {{ calculateHours(item.login_time, item.logout_time).toFixed(2) }} Stunden
                </v-tooltip>
              </span>
              <span v-else class="text-grey">Nicht erfasst</span>
            </template>
            
            <template v-slot:item.notes="{ item }">
              <v-tooltip v-if="item.notes" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" icon="mdi-note-text"></v-icon>
                </template>
                {{ item.notes }}
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
        
        <div v-else>
          <v-alert type="info" variant="tonal">
            Bitte wählen Sie einen Mitarbeiter und einen Monat aus, um Zeiterfassungsdaten anzuzeigen.
          </v-alert>
        </div>
      </v-card-text>
      
      <v-card-actions class="justify-end">
        <v-btn 
          color="primary" 
          prepend-icon="mdi-file-excel" 
          :disabled="timeEntries.length === 0"
          @click="exportToCSV"
        >
          Exportieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="3000"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { format, startOfMonth, endOfMonth, parseISO, differenceInHours, differenceInMinutes, addMonths } from 'date-fns';
import { de } from 'date-fns/locale';
import { supabase } from '@/supabase';
</script>

<script>
export default {
  name: 'EmployeeTimeTracking',
  data() {
    return {
      selectedEmployee: null,
      employees: [],
      selectedDate: format(new Date(), 'yyyy-MM'),
      loading: false,
      timeEntries: [],
      debounceTimers: {}, // For tracking debounce timers by shift ID
      savingStatus: {}, // To track saving status by shift ID
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      },
      headers: [
        { title: 'Personalnummer', key: 'internal_id', align: 'start' },
        { title: 'Mitarbeiter', key: 'employee_name', align: 'start' },
        { title: 'Datum', key: 'date', align: 'start' },
        { title: 'Hotel', key: 'hotel_name', align: 'start' },
        { title: 'Geplante Zeit', key: 'workTime', align: 'center' },
        { title: 'Tatsächliche Zeit', key: 'realTime', align: 'center' },
        { title: 'Leistungsnachweis', key: 'performance', align: 'center' },
        { title: 'Notizen', key: 'notes', align: 'center' }
      ],
      batchSaving: false,  // Add this if it doesn't exist
    };
  },
  computed: {
    dateDisplay() {
      if (!this.selectedDate) return '';
      const date = parseISO(this.selectedDate + '-01');
      return format(date, 'MMMM yyyy', { locale: de });
    },
    startDate() {
      if (!this.selectedDate) return null;
      return startOfMonth(parseISO(this.selectedDate + '-01'));
    },
    endDate() {
      if (!this.selectedDate) return null;
      return endOfMonth(parseISO(this.selectedDate + '-01'));
    },
    totalHours() {
      return this.timeEntries.reduce((sum, entry) => sum + entry.hours, 0);
    }
  },
  methods: {
    async fetchEmployees() {
      try {
        const { data, error } = await supabase
          .from('employees')
          .select('id, first_name, last_name,employee_data_matching!inner(*)')
          .eq('status',"Aktiv")
          .eq('employee_data_matching.key_id', 6)
          .eq('employee_data_matching.value_id', 2)
          .order('last_name');
        
        if (error) throw error;
        
        this.employees = data.map(emp => ({
          id: emp.id,
          displayName: `${emp.last_name}, ${emp.first_name}`,
        }));
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    },
    
    async fetchTimeTrackingData() {
      if (!this.startDate || !this.endDate) return;
      
      this.loading = true;
      this.timeEntries = [];
      this.startDate.setHours(12)
      
      try {
        const query = supabase
          .from('employees')
          .select(`
            id,
            last_name,
            first_name,
            info:employee_data_matching(*),
            shifts(
              id,
              date,
              is_training,
              hotel_id,
              is_senior,
              special_start_time,
              special_end_time,
              real_start_time,
              real_end_time,
              shift_notes(note, type_id),
              hotels(
                id,
                name,
                work_start,
                work_end
              ),
              hotel_performance_records(
                id,
                created_at,
                logout_at
              )
            )
          `)
          .gte('shifts.date', this.startDate.toISOString())
          .lte('shifts.date', this.endDate.toISOString())
          .order('date', { referencedTable: 'shifts', ascending: true });
        
        if (this.selectedEmployee) {
          query.eq('id', this.selectedEmployee.id);
        }
        const { data, error } = await query;
        if (error) throw error;
        
        this.timeEntries = [];
        if (data && data.length > 0) {
            data.forEach(employee => {
                let entry = this.processTimeEntries(employee.shifts,employee);
                this.timeEntries.push(...entry);
            });

          
        }
      } catch (error) {
        console.error('Error fetching time tracking data:', error);
      } finally {
        this.loading = false;
      }
    },
    
    processTimeEntries(shifts,employee) {
      const entry = shifts.map(shift => {
        // Get the note with type_id 4 if it exists
        const relevantNote = shift.shift_notes?.find(note => note.type_id === 4);
        
        // Determine start and end time (use special times if available, otherwise hotel default times)
        const start_time = shift.special_start_time || shift.hotels?.work_start;
        const end_time = shift.special_end_time || shift.hotels?.work_end;
        
        // Calculate hours for the shift
        const hours = this.calculateHours(start_time, end_time);
        
        // Calculate real hours if available
        let real_hours = 0;
        if (shift.real_start_time && shift.real_end_time) {
          real_hours = this.calculateHours(shift.real_start_time, shift.real_end_time);
        }
        
        // Get performance record data
        const performance = shift.hotel_performance_records?.[0];
        const login_time = performance?.created_at ? format(new Date(performance.created_at), "HH:mm") : null;
        const logout_time = performance?.logout_at ? format(new Date(performance.logout_at), "HH:mm") : null;
        
        // Calculate overtime (real hours - planned hours, only if positive)
        const overtime = Math.max(0, real_hours - hours);
        
        return {
          id: shift.id,
          internal_id: employee.info.find(info => info.key_id === 20)?.value_number,
          employee_name: `${employee.last_name}, ${employee.first_name}`,
          date: shift.date,
          hotel_id: shift.hotel_id,
          is_senior: shift.is_senior,
          check_id: 1,
          hotel_name: shift.hotels?.name || 'Unbekannt',
          start_time: start_time,
          end_time: end_time,
          is_training: shift.is_training,
          hours: hours,
          real_start_time: shift.real_start_time,
          real_end_time: shift.real_end_time,
          real_hours: real_hours,
          login_time: login_time,
          logout_time: logout_time,
          overtime: overtime,
          notes: relevantNote?.note || ''
        };
      });
      return entry
    },

    async fillAllTimes() {
      this.batchSaving = true;
      let successCount = 0;
      let errorCount = 0;
      let upsertArray = [];
      
      const itemsToUpdate = this.timeEntries.filter(entry => 
        entry.start_time && entry.end_time && 
        (!entry.real_start_time || !entry.real_end_time)
      );
      
      if (itemsToUpdate.length === 0) {
        this.showSnackbar('Alle Einträge haben bereits tatsächliche Zeiten', 'info');
        return;
      }
      
      for (const entry of itemsToUpdate) {
        entry.real_start_time = entry.start_time;
        entry.real_end_time = entry.end_time;
        try {
            upsertArray.push({
              hotel_id: entry.hotel_id,
              date: entry.date,
              is_training: entry.is_training,
              is_senior: entry.is_senior,
              check_id: entry.check_id,
              real_start_time: entry.real_start_time,
              real_end_time: entry.real_end_time
            });
            entryObject[entry.hotel_id + entry.date + entry.is_training + entry.is_senior + entry.check_id] = true

            
          
          // Update real_hours and overtime
          entry.real_hours = this.calculateHours(entry.real_start_time, entry.real_end_time);
          entry.overtime = Math.max(0, entry.real_hours - entry.hours);
          
        } catch (error) {
          console.error('Error updating time:', error);
        }
      }
      
      const response = await supabase
        .from('shifts')
        .upsert(upsertArray);
      this.batchSaving = false;
      if (response.error) {
        this.showSnackbar(response.error.message);
        this.fetchTimeTrackingData()
        return;
      }
      // Update the local timeEntries with the new real hours and overtime
      this.showSnackbar(
        `${itemsToUpdate.length} Zeiten aktualisiert`,
      );
    },
    
    calculateHours(startTime, endTime) {
      if (!startTime || !endTime) return 0;

      if (typeof startTime === 'string') {
        // Parse times (assuming format like "08:00" or "17:30")
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);
        
        // Create Date objects for today with these times
        const start = new Date();
        start.setHours(startHour, startMinute, 0);
        
        const end = new Date();
        end.setHours(endHour, endMinute, 0);
        
        // If end time is before start time, assume it's the next day
        if (end < start) {
            end.setDate(end.getDate() + 1);
        }
        
        // Calculate difference in hours
        const diffMinutes = differenceInMinutes(end, start);
        return diffMinutes / 60;
        } else {
            return 2
        }
    },
    
    handleDateChange() {
      this.dateMenu = false;
      this.fetchTimeTrackingData();
    },
    
    formatDate(dateString) {
      const date = parseISO(dateString);
      return format(date, 'EEE, dd.MM.yyyy', { locale: de });
    },
    
    formatTimeRange(start, end) {
      if (!start || !end) return '–';
      return `${start} - ${end}`;
    },
    
    navigateMonth(step) {
      // Parse the current selected date
      const currentDate = parseISO(this.selectedDate + '-01');
      // Add/subtract months based on step
      const newDate = addMonths(currentDate, step);
      // Format back to yyyy-MM
      this.selectedDate = format(newDate, 'yyyy-MM');
      // Fetch data for the new month
      this.fetchTimeTrackingData();
    },
    
    resetEmployeeSelection() {
      this.selectedEmployee = null;
      this.fetchTimeTrackingData();
    },
    
    exportToCSV() {
      if (this.timeEntries.length === 0) return;
      
      // Define column headers for CSV
      const headers = [
        'Personalnummer',
        'Mitarbeiter',
        'Datum',
        'Hotel',
        'Geplante Startzeit',
        'Geplante Endzeit',
        'Geplante Stunden',
        'Tatsächliche Startzeit',
        'Tatsächliche Endzeit',
        'Tatsächliche Stunden',
        'Login-Zeit',
        'Logout-Zeit',
        'Leistungsnachweis Stunden',
        'Überstunden',
        'Notizen',
        'Training'
      ].join(';');
      
      // Format data rows
      const rows = this.timeEntries.map(entry => {
        // Calculate performance hours
        const performanceHours = (entry.login_time && entry.logout_time) 
          ? this.calculateHours(entry.login_time, entry.logout_time).toFixed(2) 
          : '';
        
        // Format date in German format
        const formattedDate = this.formatDate(entry.date).replace('EEE, ', '');
        
        return [
          entry.internal_id || '',
          entry.employee_name || '',
          formattedDate || '',
          entry.hotel_name || '',
          entry.start_time || '',
          entry.end_time || '',
          entry.hours.toFixed(2) || '',
          entry.real_start_time || '',
          entry.real_end_time || '',
          entry.real_hours.toFixed(2) || '',
          entry.login_time || '',
          entry.logout_time || '',
          performanceHours || '',
          entry.overtime.toFixed(2) || '',
          entry.notes ? `"${entry.notes.replace(/"/g, '""')}"` : '',  // Escape quotes in CSV
          entry.is_training ? 'Ja' : 'Nein'
        ].join(';');
      });
      
      // Combine headers and rows
      const csvContent = [headers, ...rows].join('\n');
      
      // Create a downloadable blob
      const blob = new Blob(["\ufeff" + csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      
      // Create download link and trigger click
      const link = document.createElement('a');
      link.setAttribute('href', url);
      
      // Generate filename: zeiterfassung_[mitarbeiter/alle]_[monat_jahr].csv
      const month = this.dateDisplay;
      const employeeName = this.selectedEmployee ? this.selectedEmployee.displayName.replace(', ', '_') : 'alle';
      link.setAttribute('download', `zeiterfassung_${employeeName}_${month.replace(' ', '_')}.csv`);
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
    onRealTimeChange(item) {
      // Clear any existing timer for this item
      if (this.debounceTimers[item.id]) {
        clearTimeout(this.debounceTimers[item.id]);
      }
      
      // Set a new debounce timer
      this.debounceTimers[item.id] = setTimeout(() => {
        this.saveRealTime(item);
      }, 1000); // Save after 1 second of inactivity
    },
    
    async saveRealTime(item) {
      // Check if both real start and end times are provided
      if (!item.real_start_time || !item.real_end_time) {
        return;
      }
      
      // Prevent simultaneous saves for the same item
      if (this.savingStatus[item.id]) {
        return;
      }
      
      this.savingStatus[item.id] = true;
      
      try {
        const { data, error } = await supabase
          .from('shifts')
          .update({
            real_start_time: item.real_start_time,
            real_end_time: item.real_end_time
          })
          .eq('id', item.id);
          
        if (error) {
          throw error;
        }
        
        // Update real_hours in local data
        item.real_hours = this.calculateHours(item.real_start_time, item.real_end_time);
        
        // Update overtime
        item.overtime = Math.max(0, item.real_hours - item.hours);
        
        this.showSnackbar('Zeit wurde gespeichert');
      } catch (error) {
        console.error('Error saving real time:', error);
        this.showSnackbar('Fehler beim Speichern der Zeit', 'error');
      } finally {
        this.savingStatus[item.id] = false;
      }
    },
    
    copyPlannedTime(item) {
      item.real_start_time = item.start_time;
      item.real_end_time = item.end_time;
      this.saveRealTime(item);
    },
    
    copyPerformanceTime(item) {
      if (item.login_time && item.logout_time) {
        item.real_start_time = item.login_time;
        item.real_end_time = item.logout_time;
        this.saveRealTime(item);
      } else {
        this.showSnackbar('Keine Leistungsnachweiszeiten verfügbar', 'error');
      }
    },
    
    showSnackbar(text, color = 'success') {
      this.snackbar = {
        show: true,
        text: text,
        color: color
      };
    },
    
  },
  async mounted() {
    await this.fetchEmployees();
  }
};
</script>

<style scoped>
.text-red {
  color: #ff5252;
}

@media print {
  .v-btn, .v-menu {
    display: none !important;
  }
  
  .v-card {
    box-shadow: none !important;
  }
}
</style>
