<template>
  <v-btn
    style="position: fixed"
    @click="saveInput()"
    class="ma-2"
    color="primary"
    fab
    :loading="loading"
  >
    <v-icon>mdi-content-save</v-icon>
  </v-btn>
  <v-container>
    <v-dialog v-model="previewDialog">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col align="left">
              Vorschau
            </v-col>
            <v-col align="right">
              <v-btn flat @click="previewDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <Preview :hotelId="hotelObject.id"></Preview>
      </v-card>
    </v-dialog>
    
    <v-btn @click="previewDialog = true" class="ma-2" color="primary" fab>
      <v-icon>mdi-eye</v-icon>
    </v-btn>
    <v-row class="ml-4">
      <v-col v-if="loadingCategories" cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="mt-2">Lade Hotel Informationen...</div>
      </v-col>
      <v-col
        cols="12"
        v-else
        v-for="category in categories"
        :key="category.category"
      >
        <v-card elevation="8"
          ><v-card-text>
            <div class="text-h5">{{ category.category }}</div>
            <!-- Normal items without sub_category -->
            <div
              v-for="item in category.normalItems"
              :key="item.id"
              class="my-4"
            >
              <v-text-field
                persistent-hint
                :hint="item.sub_category"
                variant="solo"
                v-if="item.type === 'text-field'"
                :label="item.name"
                v-model="item.value"
              ></v-text-field>
              <div v-if="item.type == 'html'" class="mb-4">
                <strong>{{ item.name }}</strong>
                <QuillEditor
                  :options="editorOptions"
                  contentType="html"
                  theme="snow"
                  v-model:content="item.value"
                />
              </div>
            </div>
            <!-- Items grouped by sub_category -->
            <div
              v-for="group in category.groupedSubCategories"
              :key="group.subCategory"
              class="my-4"
            >
              <v-card variant="outlined">
                <v-card-title>
                  {{ group.subCategory }}
                </v-card-title>
                <v-card-text>
                  <div v-for="item in group.items" :key="item.id">
                    <v-text-field
                      variant="solo"
                      v-if="item.type === 'text-field'"
                      :label="item.name"
                      v-model="item.value"
                    ></v-text-field>
                    <div v-if="item.type === 'html'" class="mb-4">
                      <strong>{{ item.name }}</strong>
                      <QuillEditor
                        :options="editorOptions"
                        contentType="html"
                        theme="snow"
                        v-model:content="item.value"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="8">
          <v-card-title> Zugänge </v-card-title>
          <v-card-text>
            <div v-if="loadingPasswords" class="text-center py-4">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div class="mt-2">Lade Zugänge...</div>
            </div>
            <template v-else>
              <v-col v-for="(password, index) in passwords" :key="password.id">
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="password.account"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="password.account_name"
                      label="Username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="password.password"
                      label="Password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="mr-4"
                      size="small"
                      @click="
                        passwords.splice(passwords.indexOf(password), 1);
                        deleteEntry(password.id, 'hotel_account_credentials');
                      "
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      size="small"
                      @click="
                        changeOrder(
                          password,
                          passwords,
                          'hotel_account_credentials',
                          'up',
                          index,
                        )
                      "
                      ><v-icon>mdi-arrow-up</v-icon></v-btn
                    >
                    <v-btn
                      size="small"
                      @click="
                        changeOrder(
                          password,
                          passwords,
                          'hotel_account_credentials',
                          'down',
                          index,
                        )
                      "
                      ><v-icon>mdi-arrow-down</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-col>
              <v-btn @click="addEntry('passwords', passwords, hotelObject.id)"
                >Hinzufügen</v-btn
              >
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="8">
          <v-card-title> Notfallnummern </v-card-title>
          <v-card-text>
            <div v-if="loadingNumbers" class="text-center py-4">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div class="mt-2">Lade Notfallnummern...</div>
            </div>
            <template v-else>
              <v-col v-for="(number, index) in numbers" :key="number.id">
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="number.what"
                      label="Was"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="number.who"
                      label="Wer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="number.number"
                      label="Nummer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="mr-4"
                      @click="
                        numbers.splice(numbers.indexOf(number), 1);
                        deleteEntry(number.id, 'hotel_emergency_contacts');
                      "
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      size="small"
                      @click="
                        changeOrder(
                          number,
                          numbers,
                          'hotel_emergency_contacts',
                          'up',
                          index,
                        )
                      "
                      ><v-icon>mdi-arrow-up</v-icon></v-btn
                    >
                    <v-btn
                      size="small"
                      @click="
                        changeOrder(
                          number,
                          numbers,
                          'hotel_emergency_contacts',
                          'down',
                          index,
                        )
                      "
                      ><v-icon>mdi-arrow-down</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-col>
              <v-btn @click="addEntry('numbers', numbers, hotelObject.id)"
                >Hinzufügen</v-btn
              >
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="autoSaveSnackbar" :timeout="3000" color="success">
    <v-col align="center" class="my-n2"> Automatisch Gespeichert </v-col>
  </v-snackbar>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { supabase } from "@/supabase";
import { QuillEditor } from "@vueup/vue-quill";
import Preview from "@/components/Hotels/KeyFacts/Preview.vue";

const editorOptions = ref({
  theme: "snow", // Thema für den Quill Editor
  modules: {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      ["link", "image"],
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["clean"],
    ],
  },
});

const props = defineProps({
  hotelObject: {
    type: Object,
    required: true,
  },
});

let keyFacts = ref([]);
let categories = ref([]);
let passwords = ref([]);
let numbers = ref([]);
let loading = ref(false);
let autoSaveSnackbar = ref(false);
let autoSaveInterval = ref(null);

// Loading state variables
let loadingCategories = ref(true);
let loadingPasswords = ref(true);
let loadingNumbers = ref(true);
let previewDialog = ref(false);

const addEntry = async (type, array, hotel_id) => {
  if (type == "numbers") {
    let object = { hotel_id: hotel_id, what: null, who: null, number: null };
    const response = await supabase
      .from("hotel_emergency_contacts")
      .insert(object)
      .select("*");
    object.id = response.data[0].id;
    object.created_at = response.data[0].created_at;
    array.push(object);
  } else if (type == "passwords") {
    let object = {
      hotel_id: hotel_id,
      account: null,
      account_name: null,
      password: null,
    };
    const response = await supabase
      .from("hotel_account_credentials")
      .insert(object)
      .select("*");
    object.id = response.data[0].id;
    object.created_at = response.data[0].created_at;
    array.push(object);
  }
};

const structureKeyFacts = (facts) => {
  // Get all distinct main categories.
  const distinctCategories = [...new Set(facts.map((fact) => fact.category))];
  return distinctCategories.map((cat) => {
    // All facts for the current category.
    const items = facts.filter((fact) => fact.category === cat);
    // Group items with a sub_category.
    const grouped = {};
    const normalItems = [];
    items.forEach((item) => {
      if (item.sub_category) {
        if (!grouped[item.sub_category]) {
          grouped[item.sub_category] = [];
        }
        grouped[item.sub_category].push(item);
      } else {
        normalItems.push(item);
      }
    });
    // Convert grouped sub-categories to an array.
    const groupedSubCategories = Object.entries(grouped).map(
      ([subCategory, items]) => ({
        subCategory,
        items,
      }),
    );
    return { category: cat, normalItems, groupedSubCategories };
  });
};

const deleteEntry = async (id, table) => {
  await supabase.from(table).delete().eq("id", id);
};

const saveInput = async (isAutoSave = false) => {
  loading.value = true;
  // --- Save Key Facts Using Upsert ---
  const upsertData = [];
  categories.value.forEach((category) => {
    // Normal items
    category.normalItems.forEach((item) => {
      // For items with rich content, use both value and value_blob
      if (item.type === "html") {
        upsertData.push({
          value: item.value,
          hotel_id: props.hotelObject.id,
          fact_id: item.id,
          modified_at: new Date().toISOString(),
        });
      } else {
        // For regular text fields, just use value
        upsertData.push({
          value: item.value,
          hotel_id: props.hotelObject.id,
          fact_id: item.id,
          modified_at: new Date().toISOString(),
        });
      }
    });
    // Items grouped by sub_category
    category.groupedSubCategories.forEach((group) => {
      group.items.forEach((item) => {
        if (item.type === "html") {
          upsertData.push({
            value: item.value,
            hotel_id: props.hotelObject.id,
            fact_id: item.id,
            modified_at: new Date().toISOString(),
          });
        } else {
          upsertData.push({
            value: item.value,
            hotel_id: props.hotelObject.id,
            fact_id: item.id,
            modified_at: new Date().toISOString(),
          });
        }
      });
    });
  });
  if (upsertData.length > 0) {
    const upsertResponse = await supabase
      .from("hotel_key_facts_matching")
      .upsert(upsertData);
  }

  // --- Save Passwords Using Upsert ---
  if (passwords.value.length > 0) {
    await supabase.from("hotel_account_credentials").upsert(passwords.value);
  }

  // --- Save Emergency Contacts Using Upsert ---
  if (numbers.value.length > 0) {
    await supabase.from("hotel_emergency_contacts").upsert(numbers.value);
  }
  loading.value = false;

  // Show notification if it was auto-saved
  if (isAutoSave) {
    autoSaveSnackbar.value = true;
  }
};

const changeOrder = async (
  item,
  searchTable,
  tableName,
  direction,
  itemIndex,
) => {
  let upObject = {};
  let downObject = {};

  if (direction == "up" && itemIndex > 0) {
    // Swap items in the array
    const temp = searchTable[itemIndex];
    searchTable[itemIndex] = searchTable[itemIndex - 1];
    searchTable[itemIndex - 1] = temp;

    upObject = {
      id: item.id,
      order_number: itemIndex,
    };
    downObject = {
      id: searchTable[itemIndex].id,
      order_number: itemIndex + 1,
    };
  } else if (direction == "down" && itemIndex < searchTable.length - 1) {
    // Swap items in the array
    const temp = searchTable[itemIndex];
    searchTable[itemIndex] = searchTable[itemIndex + 1];
    searchTable[itemIndex + 1] = temp;

    upObject = {
      id: searchTable[itemIndex].id,
      order_number: itemIndex,
    };
    downObject = {
      id: item.id,
      order_number: itemIndex + 1,
    };
  }

  // Update order numbers in database
  if (upObject.id && downObject.id) {
    await supabase.from(tableName).upsert([upObject, downObject]);
  }
};

onMounted(async () => {
  // Reset loading states to true
  loadingCategories.value = true;
  loadingPasswords.value = true;
  loadingNumbers.value = true;

  // Load key facts
  keyFacts.value = [];
  try {
    const response = await supabase
      .from("hotel_key_facts")
      .select("*, hotel_key_facts_matching(*)")
      .eq("hotel_key_facts_matching.hotel_id", props.hotelObject.id)
      .order("order_number", { ascending: true });

    response.data.forEach((item) => {
      item.value = item.hotel_key_facts_matching[0]?.value || null;
    });
    if (response.data) {
      categories.value = structureKeyFacts(response.data);
    }
  } catch (error) {
    console.error("Error loading hotel key facts:", error);
  } finally {
    loadingCategories.value = false;
  }

  // Load passwords
  try {
    const passwordResponse = await supabase
      .from("hotel_account_credentials")
      .select("*")
      .eq("hotel_id", props.hotelObject.id)
      .order("order_number", { ascending: true });
    passwords.value = passwordResponse.data;
  } catch (error) {
    console.error("Error loading passwords:", error);
  } finally {
    loadingPasswords.value = false;
  }

  // Load emergency numbers
  try {
    const numberResponse = await supabase
      .from("hotel_emergency_contacts")
      .select("*")
      .eq("hotel_id", props.hotelObject.id)
      .order("order_number", { ascending: true });
    numbers.value = numberResponse.data;
  } catch (error) {
    console.error("Error loading emergency numbers:", error);
  } finally {
    loadingNumbers.value = false;
  }

  // Set up auto-save interval (5 minutes = 300000 milliseconds)
  autoSaveInterval.value = setInterval(() => {
    saveInput(true);
  }, 60000);
});

// Clean up interval when component unmounts
onUnmounted(() => {
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
  }
});
</script>