<template>
  <v-card
    class="d-flex flex-column"
    :style="`height: ${isDialog ? '100vh' : '100%'}`"
    :flat="!isDialog"
  >
    <v-card-title
      :class="`d-flex align-center px-3 ${
        isDialog ? 'bg-blue-grey-darken-3 fixed-top' : ''
      }`"
    >
      <v-img
        v-if="isDialog"
        src="../../assets/bellcaptain_glocke.png"
        height="56px"
        class="mr-3"
      ></v-img>
      <span
        :style="`font-size: ${smAndUp ? '20px' : '15px'};`"
        class="mr-n10 pl-4"
      >
        Einarbeitungsliste
      </span>
      <v-spacer></v-spacer>

      <TrainingStatusChanger
        v-if="trainingHotelResultsId && trainingHotelResultsStatusId"
        :training-hotel-results-id="trainingHotelResultsId"
        :current-status-id="trainingHotelResultsStatusId"
        class="mr-2"
      />

      <v-icon
        v-if="!isTrainingComplete"
        @click="openDeletionDialog"
        class="cursor-pointer mx-4"
        color="red"
      >
        mdi-delete
      </v-icon>
      <v-icon
        v-if="isDialog"
        @click="closeTrainingForm()"
        class="cursor-pointer"
      >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-stepper alt-labels height="100%" :class="{ 'view-content': isDialog }">
        <template v-slot:default="{ next }">
          <v-stepper-header :class="{ 'fixed-stepper': isDialog }">
            <v-stepper-item title="Hinweise" value="1" editable>
            </v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Aufgaben"
              value="2"
              :editable="isTrainingStarted"
            ></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Feedback"
              value="3"
              :editable="isTrainingStarted"
            ></v-stepper-item>
          </v-stepper-header>
          <v-stepper-window>
            <v-stepper-window-item value="1">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-card elevation="3" class="ma-1">
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">{{ city }}</v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-domain</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ hotel.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-calendar</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ getTrainingDates }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-account</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ trainee?.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center"
                            >Level:</v-col
                          >
                          <v-col cols="9" xs="9" md="11">
                            {{ isSenior ? "Senior" : "Clerk" }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-row class="mt-2 px-1">
                      <v-col cols="12" class="d-flex flex-column align-start">
                        <h4>Fortschritt</h4>
                        <v-progress-linear
                          :model-value="
                            (examinationTaskProgress.completedBasic /
                              examinationTaskProgress.total) *
                            100
                          "
                          color="grey"
                          rounded
                          height="15"
                          ><span style="font-size: 12px">
                            grundlegend
                            {{ examinationTaskProgress.completedBasic }}/{{
                              examinationTaskProgress.total
                            }}</span
                          >
                        </v-progress-linear>
                        <v-progress-linear
                          :model-value="
                            (examinationTaskProgress.completedWithHelp /
                              examinationTaskProgress.total) *
                            100
                          "
                          class="my-1"
                          color="blue-grey-lighten-1"
                          rounded
                          height="15"
                          ><span style="font-size: 12px">
                            mit Hilfe
                            {{ examinationTaskProgress.completedWithHelp }}/{{
                              examinationTaskProgress.total
                            }}</span
                          >
                        </v-progress-linear>
                        <v-progress-linear
                          :model-value="
                            (examinationTaskProgress.completedWithoutHelp /
                              examinationTaskProgress.total) *
                            100
                          "
                          color="success"
                          rounded
                          height="15"
                          ><span style="font-size: 12px">
                            ohne Hilfe
                            {{
                              examinationTaskProgress.completedWithoutHelp
                            }}/{{ examinationTaskProgress.total }}</span
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2 px-1">
                      <v-col cols="12">
                        <h4>Zielsetzungen</h4>
                        <p>
                          Die unten aufgeführten Zielsetzungen gelten für eine
                          Einarbeitung ohne Vor- und Fachkenntnisse der
                          Hotellerie und des Hotels, sie sind nur eine ungefähre
                          Richtung, je nach persönlicher Entwicklung des
                          Trainees während der Einarbeitung und dessen Vorwissen
                          bzw. Fachwissens können diese variiert werden.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <h4>Tag 1</h4>
                        <p>
                          Ziel des ersten Tages ist es, dem Trainee einen
                          umfassenden Einblick über das Hotel und dessen
                          Outlets, sowie der näheren Umgebung und einen
                          Überblick über die zu verrichtenden Aufgaben zu geben.
                        </p>
                        <br />
                        <h4>Tag 2</h4>
                        <p>
                          Ziel des zweiten Tages ist es, den Trainee in das
                          Hotelsystem und in erste zu verrichtende Aufgaben
                          einzuarbeiten.
                        </p>
                        <br />
                        <h4>Tag 3</h4>
                        <p>
                          Ziel des dritten Tages ist es, den Trainee
                          weiterführend in das Hotelsystem und den zu
                          verrichtenden Aufgaben einzuarbeiten.
                        </p>
                        <br />
                        <h4>Tag 4</h4>
                        <p>
                          Ziel des vierten Tages der Einarbeitung ist es, dass
                          der Trainee selbstständig mit dem Hotelsystem arbeiten
                          kann und erste Aufgaben bereits selbstständig mit
                          Hilfestellung ausführen kann.
                        </p>
                        <br />
                        <h4>Tag 5</h4>
                        <p>
                          Ziel des letzten Tages der Einarbeitung ist es, dass
                          der Trainee alle Aufgaben ohne Hilfestellungen des
                          Trainers ausführen kann, dieser steht für eventuelle
                          Rückfragen zur Verfügung und schult bei vorhandenen
                          Fragen oder Wissenslücken nach.
                        </p>
                        <br />
                      </v-col>
                      <v-col align="center">
                        <v-btn color="green" class="mt-3" @click="next">
                          Weiter
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="2" eager>
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <ProgressTaskForm />
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="3">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-expansion-panels static :model-value="commentFieldPanel">
                      <v-expansion-panel>
                        <v-expansion-panel-title class="text-h6">
                          Trainer Feedback
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-container fluid class="py-0">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-timeline side="end">
                                  <v-timeline-item
                                    v-for="(item, index) in trainerComments"
                                    :key="index"
                                    size="small"
                                    dot-color="blue-grey-darken-3"
                                  >
                                    <template v-slot:opposite>
                                      {{
                                        getTrainingDayFromDate(item.created_at)
                                      }}
                                    </template>
                                    <v-alert>
                                      <strong>{{ item.author.name }}:</strong>
                                      {{ item.comment }}
                                    </v-alert>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  :readonly="isTrainingComplete"
                                  v-model="feedbackCommentToTrainer"
                                  placeholder="Worauf möchten Sie die folgenden Trainer hinweisen?"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" align="end">
                                <v-btn
                                  prepend-icon="mdi-timeline-text-outline"
                                  color="blue-grey-darken-1"
                                  @click="saveFeedbackCommentToTrainer()"
                                  :loading="isSubmitting"
                                  :disabled="
                                    isSubmitting ||
                                    !feedbackCommentToTrainer.trim()
                                  "
                                >
                                  Speichern
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels static :model-value="0" class="mt-5">
                      <v-expansion-panel>
                        <v-expansion-panel-title class="text-h6">
                          Feedback ans Office
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-container fluid class="py-0">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-timeline
                                  side="end"
                                  v-if="officeFeedback.length"
                                >
                                  <v-timeline-item
                                    v-for="(item, index) in officeFeedback"
                                    :key="index"
                                    size="small"
                                    dot-color="blue-grey-darken-3"
                                  >
                                    <template v-slot:opposite>
                                      {{
                                        getTrainingDayFromDate(item.created_at)
                                      }}
                                    </template>
                                    <v-alert>
                                      <strong>{{ item.author.name }}:</strong>
                                      {{ item.comment }}
                                    </v-alert>
                                  </v-timeline-item>
                                </v-timeline>
                                <v-alert
                                  v-else
                                  color="grey"
                                  class="text-center"
                                  outlined
                                >
                                  Noch kein Feedback vorhanden
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-card class="mt-5">
                      <v-card-text>
                        <div class="text-h6 ml-2">Feedbackbögen</div>
                        <v-slide-group class="mt-2">
                          <v-btn
                            v-for="(item, index) in officeFeedback.filter(
                              (item) => item.feedback_form,
                            )"
                            :key="index"
                            @click="
                              setFeedback(
                                getTrainingDayFromDate(item.created_at),
                              )
                            "
                            class="my-3 mx-1"
                            :color="
                              selectedFeedbackDay ===
                              getTrainingDayFromDate(item.created_at)
                                ? 'blue-grey'
                                : ''
                            "
                            rounded
                          >
                            {{ getTrainingDayFromDate(item.created_at) }}
                          </v-btn>
                        </v-slide-group>
                        <ProgressFeedbackForm
                          :is-embedded="true"
                          v-if="selectedFeedback"
                          class="mx-n2"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>
          </v-stepper-window>
        </template>
      </v-stepper>
    </v-card-text>
  </v-card>
  <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
    {{ snackbar.text }}
  </v-snackbar>
  <v-dialog v-model="deletionDialog.isOpen" max-width="500">
    <v-card>
      <v-card-title class="headline bg-red-darken-2">
        Löschung bestätigen
      </v-card-title>
      <v-card-text>
        <div>
          Die Einarbeitungsliste wird gelöscht.
          <br />
          <br />
          Zusätzlich werden gelöscht:
          <br />
          <strong>{{ deletionDialog.commentIds.length }}</strong> Kommentar{{
            deletionDialog.commentIds.length === 1 ? "" : "e"
          }}
          <br />
          <strong>{{ deletionDialog.progressTaskIds.length }}</strong>
          Fortschritts-Eintrag{{
            deletionDialog.progressTaskIds.length === 1 ? "" : "e"
          }}
          <br />
          <strong>{{
            officeFeedback.filter((item) => item.feedback_form).length
          }}</strong>
          Feedback{{
            officeFeedback.filter((item) => item.feedback_form).length === 1
              ? "bogen"
              : "bögen"
          }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="deletionDialog.isOpen = false">
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="deleteTrainingResults">
          Bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, reactive, watch } from "vue";
import {
  startOfToday,
  subDays,
  setHours,
  isWithinInterval,
  parse,
} from "date-fns";
import { useDisplay } from "vuetify";
import { useStore } from "vuex";
import ProgressTaskForm from "./ProgressTaskForm.vue";
import { supabase } from "@/supabase";
import { useProgressFormStore } from "@/store/useProgressFormStore";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";
import ProgressFeedbackForm from "./ProgressFeedbackForm.vue";
import TrainingStatusChanger from "./TrainingStatusChanger.vue";

const { smAndUp } = useDisplay();

const props = defineProps<{
  trainee: object;
  hotel: object;
  city: string;
  isSenior: boolean;
  isDialog: boolean;
  closeDialog?: () => void;
}>();

const vuexStore = useStore();
const store = useProgressFormStore();

const currentUser = ref(null);
const trainee = ref(props.trainee);
const hotel = ref(props.hotel);
const isSenior = ref(props.isSenior);
const city = ref(props.city);

const trainingHotelResults = ref({});
const progressStates = ref([]);

const commentFieldPanel = ref([0]);
const trainerComments = ref([]);
const officeFeedback = ref([]);
const feedbackCommentToTrainer = ref("");
const feedbackCommentToOffice = ref("");
const showOfficeMessageDialog = ref(false);

const isTrainingStarted = computed(() =>
  Boolean(trainingHotelResults?.value?.started_training_at),
);

const isTrainingComplete = computed(() =>
  Boolean(trainingHotelResults?.value?.completed_training_at),
);

const trainingHotelResultsId = computed(() => {
  return trainingHotelResults?.value?.id ?? null;
});

const trainingHotelResultsStatusId = computed(() => {
  return trainingHotelResults?.value?.status_id ?? null;
});

const isSubmitting = ref(false);

const snackbar = reactive({
  isOpen: false,
  text: "",
  color: "",
});

const deletionDialog = reactive({
  isOpen: false,
  commentIds: [],
  progressTaskIds: [],
});

const openDeletionDialog = async () => {
  try {
    const commentIds = [...trainerComments.value, ...officeFeedback.value].map(
      (comment) => comment.id,
    );

    deletionDialog.commentIds = commentIds;
    const { data, error } = await supabase
      .from("training_tasks_progress")
      .select("id")
      .eq("training_hotel_results_id", trainingHotelResults.value.id);
    deletionDialog.progressTaskIds = data?.map((progress) => progress.id) ?? [];
    deletionDialog.isOpen = true;
  } catch (error) {
    console.error("error opening deletion dialog: ", error);
    snackbar.text = "Fehler beim Laden der Daten für den Löschvorgang.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

const deleteTrainingResults = async () => {
  try {
    if (deletionDialog.commentIds.length) {
      const { error } = await supabase
        .from("training_feedback_comments")
        .delete()
        .in("id", deletionDialog.commentIds);

      if (error) {
        throw error;
      }
    }

    if (deletionDialog.progressTaskIds.length) {
      const { error } = await supabase
        .from("training_tasks_progress")
        .delete()
        .in("id", deletionDialog.progressTaskIds);

      if (error) {
        throw error;
      }
    }

    if (
      !trainingHotelResults.value.started_intermediary_at &&
      !trainingHotelResults.value.started_final_at
    ) {
      const { error } = await supabase
        .from("training_hotel_results")
        .delete()
        .eq("id", trainingHotelResults.value.id);

      if (error) {
        throw error;
      }
    } else {
      const { error } = await supabase
        .from("training_hotel_results")
        .update({
          started_training_at: null,
        })
        .eq("id", trainingHotelResults.value.id);

      if (error) {
        throw error;
      }
    }

    snackbar.text = "Einarbeitungsliste erfolgreich gelöscht.";
    snackbar.color = "success";
    snackbar.isOpen = true;
    deletionDialog.isOpen = false;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    closeTrainingForm();
  } catch (error) {
    console.error("error deleting training results: ", error);
    snackbar.text = "Fehler beim Löschen der Einarbeitungsliste.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

const closeTrainingForm = () => {
  props.closeDialog();
};

const fetchOfficeFeedback = async () => {
  if (!trainingHotelResults.value.id) return false;

  const { data: feedbackData, error: feedbackError } = await supabase
    .from("training_feedback_comments")
    .select("*, author:employees!inner(id, name)")
    .eq("training_hotel_results_id", trainingHotelResults.value.id)
    .eq("is_training_feedback", true)
    .eq("visible_to_others", false)
    .order("created_at", { ascending: true });

  if (feedbackError) {
    throw feedbackError;
  }

  return feedbackData ?? [];
};

const fetchTrainerFeedbackComments = async () => {
  if (!trainingHotelResults.value.id) {
    return [];
  }

  const { data: feedbackData, error: feedbackError } = await supabase
    .from("training_feedback_comments")
    .select("created_at, comment, id, author:employees!inner(id, name)")
    .eq("training_hotel_results_id", trainingHotelResults.value.id)
    .eq("is_training_feedback", true)
    .eq("visible_to_others", true)
    .order("created_at", { ascending: true });

  if (feedbackError) {
    throw feedbackError;
  }

  return feedbackData ?? [];
};

const saveFeedbackCommentToTrainer = async () => {
  try {
    const feedbackCommentPayload = {
      author_id: currentUser.value.id,
      training_hotel_results_id: trainingHotelResults.value.id,
      comment: feedbackCommentToTrainer.value.trim(),
      is_training_feedback: true,
      visible_to_others: true,
    };

    const { error } = await supabase
      .from("training_feedback_comments")
      .insert(feedbackCommentPayload);

    if (error) {
      throw error;
    }

    trainerComments.value.push({
      ...feedbackCommentPayload,
      created_at: new Date().toISOString(),
    });
    feedbackCommentToTrainer.value = "";
  } catch (error) {
    console.error("error saving feedback comment: ", error);
    snackbar.text =
      "Fehler beim Speichern des Kommentars. Bitte nochmal versuchen.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  } finally {
    isSubmitting.value = false;
  }
};

const initializeTaskTrainingForm = (formStructure) => {
  const filteredFormStructure = formStructure.filter(
    (category) =>
      (category.is_general && category.training_general_tasks.length > 0) ||
      (!category.is_general && category.hotel_specific_tasks.length > 0),
  );

  const today9pm = setHours(startOfToday(), 21);
  const yesterday9pm = subDays(today9pm, 1);

  filteredFormStructure
    .map((category) => {
      category.training_general_tasks
        .sort((a, b) => a.order - b.order)
        .map((task) => {
          task.todaysProgress =
            task.progress?.find((progress) => {
              const createdAt = new Date(progress.created_at);
              return isWithinInterval(createdAt, {
                start: yesterday9pm,
                end: today9pm,
              });
            }) ?? null;
          task.selected = task.todaysProgress
            ? task.todaysProgress.training_progress_state_id
            : null;
          task.is_general = true;
        });
      category.hotel_specific_tasks
        .sort((a, b) => a.order - b.order)
        .map((task) => {
          task.todaysProgress =
            task.progress?.find((progress) => {
              const createdAt = new Date(progress.created_at);
              return isWithinInterval(createdAt, {
                start: yesterday9pm,
                end: today9pm,
              });
            }) ?? null;
          task.selected = task.todaysProgress
            ? task.todaysProgress.training_progress_state_id
            : null;
          task.is_general = false;
        });
    })
    .sort((a, b) => a.order - b.order);
  store.setTaskTrainingForm(filteredFormStructure);
};

const getTrainingDayFromDate = (timestamp: string) => {
  const dateInQuestion = getFormattedShiftDate(timestamp);
  const shiftDates = store.getTrainingShiftDates;

  const dateIndex = shiftDates.findIndex((date) => date === dateInQuestion);
  if (dateIndex === -1) return dateInQuestion;
  if (dateIndex === shiftDates.length - 1)
    return `Heute (Tag ${dateIndex + 1})`;
  return `Tag ${dateIndex + 1}`;
};

function calculateTrainingShiftDates() {
  const uniqueDates = new Set();

  store.getTaskTrainingForm.forEach((category) => {
    const tasks = [
      ...category.training_general_tasks,
      ...category.hotel_specific_tasks,
    ];

    tasks.forEach((task) => {
      if (task.progress && Array.isArray(task.progress)) {
        task.progress.forEach((progressEntry) => {
          const dateStr = getFormattedShiftDate(progressEntry.created_at); // Format: dd.mm.yyyy
          uniqueDates.add(dateStr);
        });
      }
    });
  });

  // add todays date
  uniqueDates.add(getFormattedShiftDate(new Date().toISOString()));

  return Array.from(uniqueDates).sort((a, b) => {
    const dateA = parse(a, "dd.MM.yyyy", new Date());
    const dateB = parse(b, "dd.MM.yyyy", new Date());
    return dateA - dateB;
  });
}

const examinationTaskProgress = computed(() => {
  let completedBasic = 0;
  let completedWithHelp = 0;
  let completedWithoutHelp = 0;
  let total = 0;

  store.getTaskTrainingForm.forEach((category) => {
    const categoryTasks = [
      ...category.hotel_specific_tasks,
      ...category.training_general_tasks,
    ];
    categoryTasks.forEach((task) => {
      const latestProgress =
        task.selected ||
        task.progress?.[task.progress.length - 1]?.training_progress_state_id ||
        0;
      if (latestProgress >= 1) completedBasic++;
      if (latestProgress >= 2) completedWithHelp++;
      if (latestProgress === 3) completedWithoutHelp++;
      total++;
    });
  });

  return { completedBasic, completedWithHelp, completedWithoutHelp, total };
});

const getTrainingDates = computed(() => {
  const startDate = getFormattedShiftDate(
    trainingHotelResults?.value?.started_training_at ??
      new Date().toISOString(),
  );
  const shiftDates = store.getTrainingShiftDates;
  if (shiftDates.length === 1) return startDate;
  if (trainingHotelResults.value.completed_training_at)
    return `${startDate} - ${getFormattedShiftDate(
      trainingHotelResults.value.completed_training_at,
    )}`;

  return `Seit ${startDate} (Tag ${shiftDates.length})`;
});

const initializeTaskTrainingData = async () => {
  try {
    const traineeId = trainee.value.id;
    const hotelId = hotel.value.id;
    const isSeniorShift = isSenior.value;

    // get current User
    const { data: employee, error: employeeFetchingError } = await supabase
      .from("employees")
      .select("id, name")
      .eq("user_id", vuexStore.state.userInfo.id);

    currentUser.value = employee[0];

    // get existing training data
    const { data: trainingResults, error } = await supabase
      .from("training_hotel_results")
      .select("*")
      .eq("trainee_id", traineeId)
      .eq("hotel_id", hotelId)
      .eq("is_senior", isSeniorShift)
      .not("started_training_at", "is", null);

    if (error) {
      throw error;
    }

    if (trainingResults.length > 0) {
      trainingHotelResults.value = trainingResults[0];
    } else {
      const resultsInitialisation = {
        trainee_id: traineeId,
        hotel_id: hotelId,
        is_senior: isSeniorShift,
      };
      trainingHotelResults.value = resultsInitialisation;
    }

    // get progress states
    const { data: progressStatesResponse, error: progressStateError } =
      await supabase.from("training_progress_states").select("id, state");

    if (progressStateError) {
      throw progressStateError;
    }

    progressStates.value = progressStatesResponse;

    // get tasks. Get results, if already saved
    let initialFetchingQuery = supabase
      .from("hotel_task_categories")
      .select(
        `*, hotel_specific_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", progress:training_tasks_progress(*)"
            : ""
        }), training_general_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", progress:training_tasks_progress(*)"
            : ""
        })`,
      )
      .neq("id", 13) // exclude "Leistungsnachweis" category
      .eq("hotel_specific_tasks.hotel_id", hotelId)
      .is("hotel_specific_tasks.is_senior", isSeniorShift)
      .is("hotel_specific_tasks.deleted_at", null)
      .is("training_general_tasks.deleted_at", null);

    if (trainingHotelResults.value.id) {
      initialFetchingQuery
        .eq(
          "hotel_specific_tasks.training_tasks_progress.training_hotel_results_id",
          trainingHotelResults.value.id,
        )
        .eq(
          "training_general_tasks.training_tasks_progress.training_hotel_results_id",
          trainingHotelResults.value.id,
        );
      store.setTrainingResultsId(trainingHotelResults.value.id);
    }
    const { data: formStructure, error: fetchError } =
      await initialFetchingQuery;

    if (fetchError) {
      throw error;
    }

    initializeTaskTrainingForm(formStructure);
    store.setTrainingShiftDates(calculateTrainingShiftDates());
    trainerComments.value = await fetchTrainerFeedbackComments();
    officeFeedback.value = await fetchOfficeFeedback();
  } catch (error) {
    console.error("error getting training results: ", error);
    snackbar.text = "Fehler beim Laden der Daten. Bitte nochmal versuchen.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

/**  -------- Feedback Form ---------  */
const selectedFeedbackDay = ref(null);

const setFeedback = (day) => {
  if (selectedFeedbackDay.value === day) {
    selectedFeedbackDay.value = null;
  } else {
    selectedFeedbackDay.value = day;
  }
};

const selectedFeedback = computed(() => {
  const feedback = officeFeedback.value.find(
    (item) =>
      getTrainingDayFromDate(item.created_at) === selectedFeedbackDay.value,
  );
  return feedback ?? null;
});

watch(selectedFeedback, (feedback) => {
  if (feedback?.feedback_form) {
    store.setFeedbackForm(feedback.feedback_form);
  } else {
    store.setFeedbackForm({});
  }
});

onMounted(async () => {
  await initializeTaskTrainingData();
});
</script>

<style scoped>
.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.fixed-stepper {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: white;
}

.view-content {
  overflow-y: auto;
  margin-top: 170px;
  max-height: calc(100% - 170px);
}
</style>
